import React, { Component } from "react";
import { connect } from "react-redux";
import Service from "../component/Adminservice";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {

  viewAllService,
  add_service,
  view_service_by_id,
  bulk_delete_service,
  update_service_profile,
  add_upload_service,
  deleteService,
  search_service,
  CheckedService,
  getallchecked,
  allCheck,
  isCheckedId




} from "../action";
import { phone_login } from '../../../auth/actions'

export class Controller extends Component {
  render() {
    return (
      <Service {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    service: store.service,
    user: store.user,
    one_user: store.one_user,
    snackbar: store.snackbar,
    login: store.login,
    loader:store.loader,


  };
};
export const mapDispatchToProps = dispatch => {
  return {
    add_service: (org_id, service_name, duration, attachment) => {
      dispatch(add_service(org_id, service_name, duration, attachment))
    },

    add_upload_service: (org_id, service_name, duration, attachment) => {
      dispatch(add_upload_service(org_id, service_name, duration, attachment))
    },
    viewAllService: (org_id) => {
      dispatch(viewAllService(org_id))
    },
    view_service_by_id: (org_id, service_id) => {
      dispatch(view_service_by_id(org_id, service_id))
    },
    bulk_delete_service: (org_id, service_id) => {
      dispatch(bulk_delete_service(org_id, service_id))
    },
    update_service_profile: (org_id, _id, service_name, renewal_duration, attachmnet) => {
      dispatch(update_service_profile(org_id, _id, service_name, renewal_duration, attachmnet))
    },



    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    phone_login: (contact_num) => {
      dispatch(phone_login(contact_num));
    },
    deleteService: (org_id, service_id) => {
      dispatch(deleteService(org_id, service_id))
    },
 
    search_service: (service, e) => {
      dispatch(search_service(service, e));
    },

    CheckedService: (index, checked, quantity, all_category) => {
      dispatch(CheckedService(index, checked, quantity, all_category));
    },

    getallchecked: (all_category) => {
      dispatch(getallchecked(all_category));
    },

    allCheck: (checked, quantity, all_category) => {
      dispatch(allCheck(checked, quantity, all_category));
    },

    isCheckedId: (org_id, all_category) => {
      dispatch(isCheckedId(org_id, all_category));
    },



  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);