import {

  SERVICE,
  SERVICE_BY_ID,
  SEARCH_SERVICE
} from "./constant";
const initial_state = {
  service: [],
  view_service_by_id: [],
  search_service: [],
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {




    case SERVICE:
      return {
        ...state,
        service: action.payload, search_service: action.payload
      };
    case SERVICE_BY_ID:
      return {
        ...state,
        view_service_by_id: action.payload
      };



    case SEARCH_SERVICE:
      return {
        ...state,
        search_service: action.payload

      };
    default:
      return state;
  }
}
