import React, { Component } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography, DialogContent
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import "../style/Assets.css";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Badge from '@mui/material/Badge';
import edit from "../../../pages/image/edit.png";
import { addUploadAsset } from '../actions';
import Loader from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
import Clip from "../../../pages/image/paperclip-2.png"
import { TextareaAutosize } from '@mui/base';
import Skeleton from '@mui/material/Skeleton';
import Search from "../../image/vuesax-outline-search-normal.png";
import Trash from "../../image/trash.png";

export default class Assets extends Component {
    constructor(props) {
        super(props);
        this.state = {

            delete: false,
            edit: false,
            deleteall: false,

            add: false,
            addname: "",
            addduration: "",
            attachmnet: "",
            index: 0,
            assets_name: "",
            duration: "",
            editattachment: "",
            all_check: false,
            chk: false,
            isChecked: [],
            assets_id: "",

        }
    }
    componentDidMount() {
        document.body.style.backgroundColor = "#f5edff";
        this.props.viewAllAssets("64566804d10c9fcdfffdc05f")
    }
    CheckedAssets(p, index, assets) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedAssets(index, true, 1, assets)
        }
        else {
            this.props.CheckedAssets(index, false, 0, assets)
        }
    }
    AllCheckedAssets(p, assets) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheck(true, 1, assets)
        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheck(false, 0, assets)
        }

    }


    render() {
        const {
            assets,
            login,
            close_snack_bar,
            snackbar,
        } = this.props;
        if (this.props.loader.open) {
            return (
                <Grid>
                    <div style={{marginLeft: "10%", marginTop: "80px", }}>

                      
                    </div>

                    <CardContent style={{ marginLeft: "5.5%", marginRight: "1%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                {/* <h2 style={{ marginLeft: "2%" }}>Companies</h2> */}
                                <Skeleton variant="rectangular" width={150} height={40} animation="wave" style={{ margin: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginRight: "3%", marginLeft: "1%", }} />
                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >

                                        <TableCell>
                                            <Skeleton variant="rectangular" animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation="pulse" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>

                            </Table>
                        </TableContainer>

                    </CardContent>

                </Grid>
            )
        }
        else
            return (
                <Grid>
                    <div style={{ marginLeft: "10%", marginTop: "80px", }}>
                       

                    </div>


                    <CardContent style={{ marginLeft: "5.5%", marginRight: "1%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row", marginBottom: "20px" }}>
                                <Typography style={{ marginLeft: "2%", marginTop: "1%", fontSize: "27px", fontWeight: "600" }}>
                                    All Assets
                                </Typography>

                            </div>
                            <Table size="" aria-label="a dense table">
                                <TableHead className="tablehead">
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                       
                                        <TableCell style={{ textAlign: "left", fontSize: "18px", }}>Sl no</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: "18px",  }}>Assets Name</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: "18px" }}>Duration</TableCell>
                                       

                                    </TableRow>
                                </TableHead>

                                {Array.isArray(this.props.assets.search_asserts) && this.props.assets.search_asserts.map((item, index) => {
                                    return (
                                        <TableRow hover={true}>
                                            
                                            <TableCell style={{ textAlign: "left", fontSize: "13px", width: "15%" }}>{index + 1}</TableCell>
                                            <TableCell style={{ textAlign: "left", fontSize: "13px", width: "25%" }}>{item.assets_name}</TableCell>

                                            <TableCell style={{ textAlign: "left", fontSize: "13px" }}>{item.renewal_duration}</TableCell>
                                        
                                        </TableRow>

                                    )
                                })}

                            </Table>
                        </TableContainer>
                    </CardContent>
                   

                    <Loader />
                    <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    />
                </Grid >

            )
    }
}
