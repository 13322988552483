import {
  
  VIEW_ALL_PHASE,
  PHASE_DETAILS,
  SET_PHASE_ID,
  SEARCH_PROJECT_PHASE,
  SEARCH_PHASE
} from "./constant";
const initial_state = {
  phase: [],
  phase_details:[],
  phase_id:"",
  search_project_phase:[],
  search_phase:[]
 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {


    case SET_PHASE_ID:
        return {
          ...state,
          phase_id: action.payload
        };
      case VIEW_ALL_PHASE:
      return state = { ...state, phase: action.payload, search_phase:action.payload};
      case PHASE_DETAILS:
      return state = { ...state, phase_details: action.payload };
      case SEARCH_PROJECT_PHASE:
          return {
              ...state,
              search_project_phase: action.payload
  
          };




          case SEARCH_PHASE:
            return {
                ...state,
                search_phase: action.payload
    
            };
    default:
      return state;
  }
}
