import {
  
 VIEW_ALL_TASK,
    VIEW_ALL_TASK_BY_ID,
    VIEW_ALL_TASK_BY_EMP_ID,
    VIEW_ALL_TASK_BY_STATUS  ,
    SEARCH_TASK
} from "./constant";
const initial_state = {
  view_task: [],
  view_task_by_id:[],
  view_task_by_emp_id:[],
  view_task_by_status:[],
  search_task:[]

 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
      case VIEW_ALL_TASK:
        console.log("alltaskbymilestone",action.payload)
      return state = { ...state, view_task: action.payload,search_task: action.payload };
        case VIEW_ALL_TASK_BY_ID:
      return state = { ...state, view_task_by_id: action.payload };
        case VIEW_ALL_TASK_BY_EMP_ID:
      return state = { ...state, view_task_by_emp_id: action.payload };
        case VIEW_ALL_TASK_BY_STATUS:
      return state = { ...state, view_task_by_status: action.payload };

      case SEARCH_TASK:
        return state = { ...state, search_task: action.payload };

    default:
      return state;
  }
}
