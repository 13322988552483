import React, { Component } from 'react'
import { RxDotFilled } from "react-icons/rx";
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography, TextField
} from '@mui/material';
import { Table, Card } from '@mui/material/';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import "../styles/project.scss";
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};

class Project extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editproject: false,
            addFormOpen: false,
            addDeleteOpen: false,
            edit_show: false,
            edit_show_id: "",
            filter_status: "On-Going",
            comp_id: "",
            project_title: "",
            project_id: "",
            project_priority: "",
            project_status: "",
            project_department: "",
            project_type: "",
            start_date: "",
            max_hr: "",
            per_hr_cost: "",
            desc: "",
            attachment: "",
            end_date: "",


        };
    }


    componentDidMount() {
        // console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii",this.props.login.org_id);
        // this.props.view_all_assets(this.props.login.org_id);
        document.body.style.backgroundColor = "#f5edff";
        this.props.view_all_project_by_org_id("64566804d10c9fcdfffdc05f")
        this.props.view_all_status("64566804d10c9fcdfffdc05f");
        // console.log("viewall", this.props.all_project.all_project)

    }
    handleClick = () => {
        this.setState(prevState => ({
            open: !prevState.open,
        }));
    };
    handleClicks = () => {
        this.setState(prevState => ({
            opens: !prevState.opens,
        }));
    };
    handelOpen = () => {
        this.setState({
            addFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            addFormOpen: false,

        })
    }
    handleDeleteOpen = () => {
        this.setState({
            addDeleteOpen: true
        })
    }

    deleteModalOff = () => {
        this.setState({
            addDeleteOpen: false
        })
    }


    CheckedProject(p, index, all_project) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedProject(index, true, 1, all_project)
        }
        else {
            this.props.CheckedProject(index, false, 0, all_project)
        }
    }
    AllCheckedProjects(p, all_project) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheck(true, 1, all_project)
        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheck(false, 0, all_project)
        }

    }


    render() {
        const { open } = this.state;
        const {
            all_project,
            login,
            close_snack_bar,
            snackbar,
        } = this.props;
        if (this.props.loader.open) {
            return (
                <Grid>
                    <div style={{ marginLeft: "10%", marginTop: "80px", }}>

                      
                    </div>

                    <CardContent style={{ marginLeft: "5.5%", marginRight: "1%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                {/* <h2 style={{ marginLeft: "2%" }}>Companies</h2> */}
                                <Skeleton variant="rectangular" width={150} height={40} animation="wave" style={{ margin: "2%" }} />
                                </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >

                                        <TableCell>
                                            <Skeleton variant="rectangular" animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation="pulse" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>

                            </Table>
                        </TableContainer>

                    </CardContent>

                </Grid>
            )
        }
        else

            return (
                <Grid>

                    <div style={{ marginLeft: "10%", marginTop: "80px", }}>

                      

                    </div>


                    <CardContent style={{ marginLeft: "5.5%", marginRight: "1%" }}>
                        <TableContainer component={Paper}>

                            <Typography style={{ marginLeft: "2%", marginTop: "1%", fontSize: "27px", fontWeight: "600" }}>
                                Projects
                            </Typography>
                            <Divider style={{ marginLeft: "1.5%", marginRight: "1.5%" }} />


                            <div style={{ display: "flex", flexdirection: "row", justifyContent: "space-between", margin: "2%" }}>


                              
                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                      
                                        <TableCell style={{ textAlign: "left", fontSize: "13px" }}>Project Name</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: "13px" }}>Company Name</TableCell>
                                      <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Email ID </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Status</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Priority </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Estimated Hours </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Start Date </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>End Date</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}></TableCell>
                                    </TableRow>

                                </TableHead>
                                {Array.isArray(this.props.all_project.search_project) && this.props.all_project.search_project.map((item, index) => {
                                    return (
                                        <TableRow hover={true}
                                            // onMouseEnter={() => {
                                            //     this.setState({
                                            //         edit_show: true,
                                            //         edit_show_id: item._id
                                            //     })
                                            // }}
                                            // onMouseLeave={() => {
                                            //     this.setState({
                                            //         edit_show: false,
                                            //         edit_show_id: ""
                                            //     })
                                            // }} onClick={() => {

                                            // }}
                                        >
                                            {/* <TableCell style={{ fontSize: "15px" }}><Checkbox
                                                // value={this.props.assets.assets.assets_id}
                                                checked={item.checked}
                                                // {...checked===true?this.state.isChecked.push(item.assets_id):false}
                                                onClick={(p) => {
                                                    this.CheckedProject(p, index, this.props.all_project.all_project)
                                                }}
                                            /></TableCell> */}
                                            <TableCell style={{ textAlign: "left", fontSize: "15px" }} >{item.project_title}</TableCell>
                                            <TableCell style={{ textAlign: "left", fontSize: "15px" }} >{item.comp_id.name}</TableCell>
                                            {/* <TableCell style={{ textAlign: "center", fontSize: "13px" }}> <BsFillPersonFill style={{ diplay: "flex", flexDirection: "row" }} />{item.auth_person}</TableCell> */}
                                            <TableCell style={{ textAlign: "left", fontSize: "15px", color: "blue" }} >{item.comp_id.email}</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "15px" }} >
                                                <Button style={{
                                                    backgroundColor: item.status_id === 'Closed' ? 'rgba(255, 84, 84, 0.40)' : item.status_id === 'On Hold' ? 'rgba(252, 255, 84, 0.40)' : item.status_id === 'Open' ? 'rgba(84, 140, 255, 0.40)' : item.status_id === 'Pending' ? 'rgba(255, 84, 217, 0.40)' : 'rgba(82, 183, 73, 0.40)',
                                                    border: item.status_id === 'Closed' ? '0.4px solid #C91E1E' : item.status_id === 'On Hold' ? '0.4px solid #C9B81E' : item.status_id === 'Open' ? '0.4px solid #1E57C9' : item.status_id === 'Pending' ? '0.4px solid #C91EA3' : '0.4px solid #008000',
                                                    color: item.status_id === 'Closed' ? '#C91E1E' : item.status_id === 'On Hold' ? '#C9B81E' : item.status_id === 'Open' ? '#1E57C9' : item.status_id === 'Pending' ? '#C91EA3' : '#008000', borderRadius: "0.8125rem", height: "1.75rem"
                                                }}
                                                    variant="outlined" >
                                                    <RxDotFilled />
                                                    {item.status_id}
                                                </Button>
                                            </TableCell>

                                            <TableCell style={{ fontSize: "15px" } }>
                                                <Typography style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                                                    <FiberManualRecordIcon style={{
                                                        color: item.priority_id === 'High' ? 'rgba(255, 84, 84, 0.40)' : item.priority_id === 'Medium' ? 'rgba(252, 255, 84, 0.40)' : 'rgba(84, 140, 255, 0.40)',
                                                        border: item.priority_id === 'High' ? '0.4px solid #C91E1E' : item.priority_id === 'Medium' ? '0.4px solid #C9B81E' : '0.4px solid #1E57C9', borderRadius: "50px", marginRight: "5px",
                                                        fontSize: "18px"
                                                    }} />
                                                    {item.priority_id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "15px" }} >{item.max_hr !== null ? item.max_hr : "- - - - - "}hrs</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "15px" }} >{item.start_date !== null ? item.start_date : "- - - - - - - -"}</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "15px" }} >{item.end_date !== null ? item.end_date : "- - - - - - - -"}</TableCell>
                                           

                                        </TableRow>)
                                })}
                            </Table>
                        </TableContainer>

                    </CardContent>
 
                </Grid>
            )
    }
}
export default withRouter(Project);

