import {
  
    VIEW_ALL_DOC ,
    VIEW_ALL_DOC_ID 
} from "./constant";
const initial_state = {
  document: [],
  documentId:[],
 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {



      case  VIEW_ALL_DOC:
      return state = { ...state, document: action.payload };
       case  VIEW_ALL_DOC_ID:
      return state = { ...state, documentId: action.payload };

    default:
      return state;
  }
}
