import React, { Component } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
    DialogContent
} from '@mui/material';
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import "../../Assets/style/Assets.css";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Badge from '@mui/material/Badge';
import edit from "../../image/edit.png";
import { ImCross } from "react-icons/im";
import Stack from '@mui/material/Stack';
import "./Tickets.css";
import Textarea from '@mui/joy/Textarea';
import Skeleton from '@mui/material/Skeleton';
import { Footer } from 'rsuite';
import Clip from "../../../pages/image/paperclip-2.png"
import Search from "../../image/vuesax-outline-search-normal.png";
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { RxDotFilled } from "react-icons/rx";
import Trash from "../../image/trash.png";
import moment from 'moment';

export default class Tickets extends Component {
    constructor(props) {
        super(props);
        this.state = {

            delete: false,
            edit: false,

            add: false,
            addFormOpen: false,
            change: false,
            editFormOpen: false,
            addticket: false,
            addmorenotes: false,
            editticket: false,
            editnotes: false,
            deletenotes: false,
            viewticket: false,
            desc: "",
            attachment: "",
            project_name: "",
            ticketshort_Id: "",
            project_phase: "N",
            request_type: "",
            filter_status: "All Tickets",
            todate: new Date(),
            month: "0" + (new Date().getMonth() + 1),
            year: new Date().getFullYear(),
            year2: "",
            uniqueNo: Math.floor(Math.random() * (99 - 100) + 100),
            ticket_no: "",
            request: [],
            ticket_date: new Date().toJSON().split('T')[0],
            project_id: "",
            phase_id: "",
            start_date: "",
            resolve_date: "",
            resolve: false,
            status: false,
            company_shortid: "",
            comp_id: "",
            auth_person: "",



        }
    }

    handelOpen = () => {
        this.setState({
            addFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            addFormOpen: false,

        })
    }
    handelOpen1 = () => {
        this.setState({
            editFormOpen: true,

        })
    }
    handelRequest = () => {
        var newRequest = {
            desc: this.state.desc,
            request_type: this.state.request_type,
            attachment: this.state.attachment,
        }
        // this.state.request.push(newRequest)
        this.props.ticket.request.push(newRequest)
        // this.props.request(this.state.request)
    }
    modaloff1 = () => {
        this.setState({
            editFormOpen: false,

        })
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f5edff";
        // console.log(moment(this.props.ticket.ticket[0].ticket_date).isAfter(moment(this.props.ticket.ticket[0].ticket_date)))
        if (this.props.type !== "c") {
            console.log(this.props.type)
            this.props.viewAllTickets(this.props.login.org_id)
        }
        if (this.props.type === "c") {
            console.log(this.props.type)
            this.setState({ comp_id: this.props.company.company_id })
            this.props.view_company_tickets(this.props.login.org_id, this.props.company.company_id)
        }
        this.props.viewAllCompany(this.props.login.org_id);
        // this.props.viewAllRequestbByproject(this.props.all_project.project_id)
        this.setState({ year2: ((this.state.year).toString().charAt(2)) + ((this.state.year).toString().charAt(3)) })
        // console.log(this.props.ticket.search_ticket)
        // console.log(this.props)

    }

    CheckedTicket(p, index, ticket) {
        if (p.target.checked) {
            this.props.CheckedTicket(index, true, 1, ticket)
        }
        else {
            this.props.CheckedTicket(index, false, 0, ticket)
        }
    }
    AllCheckedTickets(p, ticket) {
        if (p.target.checked) {
            this.props.allCheck(true, 1, ticket)
        }
        else {
            this.props.allCheck(false, 0, ticket)
        }

    }
    render() {
        const {
            ticket,
            login,
            close_snack_bar,
            snackbar,
            all_project
        } = this.props;

        if (this.props.loader.open) {
            return (
                <Grid>
                <div style={{marginLeft: "10%", marginTop: "80px", }}>

                  
                </div>

                <CardContent style={{ marginLeft: "5.5%", marginRight: "1%" }}>
                    <TableContainer component={Paper}>
                        <div style={{ display: "flex", flexdirection: "row" }}>
                            {/* <h2 style={{ marginLeft: "2%" }}>Companies</h2> */}
                            <Skeleton variant="rectangular" width={150} height={40} animation="wave" style={{ margin: "2%" }} />
                            <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%" }} />
                            <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginRight: "3%", marginLeft: "1%", }} />
                        </div>
                        <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                            <TableHead >
                                <TableRow style={{ backgroundColor: "#E3E3E3" }} >

                                    <TableCell>
                                        <Skeleton variant="rectangular" animation="wave" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableRow>
                                <TableCell style={{ fontSize: "18px" }}>
                                    <Skeleton variant="rectangular" width={"100%"} animation="pulse" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: "18px" }}>
                                    <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: "18px" }}>
                                    <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                </TableCell>
                            </TableRow>

                        </Table>
                    </TableContainer>

                </CardContent>

            </Grid>
            )
        }
        else
            return (
                <Grid>
                     
                     <div style={{ marginLeft: "10%", marginTop: "80px", }}>
                       

                       </div>
   
   
                       <CardContent style={{ marginLeft: "5.5%", marginRight: "1%" }}>
                            <TableContainer component={Paper}>
                                <div style={{ display: "flex", flexdirection: "row", marginBottom: "20px" }}>

                                    <Typography style={{ marginLeft: "2%", marginTop: "1%", fontSize: "27px", fontWeight: "600" }}>
                                        {/* {this.state.filter_status} */}
                                        All Tickets
                                    </Typography>
                                   
                                   
                                </div>
                                <Table style={{ minWidth: 600, marginTop: "2%", }} size="" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                           
                                            <TableCell style={{  fontSize: "18px" }}> Ticket ID </TableCell>
                                            <TableCell style={{  fontSize: "18px" }}> Date </TableCell>
                                            <TableCell style={{  fontSize: "18px" }}> Resolve Date </TableCell>
                                            <TableCell style={{  fontSize: "18px" }}> Company </TableCell>
                                            <TableCell style={{  fontSize: "18px" }}> Project </TableCell>
                                            <TableCell style={{  fontSize: "18px" }}> Status </TableCell>
                                            <TableCell style={{  fontSize: "18px" }}> Action </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(this.props.ticket.search_ticket) && this.props.ticket.search_ticket.sort((a, b) => moment(a.ticket_date).isBefore(moment(b.ticket_date)) ? -1 : moment(a.ticket_date).isAfter(moment(b.ticket_date)) ? 1 : 0).map((item, index) => {
                                            return (
                                                <TableRow hover={true}>
                                                   
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }} >{item.ticketshort_Id}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{moment(item.ticket_date).format('ll')}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{moment(item.resolve_date).format('ll')}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.comp_details !== undefined && item.comp_details.name}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.project_details !== undefined && item.project_details.project_title}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>
                                                        <Button

                                                            disabled={item.status}
                                                            className='Change' style={item.status ? { backgroundColor: "#74FF67", color: 'black', cursor: 'pointer' } : { backgroundColor: "#9E9D9D", color: 'white', cursor: 'pointer' }}
                                                            // onClick={() => {
                                                            //     // this.props.updateAuthPersonStatus(e._id, !e.active_status, e.project_id)
                                                            //     this.setState({ resolve: true, _id: item._id, status: !item.status, auth_person: item.auth_person, ticketshort_Id: item.ticketshort_Id, company_shortid: item.comp_details.company_shortid })
                                                            // }}
                                                        >{item.status ? "Resolved" : "Resolve"}</Button></TableCell>
                                                    <TableCell>
                                                        <Button
                                                            style={{ border: "1px solid grey", height: 35 }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    viewticket: true,
                                                                    ticketshort_Id: item.ticketshort_Id,
                                                                    ticket_date: item.ticket_date,
                                                                    resolve_date: item.resolve_date,
                                                                    project_id: item.project_details.project_id,
                                                                    phase_id: item.phase_id,
                                                                    start_date: item.start_date,
                                                                    request: item.request,
                                                                    comp_id: item.comp_details.name
                                                                })
                                                            }}
                                                        >
                                                            <VisibilityOutlinedIcon style={{ color: "blue", cursor: 'pointer', width: 35, height: 35 }} />
                                                        </Button>

                                                        {/* <Button
                                                    style={{ border: "1px solid grey", marginLeft: "7%", height: 35 }}
                                                >
                                                    <img src={edit}
                                                        onClick={() => {
                                                            console.log(item)
                                                            this.setState({
                                                                editticket: true,
                                                                ticketshort_Id: item.ticketshort_Id,
                                                                comp_id: item.comp_details._id,
                                                                project_id: item.project_details._id,
                                                                ticket_date: item.date,
                                                                org_id: item.org_id,
                                                                ticket_id: item._id,
                                                            })
                                                            this.props.request(item.request)
                                                        }}
                                                        style={{ color: "rgba(0, 0, 0, 0.4)", width: 30, height: 30, marginTop: "1%", cursor: "pointer" }} />
                                                </Button> */}
                                                    </TableCell>
                                                </TableRow>

                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    


                  


                  <Dialog
                        open={this.state.viewticket}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1.5%" }}>
                                <Grid container>
                                    <Grid item xs={11} >
                                        <Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>
                                            Ticket Info
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ viewticket: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black", display: "flex", justifyContent: "right" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent style={{ flexDirection: "row" }}>
                                <Grid container direction={"column"} spacing={1}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Ticket No:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    fontWeight={'bolder'}>
                                                    {this.state.ticketshort_Id}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Ticket Date:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    fontWeight={'bolder'}>
                                                    {this.state.ticket_date}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Company Name:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    fontWeight={'bolder'}>
                                                    {this.state.comp_id}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Project:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    fontWeight={'bolder'}>
                                                    {this.state.project_id}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} spacing={1} mb={2}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={10}>
                                        {/* <TableContainer component={Paper}> */}
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow style={{ backgroundColor: "#E3E3E3", }} >
                                                    <TableCell style={{ fontSize: "13px" }}>S No.</TableCell>
                                                    <TableCell style={{ fontSize: "13px" }}>Request</TableCell>
                                                    <TableCell style={{ fontSize: "13px" }}>Type</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {Array.isArray(this.state.request) && this.state.request.map((e, index) => (
                                                <TableRow  >
                                                    <TableCell style={{ fontSize: "13px" }}>{index + 1}</TableCell>
                                                    <TableCell style={{ fontSize: "13px" }}>{e.desc}</TableCell>
                                                    <TableCell style={{ fontSize: "13px" }}>{e.request_type}</TableCell>
                                                </TableRow>
                                            ))}

                                        </Table>
                                        {/* </TableContainer> */}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Grid>
                    </Dialog>

                  


                </Grid >

            )
    }
}
