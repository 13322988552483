import React, { Component } from "react";
import { connect } from "react-redux";
import Companymilestone from '../../Company/component/Companymilestone';

import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  view_all_status,
  view_all_priority,
  view_all_department,
  view_tms_access,
  create_new_access

} from "../../../auth/actions";
import {
  addUploadDocuments
} from "../../Document/action"

import { add_upload_milestone, view_all_milestone, update_milestone_profile, CheckedMilestone, getallcheckedMilestone, allCheckMilestone, isCheckedIdMilestone, search_Milestone, setMilestoneId, setMilestoneName, update_milestone_status } from "../../Milestone/action"
import { add_upload_request, upload_upadate_request, view_all_request_by_project } from "../../Company/action"
import { add_testing, view_all_testing, CheckTesting, getallcheckedTesting, allCheckTesting, isCheckedTesting, searchTestign } from "../../Testing/action"

import { view_All_Employee_By_Dept_Id } from '../../Employee/action'
import { add_members, view_all_members_by_phase, CheckedPhaseMember, getallcheckedMember, allCheckPhaseMember, isCheckedIdMember, search_Members } from '../../Members/action'
import {

  isCheckedIdChange, allCheckChange, CheckedChange, search_change_request,
  viewAllRequestByProjectAndTypeChange, convertRequest,


} from "../action";


export class Controller extends Component {
  render() {
    return (
      <Companymilestone {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {

    companyassets: store.companyassets,
    request: store.request,
    snackbar: store.snackbar,
    login: store.login,
    company: store.company,
    all_project: store.all_project,
    phase: store.phase,
    milestone: store.milestone,
    testing: store.testing,
    employee: store.employee,
    members: store.members

  };
};

export const mapDispatchToProps = dispatch => {
  return {
    view_all_status: (org_id) => {
      dispatch(view_all_status(org_id));
    },
    add_upload_milestone: (project_id, phase_id, title, desc, start_date, dead_line, status, priority, max_hr, attachment) => {
      dispatch(add_upload_milestone(project_id, phase_id, title, desc, start_date, dead_line, status, priority, max_hr, attachment));
    },
    addUploadDocuments: (date, time, title, link, attach_files, access_id, milestone_id) => {
      dispatch(addUploadDocuments(date, time, title, link, attach_files, access_id, milestone_id));
    },
    view_all_priority: (org_id) => {
      dispatch(view_all_priority(org_id));
    },
    view_all_milestone: (phase_id) => {
      dispatch(view_all_milestone(phase_id));
    },
    view_all_department: (org_id) => {
      dispatch(view_all_department(org_id));
    },
    update_milestone_profile: (_id, title, desc, start_date, dead_line, status_id, priority, max_hr, attachment, phase_id, attachment1) => {
      dispatch(update_milestone_profile(_id, title, desc, start_date, dead_line, status_id, priority, max_hr, attachment, phase_id, attachment1));
    },
    CheckedMilestone: (index, checked, quantity, all_category) => {
      dispatch(CheckedMilestone(index, checked, quantity, all_category));
    },
    getallcheckedMilestone: (all_category) => {
      dispatch(getallcheckedMilestone(all_category));
    },
    allCheckMilestone: (checked, quantity, all_category) => {
      dispatch(allCheckMilestone(checked, quantity, all_category));
    },
    isCheckedIdMilestone: (all_category, phase_id) => {
      dispatch(isCheckedIdMilestone(all_category, phase_id));
    },
    search_Milestone: (milestone, e) => {
      dispatch(search_Milestone(milestone, e));
    },
    upload_upadate_request: (project_id, _id, change_title, desc, request_date, attachment, request_type, priority, attachments1) => {
      dispatch(upload_upadate_request(project_id, _id, change_title, desc, request_date, attachment, request_type, priority, attachments1))
    },
    // add_upload_addon: (phase_id, project_name, project_phase, desc, req_date, starting_date, res_date, attachment) => {
    //   dispatch(add_upload_addon(phase_id, project_name, project_phase, desc, req_date, starting_date, res_date, attachment));
    // },
    add_upload_request: (project_id, change_title, desc, request_date, attachment, request_type, priority) => {
      dispatch(add_upload_request(project_id, change_title, desc, request_date, attachment, request_type, priority))
    },
    add_testing: (test_case_type, testing_phase, phase_id) => {
      dispatch(add_testing(test_case_type, testing_phase, phase_id))
    },
    view_all_testing: (phase_id) => {
      dispatch(view_all_testing(phase_id))
    },
    // CheckedTesting: (index, checked, quantity, all_category) => {
    //   dispatch(CheckedTesting(index, checked, quantity, all_category));
    // },
    // getallchecked: (all_category) => {
    //   dispatch(getallchecked(all_category));
    // },
    // allCheck: (checked, quantity, all_category) => {
    //   dispatch(allCheck(checked, quantity, all_category));
    // },
    // isCheckedId: (phase_id, all_category) => {
    //   dispatch(isCheckedId(phase_id, all_category));
    // },


    CheckTesting: (index, checked, quantity, all_category) => {
      dispatch(CheckTesting(index, checked, quantity, all_category));
    },
    getallcheckedTesting: (all_category) => {
      dispatch(getallcheckedTesting(all_category));
    },
    allCheckTesting: (checked, quantity, all_category) => {
      dispatch(allCheckTesting(checked, quantity, all_category));
    },
    isCheckedTesting: (phase_id, all_category) => {
      dispatch(isCheckedTesting(phase_id, all_category));
    },



    CheckedChange: (index, checked, quantity, phase) => {
      dispatch(CheckedChange(index, checked, quantity, phase))
    },
    allCheckChange: (checked, quantity, all_category) => {
      dispatch(allCheckChange(checked, quantity, all_category))
    },
    isCheckedIdChange: (project_id, phase) => {
      dispatch(isCheckedIdChange(project_id, phase))
    },

    searchTestign: (testing, e) => {
      dispatch(searchTestign(testing, e))
    },

    search_change_request: (company_service, e) => {
      dispatch(search_change_request(company_service, e))
    },
    view_All_Employee_By_Dept_Id: (department_id) => {
      dispatch(view_All_Employee_By_Dept_Id(department_id))
    },

    setMilestoneId: (payload) => {
      dispatch(setMilestoneId(payload))
    },

    setMilestoneName: (payload) => {
      dispatch(setMilestoneName(payload))
    },


    add_members: (emp_id, phase_id) => {
      dispatch(add_members(emp_id, phase_id))
    },

    view_all_members_by_phase: (phase_id) => {
      dispatch(view_all_members_by_phase(phase_id))
    },
    CheckedPhaseMember: (index, checked, quantity, all_category) => {
      dispatch(CheckedPhaseMember(index, checked, quantity, all_category))
    },
    getallcheckedMember: (all_category) => {
      dispatch(getallcheckedMember(all_category))
    },
    allCheckPhaseMember: (checked, quantity, all_category) => {
      dispatch(allCheckPhaseMember(checked, quantity, all_category))
    },
    isCheckedIdMember: (phase_id, all_category) => {
      dispatch(isCheckedIdMember(phase_id, all_category))
    },

    search_Members: (members, e) => {
      dispatch(search_Members(members, e))
    },

    viewAllRequestByProjectAndTypeChange: (project_id, request_type) => {
      dispatch(viewAllRequestByProjectAndTypeChange(project_id, request_type))
    },
    convertRequest: (milestone, request, org_id) => {
      dispatch(convertRequest(milestone, request, org_id))
    },
    view_tms_access: (user_id, org_id) => {
      dispatch(view_tms_access(user_id, org_id))
    },
    create_new_access: (user_id, org_id, product) => {
      dispatch(create_new_access(user_id, org_id, product))
    },
    update_milestone_status: (id, status_id, phase_id, user_type, emp_id) => {
      dispatch(update_milestone_status(id, status_id, phase_id, user_type, emp_id))
    },
    view_all_request_by_project: (project_id) => {
      dispatch(view_all_request_by_project(project_id))
    },









  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);