import React, { Component } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
    DialogContent
} from '@mui/material';
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import "../../Assets/style/Assets.css";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Badge from '@mui/material/Badge';
import edit from "../../image/edit.png";
import { ImCross } from "react-icons/im";
import Stack from '@mui/material/Stack';
import "./Tickets.css";
import Textarea from '@mui/joy/Textarea';
import Skeleton from '@mui/material/Skeleton';
import { Footer } from 'rsuite';
import Clip from "../../../pages/image/paperclip-2.png"
import Search from "../../image/vuesax-outline-search-normal.png";
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { RxDotFilled } from "react-icons/rx";
import Trash from "../../image/trash.png";
import moment from 'moment';

export default class Tickets extends Component {
    constructor(props) {
        super(props);
        this.state = {

            delete: false,
            edit: false,

            add: false,
            addFormOpen: false,
            change: false,
            editFormOpen: false,
            addticket: false,
            addmorenotes: false,
            editticket: false,
            editnotes: false,
            deletenotes: false,
            viewticket: false,
            desc: "",
            attachment: "",
            project_name: "",
            ticketshort_Id: "",
            project_phase: "N",
            request_type: "",
            filter_status: "All Tickets",
            todate: new Date(),
            month: "0" + (new Date().getMonth() + 1),
            year: new Date().getFullYear(),
            year2: "",
            uniqueNo: Math.floor(Math.random() * (99 - 100) + 100),
            ticket_no: "",
            request: [],
            ticket_date: new Date().toJSON().split('T')[0],
            project_id: "",
            phase_id: "",
            start_date: "",
            resolve_date: "",
            resolve: false,
            status: false,
            company_shortid: "",
            comp_id: "",
            auth_person: "",
            client_response: "",
            client_remark: "",



        }
    }

    handelOpen = () => {
        this.setState({
            addFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            addFormOpen: false,

        })
    }
    handelOpen1 = () => {
        this.setState({
            editFormOpen: true,

        })
    }
    handelRequest = () => {
        var newRequest = {
            desc: this.state.desc,
            request_type: this.state.request_type,
            attachment: this.state.attachment,
        }
        // this.state.request.push(newRequest)
        this.props.ticket.request.push(newRequest)
        // this.props.request(this.state.request)
    }
    handelDeleteRequest = (req, index) => {
        console.log(req, index)
        var temp = req
        temp.splice(index, 1)
        this.props.request(temp)
    }
    modaloff1 = () => {
        this.setState({
            editFormOpen: false,

        })
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f5edff";
        // console.log(moment(this.props.ticket.ticket[0].ticket_date).isAfter(moment(this.props.ticket.ticket[0].ticket_date)))
        if (this.props.type !== "c") {
            console.log(this.props.type)
            this.props.viewAllTickets(this.props.login.org_id)
        }
        if (this.props.type === "c") {
            console.log(this.props.type)
            this.setState({ comp_id: this.props.company.company_id })
            this.props.view_company_tickets(this.props.login.org_id, this.props.company.company_id)
        }
        this.props.viewAllCompany(this.props.login.org_id);
        // this.props.viewAllRequestbByproject(this.props.all_project.project_id)
        this.setState({ year2: ((this.state.year).toString().charAt(2)) + ((this.state.year).toString().charAt(3)) })
        // console.log(this.props.ticket.search_ticket)
        // console.log(this.props)

    }

    CheckedTicket(p, index, ticket) {
        if (p.target.checked) {
            this.props.CheckedTicket(index, true, 1, ticket)
        }
        else {
            this.props.CheckedTicket(index, false, 0, ticket)
        }
    }
    AllCheckedTickets(p, ticket) {
        if (p.target.checked) {
            this.props.allCheck(true, 1, ticket)
        }
        else {
            this.props.allCheck(false, 0, ticket)
        }

    }
    render() {
        const {
            ticket,
            login,
            close_snack_bar,
            snackbar,
            all_project
        } = this.props;

        if (this.props.loader.open) {
            return (
                <Grid>
                    <div style={{ width: "98%", marginTop: "85px", }}>

                        <Paper
                            component="form"
                            style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none" }}
                        >
                            <Skeleton variant="rectangular" width={"367px"} height={"43px"} animation="wave" />
                        </Paper>

                    </div>

                    <CardContent style={{ marginLeft: "1.5%", marginRight: "1.5%" }} >
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                <Skeleton variant="rectangular" width={150} height={40} animation="wave" style={{ margin: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginRight: "3%", marginLeft: "1%", }} />
                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >

                                        <TableCell>
                                            <Skeleton variant="rectangular" animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation="pulse" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>

                            </Table>
                        </TableContainer>

                    </CardContent>

                </Grid>
            )
        }
        else
            return (
                <Grid>
                    {this.props.type !== "c" &&
                        <div style={{ marginRight: "1%", marginTop: "80px", }}>

                            <Paper component="form" style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}>
                                <IconButton
                                    type="button"
                                    style={{ p: "15px" }}
                                    aria-label="search"
                                >
                                    <img src={Search} />
                                </IconButton>
                                <InputBase
                                    style={{ ml: 5, flex: 1, fontSize: "16px", fontFamily: "Montserrat", color: "#8C8C8C", fontWeight: "300" }}
                                    placeholder="Search here"
                                    inputProps={{ "aria-label": "Search here" }}
                                    onChange={(e) => this.props.searchTicket(ticket.ticket, e.target.value)}
                                />
                            </Paper>
                        </div>}
                    {this.props.type === "c" ?
                        <Grid>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                <Typography style={{ margin: "2%", fontSize: "20px" }}>All Tickets</Typography>
                                <TextField
                                    style={{ marginTop: "2%" }}
                                    size='small'
                                    type='date'
                                    onChange={(e) => { this.props.searchTicketDate(ticket.ticket, (e.target.value.toString())) }}
                                />
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            addticket: true,
                                            ticket_no: "T" + this.state.year2 + this.state.month + "0" + "0" + (Math.floor(Math.random() * (999 - 100)) + 100)
                                        })
                                    }}
                                    style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" stle> Add Ticket</Button>
                                {this.props.login.type === "Owner" && <Button
                                    onClick={() => {
                                        this.setState({
                                            delete: true,
                                        })
                                    }}
                                    style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginLeft: "1%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" startIcon={<img src={Trash} style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                    Delete
                                </Button>
                                }
                            </div>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                        <TableCell style={{ fontSize: "13px" }}><Checkbox
                                            checked={Array.isArray(this.props.ticket.search_ticket) && this.props.ticket.search_ticket.length > 0 && this.props.ticket.search_ticket.allCheck}
                                            onClick={(p) => {
                                                {
                                                    this.AllCheckedTickets(p, this.props.ticket.ticket)
                                                }
                                            }}
                                        /></TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Ticket ID </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Date </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Resolve Date </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Company </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Project </TableCell>
                                        {/* <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Type </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Subject </TableCell> */}
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Status</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Action </TableCell>
                                        {/* <TableCell style={{ textAlign: "center", fontSize: "13px" }}> </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}> </TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(this.props.ticket.search_ticket) && this.props.ticket.search_ticket.sort((a, b) => moment(a.ticket_date).isAfter(moment(b.ticket_date)) ? -1 : moment(a.ticket_date).isBefore(moment(b.ticket_date)) ? 1 : 0).map((item, index) => {
                                        return (
                                            <TableRow hover={true}>
                                                <TableCell style={{ fontSize: "13px" }}><Checkbox
                                                    checked={item.checked}
                                                    onClick={(p) => { this.CheckedTicket(p, index, this.props.ticket.ticket) }}
                                                /></TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }} >{item.ticketshort_Id}</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{moment(item.ticket_date).format('ll')}</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{moment(item.resolve_date).format('ll')}</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.comp_details !== undefined && item.comp_details.name}</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.project_details !== undefined && item.project_details.project_title}</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }}>
                                                    <Button

                                                        disabled={item.status}
                                                        className='Change' style={item.status ? { backgroundColor: "#74FF67", color: 'black', cursor: 'pointer' } : { backgroundColor: "#9E9D9D", color: 'white', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            // this.props.updateAuthPersonStatus(e._id, !e.active_status, e.project_id)
                                                            this.setState({ resolve: true, _id: item._id, status: !item.status, auth_person: item.auth_person, ticketshort_Id: item.ticketshort_Id, company_shortid: item.comp_details.company_shortid })
                                                        }}
                                                    >{item.status ? "Resolved" : "Resolve"}</Button></TableCell>
                                                <TableCell>
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({
                                                                viewticket: true,
                                                                ticketshort_Id: item.ticketshort_Id,
                                                                ticket_date: item.ticket_date,
                                                                resolve_date: item.resolve_date,
                                                                auth_person: item.auth_person,
                                                                phase_id: item.phase_id,
                                                                start_date: item.start_date,
                                                                request: item.request,
                                                                comp_id: item.comp_details.name,
                                                                client_response: item.client_response,
                                                                client_remark: item.client_remark,
                                                            })
                                                        }}
                                                    >
                                                        <VisibilityOutlinedIcon style={{ color: "blue", cursor: 'pointer', width: 35, height: 35 }} />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>

                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Grid>
                        :
                        <CardContent style={{ marginLeft: "1.5%", marginRight: "1.5%" }}>
                            <TableContainer component={Paper}>
                                <div style={{ display: "flex", flexdirection: "row", marginBottom: "20px" }}>

                                    <Typography style={{ marginLeft: "2%", marginTop: "1%", fontSize: "27px", fontWeight: "600" }}>
                                        {this.state.filter_status}
                                    </Typography>
                                    <FormControl variant="standard" sx={{ marginTop: "1.5%", border: "none" }}>
                                        <Select

                                            size='small'
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard select"
                                            disableUnderline
                                            value={""}
                                            onChange={(e) => {
                                                // this.props.filter_employee(this.props.employee.employee, e.target.value)
                                                this.props.filterTicket(this.props.ticket.ticket, e.target.value)
                                                this.setState({ filter_status: e.target.value })
                                            }}
                                            style={{ minWidth: 0 }}
                                            IconComponent={KeyboardArrowDownIcon}
                                        >
                                            <MenuItem value={"All Tickets"}>{"All Tickets"}</MenuItem>
                                            {Array.isArray(this.props.company.company) && this.props.company.company.map((e, index) => (
                                                <MenuItem value={e.name}>{e.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button

                                        onClick={() => {
                                            this.setState({
                                                ticket_no: "T" + this.state.year2 + this.state.month + "0" + "0" + (Math.floor(Math.random() * (999 - 100)) + 100),
                                                addticket: true,
                                            })
                                        }}

                                        style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "1.5%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined"> Add Ticket</Button>
                                    {this.props.login.type === "Owner" && <Button
                                        onClick={() => {
                                            this.setState({
                                                delete: true,

                                            })

                                        }}
                                        style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "1.5%", marginRight: "3%", marginLeft: "1%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" startIcon={<DeleteOutlineOutlinedIcon style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                        Delete
                                    </Button>}
                                </div>
                                <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                            <TableCell style={{ fontSize: "13px" }}><Checkbox
                                                checked={Array.isArray(this.props.ticket.search_ticket) && this.props.ticket.search_ticket.length > 0 && this.props.ticket.search_ticket.allCheck}
                                                onClick={(p) => {
                                                    {
                                                        this.AllCheckedTickets(p, this.props.ticket.ticket)
                                                    }
                                                }}
                                            /></TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Ticket ID </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Date </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Resolve Date </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Company </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Project </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Status </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}> Action </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(this.props.ticket.search_ticket) && this.props.ticket.search_ticket.sort((a, b) => moment(a.ticket_date).isBefore(moment(b.ticket_date)) ? -1 : moment(a.ticket_date).isAfter(moment(b.ticket_date)) ? 1 : 0).map((item, index) => {
                                            return (
                                                <TableRow hover={true}>
                                                    <TableCell style={{ fontSize: "13px" }}><Checkbox
                                                        checked={item.checked}
                                                        onClick={(p) => { this.CheckedTicket(p, index, this.props.ticket.ticket) }}
                                                    /></TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }} >{item.ticketshort_Id}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{moment(item.ticket_date).format('ll')}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{moment(item.resolve_date).format('ll')}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.comp_details !== undefined && item.comp_details.name}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.project_details !== undefined && item.project_details.project_title}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>
                                                        <Button

                                                            disabled={item.status}
                                                            className='Change' style={item.status ? { backgroundColor: "#74FF67", color: 'black', cursor: 'pointer' } : { backgroundColor: "#9E9D9D", color: 'white', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                // this.props.updateAuthPersonStatus(e._id, !e.active_status, e.project_id)
                                                                this.setState({ resolve: true, _id: item._id, status: !item.status, auth_person: item.auth_person, ticketshort_Id: item.ticketshort_Id, company_shortid: item.comp_details.company_shortid })
                                                            }}
                                                        >{item.status ? "Resolved" : "Resolve"}</Button></TableCell>
                                                    <TableCell>
                                                        <Button
                                                            style={{ border: "1px solid grey", height: 35 }}
                                                            onClick={(e) => {
                                                                // console.log(e)
                                                                this.setState({
                                                                    viewticket: true,
                                                                    client_response: item.client_response,
                                                                    client_remark: item.client_remark,
                                                                    ticketshort_Id: item.ticketshort_Id,
                                                                    ticket_date: item.ticket_date,
                                                                    resolve_date: item.resolve_date,
                                                                    project_id: item.project_details.project_id,
                                                                    phase_id: item.phase_id,
                                                                    start_date: item.start_date,
                                                                    request: item.request,
                                                                    comp_id: item.comp_details.name,

                                                                })
                                                            }}
                                                        >
                                                            <VisibilityOutlinedIcon style={{ color: "blue", cursor: 'pointer', width: 35, height: 35 }} />
                                                        </Button>

                                                        {/* <Button
                                                    style={{ border: "1px solid grey", marginLeft: "7%", height: 35 }}
                                                >
                                                    <img src={edit}
                                                        onClick={() => {
                                                            console.log(item)
                                                            this.setState({
                                                                editticket: true,
                                                                ticketshort_Id: item.ticketshort_Id,
                                                                comp_id: item.comp_details._id,
                                                                project_id: item.project_details._id,
                                                                ticket_date: item.date,
                                                                org_id: item.org_id,
                                                                ticket_id: item._id,
                                                            })
                                                            this.props.request(item.request)
                                                        }}
                                                        style={{ color: "rgba(0, 0, 0, 0.4)", width: 30, height: 30, marginTop: "1%", cursor: "pointer" }} />
                                                </Button> */}
                                                    </TableCell>
                                                </TableRow>

                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    }


                    {/* ----------------------------------- Add ticket ----------------------------------- */}
                    <Dialog
                        open={this.state.addticket}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1.5%" }}>
                                <Grid container>
                                    <Grid item xs={11} >
                                        <Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>
                                            Add Tickets
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ addticket: false, comp_id: "", project_id: "" });
                                            this.props.request([])
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black", display: "flex", justifyContent: "right" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent style={{ flexDirection: "row" }}>
                                <Grid container direction={"column"} spacing={1}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Ticket No<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    color={'blue'}
                                                    fontWeight={'bolder'}
                                                // value={this.state.comp_no}
                                                // disabled
                                                >{this.state.ticket_no}</Typography>
                                            </Grid>
                                            <Grid item xs={2}></Grid>

                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Ticket Date<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    type='date'
                                                    size='small'
                                                    defaultValue={this.state.ticket_date}
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    onChange={(e) => this.setState({ ticket_date: e.target.value })}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Company Name<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    disabled={this.props.type === "c"}
                                                    select
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    value={this.props.type === "c" ? this.props.company.company_id : this.state.comp_id}
                                                    onChange={(e) => {
                                                        this.setState({ comp_id: e.target.value })
                                                        this.props.view_all_project_by_comp_id(e.target.value);
                                                    }}
                                                >
                                                    {Array.isArray(this.props.company.company) && this.props.company.company.map((e, index) => (
                                                        <MenuItem value={e._id}>{e.name}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={2}></Grid>

                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Project<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    select
                                                    value={this.state.project_id}
                                                    onChange={(e) => {
                                                        this.setState({ project_id: e.target.value })
                                                        this.props.viewAllAuthPerson(e.target.value)
                                                    }}>
                                                    {/* <MenuItem >{ }</MenuItem> */}
                                                    {Array.isArray(this.props.all_project.project_comp_id) && this.props.all_project.project_comp_id.map((e) => (
                                                        <MenuItem value={e._id}>{e.project_title}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Authorized Person <strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    select
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    onChange={(e) => {
                                                        this.setState({ auth_person: e.target.value })
                                                    }}
                                                >
                                                    {Array.isArray(this.props.authperson.authoperson) && this.props.authperson.authoperson.map((e, index) => (
                                                        <MenuItem value={e._id}>{e.auth_name}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                        </Grid>

                                    </Grid>
                                    {/* <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Start Time<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    type='date'
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    onChange={(e) => this.setState({ start_date: e.target.value })}
                                                    date
                                                />
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Dead Line<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    type='date'
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    onChange={(e) => this.setState({ resolve_date: e.target.value })}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid> */}
                                    <Grid item mt={-5}>
                                        <Grid container>
                                            <Grid item xs={9.5}></Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            addmorenotes: true,
                                                        })
                                                    }}
                                                    style={{ borderRadius: "0.3125rem", background: "rgba(50, 104, 215, 0.20)", color: "black" }} variant="contained"> + Add More Note</Button>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} spacing={1}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={10}>
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow style={{ backgroundColor: "#E3E3E3", }} >
                                                        <TableCell style={{ fontSize: "13px" }}></TableCell>
                                                        <TableCell style={{ fontSize: "13px" }}>Request</TableCell>
                                                        <TableCell style={{ fontSize: "13px" }}>Type</TableCell>
                                                        <TableCell style={{ fontSize: "13px" }}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {Array.isArray(this.props.ticket.request) && this.props.ticket.request.map((e, index) => (
                                                    <TableRow  >
                                                        <TableCell style={{ fontSize: "13px" }}>{index + 1}</TableCell>
                                                        <TableCell style={{ fontSize: "13px" }}>{e.desc}</TableCell>
                                                        <TableCell style={{ fontSize: "13px" }}>{e.request_type}</TableCell>
                                                        <TableCell style={{ fontSize: "13px" }}>
                                                            {/* <Button onClick={() => {
                                                                this.setState({
                                                                    editnotes: true,
                                                                    desc: e.desc,
                                                                    request_type: e.request_type,
                                                                    attachment: e.attachment
                                                                })
                                                            }}
                                                            >
                                                                <img style={{ width: 25 }} src={edit} />
                                                            </Button> */}
                                                            <Button onClick={() => {
                                                              this.handelDeleteRequest(this.props.ticket.request, index)
                                                            }}
                                                            >
                                                                <DeleteOutlineOutlinedIcon style={{ width: 25, color: "red" }} />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}

                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            addticket: false,
                                        })
                                        this.props.request([])
                                    }}
                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={this.state.comp_id === "" || this.state.project_id === "" || this.state.auth_person === ""}
                                    onClick={() => {
                                        this.props.addTicket(this.props.login.org_id, this.state.ticket_no, this.state.ticket_date, this.state.comp_id, this.state.project_id, this.state.start_date, this.state.resolve_date, this.props.ticket.request, this.props.type, this.state.auth_person);
                                        this.setState({
                                            addticket: false,
                                            project_id: "",
                                            auth_person: "",
                                        });
                                        this.props.request([])
                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Add Ticket
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Dialog>


                    {/* --------------------------------- View ticket ------------------------------------- */}
                    <Dialog
                        open={this.state.viewticket}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1.5%" }}>
                                <Grid container>
                                    <Grid item xs={11} >
                                        <Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>
                                            Ticket Info
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ viewticket: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black", display: "flex", justifyContent: "right" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent style={{ flexDirection: "row" }}>
                                <Grid container direction={"column"} spacing={1}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Ticket No:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    fontWeight={'bolder'}>
                                                    {this.state.ticketshort_Id}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Ticket Date:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    fontWeight={'bolder'}>
                                                    {this.state.ticket_date}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Company Name:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    fontWeight={'bolder'}>
                                                    {this.state.comp_id}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Project:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    fontWeight={'bolder'}>
                                                    {this.state.project_id}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Client Response:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    fontWeight={'bolder'}>
                                                    {this.state.client_response ? this.state.client_response : "No Response"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Client Remark:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    fontWeight={'bolder'}>
                                                    {this.state.client_remark ? this.state.client_remark : "No Remark"}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} spacing={1} mb={2}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={10}>
                                        {/* <TableContainer component={Paper}> */}
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow style={{ backgroundColor: "#E3E3E3", }} >
                                                    <TableCell style={{ fontSize: "13px" }}>S No.</TableCell>
                                                    <TableCell style={{ fontSize: "13px" }}>Request</TableCell>
                                                    <TableCell style={{ fontSize: "13px" }}>Type</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {Array.isArray(this.state.request) && this.state.request.map((e, index) => (
                                                <TableRow  >
                                                    <TableCell style={{ fontSize: "13px" }}>{index + 1}</TableCell>
                                                    <TableCell style={{ fontSize: "13px" }}>{e.desc}</TableCell>
                                                    <TableCell style={{ fontSize: "13px" }}>{e.request_type}</TableCell>
                                                </TableRow>
                                            ))}

                                        </Table>
                                        {/* </TableContainer> */}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Grid>
                    </Dialog>

                    {/* -------------------------------- Delete ticket ------------------------------------- */}
                    <Dialog
                        fullWidth
                        maxWidth="xs"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                        open={this.state.delete}
                        onClose={() => {
                            this.setState({ delete: false });
                        }}
                    >
                        <Grid textAlign={"center"} mt={3}>
                            <DialogTitle>
                                <ErrorOutlineOutlinedIcon style={{ fontSize: "66px", color: "#FF0000" }} />
                                <Typography style={{ fontSize: "20px", color: "#FF0000", fontWeight: 700 }}>Are You Sure ?</Typography >
                                <Typography mt={1} style={{ fontSize: "16px" }}>You want to Delete ?</Typography >
                            </DialogTitle>
                            <DialogActions>
                                <Grid container >
                                    <Grid item xs={6}
                                        style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black", cursor: "pointer", borderRadius: "0px 0px 0px 10px" }}
                                        onClick={() => { this.setState({ delete: false }) }}>
                                        <Typography style={{ fontWeight: 500, fontSize: "16px", marginTop: "revert" }}>Cancel</Typography >
                                    </Grid>
                                    <Grid item xs={6}
                                        style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px", cursor: "pointer", borderRadius: "0px 0px 10px 0px" }}
                                        onClick={() => {
                                            this.setState({ delete: false })
                                            this.props.isCheckedId(this.props.login.org_id, this.props.ticket.ticket)
                                        }}
                                    >
                                        <Typography style={{ fontWeight: 500, fontSize: "16px", color: "white", marginTop: "revert" }}>Confirm</Typography >
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Grid>
                    </Dialog>

                    {/* ---------------------------------- Add Notes ------------------------------------ */}
                    <Dialog
                        open={this.state.addmorenotes}
                        maxWidth="xl"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <Grid style={{ minWidth: "900px", height: "500px" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                <h4 style={{ marginLeft: "2%", marginTop: "5%" }}>Add More Notes</h4>

                                <CloseOutlinedIcon onClick={() => {
                                    this.setState({ addmorenotes: false })
                                }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />


                            </div>
                            <Grid>
                                <div style={{ display: "flex", flexDirection: "row", }}>
                                    <Grid container direction={"row"} spacing={3}>
                                        <Grid item={2}></Grid>
                                        <Grid item={2}>
                                            <Typography>Description:</Typography>
                                        </Grid>
                                        <Grid item={8}>
                                            <Textarea
                                                style={{ width: '700px', height: '250px', border: "1px solid grey", backgroundColor: "#FCFCFC" }}
                                                name="Outlined"
                                                placeholder="Type in here…"
                                                variant="outlined"
                                                onChange={(e) => this.setState({ desc: e.target.value })}
                                            >
                                            </Textarea>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ display: "flex", marginTop: "20px" }}>
                                    <Grid container direction={"row"} spacing={3}>
                                        <Grid item={2}></Grid>
                                        {/* <Grid item={2}>
                                            <Typography>Attachment:</Typography>
                                        </Grid> */}
                                        <Grid item={3}>
                                            {/* <Box style={{ width: "300px", height: "50px", border: "1px solid rgba(0, 0, 0, 0.4)", borderRadius: "7px", backgroundColor: "#FCFCFC" }}>
                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                    <img src={Clip} style={{ marginLeft: "3%", marginTop: "2%", height: "20px" }} />
                                                    <input style={{ display: "none" }} type="file" id="file"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                attachment: e.target.files[0]
                                                            })
                                                        }} />
                                                    <Typography style={{ fontSize: "18px", marginTop: "1%", overflow: "hidden", marginLeft: "1%", color: "rgba(0, 0, 0, 0.4)" }}>
                                                        <label htmlFor="file">
                                                            {this.state.attachment !== "" ? (this.state.attachment).name : "Attach"}
                                                        </label>
                                                    </Typography>
                                                </label>
                                                <input style={{ display: "none" }} type="file" id="file" />
                                            </Box> */}
                                        </Grid>
                                        <Grid item={2}>
                                            <Typography>Type:</Typography>
                                        </Grid>
                                        <Grid item={3}>
                                            <TextField
                                                style={{ width: "300px", height: "50px" }}
                                                select
                                                onChange={(e) => this.setState({ request_type: e.target.value })}>
                                                <MenuItem value={"change_request"} >
                                                    Change Request
                                                </MenuItem>
                                                <MenuItem value={"add-on"}>
                                                    Add On
                                                </MenuItem>
                                                <MenuItem value={"problem"}>
                                                    Problem
                                                </MenuItem>
                                                <MenuItem value={"access_request"}>
                                                    Access Request
                                                </MenuItem>
                                                <MenuItem value={"project_phase"}>
                                                    Phase
                                                </MenuItem>
                                            </TextField>

                                        </Grid>
                                    </Grid>
                                </div>

                                <div style={{ display: "flex", flexdirection: "row" }}>

                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                addmorenotes: false,
                                            })
                                        }}
                                        style={{ backgroundColor: "#D9D9D9", marginTop: "4%", width: "148px", color: "#8C8C8C", height: "44px", marginLeft: "35%" }} variant="contained" >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            this.setState({ addmorenotes: false })
                                            // this.props.add_upload_request(this.state.project_id, this.state.desc, this.state.request_date, this.state.attachment, this.state.request_type, this.state.request_status)
                                            this.handelRequest()
                                            // console.log("sdfghj")
                                        }}
                                        style={{ backgroundColor: "#7900FF", marginTop: "4%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                        Add Note
                                    </Button>

                                </div>
                            </Grid>
                        </Grid>
                    </Dialog>

                    {/* ---------------------------------- Edit Notes ------------------------------------ */}
                    <Dialog
                        open={this.state.editnotes}
                        maxWidth="xl"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <Grid style={{ minWidth: "900px", height: "500px" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                <h4 style={{ marginLeft: "2%", marginTop: "5%" }}>Add More Notes</h4>

                                <CloseOutlinedIcon onClick={() => {
                                    this.setState({ editnotes: false })
                                }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />


                            </div>
                            <Grid>
                                <div style={{ display: "flex", flexDirection: "row", }}>
                                    <Grid container direction={"row"} spacing={3}>
                                        <Grid item={2}></Grid>
                                        <Grid item={2}>
                                            <Typography>Description:</Typography>
                                        </Grid>
                                        <Grid item={8}>
                                            <Textarea
                                                style={{ width: '700px', height: '250px', border: "1px solid grey", backgroundColor: "#FCFCFC" }}
                                                name="Outlined"
                                                placeholder="Type in here…"
                                                variant="outlined"
                                                value={this.state.desc}
                                                onChange={(e) => this.setState({ desc: e.target.value })}
                                            >
                                            </Textarea>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                                    <Grid container direction={"row"} spacing={3}>
                                        <Grid item={2}></Grid>
                                        <Grid item={2}>
                                            <Typography>Attachment:</Typography>
                                        </Grid>
                                        <Grid item={3}>
                                            <Box style={{ width: "300px", height: "50px", border: "1px solid rgba(0, 0, 0, 0.4)", borderRadius: "7px", backgroundColor: "#FCFCFC" }}>
                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                    <img src={Clip} style={{ marginLeft: "3%", marginTop: "2%", height: "20px" }} />
                                                    <input style={{ display: "none" }} type="file" id="file"
                                                        value={this.state.attachment}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                attachment: e.target.files[0]
                                                            })
                                                        }} />
                                                    <Typography style={{ fontSize: "18px", marginTop: "1%", overflow: "hidden", marginLeft: "1%", color: "rgba(0, 0, 0, 0.4)" }}>
                                                        <label htmlFor="file">
                                                            {this.state.attachment !== "" ? (this.state.attachment).name : "Attach"}
                                                        </label>
                                                    </Typography>
                                                </label>
                                                <input style={{ display: "none" }} type="file" id="file" />
                                            </Box>
                                        </Grid>
                                        <Grid item={2}>
                                            <Typography>Type:</Typography>
                                        </Grid>
                                        <Grid item={3}>
                                            <TextField
                                                style={{ width: "300px", height: "50px" }}
                                                select
                                                value={this.state.request_type}
                                                onChange={(e) => this.setState({ request_type: e.target.value })}>
                                                <MenuItem value={"change_request"} >
                                                    Change Requset
                                                </MenuItem>
                                                <MenuItem value={"problem"}>
                                                    Problem
                                                </MenuItem>
                                                <MenuItem value={"add-on"}>
                                                    Add-On
                                                </MenuItem>
                                                <MenuItem value={"project_phase"}>
                                                    Project Phase
                                                </MenuItem>
                                            </TextField>

                                        </Grid>
                                    </Grid>
                                </div>

                                <div style={{ display: "flex", flexdirection: "row" }}>

                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                editnotes: false,
                                            })
                                        }}
                                        style={{ backgroundColor: "#D9D9D9", marginTop: "4%", width: "148px", color: "#8C8C8C", height: "44px", marginLeft: "35%" }} variant="contained" >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            this.setState({ editnotes: false })
                                            // this.setState({ request: this.state.request.splice(0, 0, desc, attachment, request_type) })
                                            this.props.request(this.state.request)
                                        }}
                                        style={{ backgroundColor: "#7900FF", marginTop: "4%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                        Update Note
                                    </Button>

                                </div>
                            </Grid>
                        </Grid>
                    </Dialog>
                    {/* -----------------------------------Resolve Modal------------------------------------ */}
                    <Dialog
                        fullWidth
                        maxWidth="xs"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                        open={this.state.resolve}
                        onClose={() => {
                            this.setState({ resolve: false });
                        }}
                    >
                        <Grid textAlign={"center"} mt={3}>
                            <DialogTitle>
                                <ErrorOutlineOutlinedIcon style={{ fontSize: "66px", color: "#FF0000" }} />
                                <Typography style={{ fontSize: "20px", color: "#FF0000", fontWeight: 700 }}>Is this Ticket is Resolve ? ?</Typography >
                                {/* <Typography mt={1} style={{ fontSize: "16px" }}>Is this Ticket is Resolve ?</Typography > */}
                            </DialogTitle>
                            <DialogActions>
                                <Grid container >
                                    <Grid item xs={6}
                                        style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black", cursor: "pointer", borderRadius: "0px 0px 0px 10px" }}
                                        onClick={() => { this.setState({ resolve: false }) }}>
                                        <Typography style={{ fontWeight: 500, fontSize: "16px", marginTop: "revert" }}>Cancel</Typography >
                                    </Grid>
                                    <Grid item xs={6}
                                        style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px", cursor: "pointer", borderRadius: "0px 0px 10px 0px" }}
                                        onClick={() => {
                                            this.setState({ resolve: false })
                                            this.props.updateTicketStatus(this.props.login.org_id, this.props.company.company_id, this.state._id, this.state.status, this.props.type, this.state.auth_person, this.state.ticketshort_Id, this.state.company_shortid)
                                        }}
                                    >
                                        <Typography style={{ fontWeight: 500, fontSize: "16px", color: "white", marginTop: "revert" }}>Confirm</Typography >
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Grid>
                    </Dialog>



                </Grid >

            )
    }
}
