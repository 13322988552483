import {
   
    VIEW_ALL_TESTING,
    SEARCH_TESTING
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";




export function add_testing(test_case_type, testing_phase, phase_id) 
{
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_testing", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                phase_id : phase_id
            },
            body: JSON.stringify({
                test_case_type : test_case_type , 
                testing_phase  : testing_phase ,

               

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_testing(phase_id));
                   
                    dispatch(unsetLoader());
                } else {
                   
                     dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function view_all_testing(phase_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_testing", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                phase_id: phase_id
            },
          
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)
                 
                    dispatch({ type: VIEW_ALL_TESTING, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    
                    dispatch({ type:  VIEW_ALL_TESTING, payload: [] })
                    
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}



export function bulk_delete_testing(isChecked, phase_id ) 
{
    return (dispatch) => {
        console.log("alldeletetesting",isChecked)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "bulk_delete_testing", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
               
            },
            body: JSON.stringify({
                testing_id : isChecked 

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_testing(phase_id));
                   
                    dispatch(unsetLoader());
                } else {
                   
                     dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function CheckTesting(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheck=false
    return {
        type: VIEW_ALL_TESTING,
        payload: payload
    }
};

export function getallcheckedTesting( all_category) {
    for(var i =0;i<=all_category.length -1;i++){
        all_category[i].checked= true;
    }
    return {
        type: VIEW_ALL_TESTING,
        
    }
};

export function allCheckTesting(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: VIEW_ALL_TESTING,
                payload: payload
            }
    }
};

export function isCheckedTesting(phase_id, all_category) {
    var count=0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= all_category.length - 1; i++) {
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
            }
            count=i;
        }
         if (count === all_category.length - 1) {
            dispatch(bulk_delete_testing(isChecked, phase_id))
        }
    }

};

export function view_testing_by_id(testing_id, phase_id) 
{
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_testing_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
               
            },
            body: JSON.stringify({
                testing_id : testing_id 

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_testing(phase_id));
                   
                    dispatch(unsetLoader());
                } else {
                   
                     dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function update_testing(test_case_type, testing_phase, _id, phase_id) 
{
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_testing", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
               
            },
            body: JSON.stringify({
                test_case_type : test_case_type, 
                testing_phase :  testing_phase,
                 _id : _id


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_testing(phase_id));
                   
                    dispatch(unsetLoader());
                } else {
                   
                     dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function update_testing_status(_id, status_id, phase_id) 
{
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_testing_status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
               
            },
            body: JSON.stringify({
            
                 _id : _id,
                 status_id : status_id


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_testing(phase_id));
                   
                    dispatch(unsetLoader());
                } else {
                   
                     dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}



export function searchTestign(testing, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_TESTING, payload: testing });
        }
        else {
            const newArray = testing.filter((el) => {
                return (el.testing_phase.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_TESTING, payload: newArray })       
        }
    };
}



