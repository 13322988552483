import React, { Component } from 'react'
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineDown } from "react-icons/ai";
import { RxDotFilled } from "react-icons/rx";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Textarea from '@mui/joy/Textarea';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography, Card, Tooltip
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ImCross } from "react-icons/im";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import "../../Company/styles/companyopen.scss";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineRounded';
import edit from "../../image/edit.png";
import Snackbar from "../../../common/snackbar/components/snackbar";
// import { Select, MenuItem } from "@mui/material";
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';
import ProjectTicket from '../../Tickets/container/AdminTicketCont';
import TableContainer from '@mui/material/TableContainer';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Search from "../../image/vuesax-outline-search-normal.png";
import Trash from "../../image/trash.png";
import paperclip from "../../image/paperclip-2.png";
import DialogContent from '@mui/material/DialogContent';
import PaidIcon from '@mui/icons-material/Paid';
import moment from 'moment/moment';

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};
class Companyopen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addnewproject: false,
            editnewproject: false,
            deletenewproject: false,


            addassets: false,
            deleteassets: false,

            addservice: false,
            deleteservice: false,


            adddTicket1: false,
            editTicket1: false,
            addmorenotes1: false,
            deleteTicket1: false,
            editmorenotes: false,


            addupload: false,
            uploaddelete: false,


            Projectopen: true,
            editinvoice: false,
            // Assetsopen: false,
            // Serviceopen: false,
            // Ticketsopen: false,
            // invoiceopen: false,



            // projectaddFormOpen: false,
            // projecteditFormOpen: false,
            // projectviewFormOpen: false,

            // addDeleteOpen: false,
            assets_id: "N",
            project_title: "",
            project_priority: "N",
            project_department: "N",
            project_name: "N",
            project_auth_person: "",
            project_maxhr: "",
            project_id: "",
            project_status: "N",
            project_type: "N",
            project_startdate: "",
            project_hrcost: "",
            project_desc: "",
            project_atch: "",
            attachmnet: "",
            project_assets: "N",
            purchase_date: "",

            all_check: false,
            chk: false,
            isChecked: [],

            assets_comp_name: "",
            assets_pursh_name: "",
            assets_name: "",
            assets_expdate: "",
            assets_desc: "",
            service_name: "N",
            related_to: "N",
            service_id: "N",
            end_date: "",
            comp_service_name: "",
            desc: "",

            title: "",

            total_amount: "",
            pending_amount: 0,
            due_date: "",
            status_id: "",
            attachment: "",
            attachment1: "",
            invoice_status: "",
            paid_amount: 0,
            comp_id: "",
            Individual: "O",
            project: "N",
            edit_show: false,
            edit_show_id: "",
            _id: '',
            test: true,
            owner_name: "",
            month: "0" + (new Date().getMonth() + 1),
            year: new Date().getFullYear(),
            year2: "",
            uniqueNo: Math.floor(Math.random() * (99 - 100) + 100),
            project_no: "",
            page: "C",
            amount: false,
            paid: 0,
        }
    }
    handelProjectopen = () => {
        this.setState({
            Projectopen: true,
            Assetsopen: false,
            Serviceopen: false,
            Ticketsopen: false,
            invoiceopen: false,
        })

    }
    handelAssetsopen = () => {
        this.setState({

            Projectopen: false,
            Assetsopen: true,
            Serviceopen: false,
            Ticketsopen: false,
            invoiceopen: false,
        })

    }
    handelServiceopen = () => {
        this.setState({
            Projectopen: false,
            Assetsopen: false,
            Serviceopen: true,
            Ticketsopen: false,
            invoiceopen: false,
        })

    }
    handelTicketsopen = () => {
        this.setState({
            Projectopen: false,
            Assetsopen: false,
            Serviceopen: false,
            Ticketsopen: true,
            invoiceopen: false
        })

    }

    handelInvoiceOpen = () => (
        this.setState({
            Projectopen: false,
            Assetsopen: false,
            Serviceopen: false,
            Ticketsopen: false,
            invoiceopen: true
        })
    )

    handelOpen = () => {
        this.setState({
            projectaddFormOpen: true,

        })
    }

    handleDeleteOpen = () => {
        this.setState({
            addDeleteOpen: true
        })
    }
    modaloff = () => {
        this.setState({
            projectaddFormOpen: false,

        })
    }
    deleteModalOff = () => {
        this.setState({
            addDeleteOpen: false
        })
    }



    handelOpen = () => {
        this.setState({
            projectaddFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            projectaddFormOpen: false,

        })
    }


    handelOpen = () => {
        this.setState({
            projecteditFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            projecteditFormOpen: false,

        })
    }


    handelOpen = () => {
        this.setState({
            projectviewFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            projectviewFormOpen: false,

        })
    }


    handelmemberOpen = () => {
        console.log("MEMBER", this.state.addTaskOpen)
        console.log("TASK", this.state.addTaskOpen)
        // console.log("MEETING", this.state.addTaskOpen)
        // console.log("DOCUMENTS", this.state.addTaskOpen)
        // console.log("REQUEST", this.state.addTaskOpen)
        // console.log("FOLLOWUP", this.state.addTaskOpen)
        // console.log("TIMESHEET", this.state.addTaskOpen)
        this.setState(prevState => ({
            addmemberopen: true,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addDocumentsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false

        }));
    }
    handelTaskOpen = () => {
        console.log("MEMBER", this.state.addTaskOpen)
        console.log("TASK", this.state.addTaskOpen)
        // console.log("MEETING", this.state.addTaskOpen)
        // console.log("DOCUMENTS", this.state.addTaskOpen)
        // console.log("REQUEST", this.state.addTaskOpen)
        // console.log("FOLLOWUP", this.state.addTaskOpen)
        // console.log("TIMESHEET", this.state.addTaskOpen)
        this.setState(prevState => ({
            addTaskOpen: true,
            addmemberopen: false,
            addMeetingsOpen: false,
            addDocumentsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false
        }));
    }
    handelmeetingOpen = () => {
        this.setState(prevState => ({
            addMeetingsOpen: true,
            addmemberopen: false,
            addTaskOpen: false,
            addDocumentsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false
        }));

    }
    handeldocumentsOpen = () => {
        this.setState({

            addDocumentsOpen: true,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false
        })


    }

    handelRequestsOpen = () => {
        this.setState({

            addRequestsOpen: true,
            addDocumentsOpen: false,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false
        })


    }

    handelfollowupOpen = () => {
        this.setState({
            addFollowupsOpen: true,
            addDocumentsOpen: false,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addRequestsOpen: false,
            addTimesheetOpen: false
        })
    }

    handelTimesheetOpen = () => {

        this.setState({
            addDocumentsOpen: false,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: true
        })

    }
    handelAuthopen = () => {
        this.setState({
            authPerson: true,
            Projectopen: false,
            Assetsopen: true,
            Serviceopen: false,
            Ticketsopen: false,
            invoiceopen: false,
        })

    }
    componentDidMount() {
        document.body.style.backgroundColor = "#f5edff";
        // console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii",this.props.login.org_id);
        // this.props.view_all_assets(this.props.login.org_id);
        // console.log("project details",this.props.company.company_id)
        // console.log(this.props.login.org_id);
        this.props.view_all_status(this.props.login.org_id);
        this.props.view_all_priority(this.props.login.org_id);
        this.props.view_all_department(this.props.login.org_id);
        this.props.view_all_project_by_comp_id(this.props.company.company_id);
        this.props.view_all_assets(this.props.login.org_id);
        this.props.viewAllService(this.props.login.org_id);
        this.props.view_all_comp_service(this.props.company.company_id);
        this.props.viewAllInvoice(this.props.company.company_id);
        this.props.view_all_comp_assets(this.props.company.company_id);
        this.setState({ year2: ((this.state.year).toString().charAt(2)) + ((this.state.year).toString().charAt(3)) })
        console.log(this.props.company.company_id)
    }
    CheckedCompanyProject(p, index, project_comp_id) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedCompanyProject(index, true, 1, project_comp_id)
        }
        else {
            this.props.CheckedCompanyProject(index, false, 0, project_comp_id)
        }
    }
    AllCheckedProject(p, project_comp_id) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckproject(true, 1, project_comp_id)

        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheckproject(false, 0, project_comp_id)
        }

    }

    CheckedInvoice(p, index, invoice) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedInvoice(index, true, 1, invoice)
        }
        else {
            this.props.CheckedInvoice(index, false, 0, invoice)
        }
    }

    AllCheckedInvoice(p, invoice) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheck(true, 1, invoice)

        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheck(false, 0, invoice)
        }

    }


    CheckedService(p, index, service) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedService(index, true, 1, service)
        }
        else {
            this.props.CheckedService(index, false, 0, service)
        }
    }

    AllCheckedService(p, service) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckk(true, 1, service)

        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheckk(false, 0, service)
        }

    }


    Checkedcompanyassets(p, index, assetscompany) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.Checkedcompanyassets(index, true, 1, assetscompany)
        }
        else {
            this.props.Checkedcompanyassets(index, false, 0, assetscompany)
        }
    }
    AllCheckedAssets(p, assetscompany) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckcompanyassets(true, 1, assetscompany)
        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheckcompanyassets(false, 0, assetscompany)
        }

    }
    render() {
        const { company, service, invoice, all_project, snackbar, close_snack_bar
        } = this.props

        if (this.props.loader.open) {
            return (
                <Grid>
                    <div style={{ marginLeft: "10%", marginTop: "80px", }}>

                      
                    </div>

                    <CardContent style={{ marginLeft: "5.5%", marginRight: "1%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                {/* <h2 style={{ marginLeft: "2%" }}>Companies</h2> */}
                                <Skeleton variant="rectangular" width={150} height={40} animation="wave" style={{ margin: "2%" }} />
                                </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >

                                        <TableCell>
                                            <Skeleton variant="rectangular" animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation="pulse" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>

                            </Table>
                        </TableContainer>

                    </CardContent>

                </Grid>
            )
        }
        else

            return (
       
               
               <Grid>

                    <div style={{ marginLeft: "10%", marginTop: "80px", }}>

                      

                    </div>


                    <CardContent style={{ marginLeft: "5.5%", marginRight: "1%" }}>
                        <TableContainer component={Paper}>

                            <Typography style={{ marginLeft: "2%", marginTop: "1%", fontSize: "27px", fontWeight: "600" }}>
                                Invoice
                            </Typography>
                            <Divider style={{ marginLeft: "1.5%", marginRight: "1.5%" }} />


                            <div style={{ display: "flex", flexdirection: "row", justifyContent: "space-between", margin: "2%" }}>


                              
                            </div>
                                  
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead >
                                            <TableRow style={{ backgroundColor: "#F1F1F1" }} >
                                                {/* <TableCell> <Checkbox
                                                    checked={this.props.invoice.invoice.allCheck}
                                                    onClick={(p) => {
                                                        {
                                                            this.AllCheckedInvoice(p, this.props.invoice.invoice)
                                                        }
                                                    }}

                                                /></TableCell> */}
                                                <TableCell>Title</TableCell>
                                                <TableCell>Due Date</TableCell>
                                                <TableCell>Pending Amount</TableCell>
                                                <TableCell>Paid Amount</TableCell>
                                                <TableCell>Total Amount</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>

                                        </TableHead>
                                        {Array.isArray(this.props.invoice.search_invoice) && this.props.invoice.search_invoice.sort((a, b) => moment(a.date).isAfter(moment(b.date)) ? -1 : moment(a.date).isBefore(moment(b.date)) ? 1 : 0).map((e, index) => {
                                            return (

                                                <TableRow hover={true}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            edit_show: true,
                                                            edit_show_id: e._id
                                                        })
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            edit_show: false,
                                                            edit_show_id: ""
                                                        })
                                                    }}>

                                                    {/* <TableCell>
                                                        <Checkbox

                                                            checked={e.checked}

                                                            onClick={(p) => { this.CheckedInvoice(p, index, this.props.invoice.invoice) }}
                                                        />
                                                    </TableCell> */}
                                                    <TableCell>{e.title}</TableCell>
                                                    <TableCell>{moment(e.due_date).format('ll')}</TableCell>
                                                    <TableCell>{e.pending_amount}</TableCell>
                                                    <TableCell>{e.paid_amount}</TableCell>
                                                    <TableCell>{e.total_amount}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "15px" }} onClick={() => { this.props.navigate('/companyphase') }}>
                                                        <Button style={{

                                                            color: e.invoice_status === 'Unpaid' ? '#C91E1E' : e.invoice_status === 'Partially Paid' ? '#C9B81E' : '#1E57C9',
                                                        }}
                                                        >
                                                            <RxDotFilled />
                                                            {e.invoice_status}
                                                        </Button>
                                                    </TableCell>



                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}  >
                                                        <Tooltip title="Update Amount">
                                                            <Button
                                                                onClick={() => {
                                                                    this.setState({
                                                                        amount: true,
                                                                        _id: e._id,
                                                                        total_amount: e.total_amount,
                                                                        pending_amount: e.pending_amount,
                                                                        paid: e.paid_amount
                                                                    })
                                                                }}
                                                            >{this.state.edit_show && this.state.edit_show_id === e._id && <PaidIcon />}</Button>
                                                        </Tooltip>
                                                        {/* <Tooltip title="Edit">
                                                            <Button onClick={() => {
                                                                this.setState({
                                                                    editinvoice: true,
                                                                    _id: e._id,
                                                                    title: e.title,
                                                                    project_name: e.project_id,
                                                                    total_amount: e.total_amount,
                                                                    paid_amount: e.paid_amount,
                                                                    pending_amount: e.pending_amount,
                                                                    due_date: e.due_date,
                                                                    project_status: e.invoice_status,
                                                                    attachment: e.attachment,

                                                                })
                                                            }}
                                                            >{this.state.edit_show && this.state.edit_show_id === e._id &&
                                                                <img style={{ color: "green", width: 25 }} src={edit} />} </Button>
                                                        </Tooltip> */}
                                                    </TableCell>



                                                </TableRow>


                                            )
                                        })}


                                    </Table>
                                    </TableContainer>

</CardContent>

</Grid>
        )
    }
}
export default withRouter(Companyopen);
