import {
  
  VIEW_ALL_MILESTONE_MEMBERS,
    VIEW_ALL_MILESTONE_MEMBERS_BY_ID,
    SEARCH_MILESTONE_MEMBERS
} from "./constant";
const initial_state = {
  milestone_members: [],
  milestone_members_by_id:[],
  search_milestone_members:[]
 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {



      case VIEW_ALL_MILESTONE_MEMBERS:
        console.log("pritiiiii",action.payload)
      return state = { ...state, milestone_members: action.payload ,search_milestone_members: action.payload };
       case VIEW_ALL_MILESTONE_MEMBERS_BY_ID:
      return state = { ...state, milestone_members_by_id: action.payload };

      case SEARCH_MILESTONE_MEMBERS:
      return state = { ...state, search_milestone_members: action.payload };

    default:
      return state;
  }
}
