import React, { Component } from 'react'
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineDown } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
// import "../../Component/Header.scss"
import { Link } from 'react-router-dom';
import "../../Company/styles/Companymilestone.scss"
import { ImCross } from "react-icons/im";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import exp from "../../image/export.png";
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';



import OutlinedInput from '@mui/material/OutlinedInput';
import {
    Box, Icon, Tooltip, Typography
} from '@mui/material';

import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog,
    DialogContentText
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Checkbox from '@mui/material/Checkbox';
import edit from "../../image/edit.png";
import paperclip from "../../image/paperclip-2.png";
import {
    DialogContent
} from '@mui/material';

import { withStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';



import Chip from '@mui/material/Chip';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Item from 'antd/es/list/Item';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Ui',
    'Redux',
    'Redux mix',
    'Figma',
    'Backend',

];

const members = [
    'abc',
    'def',
    'ghi',
    'xyz',
    'klm',

];
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };


}

function getStylesmember(member, memberName, theme, personName) {
    return {
        fontWeight:
            personName.indexOf(member) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };


}
const styles = (theme) => ({
    fontWeightRegular: {
        fontWeight: theme.typography.fontWeightRegular,
    },
    fontWeightMedium: {
        fontWeight: theme.typography.fontWeightMedium,
    },
});
export default class Companymilestone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            personName: [],
            memberName: [],

            addmilestone: false,
            editmilestone: false,
            deletemilestone: false,
            addonmod: false,
            deleteaddon: false,
            addtesting: false,
            deletetesting: false,
            addmember: false,
            deletemember: false,
            holdsssss: false,

            milestone: true,
            addonOpen: false,
            testingOpen: false,
            Memberopen: false,
            TimesheetOpen: false,
            addFormOpen: false,
            addDeleteOpen: false,
            editaddon: false,
            title: "",
            desc: "",
            start_date: "",
            dead_line: "",
            status: "",
            priority: "",
            max_hr: "",
            attachment: "",
            project_priority: "N",
            project_status: "Pending",
            project_phase: "N",
            department_employee: "N",
            project_department: "N",
            phase_id: "",
            project_name: "",
            req_date: "",
            starting_date: "",
            res_date: "",
            change_attachment: "",
            change_attachment1: "",
            test_case_type: "",
            testing_phase: "",
            edit_show: false,
            edit_show_id: "",
            attachment1: "",
            problemOpen: false,
            changeReqOpen: false,
            personName: [],
            batch_id: [],
            request: "",
            accepted: false,
            accessModal: false,
            mile_id: "",

        };
    }

    handelMilestoneOpen = () => {
        this.setState({
            milestone: true,
            addonOpen: false,
            testingOpen: false,
            Memberopen: false,
            TimesheetOpen: false,
            changeReqOpen: false,
            problemOpen: false,
        })

    }
    handelAddonOpen = () => {
        this.setState({
            milestone: false,
            addonOpen: true,
            testingOpen: false,
            Memberopen: false,
            TimesheetOpen: false,
            changeReqOpen: false,
            problemOpen: false,
        })
        this.props.viewAllRequestByProjectAndTypeChange(this.props.all_project.project_id, 'add-on')

    }
    handelTestingOpen = () => {
        this.setState({
            milestone: false,
            addonOpen: false,
            testingOpen: true,
            Memberopen: false,
            TimesheetOpen: false,
            changeReqOpen: false,
            problemOpen: false,
        })

    }
    handelMemberOpen = () => {
        this.setState({
            milestone: false,
            addonOpen: false,
            testingOpen: false,
            Memberopen: true,
            TimesheetOpen: false,
            changeReqOpen: false,
            problemOpen: false,
        })

    }
    handelTimesheetOpen = () => {
        this.setState({
            milestone: false,
            addonOpen: false,
            testingOpen: false,
            Memberopen: false,
            TimesheetOpen: true,
            changeReqOpen: false,
            problemOpen: false,

        })


    }
    handelChangeRequestopen = () => {
        this.props.viewAllRequestByProjectAndTypeChange(this.props.all_project.project_id, "change_request");
        this.setState({
            milestone: false,
            addonOpen: false,
            testingOpen: false,
            Memberopen: false,
            TimesheetOpen: false,
            changeReqOpen: true,
            problemOpen: false,
        })

    }
    handelProblemopen = () => {
        this.props.viewAllRequestByProjectAndTypeChange(this.props.all_project.project_id, "problem");
        this.setState({
            milestone: false,
            addonOpen: false,
            testingOpen: false,
            Memberopen: false,
            TimesheetOpen: false,
            problemOpen: true,
            changeReqOpen: false,
        })

    }
    handelOpen = () => {
        this.setState({
            addFormOpen: true,

        })
    }

    handleDeleteOpen = () => {
        this.setState({
            addDeleteOpen: true
        })
    }
    modaloff = () => {
        this.setState({
            addFormOpen: false,

        })
    }
    deleteModalOff = () => {
        this.setState({
            addDeleteOpen: false
        })
    }
    handlePopoverClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };
    handlePopoverCloseClick = (event) => {
        this.setState({
            anchorEl: null
        });
    };
    componentDidMount() {
        this.props.view_all_status(this.props.login.org_id);
        this.props.view_all_department(this.props.login.org_id);
        this.props.view_all_priority(this.props.login.org_id);
        this.props.view_all_milestone(this.props.phase.phase_id);
        this.props.view_all_testing(this.props.phase.phase_id);
        this.props.view_all_members_by_phase(this.props.phase.phase_id);
        this.props.view_tms_access(this.props.login.user_id, this.props.login.org_id);
        this.props.view_all_request_by_project(this.props.all_project.project_id);
        // // this.props.view_all_employee_by_dept_id(this.props.phase.phase_id);
        console.log(this.props.request.view_all_request)

    }


    CheckedMilestone(p, index, milestone) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedMilestone(index, true, 1, milestone)
        }
        else {
            this.props.CheckedMilestone(index, false, 0, milestone)
        }
    }
    AllCheckedMilestone(p, milestone) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckMilestone(true, 1, milestone)
        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheckMilestone(false, 0, milestone)
        }

    }


    // CheckedTesting(p, index, testing) {
    //     // console.log(p.target.checked);
    //     if (p.target.checked) {
    //         this.props.CheckedTesting(index, true, 1, testing)
    //     }
    //     else {
    //         this.props.CheckedTesting(index, false, 0, testing)
    //     }
    // }
    // AllCheckedTesting(p, testing) {
    //     // console.log(p.target.checked);
    //     if (p.target.checked) {
    //         // this.props.CheckedAssets(index,)
    //         this.props.allCheck(true, 1, testing)
    //     }
    //     else {
    //         // this.props.CheckedAssets(index, false, 0, assets)
    //         this.props.allCheck(false, 0, testing)
    //     }


    // }
    handleChange = (event) => {
        console.log(event.content)
        console.log(event)

        const { value } = event.target;
        this.setState({
            personName: typeof value === 'string' ? value.split(',') : value,
        });
    };

    handleChangemember = (event) => {
        const { value } = event.target;
        this.setState({
            memberName: typeof value === 'string' ? value.split(',') : value,
        });
    };


    CheckedChangeRequest(p, index, assets) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedChange(index, true, 1, assets)
        }
        else {
            this.props.CheckedChange(index, false, 0, assets)
        }
    }
    AllCheckedChangeRequest(p, assets) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckChange(true, 1, assets)

        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheckChange(false, 0, assets)
        }

    }



    CheckTesting(p, index, testing) {
        if (p.target.checked) {
            this.props.CheckTesting(index, true, 1, testing)
        }
        else {
            this.props.CheckTesting(index, false, 0, testing)
        }
    }
    AllCheckedTesting(p, testing) {
        if (p.target.checked) {
            this.props.allCheck(true, 1, testing)
        }
        else {
            this.props.allCheck(false, 0, testing)
        }

    }



    CheckedPhaseMember(p, index, members) {
        if (p.target.checked) {
            this.props.CheckedPhaseMember(index, true, 1, members)
        }
        else {
            this.props.CheckedPhaseMember(index, false, 0, members)
        }
    }
    AllCheckedMembers(p, members) {
        if (p.target.checked) {
            this.props.allCheckPhaseMember(true, 1, members)
        }
        else {
            this.props.allCheckPhaseMember(false, 0, members)
        }

    }
    addBatchId(new_value) {
        // Get the current valueArray from state
        const { batch_id } = this.state;

        // Define the new value
        const newValue = new_value;

        // Check if the new value already exists in the array
        if (batch_id.includes(newValue)) {
            // If it exists, remove it from the array
            const newValueArray = batch_id.filter((value) => value !== newValue);
            this.setState({ batch_id: newValueArray });
        } else {
            // If it doesn't exist, add it to the array
            const newValueArray = [...batch_id, newValue];
            this.setState({ batch_id: newValueArray });
        }
    };

    render() {
        const open = Boolean(this.state.anchorEl);
        const toDate = new Date();
        const id = open ? 'simple-popover' : undefined;
        const { phase, testing, all_project, company } = this.props
        const { classes } = this.props;
        const { personName, memberName } = this.state;
        return (
            <Grid style={{ marginTop: "80px" }}>
                {/* <Paper
                    component="form"
                    style={{ display: "flex", marginLeft: "73%", width: "367px", height: "43px", marginTop: "6%", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none" }}
                >
                    <IconButton
                        type="button"
                        style={{ p: "150px" }}
                        aria-label="search"
                    >
                        <SearchIcon />
                    </IconButton>
                    <InputBase
                        style={{ ml: 5, flex: 1, fontSize: "15px" }}
                        placeholder="Search here"
                        inputProps={{ "aria-label": "Search here" }}
                        onChange={e => {
                            this.props.search_Milestone(this.props.milestone.milestone, e.target.value);
                            this.props.searchTestign(this.props.testing.testing, e.target.value);
                            this.props.search_change_request(this.props.company.change_request, e.target.value);
                            this.props.search_Members(this.props.members.members, e.target.value)

                        }}
                    />
                </Paper> */}


                <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between", marginLeft: "2%", marginRight: "1%" }}>
                    {this.props.login.type === "Owner" || this.props.login.type === "AuthPerson" && <div style={{ display: "flex", flexDirection: "row", }}>
                        <Link style={{ textDecoration: "none" }} to="/company">
                            <Button style={{ color: "#7A7E86" }}>
                                Company
                            </Button>
                        </Link>
                        <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
                        <Link style={{ textDecoration: "none" }} to="/companyopen">
                            <Button style={{ color: "#7A7E86" }}>
                                Projects
                            </Button>
                        </Link>
                        <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
                        <Link style={{ textDecoration: "none" }} to="/companyphase">
                            <Button style={{ color: "#7A7E86" }}>
                                Phase
                            </Button>
                        </Link>
                        <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
                        <Link style={{ textDecoration: "none" }} to="/Companymilestone">
                            <Button style={{ color: "#7A7E86" }}>
                                Milestone
                            </Button>
                        </Link>




                    </div>}
                    <div>
                        <Paper
                            component="form"
                            style={{ width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}
                        >
                            <IconButton
                                type="button"
                                style={{ p: "150px" }}
                                aria-label="search"
                            >
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                style={{ ml: 5, flex: 1, fontSize: "15px" }}
                                placeholder="Search here"
                                inputProps={{ "aria-label": "Search here" }}
                                onChange={e => {
                                    this.props.search_Milestone(this.props.milestone.milestone, e.target.value);
                                    this.props.searchTestign(this.props.testing.testing, e.target.value);
                                    this.props.search_change_request(this.props.company.change_request, e.target.value);
                                    this.props.search_Members(this.props.members.members, e.target.value)

                                }}
                            />
                        </Paper>
                    </div>
                </div>


                <div className="companyMileStone">

                    <div style={{ backgroundColor: "white" }} className="left">
                        <div className="title">
                            <h2>
                                {this.props.company.title}

                            </h2>
                            {/* <p>
                                created by  <span>
                                    sudipta
                                </span>  on 9 jun 2023 at 9 pm
                            </p> */}
                        </div>
                        <div className="list">
                            <ul>
                                <li style={this.state.milestone ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelMilestoneOpen}>Milestone </li>
                                <li style={this.state.changeReqOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelChangeRequestopen}>Change Request</li>
                                <li style={this.state.problemOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelProblemopen}>Problems</li>
                                <li style={this.state.addonOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelAddonOpen}>Add on</li>
                                <li style={this.state.Memberopen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelMemberOpen}>Member</li>
                                <li style={this.state.testingOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelTestingOpen}>Testing</li>
                                {this.props.login.type !== "AuthPerson" && <li style={this.state.TimesheetOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelTimesheetOpen}>Timesheet</li>}
                            </ul>
                        </div>
                        {/* Milestone */}
                        {
                            this.state.milestone &&
                            <>
                                <div className="header">
                                    <div className="title">

                                        All Milestone
                                    </div>
                                    {this.props.login.type !== "AuthPerson" && <div className="button">
                                        <button className="add-btn"

                                            onClick={() => {
                                                this.setState({
                                                    addmilestone: true,

                                                })
                                            }}

                                        >
                                            Add  MileStone
                                        </button>
                                        <button className="del-btn"

                                            onClick={() => {
                                                this.setState({
                                                    deletemilestone: true,

                                                })
                                            }}

                                        >
                                            <MdDeleteOutline className='del-icon' /> Delete
                                        </button>
                                    </div>}
                                </div>
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <th>
                                                {this.props.login.type !== "AuthPerson" && <Checkbox
                                                    checked={this.props.milestone.milestone.allCheckMilestone}
                                                    onClick={(p) => {
                                                        {
                                                            this.AllCheckedMilestone(p, this.props.milestone.milestone)
                                                        }
                                                    }}
                                                />}
                                            </th>
                                            <th> Milestone  </th>
                                            <th>Start Date</th>
                                            <th>Priority</th>
                                            <th>Status</th>
                                            <th>Maximum Hours</th>
                                            <th>Deadline</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(this.props.milestone.search_milestone) && this.props.milestone.search_milestone.map((e, index) => (

                                            <tr
                                                hover={true}
                                                onMouseEnter={() => {
                                                    this.setState({
                                                        edit_show: true,
                                                        edit_show_id: e._id
                                                    })
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        edit_show: false,
                                                        edit_show_id: ""
                                                    })
                                                }}
                                            >
                                                <td>
                                                    {this.props.login.type !== "AuthPerson" && <Checkbox
                                                        // value={this.props.assets.assets.assets_id}
                                                        checked={e.checked}
                                                        // {...checked===true?this.state.isChecked.push(item.assets_id):false}
                                                        onClick={(p) => { this.CheckedMilestone(p, index, this.props.milestone.milestone) }}
                                                    />}
                                                </td>
                                                <td>{e.title}</td>
                                                <td>{e.start_date}</td>
                                                <td>
                                                    {e.priority}
                                                </td>

                                                <td>

                                                    <Link to="/Companymemberpage">
                                                        <Button style={{ backgroundColor: "lightblue", borderRadius: "20px", color: "black", width: "100px" }} variant="outlined"
                                                            onClick={() => {
                                                                this.props.setMilestoneId(e._id);
                                                                this.props.setMilestoneName(e.title);
                                                            }}
                                                        >
                                                            <RxDotFilled />
                                                            {e.status}
                                                        </Button>
                                                    </Link>

                                                </td>

                                                <td>  {e.max_hr}</td>

                                                <td> {e.dead_line}</td>
                                                <td style={{ display: "flex", flexDirection: "row" }}>
                                                    <IconButton
                                                        // style={{ backgroundColor: "#81FF7E", width: "5px", border: "2px solid #038300" }}
                                                        style={{ width: 45, }}
                                                        onClick={() => {
                                                            this.setState({
                                                                editmilestone: true,
                                                                _id: e._id,
                                                                title: e.title,
                                                                desc: e.desc,
                                                                start_date: e.start_date,
                                                                dead_line: e.dead_line,
                                                                project_status: e.status,
                                                                project_priority: e.priority,
                                                                max_hr: e.max_hr,
                                                                attachment: e.attachment,
                                                                // editattachment: e.attachmnet.substring(8, 16)
                                                            })
                                                        }}>
                                                        {this.state.edit_show && this.state.edit_show_id === e._id &&
                                                            <img style={{ color: "green", width: 25 }} src={edit} />}
                                                    </IconButton>
                                                    <IconButton
                                                        style={{ width: 45, }}
                                                        onClick={() => {
                                                            this.setState({
                                                                holdsssss: true,


                                                            })
                                                        }}
                                                    >{this.state.edit_show && this.state.edit_show_id === e._id &&
                                                        <HourglassEmptyRoundedIcon style={{ color: "red" }} />} </IconButton>
                                                    <IconButton
                                                        style={{ width: 45, }}
                                                        onClick={(event) => {
                                                            this.handlePopoverClick(event)
                                                            this.setState({ mile_id: e._id })

                                                        }}
                                                    >
                                                        <MoreHorizIcon style={{ color: "blue" }} /></IconButton>
                                                    <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={this.state.anchorEl}
                                                        onClose={() => { this.handlePopoverCloseClick() }}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((item) => (
                                                            <MenuItem value={item.name}
                                                                onClick={(e1) => {
                                                                    this.props.update_milestone_status(this.state.mile_id, item.name, this.props.phase.phase_id, this.props.login.type, this.props.login.user_id)
                                                                    this.handlePopoverCloseClick()
                                                                }}
                                                            >{item.name}</MenuItem>
                                                        ))}

                                                    </Popover>
                                                </td>

                                            </tr>

                                        ))}

                                    </tbody>

                                </table>




                                {/*add milestone  dialog */}


                                <Dialog
                                    open={this.state.addmilestone}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >
                                    <Grid style={{ minWidth: "1000px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid black" }}>

                                            <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>Add Milestone</h4>

                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ addmilestone: false })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>


                                        <div style={{ display: "flex", flexDirection: "row", }}>

                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                Title <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "15%", marginTop: "4%" },
                                            }}>
                                                <input type="text" placeholder='ABC' style={{ width: "40rem", height: "2rem", borderRadius: "5px", border: "1px solid #AFAFFF" }}
                                                    onChange={(e) => this.setState({ title: e.target.value })}
                                                />

                                            </FormControl>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                Description<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <Typography style={{ marginLeft: "5%" }}>
                                                <textarea style={{ borderRadius: "8px", border: "1px solid gray" }} name="" id="" cols="84" rows="7"
                                                    onChange={(e) => this.setState({ desc: e.target.value })}
                                                ></textarea>
                                            </Typography>

                                        </div>


                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                Start Date<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: "15rem", height: "2.5rem", borderRadius: "5px", borderColor: "#AFAFFF", marginLeft: "5rem" }}
                                                    onChange={(e) => this.setState({ start_date: e.target.value })}
                                                />
                                            </Typography>

                                            <Typography style={{ fontSize: "15px", marginLeft: "6%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                Deadline<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: "15rem", height: "2.5rem", borderRadius: "5px", borderColor: "#AFAFFF", marginLeft: "1rem" }}
                                                    onChange={(e) => this.setState({ dead_line: e.target.value })}
                                                />
                                            </Typography>
                                        </div>




                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                Status<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <TextField
                                                disabled={true}
                                                defaultValue={"Pending"}
                                                style={{ width: '240px', height: '2vh', marginLeft: "10%" }}
                                                id="outlined-select-currency-native"
                                                select
                                                // value={this.state.project_status}
                                                onChange={(e) => {
                                                    this.setState({ project_status: e.target.value })
                                                    // console.log(all_category)
                                                }}

                                            >
                                                <MenuItem value={"N"}>{"Select Status"}</MenuItem>
                                                {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                                    <MenuItem value={e.name}>{e.name}</MenuItem>
                                                ))}

                                            </TextField>


                                            <Typography style={{ fontSize: "15px", marginLeft: "7%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                Priority<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <TextField
                                                style={{ width: '240px', height: '2vh', marginLeft: "2%" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.project_priority}
                                                onChange={(e) => {
                                                    this.setState({ project_priority: e.target.value })
                                                    // console.log(all_category)
                                                }}
                                            >
                                                <MenuItem value={"N"}>{"Select Priority"}</MenuItem>
                                                {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e) => (
                                                    <MenuItem value={e.name}>{e.name}</MenuItem>
                                                ))}


                                            </TextField>


                                        </div>





                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                maximum Hours<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="number" style={{ width: "15rem", height: "2.5rem", borderRadius: "5px", borderColor: "#AFAFFF", marginLeft: "14%" }}
                                                    onChange={(e) => this.setState({ max_hr: e.target.value })}
                                                />
                                            </Typography>


                                        </div>
                                        <Typography style={{ fontSize: "15px", display: "flex", flexDirection: "row", marginTop: "2%", marginLeft: "8%" }}>
                                            Attachment:


                                            <Box style={{ width: '638px', height: '103px', marginLeft: "8%", border: "2px solid   #C0ADAD", backgroundColor: "#FCFCFC" }}>


                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                    <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                    <Typography style={{ fontSize: "18px", marginTop: "3%", overflow: "hidden" }}>
                                                        {this.state.attachment !== "" ? (this.state.attachment).name : "Attachment File"}

                                                    </Typography>

                                                </label>

                                                <input style={{ display: "none" }} type="file" id="file"
                                                    onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }}
                                                />

                                            </Box>

                                        </Typography>




                                        <div style={{ display: "flex", flexDirection: "row", gap: '2rem', justifyContent: "center", marginTop: "2rem" }}>
                                            <div><button onClick={() => {
                                                this.setState({ addmilestone: false })
                                            }} style={{ minWidth: "7rem", height: "3rem", border: "none", borderRadius: "5px", backgroundColor: "#D9D9D9", color: "#8C8C8C", fontSize: "14px" }}>Cancel</button></div>
                                            <div><button style={{ minWidth: "7rem", height: "3rem", border: "none", borderRadius: "5px", backgroundColor: "#7900FF", color: "#FFFFFF", fontSize: "14px" }}
                                                onClick={() => {
                                                    this.setState({ addmilestone: false, title: "", desc: "", start_date: "", dead_line: "", project_status: "", project_priority: "", max_hr: "", attachment: "" });

                                                    this.props.add_upload_milestone(this.props.all_project.project_id, this.props.phase.phase_id, this.state.title, this.state.desc, this.state.start_date, this.state.dead_line, this.state.project_status, this.state.project_priority, this.state.max_hr, this.state.attachment)

                                                }}
                                            >Save</button></div>
                                        </div>

                                        <div style={{ marginBottom: "3%" }}>
                                        </div>

                                    </Grid>

                                </Dialog>



                                {/* edit milestone modal start */}



                                <Dialog
                                    open={this.state.editmilestone}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ marginTop: "-5%" }}
                                >
                                    <Grid style={{ minWidth: "1000px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid black" }}>

                                            <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>Add Milestone</h4>

                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ editmilestone: false })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>


                                        <div style={{ display: "flex", flexDirection: "row", }}>

                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                Title <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "15%", marginTop: "4%" },
                                            }}>
                                                <input type="text" placeholder='ABC' style={{ width: "40rem", height: "2rem", borderRadius: "5px", border: "1px solid #AFAFFF" }}
                                                    value={this.state.title}
                                                    onChange={(text) => this.setState({ title: text.target.value })}
                                                />

                                            </FormControl>

                                        </div>




                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                Description<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <Typography style={{ marginLeft: "5%" }}>
                                                <textarea style={{ borderRadius: "8px", border: "1px solid gray" }} name="" id="" cols="84" rows="7"
                                                    value={this.state.desc}
                                                    onChange={(text) => this.setState({ desc: text.target.value })}
                                                ></textarea>
                                            </Typography>

                                        </div>


                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                Start Date<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: "15rem", height: "2.5rem", borderRadius: "5px", borderColor: "#AFAFFF", marginLeft: "5rem" }}
                                                    value={this.state.start_date}
                                                    onChange={(text) => this.setState({ start_date: text.target.value })}
                                                />
                                            </Typography>

                                            <Typography style={{ fontSize: "15px", marginLeft: "6%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                Deadline<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: "15rem", height: "2.5rem", borderRadius: "5px", borderColor: "#AFAFFF", marginLeft: "1rem" }}
                                                    value={this.state.dead_line}
                                                    onChange={(text) => this.setState({ dead_line: text.target.value })}
                                                />
                                            </Typography>
                                        </div>




                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                Status<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <TextField
                                                style={{ width: '240px', height: '2vh', marginLeft: "10%" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.project_status}
                                                onChange={(e) => {
                                                    this.setState({ project_status: e.target.value })
                                                    // console.log(all_category)
                                                }}

                                            >
                                                <MenuItem value={"N"}>{"Select Status"}</MenuItem>
                                                {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                                    <MenuItem value={e.name}>{e.name}</MenuItem>
                                                ))}

                                            </TextField>


                                            <Typography style={{ fontSize: "15px", marginLeft: "7%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                Priority<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <TextField
                                                style={{ width: '240px', height: '2vh', marginLeft: "2%" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.project_priority}
                                                onChange={(e) => {
                                                    this.setState({ project_priority: e.target.value })
                                                    // console.log(all_category)
                                                }}
                                            >
                                                <MenuItem value={"N"}>{"Select Priority"}</MenuItem>
                                                {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e) => (
                                                    <MenuItem value={e.name}>{e.name}</MenuItem>
                                                ))}


                                            </TextField>


                                        </div>





                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                maximum Hours<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="number" style={{ width: "15rem", height: "2.5rem", borderRadius: "5px", borderColor: "#AFAFFF", marginLeft: "14%" }}
                                                    value={this.state.max_hr}
                                                    onChange={(text) => this.setState({ max_hr: text.target.value })}
                                                />
                                            </Typography>


                                        </div>








                                        <Typography style={{ fontSize: "15px", display: "flex", flexDirection: "row", marginTop: "2%", marginLeft: "8%" }}>
                                            Attachment:


                                            <Box style={{ width: '638px', height: '103px', marginLeft: "8%", border: "2px solid   #C0ADAD", backgroundColor: "#FCFCFC" }}>


                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                    <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                    <Typography style={{ fontSize: "18px", marginTop: "3%", overflow: "hidden" }}>
                                                        {/* {this.state.attachment !== "" ? (this.state.attachment).name : "Attachment File"} */}
                                                        {this.state.attachment !== "" && this.state.attachment1 === "" ? "File Uploaded" : this.state.attachment1.name}


                                                    </Typography>

                                                </label>

                                                <input style={{ display: "none" }} type="file" id="file"
                                                    onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }}
                                                />

                                            </Box>

                                        </Typography>




                                        <div style={{ display: "flex", flexDirection: "row", gap: '2rem', justifyContent: "center", marginTop: "2rem" }}>
                                            <div><button onClick={() => {
                                                this.setState({ editmilestone: false })
                                            }} style={{ minWidth: "7rem", height: "3rem", border: "none", borderRadius: "5px", backgroundColor: "#D9D9D9", color: "#8C8C8C", fontSize: "14px" }}>Cancel</button></div>
                                            <div><button style={{ minWidth: "7rem", height: "3rem", border: "none", borderRadius: "5px", backgroundColor: "#7900FF", color: "#FFFFFF", fontSize: "14px" }}
                                                onClick={() => {
                                                    this.setState({ editmilestone: false, attachment1: "" });

                                                    this.props.update_milestone_profile(this.state._id, this.state.title, this.state.desc, this.state.start_date, this.state.dead_line, this.state.project_status, this.state.project_priority, this.state.max_hr, this.state.attachment, this.props.phase.phase_id, this.state.attachment1)



                                                }}
                                            >Save</button></div>
                                        </div>

                                        <div style={{ marginBottom: "3%" }}>
                                        </div>

                                    </Grid>
                                </Dialog>


                                <Dialog
                                    open={this.state.holdsssss}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}> Hold Phase</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ holdsssss: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>



                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Description :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                placeholder='Description'
                                                                size='small'
                                                                fullWidth
                                                                multiline
                                                                onChange={(e) => this.setState({
                                                                    project_desc: e.target.value
                                                                })}
                                                                inputProps={{
                                                                    style: {
                                                                        height: 50,
                                                                    },
                                                                }}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ holdsssss: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    this.setState({ holdsssss: false, })
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Hold Phase
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >

                                <Dialog
                                    open={this.state.deletemilestone}
                                    onClose={() => {
                                        this.setState({ deletemilestone: false });
                                    }}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ width: "100%", height: "100%", }}
                                >
                                    {/* <IconButton
                    > */}

                                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                                        <center>
                                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                                        </center>
                                        <DialogActions style={{ marginTop: "42px" }}>

                                            <Button variant="contained"
                                                style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}
                                                onClick={() => { this.setState({ deletemilestone: false }) }}>

                                                Cancel
                                            </Button>
                                            <Button variant="contained"
                                                style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}

                                                onClick={() => {
                                                    this.setState({ deletemilestone: false })
                                                    this.props.isCheckedIdMilestone(this.props.milestone.milestone, this.props.phase.phase_id)

                                                }}
                                            >

                                                Confirm
                                            </Button>


                                        </DialogActions>
                                    </Grid>






                                </Dialog>







                                {/* <div className={this.state.addFormOpen ? `comapanyMilestoneFormopen` : `phaseFormclose`}>
                                    <div className="header">
                                        <div className="title">
                                            Add milestone
                                        </div>
                                        <div className="cross" onClick={this.modaloff}>
                                            <ImCross />
                                        </div>
                                    </div>
                                    <div className="inputBox">

                                        <div className="titleBox">
                                            Title : <input type="text" onChange={(e) => this.setState({ title: e.target.value })} />
                                        </div>
                                        <div className="descBox">

                                            Description:
                                            <input type="text" onChange={(e) => this.setState({ desc: e.target.value })} />
                                        </div>
                                        <div className="midSection">
                                            <div className="leftPart">
                                                <span>
                                                    <p>
                                                        Start Date:
                                                    </p>
                                                    <input type="date" name="" id="" onChange={(e) => this.setState({ start_date: e.target.value })} />
                                                </span>
                                                <span>
                                                    <p>
                                                        Maximum Hours:
                                                    </p>
                                                    <input type="text" onChange={(e) => this.setState({ max_hr: e.target.value })} />
                                                </span>

                                            </div>
                                            <div className="rightPart">
                                                <span>
                                                    <p>

                                                        Deadline:
                                                    </p>
                                                    <input type="date" onChange={(e) => this.setState({ dead_line: e.target.value })} />
                                                </span>
                                                <span>
                                                    <p>
                                                        Status:
                                                    </p>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={this.state.project_status}
                                                        style={{ width: "145px", marginTop: "-1%" }}
                                                        onChange={(e) => {
                                                            this.setState({ status: e.target.value })
                                                            // console.log(all_category)
                                                        }}
                                                    >
                                                        <MenuItem value={"N"}>{"Select Status"}</MenuItem>
                                                        {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                                            <MenuItem value={e.name}>{e.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </span>
                                                <span>
                                                    <p>

                                                        Priority:
                                                    </p>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        placeholder='Select Department'
                                                        value={this.state.priority}
                                                        style={{ width: "145px", marginTop: "-1%" }}
                                                        onChange={(e) => {
                                                            this.setState({ priority: e.target.value })
                                                            // console.log(all_category)
                                                        }}
                                                    >
                                                        <MenuItem value={"N"}>{"Select Priority"}</MenuItem>
                                                        {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e) => (
                                                            <MenuItem value={e.name}>{e.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </span>

                                            </div>
                                        </div>
                                        <Typography style={{ fontSize: "20px", marginLeft: "5%", display: "flex", flexDirection: "row", marginTop: "5%" }}>
                                            Attachment:


                                            <Box style={{ width: '343px', height: '103px', marginLeft: "7%", border: "1px solid grey", backgroundColor: "#FCFCFC" }}>


                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}

                                                >
                                                    <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                    <Typography style={{ fontSize: "18px", marginTop: "3%" }}
                                                    //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                    >
                                                        Attach File
                                                    </Typography>

                                                </label>

                                                <input style={{ display: "none" }} type="file" id="file"
                                                    onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }}
                                                />

                                            </Box>

                                        </Typography>
                                        <div className="btn">

                                            <button className='btn-add'
                                                onClick={() => {
                                                    this.setState({ addFormOpen: false });
                                                    this.setState({
                                                        title: "",
                                                        desc: "",
                                                        start_date: "",
                                                        dead_line: "",
                                                        status: "",
                                                        priority: "",
                                                        max_hr: "",
                                                        attachment: "",
                                                    })
                                                    this.props.add_upload_milestone(this.props.all_project.project_id,this.props.phase.phase_id, this.state.title, this.state.desc, this.state.start_date, this.state.dead_line, this.state.status, this.state.priority, this.state.max_hr, this.state.attachment)
                                                }}
                                            >Add Milestone</button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.addDeleteOpen ? `deletemodalopen` : `deletemodaloff`}>
                                    <div className="upper">
                                        <AiOutlineExclamationCircle className='icon' />
                                        <span>Are you sure ?</span>
                                        <p>You want to Delete</p>

                                    </div>
                                    <div className="lowerBtn">
                                        <button className='cancel-btn' onClick={this.deleteModalOff}>Cancel</button>
                                        <button className='confirm-btn'>Confirm</button>
                                    </div>
                                </div> */}
                            </>
                        }
                        {this.state.changeReqOpen &&

                            <>

                                {/* <div className="header">
                                </div> */}
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <th>{this.props.login.type !== "AuthPerson" && <Checkbox type="checkbox"
                                                name="" id=""
                                                checked={this.props.company.change_request.allCheck}
                                                onClick={(p) => { this.AllCheckedChangeRequest(p, this.props.company.change_request) }}
                                            />}</th>
                                            <th>Desc</th>
                                            <th>Created Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(this.props.company.search_change_rquest) && this.props.company.search_change_rquest.map((e, index) => (
                                            <tr hover={true}
                                                onMouseEnter={() => {
                                                    this.setState({
                                                        edit_show: true,
                                                        edit_show_id: e._id
                                                    })
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        edit_show: false,
                                                        edit_show_id: ""
                                                    })
                                                }}
                                            >
                                                <td>
                                                    {this.props.login.type !== "AuthPerson" && <Checkbox type="checkbox"
                                                        name="" id=""
                                                        checked={e.checked}
                                                        onClick={(p) => { this.CheckedChangeRequest(p, index, this.props.company.change_request) }}
                                                    />}
                                                </td>
                                                <td>{e.desc}</td>
                                                <td>{e.date}</td>
                                                <td >
                                                    {e.assign_status ?
                                                        <Tooltip title="Assigned">
                                                            <Icon style={{ color: 'green', cursor: 'pointer' }}
                                                            // onClick={(e) => {
                                                            //     this.setState({ asignMilestone: true, request: Item })
                                                            // }}
                                                            >assignment_turned_in</Icon>
                                                        </Tooltip> :
                                                        <Tooltip title="Add To Milestone">
                                                            <Icon style={{ color: 'red', cursor: 'pointer' }}

                                                                onClick={(e1) => {
                                                                    if (this.props.login.type !== "AuthPerson") {
                                                                        this.setState({ asignMilestone: true, request: e })
                                                                    }
                                                                }}
                                                            >assignment</Icon>
                                                        </Tooltip>
                                                    }

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Dialog
                                    open={this.state.asignMilestone}
                                    fullWidth
                                    maxWidth="sm"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Assign Milestone</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ asignMilestone: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} >
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={4}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Select Milestone :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                fullWidth
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                multiple
                                                                value={this.state.personName}
                                                                onChange={(e) => {
                                                                    this.handleChange(e)
                                                                    console.log(this.state.batch_id)
                                                                }}
                                                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                        {selected.map((value) => (
                                                                            <Chip key={value} label={value} />
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {Array.isArray(this.props.milestone.milestone) && this.props.milestone.milestone.map((item) => (
                                                                    <MenuItem value={item.title}
                                                                        onClick={() => {
                                                                            this.addBatchId(item._id)
                                                                        }}
                                                                    >{item.title}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>

                                                </Grid>
                                            </Grid>

                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ asignMilestone: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                // disabled={()}
                                                onClick={() => {
                                                    this.setState({ asignMilestone: false })
                                                    this.props.convertRequest(this.state.batch_id, this.state.request, this.props.login.org_id)
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Assign
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >
                                <Dialog
                                    open={this.state.addFormOpen}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <Grid>
                                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1%" }}>
                                                <Grid container>
                                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Add Change Request</Typography></Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => {
                                                            this.setState({
                                                                addFormOpen: false,
                                                                change_title: "",
                                                                change_desc: "",
                                                                change_startdate: "",
                                                                change_deadline: "",
                                                                change_status: "",
                                                                change_priority: "",
                                                                change_maxhr: "",
                                                                change_attachment: "",
                                                                change_attachment1: "",
                                                            });
                                                        }}>
                                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                            <DialogContent style={{ flexDirection: "row" }}>
                                                <Grid container direction={"column"} spacing={2}>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Title<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8} height={40}>

                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}

                                                                    id="outlined-select-currency-native"

                                                                    onChange={(e) => this.setState({ change_title: e.target.value })}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Description<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} height={125}>
                                                                <TextField
                                                                    multiline
                                                                    rows={4}
                                                                    style={{ width: '100%', height: "166.628px" }}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => { this.setState({ change_desc: e.target.value }) }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Request Date<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    type='date'
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => this.setState({ change_startdate: e.target.value })}
                                                                >

                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>

                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Priority<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    select
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => this.setState({ change_priority: e.target.value })}
                                                                >
                                                                    {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e, index) => (
                                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Attachment<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80, }} >



                                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}

                                                                >
                                                                    <img src={paperclip} style={{
                                                                        width: "21.338px",
                                                                        height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                    }} />
                                                                    <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                    //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                                    >
                                                                        {this.state.change_attachment !== "" ? (this.state.change_attachment).name : "Attachment"}

                                                                    </Typography>

                                                                </label>

                                                                <input style={{ display: "none" }} type="file" id="file"
                                                                    onChange={(e) => { this.setState({ change_attachment: e.target.files[0] }) }}
                                                                />

                                                                {/* </Box> */}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            addFormOpen: false,
                                                            change_title: "",
                                                            change_desc: "",
                                                            change_startdate: "",
                                                            change_deadline: "",
                                                            change_status: "",
                                                            change_priority: "",
                                                            change_maxhr: "",
                                                            change_attachment: "",
                                                            change_attachment1: "",
                                                        })
                                                    }}
                                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        this.props.add_upload_request(this.props.all_project.project_id, this.state.change_title, this.state.change_desc, this.state.change_startdate, this.state.change_attachment, "change_request", this.state.change_priority);
                                                        this.setState({
                                                            addFormOpen: false,
                                                            change_title: "",
                                                            change_desc: "",
                                                            change_startdate: "",
                                                            change_deadline: "",
                                                            change_status: "",
                                                            change_priority: "",
                                                            change_maxhr: "",
                                                            change_attachment: "",
                                                            change_attachment1: "",
                                                        });

                                                    }}
                                                    // onClick={() => { this.setState({ add: false }) }}
                                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Grid>
                                    </Grid>
                                </Dialog >

                                <Dialog
                                    open={this.state.editCR}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <Grid>
                                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1%" }}>
                                                <Grid container>
                                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Add Change Request</Typography></Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => {
                                                            this.setState({
                                                                editCR: false,
                                                                change_title: "",
                                                                change_desc: "",
                                                                change_startdate: "",
                                                                change_deadline: "",
                                                                change_status: "",
                                                                change_priority: "",
                                                                change_maxhr: "",
                                                                change_attachment: "",
                                                                change_attachment1: "",
                                                            });
                                                        }}>
                                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                            <DialogContent style={{ flexDirection: "row" }}>
                                                <Grid container direction={"column"} spacing={2}>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Title<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8} height={40}>

                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}

                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_title}
                                                                    onChange={(e) => this.setState({ change_title: e.target.value })}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Description<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} height={125}>
                                                                <TextField
                                                                    multiline
                                                                    rows={4}
                                                                    style={{ width: '100%', height: "166.628px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_desc}
                                                                    onChange={(e) => { this.setState({ change_desc: e.target.value }) }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Request Date<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    type='date'
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_startdate}
                                                                    onChange={(e) => this.setState({ change_startdate: e.target.value })}
                                                                >

                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>

                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Priority<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    select
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_priority}
                                                                    onChange={(e) => this.setState({ change_priority: e.target.value })}
                                                                >
                                                                    {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e, index) => (
                                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Attachment<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80, }} >



                                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}

                                                                >
                                                                    <img src={paperclip} style={{
                                                                        width: "21.338px",
                                                                        height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                    }} />
                                                                    <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                    //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                                    >
                                                                        {this.state.change_attachment !== "" ? "File Uploaded" : "Attachment"}

                                                                    </Typography>

                                                                </label>

                                                                <input style={{ display: "none" }} type="file" id="file"
                                                                    onChange={(e) => { this.setState({ change_attachment1: e.target.files[0] }) }}
                                                                />

                                                                {/* </Box> */}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            editCR: false,
                                                            change_title: "",
                                                            change_desc: "",
                                                            change_startdate: "",
                                                            change_deadline: "",
                                                            change_status: "",
                                                            change_priority: "",
                                                            change_maxhr: "",
                                                            change_attachment: "",
                                                            change_attachment1: "",
                                                        })
                                                    }}
                                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        this.props.upload_upadate_request(this.props.all_project.project_id, this.state.change_id, this.state.change_title, this.state.change_desc, this.state.change_startdate, this.state.change_attachment, "change_request", this.state.change_priority, this.state.change_attachment1);
                                                        this.setState({
                                                            editCR: false,
                                                            change_title: "",
                                                            change_desc: "",
                                                            change_startdate: "",
                                                            change_deadline: "",
                                                            change_status: "",
                                                            change_priority: "",
                                                            change_maxhr: "",
                                                            change_attachment: "",
                                                            change_attachment1: "",
                                                        });

                                                    }}
                                                    // onClick={() => { this.setState({ add: false }) }}
                                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Grid>
                                    </Grid>
                                </Dialog >
                                <div className={this.state.addDeleteOpen ? `deletemodalopenforFewField` : `deletemodaloff`}>
                                    <div className="upper">
                                        <AiOutlineExclamationCircle className='icon' />
                                        <h2>Are you sure ?</h2>
                                        <p>You want to Delete</p>

                                    </div>
                                    <div className="lowerBtn">
                                        <button className='cancel-btn' onClick={this.deleteModalOff}>Cancel</button>
                                        <button className='confirm-btn'
                                            onClick={(p) => {
                                                this.deleteModalOff()
                                                this.props.isCheckedIdChange(this.props.all_project.project_id, this.props.company.change_request)
                                            }}
                                        >Confirm</button>
                                    </div>
                                </div>
                            </>
                        }
                        {this.state.problemOpen &&

                            <>

                                {/* <div className="header">
                                </div> */}
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <th>{this.props.login.type !== "AuthPerson" && <Checkbox type="checkbox"
                                                name="" id=""
                                                checked={this.props.company.change_request.allCheck}
                                                onClick={(p) => { this.AllCheckedChangeRequest(p, this.props.company.change_request) }}
                                            />}</th>
                                            <th>Desc</th>
                                            <th>Created Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(this.props.company.search_change_rquest) && this.props.company.search_change_rquest.map((e, index) => (
                                            <tr
                                                hover={true}
                                                onMouseEnter={() => {
                                                    this.setState({
                                                        edit_show: true,
                                                        edit_show_id: e._id
                                                    })
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        edit_show: false,
                                                        edit_show_id: ""
                                                    })
                                                }}
                                            >
                                                <td>
                                                    {this.props.login.type !== "AuthPerson" && <Checkbox type="checkbox"
                                                        name="" id=""
                                                        checked={e.checked}
                                                        onClick={(p) => { this.CheckedChangeRequest(p, index, this.props.company.change_request) }}
                                                    />}
                                                </td>
                                                <td>{e.desc}</td>
                                                <td>{e.date}</td>
                                                <td >
                                                    {e.assign_status ?
                                                        <Tooltip title="Assigned">
                                                            <Icon style={{ color: 'green', cursor: 'pointer' }}
                                                            // onClick={(e) => {
                                                            //     this.setState({ asignMilestone: true, request: Item })
                                                            // }}
                                                            >assignment_turned_in</Icon>
                                                        </Tooltip> :
                                                        <Tooltip title="Add To Milestone">
                                                            <Icon style={{ color: 'red', cursor: 'pointer' }}
                                                                onClick={(e1) => {
                                                                    console.log(this.props.login.org_id)
                                                                    { this.props.login.type !== "AuthPerson" && this.setState({ asignMilestone: true, request: e }) }
                                                                }}
                                                            >assignment</Icon>
                                                        </Tooltip>
                                                    }

                                                    {/* <AssignmentIcon/> */}

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Dialog
                                    open={this.state.asignMilestone}
                                    fullWidth
                                    maxWidth="sm"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Assign Milestone</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ asignMilestone: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} >
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={4}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Select Milestone :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                fullWidth
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                multiple
                                                                value={this.state.personName}
                                                                onChange={(e) => {
                                                                    this.handleChange(e)
                                                                    console.log(this.state.batch_id)
                                                                }}
                                                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                        {selected.map((value) => (
                                                                            <Chip key={value} label={value} />
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {Array.isArray(this.props.milestone.milestone) && this.props.milestone.milestone.map((item) => (
                                                                    <MenuItem value={item.title}
                                                                        onClick={() => {
                                                                            this.addBatchId(item._id)
                                                                        }}
                                                                    >{item.title}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>

                                                </Grid>
                                            </Grid>

                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ asignMilestone: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                // disabled={()}
                                                onClick={() => {
                                                    this.setState({ asignMilestone: false })
                                                    this.props.convertRequest(this.state.batch_id, this.state.request, this.props.login.org_id)
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Assign
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >
                                <Dialog
                                    open={this.state.addFormOpen}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <Grid>
                                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1%" }}>
                                                <Grid container>
                                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Add Change Request</Typography></Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => {
                                                            this.setState({
                                                                addFormOpen: false,
                                                                change_title: "",
                                                                change_desc: "",
                                                                change_startdate: "",
                                                                change_deadline: "",
                                                                change_status: "",
                                                                change_priority: "",
                                                                change_maxhr: "",
                                                                change_attachment: "",
                                                                change_attachment1: "",
                                                            });
                                                        }}>
                                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                            <DialogContent style={{ flexDirection: "row" }}>
                                                <Grid container direction={"column"} spacing={2}>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Title<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8} height={40}>

                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}

                                                                    id="outlined-select-currency-native"

                                                                    onChange={(e) => this.setState({ change_title: e.target.value })}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Description<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} height={125}>
                                                                <TextField
                                                                    multiline
                                                                    rows={4}
                                                                    style={{ width: '100%', height: "166.628px" }}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => { this.setState({ change_desc: e.target.value }) }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Request Date<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    type='date'
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => this.setState({ change_startdate: e.target.value })}
                                                                >

                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>

                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Priority<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    select
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => this.setState({ change_priority: e.target.value })}
                                                                >
                                                                    {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e, index) => (
                                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Attachment<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80, }} >



                                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}

                                                                >
                                                                    <img src={paperclip} style={{
                                                                        width: "21.338px",
                                                                        height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                    }} />
                                                                    <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                    //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                                    >
                                                                        {this.state.change_attachment !== "" ? (this.state.change_attachment).name : "Attachment"}

                                                                    </Typography>

                                                                </label>

                                                                <input style={{ display: "none" }} type="file" id="file"
                                                                    onChange={(e) => { this.setState({ change_attachment: e.target.files[0] }) }}
                                                                />

                                                                {/* </Box> */}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            addFormOpen: false,
                                                            change_title: "",
                                                            change_desc: "",
                                                            change_startdate: "",
                                                            change_deadline: "",
                                                            change_status: "",
                                                            change_priority: "",
                                                            change_maxhr: "",
                                                            change_attachment: "",
                                                            change_attachment1: "",
                                                        })
                                                    }}
                                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        this.props.add_upload_request(this.props.all_project.project_id, this.state.change_title, this.state.change_desc, this.state.change_startdate, this.state.change_attachment, "problem", this.state.change_priority);
                                                        this.setState({
                                                            addFormOpen: false,
                                                            change_title: "",
                                                            change_desc: "",
                                                            change_startdate: "",
                                                            change_deadline: "",
                                                            change_status: "",
                                                            change_priority: "",
                                                            change_maxhr: "",
                                                            change_attachment: "",
                                                            change_attachment1: "",
                                                        });

                                                    }}
                                                    // onClick={() => { this.setState({ add: false }) }}
                                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Grid>
                                    </Grid>
                                </Dialog >

                                <Dialog
                                    open={this.state.editCR}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <Grid>
                                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1%" }}>
                                                <Grid container>
                                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Add Change Request</Typography></Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => {
                                                            this.setState({
                                                                editCR: false,
                                                                change_title: "",
                                                                change_desc: "",
                                                                change_startdate: "",
                                                                change_deadline: "",
                                                                change_status: "",
                                                                change_priority: "",
                                                                change_maxhr: "",
                                                                change_attachment: "",
                                                                change_attachment1: "",
                                                            });
                                                        }}>
                                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                            <DialogContent style={{ flexDirection: "row" }}>
                                                <Grid container direction={"column"} spacing={2}>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Title<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8} height={40}>

                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}

                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_title}
                                                                    onChange={(e) => this.setState({ change_title: e.target.value })}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Description<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} height={125}>
                                                                <TextField
                                                                    multiline
                                                                    rows={4}
                                                                    style={{ width: '100%', height: "166.628px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_desc}
                                                                    onChange={(e) => { this.setState({ change_desc: e.target.value }) }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Request Date<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    type='date'
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_startdate}
                                                                    onChange={(e) => this.setState({ change_startdate: e.target.value })}
                                                                >

                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>

                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Priority<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    select
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_priority}
                                                                    onChange={(e) => this.setState({ change_priority: e.target.value })}
                                                                >
                                                                    {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e, index) => (
                                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Attachment<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80, }} >



                                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}

                                                                >
                                                                    <img src={paperclip} style={{
                                                                        width: "21.338px",
                                                                        height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                    }} />
                                                                    <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                    //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                                    >
                                                                        {this.state.change_attachment !== "" ? "File Uploaded" : "Attachment"}

                                                                    </Typography>

                                                                </label>

                                                                <input style={{ display: "none" }} type="file" id="file"
                                                                    onChange={(e) => { this.setState({ change_attachment1: e.target.files[0] }) }}
                                                                />

                                                                {/* </Box> */}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            editCR: false,
                                                            change_title: "",
                                                            change_desc: "",
                                                            change_startdate: "",
                                                            change_deadline: "",
                                                            change_status: "",
                                                            change_priority: "",
                                                            change_maxhr: "",
                                                            change_attachment: "",
                                                            change_attachment1: "",
                                                        })
                                                    }}
                                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        this.props.upload_upadate_request(this.props.all_project.project_id, this.state.change_id, this.state.change_title, this.state.change_desc, this.state.change_startdate, this.state.change_attachment, "problem", this.state.change_priority, this.state.change_attachment1);
                                                        this.setState({
                                                            editCR: false,
                                                            change_title: "",
                                                            change_desc: "",
                                                            change_startdate: "",
                                                            change_deadline: "",
                                                            change_status: "",
                                                            change_priority: "",
                                                            change_maxhr: "",
                                                            change_attachment: "",
                                                            change_attachment1: "",
                                                        });

                                                    }}
                                                    // onClick={() => { this.setState({ add: false }) }}
                                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Grid>
                                    </Grid>
                                </Dialog >
                                <div className={this.state.addDeleteOpen ? `deletemodalopenforFewField` : `deletemodaloff`}>
                                    <div className="upper">
                                        <AiOutlineExclamationCircle className='icon' />
                                        <h2>Are you sure ?</h2>
                                        <p>You want to Delete</p>

                                    </div>
                                    <div className="lowerBtn">
                                        <button className='cancel-btn' onClick={this.deleteModalOff}>Cancel</button>
                                        <button className='confirm-btn'
                                            // onClick={(p) => {
                                            //     this.addDeleteOpen:false

                                            //     this.props.isCheckedIdChange(this.props.all_project.project_id, this.props.company.change_request)
                                            // }}

                                            onClick={() => {
                                                this.props.isCheckedIdChange(this.props.all_project.project_id, this.props.company.change_request);
                                                this.setState({
                                                    deleteModalOff: false,
                                                });

                                            }}
                                        >Confirm</button>
                                    </div>
                                </div>
                            </>
                        }
                        {/* add on */}
                        {
                            this.state.addonOpen &&
                            <>
                                {/* <div className="header">
                                    <div className="title">

                                        Add on
                                    </div> */}
                                {/* <div className="button">
                                        <button className="add-btn"

                                            onClick={() => {
                                                this.setState({
                                                    addonmod: true,
                                                })
                                            }}
                                        >
                                            Add  On
                                        </button>
                                        <button className="del-btn"

                                            onClick={() => {
                                                this.setState({
                                                    deleteaddon: true,
                                                })
                                            }}

                                        >
                                            <MdDeleteOutline className='del-icon' /> Delete
                                        </button>
                                    </div> */}
                                {/* </div> */}
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <th>
                                                {this.props.login.type !== "AuthPerson" && <Checkbox type="checkbox"
                                                    name="" id=""
                                                    checked={this.props.company.change_request.allCheck}
                                                    onClick={(p) => { this.AllCheckedChangeRequest(p, this.props.company.change_request) }}
                                                />}
                                            </th>
                                            <th>Desc</th>
                                            <th>Created Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {Array.isArray(this.props.company.search_change_rquest) && this.props.company.search_change_rquest.map((e, index) => (
                                            <tr
                                                hover={true}
                                                onMouseEnter={() => {
                                                    this.setState({
                                                        edit_show: true,
                                                        edit_show_id: e._id
                                                    })
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        edit_show: false,
                                                        edit_show_id: ""
                                                    })
                                                }}
                                            >
                                                <td>
                                                    {this.props.login.type !== "AuthPerson" && <Checkbox type="checkbox"
                                                        name="" id=""
                                                        checked={e.checked}
                                                        onClick={(p) => { this.CheckedChangeRequest(p, index, this.props.company.change_request) }}
                                                    />}
                                                </td>
                                                <td>{e.desc}</td>
                                                <td>{e.date}</td>
                                                {this.props.login.tms ? <td >
                                                    {e.assign_status ?
                                                        <Tooltip title="Assigned">
                                                            <Icon style={{ color: 'green', cursor: 'pointer' }}
                                                            // onClick={(e) => {
                                                            //     this.setState({ asignMilestone: true, request: Item })
                                                            // }}
                                                            >assignment_turned_in</Icon>
                                                        </Tooltip> :
                                                        <Tooltip title="Add To Milestone">
                                                            <Icon style={{ color: 'red', cursor: 'pointer' }}
                                                                onClick={(e1) => {
                                                                    if (this.props.login.type !== "AuthPerson") { this.setState({ asignMilestone: true, request: e }) }
                                                                }}
                                                            >assignment</Icon>
                                                        </Tooltip>
                                                    }

                                                    {/* <AssignmentIcon/> */}

                                                </td> :
                                                    <td>
                                                        <Typography>You do not have access to task </Typography>
                                                        <Button
                                                            disabled={this.props.login.type !== "Owner"}
                                                            onClick={() => {
                                                                this.setState({ accessModal: true })
                                                            }}
                                                        >
                                                            Click to Access
                                                        </Button>
                                                    </td>}

                                            </tr>
                                        ))}


                                    </tbody>
                                </table>


                                {/* Addon dialog */}

                                <Dialog
                                    open={this.state.asignMilestone}
                                    fullWidth
                                    maxWidth="sm"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Assign Milestone</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ asignMilestone: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} >
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={4}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Select Milestone :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                fullWidth
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                multiple
                                                                value={this.state.personName}
                                                                onChange={(e) => {
                                                                    this.handleChange(e)
                                                                    console.log(this.state.batch_id)
                                                                }}
                                                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                        {selected.map((value) => (
                                                                            <Chip key={value} label={value} />
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {Array.isArray(this.props.milestone.milestone) && this.props.milestone.milestone.map((item) => (
                                                                    <MenuItem value={item.title}
                                                                        onClick={() => {
                                                                            this.addBatchId(item._id)
                                                                        }}
                                                                    >{item.title}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>

                                                </Grid>
                                            </Grid>

                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ asignMilestone: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                // disabled={()}
                                                onClick={() => {
                                                    this.setState({ asignMilestone: false })
                                                    this.props.convertRequest(this.state.batch_id, this.state.request, this.props.login.org_id)
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Assign
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >
                                <Dialog
                                    open={this.state.addonmod}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >
                                    <Grid style={{ minWidth: "1000px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid black" }}>

                                            <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>Add-ON</h4>

                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ addonmod: false })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>


                                        <Grid container style={{ marginTop: "3%", marginBottom: "2%" }}>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Title<strong style={{ color: "red", marginTop: "5%" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} height={40}>

                                                <TextField
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}

                                                    id="outlined-select-currency-native"

                                                    onChange={(e) => this.setState({ change_title: e.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={1}></Grid>
                                                <Grid item xs={2}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Description<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={8} height={125}>
                                                    <TextField
                                                        multiline
                                                        rows={4}
                                                        style={{ width: '100%', height: "166.628px" }}
                                                        id="outlined-select-currency-native"
                                                        onChange={(e) => { this.setState({ change_desc: e.target.value }) }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                        <Grid item style={{ marginTop: "2%", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={1}></Grid>
                                                <Grid item xs={2}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Request Date<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2.5} height={40}>
                                                    <TextField
                                                        type='date'
                                                        size='small'
                                                        style={{ width: "100%", borderRadius: "7.113px" }}
                                                        id="outlined-select-currency-native"
                                                        onChange={(e) => this.setState({ change_startdate: e.target.value })}
                                                    >

                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={1}></Grid>

                                                <Grid item xs={2}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Priority<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2.5} height={40}>
                                                    <TextField
                                                        select
                                                        size='small'
                                                        style={{ width: "100%", borderRadius: "7.113px" }}
                                                        id="outlined-select-currency-native"
                                                        onChange={(e) => this.setState({ change_priority: e.target.value })}
                                                    >
                                                        {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e, index) => (
                                                            <MenuItem value={e.name}>{e.name}</MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={1}></Grid>
                                                <Grid item xs={2}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Attachment<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80, }} >



                                                    <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}

                                                    >
                                                        <img src={paperclip} style={{
                                                            width: "21.338px",
                                                            height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                        }} />
                                                        <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                        //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                        >
                                                            {this.state.change_attachment !== "" ? (this.state.change_attachment).name : "Attachment"}

                                                        </Typography>

                                                    </label>

                                                    <input style={{ display: "none" }} type="file" id="file"
                                                        onChange={(e) => { this.setState({ change_attachment: e.target.files[0] }) }}
                                                    />

                                                    {/* </Box> */}

                                                </Grid>
                                            </Grid>
                                        </Grid>




                                        <div style={{ display: "flex", flexDirection: "row", gap: '2rem', justifyContent: "center", marginTop: "2rem" }}>
                                            <div> <Button
                                                onClick={() => {
                                                    this.setState({
                                                        addonmod: false,
                                                        change_title: "",
                                                        change_desc: "",
                                                        change_startdate: "",
                                                        change_deadline: "",
                                                        change_status: "",
                                                        change_priority: "",
                                                        change_maxhr: "",
                                                        change_attachment: "",
                                                        change_attachment1: "",
                                                    })
                                                }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button></div>
                                            <div><Button
                                                onClick={() => {
                                                    this.props.add_upload_request(this.props.all_project.project_id, this.state.change_title, this.state.change_desc, this.state.change_startdate, this.state.change_attachment, "add_on", this.state.change_priority);
                                                    this.setState({
                                                        addonmod: false,
                                                        change_title: "",
                                                        change_desc: "",
                                                        change_startdate: "",
                                                        change_deadline: "",
                                                        change_status: "",
                                                        change_priority: "",
                                                        change_maxhr: "",
                                                        change_attachment: "",
                                                        change_attachment1: "",
                                                    });

                                                }}
                                                // onClick={() => { this.setState({ add: false }) }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Save
                                            </Button></div>
                                        </div>

                                        <div style={{ marginBottom: "3%" }}>
                                        </div>
                                    </Grid>

                                </Dialog>
                                {/* edit addon dialog */}

                                <Dialog
                                    open={this.state.editaddon}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <Grid>
                                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1%" }}>
                                                <Grid container>
                                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Update Add On</Typography></Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => {
                                                            this.setState({
                                                                editaddon: false,
                                                                change_title: "",
                                                                change_desc: "",
                                                                change_startdate: "",
                                                                change_deadline: "",
                                                                change_status: "",
                                                                change_priority: "",
                                                                change_maxhr: "",
                                                                change_attachment: "",
                                                                change_attachment1: "",
                                                            });
                                                        }}>
                                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                            <DialogContent style={{ flexDirection: "row" }}>
                                                <Grid container direction={"column"} spacing={2}>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Title<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8} height={40}>

                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}

                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_title}
                                                                    onChange={(e) => this.setState({ change_title: e.target.value })}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Description<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} height={125}>
                                                                <TextField
                                                                    multiline
                                                                    rows={4}
                                                                    style={{ width: '100%', height: "166.628px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_desc}
                                                                    onChange={(e) => { this.setState({ change_desc: e.target.value }) }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Request Date<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    type='date'
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_startdate}
                                                                    onChange={(e) => this.setState({ change_startdate: e.target.value })}
                                                                >

                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>

                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Priority<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    select
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.change_priority}
                                                                    onChange={(e) => this.setState({ change_priority: e.target.value })}
                                                                >
                                                                    {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e, index) => (
                                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Attachment<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80, }} >



                                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}

                                                                >
                                                                    <img src={paperclip} style={{
                                                                        width: "21.338px",
                                                                        height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                    }} />
                                                                    <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                    //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                                    >
                                                                        {this.state.change_attachment !== "" ? "File Uploaded" : "Attachment"}

                                                                    </Typography>

                                                                </label>

                                                                <input style={{ display: "none" }} type="file" id="file"
                                                                    onChange={(e) => { this.setState({ change_attachment1: e.target.files[0] }) }}
                                                                />

                                                                {/* </Box> */}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            editaddon: false,
                                                            change_title: "",
                                                            change_desc: "",
                                                            change_startdate: "",
                                                            change_deadline: "",
                                                            change_status: "",
                                                            change_priority: "",
                                                            change_maxhr: "",
                                                            change_attachment: "",
                                                            change_attachment1: "",
                                                        })
                                                    }}
                                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        this.props.upload_upadate_request(this.props.all_project.project_id, this.state.change_id, this.state.change_title, this.state.change_desc, this.state.change_startdate, this.state.change_attachment, "add_on", this.state.change_priority, this.state.change_attachment1);
                                                        this.setState({
                                                            editaddon: false,
                                                            change_title: "",
                                                            change_desc: "",
                                                            change_startdate: "",
                                                            change_deadline: "",
                                                            change_status: "",
                                                            change_priority: "",
                                                            change_maxhr: "",
                                                            change_attachment: "",
                                                            change_attachment1: "",
                                                        });

                                                    }}
                                                    // onClick={() => { this.setState({ add: false }) }}
                                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Grid>
                                    </Grid>
                                </Dialog >




                                <Dialog
                                    open={this.state.deleteaddon}
                                    onClose={() => {
                                        this.setState({ deleteaddon: false });
                                    }}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ width: "100%", height: "100%", }}
                                >
                                    {/* <IconButton
                    > */}

                                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                                        <center>
                                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                                        </center>
                                        <DialogActions style={{ marginTop: "42px" }}>

                                            <Button variant="contained"
                                                style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}
                                                onClick={() => { this.setState({ deleteaddon: false }) }}>

                                                Cancel
                                            </Button>
                                            <Button variant="contained"
                                                style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                                // onClick={() => { this.setState({ deleteaddon: false }) }}
                                                onClick={() => {
                                                    this.props.isCheckedIdChange(this.props.all_project.project_id, this.props.company.change_request);
                                                    this.setState({
                                                        deleteaddon: false,
                                                    });

                                                }}
                                            >

                                                Confirm
                                            </Button>


                                        </DialogActions>
                                    </Grid>






                                </Dialog>





                                {/* <div className={this.state.addFormOpen ? `comapanyMilestoneFormopen` : `phaseFormclose`}>
                                    <div className="header">
                                        <div className="title">
                                            Add on
                                        </div>
                                        <div className="cross" onClick={this.modaloff}>
                                            <ImCross />
                                        </div>
                                    </div>
                                    <div className="inputBox">
                                        <div className="upperBox">
                                            <span>
                                                <p>
                                                    Project Name:
                                                </p>
                                                <select name="" id="">
                                                    <option value="Project Name">Project 1</option>
                                                    <option value="Project2">Project 2</option>
                                                    <option value="project3">Project 3</option>

                                                </select>
                                            </span>
                                            <span>
                                                <p>
                                                    Project Phase:
                                                </p>
                                                <select name="" id="">
                                                    <option value="Project Name">Project Name</option>
                                                    <option value="Phase1">Phase 1</option>
                                                    <option value="Phase2">Phase 2 </option>

                                                </select>
                                            </span>
                                        </div>

                                        <div className="descBox">

                                            Description:
                                            <input type="text" />
                                        </div>
                                        <div className="lowerbox">
                                            <div className="leftPart">
                                                <span>
                                                    <p>
                                                        Requested Date:
                                                    </p>
                                                    <input type="date" name="" id="" />
                                                </span>



                                            </div>
                                            <div className="rightPart">
                                                <span>
                                                    <p>

                                                        Resolved Date:
                                                    </p>
                                                    <input type="date" />
                                                </span>


                                            </div>
                                        </div>
                                        <div className="footerSection">

                                            <div className="second">

                                                Attachment:
                                                <input type="text" />
                                            </div>
                                        </div>
                                        <div className="btn">

                                            <button className='btn-add'>Add Project</button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.addDeleteOpen ? `deletemodalopen` : `deletemodaloff`}>
                                    <div className="upper">
                                        <AiOutlineExclamationCircle className='icon' />
                                        <span>Are you sure ?</span>
                                        <p>You want to Delete</p>

                                    </div>
                                    <div className="lowerBtn">
                                        <button className='cancel-btn' onClick={this.deleteModalOff}>Cancel</button>
                                        <button className='confirm-btn'>Confirm</button>
                                    </div>
                                </div> */}
                            </>
                        }


                        {/* testing */}
                        {
                            this.state.testingOpen &&
                            <>
                                <div className="header">
                                    <div className="title">

                                        All Testing
                                    </div>
                                    <div className="button">
                                        <button className="add-btn"

                                            onClick={() => {
                                                this.setState({
                                                    addtesting: true,
                                                })
                                            }}

                                        >
                                            Add  Testing Phase
                                        </button>
                                        <button className="del-btn"
                                            onClick={() => {
                                                this.setState({
                                                    deletetesting: true,
                                                })
                                            }}

                                        >
                                            <MdDeleteOutline className='del-icon' /> Delete
                                        </button>
                                    </div>
                                </div>
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <th>
                                                {this.props.login.type !== "AuthPerson" && <Checkbox
                                                    checked={this.props.testing.testing.allCheck}
                                                    onClick={(p) => {
                                                        {
                                                            this.AllCheckedTesting(p, this.props.testing.testing)
                                                        }
                                                    }}
                                                />}
                                            </th>
                                            <th> Project Name  </th>
                                            <th>Phase</th>
                                            <th>Test Type</th>
                                            <th>Status</th>


                                        </tr>
                                    </thead>
                                    <tbody>



                                        {Array.isArray(this.props.testing.search_testing) && this.props.testing.search_testing.map((item, index) => {
                                            return (
                                                <tr >
                                                    <td>
                                                        {this.props.login.type !== "AuthPerson" && <Checkbox

                                                            checked={item.checked}

                                                            onClick={(p) => { this.CheckTesting(p, index, this.props.testing.testing) }}
                                                        />}
                                                    </td>
                                                    <td> {all_project.project_title}</td>
                                                    <td>{item.testing_phase} </td>
                                                    <td>{item.test_case_type}</td>

                                                    <td>   </td>

                                                </tr>

                                            )
                                        })}


                                    </tbody>
                                </table>

                                {/* testing dialog */}


                                <Dialog
                                    open={this.state.addtesting}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >
                                    <Grid style={{ minWidth: "600px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>



                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ addtesting: false })
                                            }} style={{ marginLeft: "90%", marginTop: "1%", cursor: "pointer" }} />
                                        </div>





                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>




                                            <Typography style={{ fontSize: "15px", marginLeft: "9%", display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                                Test Case Type:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '40px', marginLeft: "19%", marginTop: "0%" },
                                            }}>
                                                <OutlinedInput
                                                    onChange={(e) => { this.setState({ test_case_type: e.target.value }) }}



                                                />
                                            </FormControl>




                                        </div>


                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>




                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                Testing Phase:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '40px', marginLeft: "19%", marginTop: "2%" },
                                            }}>
                                                <OutlinedInput
                                                    onChange={(e) => { this.setState({ testing_phase: e.target.value }) }}

                                                />
                                            </FormControl>




                                        </div>
                                        <div style={{ marginBottom: "3%" }}>
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "row", gap: '2rem', justifyContent: "center", marginTop: "2rem", marginBottom: "2%" }}>
                                            <div><button onClick={() => {
                                                this.setState({ addtesting: false })
                                            }} style={{ minWidth: "7rem", border: "none", height: "3rem", borderRadius: "8px", backgroundColor: "#D9D9D9", marginBottom: "2%", color: "#8C8C8C", fontSize: "14px" }}>Cancel</button></div>
                                            <div><button style={{ minWidth: "7rem", border: "none", height: "3rem", borderRadius: "8px", backgroundColor: "#7900FF", color: "#FFFFFF", fontSize: "14px" }}
                                                onClick={() => {
                                                    this.props.add_testing(this.state.test_case_type, this.state.testing_phase, this.props.phase.phase_id);
                                                    this.setState({ addtesting: false });

                                                }}
                                            >Save</button></div>
                                        </div>
                                    </Grid>

                                </Dialog>



                                <Dialog
                                    open={this.state.deletetesting}
                                    onClose={() => {
                                        this.setState({ deletetesting: false });
                                    }}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ width: "100%", height: "100%", }}
                                >
                                    {/* <IconButton
                    > */}

                                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                                        <center>
                                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                                        </center>
                                        <DialogActions style={{ marginTop: "42px" }}>

                                            <Button variant="contained"
                                                style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}
                                                onClick={() => { this.setState({ deletetesting: false }) }}>

                                                Cancel
                                            </Button>
                                            <Button variant="contained"
                                                style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}

                                                onClick={() => {
                                                    this.setState({ deletetesting: false })
                                                    this.props.isCheckedTesting(this.props.phase.phase_id, this.props.testing.testing)

                                                }}
                                            >


                                                Confirm
                                            </Button>


                                        </DialogActions>
                                    </Grid>

                                </Dialog>


                                {/* <div className={this.state.addFormOpen ? `testingFormopen` : `phaseFormclose`}>
                                    <div className="testingHeader">

                                        <div className="Testingtitle">
                                            <h2>
                                                Project Title(Phase-1)
                                            </h2>
                                            <p>
                                                created by  <span>
                                                    sudipta
                                                </span>  on 9 jun 2023 at 9 pm
                                            </p>
                                        </div>

                                        <div className="cross" onClick={this.modaloff}>
                                            <ImCross />
                                        </div>
                                    </div>
                                    <div className="testingInputBox">
                                        <div className="testingBox">
                                            <span>
                                                <p>
                                                    Project Name:
                                                </p>
                                                <input type="text" name="" id="" />
                                            </span>
                                            <span>
                                                <p>
                                                    Testing phase:
                                                </p>
                                                <input type="text" name="" id="" />
                                            </span>
                                        </div>


                                        <div className="btn">

                                            <button className='btn-cancel'>Cancel</button>
                                            <button className='btn-add'>Add Project</button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.addDeleteOpen ? `deletemodalopen` : `deletemodaloff`}>
                                    <div className="upper">
                                        <AiOutlineExclamationCircle className='icon' />
                                        <span>Are you sure ?</span>
                                        <p>You want to Delete</p>

                                    </div>
                                    <div className="lowerBtn">
                                        <button className='cancel-btn' onClick={this.deleteModalOff}>Cancel</button>
                                        <button className='confirm-btn'>Confirm</button>
                                    </div>
                                </div> */}


                            </>
                        }


                        {/* Member */}
                        {
                            this.state.Memberopen &&
                            <>

                                <div className="header">
                                    <div className="title">

                                        All Member
                                    </div>
                                    {this.props.login.type !== "AuthPerson" && <div className="button">
                                        <button className="add-btn"

                                            onClick={() => {
                                                this.setState({
                                                    addmember: true,

                                                })
                                            }}

                                        >
                                            Add  Member
                                        </button>
                                        <button className="del-btn"

                                            onClick={() => {
                                                this.setState({
                                                    deletemember: true,

                                                })
                                            }}

                                        >
                                            <MdDeleteOutline className='del-icon' /> Delete
                                        </button>
                                    </div>}
                                </div>
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <th>
                                                {this.props.login.type !== "AuthPerson" && <Checkbox
                                                    checked={this.props.members.members.allCheck}
                                                    onClick={(p) => {
                                                        {
                                                            this.AllCheckedMembers(p, this.props.members.members)
                                                        }
                                                    }}
                                                />}
                                            </th>
                                            <th> Employee Name  </th>
                                            <th>Employee ID</th>
                                            <th>Email ID</th>
                                            <th>Department</th>
                                            <th>Project Name</th>

                                            <th></th>
                                        </tr>
                                    </thead>

                                    {/* <tr>
                                            <td>
                                                <input type="checkbox"
                                                    name="" id="" />
                                            </td>
                                            <td>sudipta</td>
                                            <td>sb70283029</td>
                                            <td >
                                                abs@gmail.com
                                            </td>
                                            <td>UX Design</td>
                                            <td>Project Name</td>
                                            <td>Milestone-1</td>
                                        </tr> */}

                                    {Array.isArray(this.props.members.search_members) && this.props.members.search_members.map((e, index) => {
                                        return (
                                            <tbody>
                                                <td>
                                                    {this.props.login.type !== "AuthPerson" && <Checkbox
                                                        checked={e.checked}
                                                        onClick={(p) => { this.CheckedPhaseMember(p, index, this.props.members.members) }}
                                                    />}
                                                </td>
                                                <td>{e.emp_details.first_name ? e.emp_details.first_name : e.emp_details.name}</td>
                                                <td>{e.emp_details.emp_id}</td>
                                                <td>
                                                    {e.emp_details.email_id}
                                                </td>
                                                <td>{e.department}</td>
                                                <td> {this.props.all_project.project_title}</td>
                                                <td></td>
                                            </tbody>

                                        )
                                    })}

                                </table>
                                {/* member dialog */}

                                <Dialog
                                    open={this.state.addmember}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >
                                    <Grid style={{ minWidth: "730px", }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>



                                            <div className="title" style={{ marginLeft: "5%" }}>
                                                <h3>
                                                    Add Member To Phase
                                                </h3>
                                            </div>
                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ addmember: false, project_department: "N", department_employee: "N" })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", marginTop: "3%" }}>
                                                Department<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <TextField
                                                style={{ width: '223px', height: '2vh', marginTop: "2%", marginLeft: "4%" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.project_department}
                                                onChange={(e) => {
                                                    this.setState({ project_department: e.target.value })
                                                    this.props.view_All_Employee_By_Dept_Id(e.target.value);
                                                }}
                                            ><MenuItem value={"N"}>{"Select Department"}</MenuItem>
                                                {Array.isArray(this.props.login.all_department) && this.props.login.all_department.map((e) => (
                                                    <MenuItem value={e._id}

                                                    >{e.name}</MenuItem>
                                                ))}
                                            </TextField>
                                        </div>



                                        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "5%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", marginTop: "3%" }}>
                                                Employee<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <TextField
                                                style={{ width: '223px', height: '2vh', marginTop: "2%", marginLeft: "6%" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.department_employee}
                                                onChange={(e) => {
                                                    this.setState({ department_employee: e.target.value })
                                                    // console.log(all_category)
                                                }}

                                            ><MenuItem value={"N"}>{"Select Employee"}</MenuItem>
                                                {Array.isArray(this.props.employee.employee_by_dept_id) && this.props.employee.employee_by_dept_id.map((e) => (
                                                    <MenuItem value={e._id}>{e.first_name ? e.first_name : e.name} {e.last_name}</MenuItem>
                                                ))}
                                            </TextField>
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "row", gap: '2rem', justifyContent: "center", marginTop: "2rem" }}>
                                            <div><button onClick={() => {
                                                this.setState({ addmember: false, project_department: "N", department_employee: "N" })
                                            }} style={{ minWidth: "7rem", height: "3rem", border: "none", borderRadius: "8px", backgroundColor: "#D9D9D9", color: "#8C8C8C", fontSize: "14px" }}>Cancel</button></div>
                                            <div><button style={{ minWidth: "7rem", height: "3rem", border: "none", borderRadius: "8px", backgroundColor: "#7900FF", color: "#FFFFFF", fontSize: "14px" }}
                                                onClick={() => {
                                                    this.setState({ addmember: false, project_department: "N", department_employee: "N" });
                                                    this.props.add_members(this.state.department_employee, this.props.phase.phase_id)
                                                }}
                                            >Save</button></div>
                                        </div>

                                        <div style={{ marginBottom: "3%" }}>
                                        </div>
                                    </Grid>

                                </Dialog>

                                <Dialog
                                    open={this.state.deletemember}
                                    onClose={() => {
                                        this.setState({ deletemember: false });
                                    }}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ width: "100%", height: "100%", }}
                                >
                                    {/* <IconButton
                    > */}

                                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                                        <center>
                                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                                        </center>
                                        <DialogActions style={{ marginTop: "42px" }}>

                                            <Button variant="contained"
                                                style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}
                                                onClick={() => { this.setState({ deletemember: false }) }}>

                                                Cancel
                                            </Button>
                                            <Button variant="contained"
                                                style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}

                                                onClick={() => {
                                                    this.setState({ deletemember: false });

                                                    this.props.isCheckedIdMember(this.props.members.members, this.props.phase.phase_id)

                                                }}
                                            >

                                                Confirm
                                            </Button>


                                        </DialogActions>
                                    </Grid>






                                </Dialog>




                            </>
                        }
                        {/* timesheet */}

                        {
                            this.props.login.type !== "AuthPerson" && this.state.TimesheetOpen &&
                            <>

                                <div className="header">
                                    <div className="title">

                                        All Timesheet <AiOutlineDown className='downArrow' />
                                    </div>
                                    <div className="button">

                                        <button className='export'>
                                            <img src={exp} alt="" srcset="" /> Export
                                        </button>
                                    </div>
                                </div>
                                <table className="student-table" >
                                    <thead>
                                        <tr>

                                            <th className='timeSheet'> Member  </th>
                                            <th className='timeSheet'>10-06-2023</th>
                                            <th className='timeSheet'>11-06-2023</th>
                                            <th className='timeSheet'>12-06-2023</th>
                                            <th className='timeSheet'>13-06-2023 </th>
                                            <th className='timeSheet'>14-06-2023</th>

                                            <th className='TimeSheet'  >Total Time Spend</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr >

                                            <td className='timeSheet'>1. Milestone 1 </td>
                                            <td className='timeSheet'></td>
                                            <td className='timeSheet'>

                                            </td>

                                            <td className='timeSheet'>
                                                2hr

                                            </td>

                                            <td className='timeSheet'>2hr</td>
                                            <td className='timeSheet'>2hr</td>

                                            <td className='TimeSheet' >10 hr</td>

                                        </tr>

                                    </tbody>
                                </table>
                            </>
                        }

                    </div >

                    <Dialog open={this.state.accessModal}
                        onClose={() => {
                            this.setState({ accessModal: false })
                        }}>
                        <DialogTitle>Terms and Conditions</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please read and accept the terms and conditions before proceeding.
                            </DialogContentText>
                            <Checkbox

                                checked={this.state.accepted}
                                onChange={() => {
                                    this.setState({ accepted: true })
                                }}
                                color="primary"
                            />
                            <label> I accept the terms and conditions</label>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { this.setState({ accessModal: false }) }} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    this.props.create_new_access(this.props.login.user_id, this.props.login.org_id, 'tms')
                                }}
                                color="primary"
                                disabled={!this.state.accepted}
                            >
                                Agree
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div className="right">
                        <div className="upper">

                            <div className="title">
                                <p>Project Name:</p>
                                <span>
                                    {all_project.project_title}
                                </span>
                            </div>
                            {/* <div className="pid">
                                <p>
                                    Project ID:
                                </p>
                                <span>
                                    {all_project.project_id}
                                </span>
                            </div> */}
                            <div className="status">
                                <p>Status:</p>
                                <span>
                                    <RxDotFilled />
                                    {all_project.status_id}
                                </span>
                            </div>
                            <div className="priority">
                                <p>

                                    Priority:
                                </p>
                                <span>   <RxDotFilled className='dotIcon' />{all_project.priority_id}</span>


                            </div>
                            {/* <div className="Authorizedperoson">
                                <p>Authorized Person:</p>
                                <span>
                                    {all_project.auth_person}
                                </span>
                            </div>
                            <div className="progress">
                                Progress:
                                <div className='progressbar'>

                                    <div className='innerBorder'></div>
                                </div>
                                <span>

                                    5%
                                </span>
                            </div> */}

                        </div>
                        <div className="middel">
                            <div className="title">
                                MileStone
                            </div>
                            <div className="task">
                                <div className='first'>
                                    <span>{this.props.milestone.milestone.filter(item => item.status === 'Completed').length}</span>
                                    <p>completed</p>
                                </div>
                                <div className='second'>
                                    <span>{this.props.milestone.milestone.filter(item => item.status === 'Pending').length}</span>
                                    <p>pending</p>
                                </div>
                                <div className='third'>
                                    <span>{this.props.milestone.milestone.length}</span>
                                    <p>total</p>
                                </div>
                            </div>
                        </div>
                        <div className="down">
                            <div className="req">
                                <p>Problems:</p>
                                <span>{this.props.request.view_all_request.filter(item => item.request_type === 'problem').length}</span>
                            </div>
                            <div className="req">
                                <p>Change Request:</p>
                                <span>{this.props.request.view_all_request.filter(item => item.request_type === 'change_request').length}</span>
                            </div>
                            <div className="req">
                                <p>Adds on:</p>
                                <span>{this.props.request.view_all_request.filter(item => item.request_type === 'add-on').length}</span>
                            </div>
                        </div>

                    </div>
                </div >
            </Grid >
        )
    }
}
