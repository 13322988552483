import {

    VIEW_ALL_MILESTONE,
    VIEW_ALL_MILESTONE_BY_ID,
    SEARCH_MILESTONE,
    SET_MILESTONE_ID,
    SET_MILESTONE_NAME
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";

import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";




export function add_milestone(project_id, phase_id, title, desc, start_date, dead_line, status, priority, max_hr, attachment) {
    return (dispatch) => {
        dispatch(setLoader());
        console.log("ppphhhaseeeeee", "phase_id")
        return fetch(UNIVERSAL.BASEURL + "add_milestone", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                phase_id: phase_id,
                project_id: project_id

            },
            body: JSON.stringify({
                title: title,
                desc: desc,
                start_date: start_date,
                dead_line: dead_line,
                status: status,
                priority: priority,
                max_hr: max_hr,
                attachment: attachment,



            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_milestone(phase_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function add_upload_milestone(project_id, phase_id, title, desc, start_date, dead_line, status, priority, max_hr, attachment) {
    return dispatch => {
        console.log("milestoneeeeeeee", status)
        dispatch(setLoader());
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/Milestone/" + attachment + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachment);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachment) {
                        console.log(attachment)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_milestone(project_id, phase_id, title, desc, start_date, dead_line, status, priority, max_hr, attachment));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(add_milestone(project_id, phase_id, title, desc, start_date, dead_line, status, priority, max_hr, attachment));
        }

    }
}
export function view_all_milestone(phase_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_milestone", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                phase_id: phase_id
            },


        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)

                    dispatch({ type: VIEW_ALL_MILESTONE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_MILESTONE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_milestone_by_id(phase_id, milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_milestone_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                milestone_id: milestone_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)

                    dispatch({ type: VIEW_ALL_MILESTONE_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_MILESTONE_BY_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });

    };
}
export function bulk_delete_milestone(isChecked, phase_id) {
    return (dispatch) => {
        console.log("ischeckmilestoneeee", isChecked)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_milestone", {

            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                milestone_id: isChecked
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(view_all_milestone(phase_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());

                console.error(error);
            });
    };
}





export function CheckedMilestone(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheckMilestone = false
    return {
        type: VIEW_ALL_MILESTONE,
        payload: payload
    }
};
export function getallcheckedMilestone(all_category) {
    for (var i = 0; i <= all_category.length - 1; i++) {
        all_category[i].checked = true;
    }
    return {
        type: VIEW_ALL_MILESTONE,

    }
};
export function allCheckMilestone(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheckMilestone = checked;
        if (i === payload.length - 1)
            return {
                type: VIEW_ALL_MILESTONE,
                payload: payload
            }
    }
};
export function isCheckedIdMilestone(all_category, phase_id) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= all_category.length - 1; i++) {
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
            }
            count = i;
        }
        if (count === all_category.length - 1) {
            dispatch(bulk_delete_milestone(isChecked, phase_id))
        }
    }

};

export function update_milestone(_id, title, desc, start_date, dead_line, status_id, priority, max_hr, attachment, phase_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_milestone", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
                title: title,
                desc: desc,
                start_date: start_date,
                dead_line: dead_line,
                status_id: status_id,
                priority: priority,
                max_hr: max_hr,
                attachment: attachment

            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_milestone(phase_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}

export function update_milestone_profile(_id, title, desc, start_date, dead_line, status_id, priority, max_hr, attachment, phase_id, attachment1) {
    return dispatch => {
        console.log("idddddd", status_id)
        dispatch(setLoader(true));
        if (attachment1 == "") {
            dispatch(update_milestone(_id, title, desc, start_date, dead_line, status_id, priority, max_hr, attachment, phase_id));

        } else {
            const storageRef = ref(getStorage(), "/Milestone/" + attachment1 + ".png")

            // .put(image)
            // uploadBytes(storageRef, image).then((snapshot) => {
            //     console.log('Uploaded a blob or file!');
            //   });
            const uploadTask = uploadBytesResumable(storageRef, attachment1);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (attachment1) {
                        // console.log(downloadURL)
                        dispatch(update_milestone(_id, title, desc, start_date, dead_line, status_id, priority, max_hr, attachment1, phase_id));
                    });
                }
            );
        }
    }
}

export function update_milestone_status(id, status_id, phase_id, user_type, emp_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_milestone_status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,
                status_id: status_id,
                phase_id: phase_id,

            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    if (user_type === "TeamLead") {
                        view_all_milestone_by_member(emp_id)
                    }
                    else {
                        dispatch(view_all_milestone(phase_id));
                    }
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}


export function search_Milestone(milestone, e) {
    return (dispatch) => {
        // console.log(e)
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_MILESTONE, payload: milestone });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = milestone.filter((el) => {
                // console.log(el.first_name)
                return (el.title.toLowerCase().includes(e.toLowerCase()));
            })
            // const array = report.fil
            dispatch({ type: SEARCH_MILESTONE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}
export function search_Milestone2(milestone, e) {
    return (dispatch) => {
        // console.log(e)
        // dispatch(setLoader());
        if (e === "" || e === "N") {
            dispatch({ type: SEARCH_MILESTONE, payload: milestone });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = milestone.filter((el) => {
                // console.log(el.first_name)
                return (el.status.toLowerCase().includes(e.toLowerCase()));
            })
            // const array = report.fil
            dispatch({ type: SEARCH_MILESTONE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

export function setMilestoneId(payload) {
    return {

        type: SET_MILESTONE_ID,
        payload: payload,
    };
}

export function setMilestoneName(payload) {
    return {

        type: SET_MILESTONE_NAME,
        payload: payload,
    };
}
export function view_all_milestone_by_member(emp_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_milestone_by_member", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                emp_id: emp_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)

                    dispatch({ type: VIEW_ALL_MILESTONE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_MILESTONE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}