import React, { Component } from "react";
import { connect } from "react-redux";
import Companymemberpage from '../../Company/component/Companymemberpage';

import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  view_all_status,
  view_all_priority,
  view_all_department,
  create_new_access,
} from "../../../auth/actions";

import { add_upload_milestone, view_all_milestone, update_milestone_profile, CheckedMilestone, getallcheckedMilestone, allCheckMilestone, isCheckedIdMilestone, search_Milestone, } from "../../Milestone/action"
import { add_upload_request, upload_upadate_request, generate_task_repote_for_space, add_requirement ,view_all_requirement_by_milestone, update_requirement_status} from "../../Company/action"
import { add_testing, view_all_testing, CheckedTesting, getallchecked, allCheck, isCheckedId } from "../../Testing/action"
// import { view_all_task_by_milestone } from "../action"
import { add_Upload_Task, view_All_Task_By_Milestone, update_Milestone_Task, search_Task, CheckedTask, getallcheckedTask, allCheckTask, isCheckedTask } from '../../Task/action'

import { addAddFollowUps, viewAllFollowByMilestone, updateFollowUp, search_FollowUps, CheckedFollowUp, getallcheckedd, allCheckk, isCheckedId2 } from '../../FollowUps/action'
import { add_milestone_member, view_all_milestone_members, update_milestone_members, search_Milestone_Members, } from '../../MilestoneMembers/action'
import { addUploadMeetings, addMeeting, viewAllMeetingByMilestone } from '../../Meeting/action'
import { view_all_members_by_phase, } from '../../Members/action'
import { view_all_employee } from '../../Employee/action'
import { view_all_documnet_by_milestone, addUploadDocuments } from '../../Document/action'
import { add_request } from '../../Request/action'
export class Controller extends Component {
  render() {
    return (
      <Companymemberpage {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    document: store.document,
    employee: store.employee,
    companyassets: store.companyassets,
    snackbar: store.snackbar,
    login: store.login,
    company: store.company,
    all_project: store.all_project,
    phase: store.phase,
    milestone: store.milestone,
    testing: store.testing,
    task: store.task,
    followup: store.followup,
    all_project: store.all_project,
    milestoneMembers: store.milestoneMembers,
    members: store.members,
    meeting: store.meeting

  };
};

export const mapDispatchToProps = dispatch => {
  return {
    view_all_status: (org_id) => {
      dispatch(view_all_status(org_id));
    },
    view_all_employee: (org_id) => {
      dispatch(view_all_employee(org_id))
    },
    add_upload_milestone: (project_id, phase_id, title, desc, start_date, dead_line, status, priority, max_hr, attachment) => {
      dispatch(add_upload_milestone(project_id, phase_id, title, desc, start_date, dead_line, status, priority, max_hr, attachment));
    },
    view_all_priority: (org_id) => {
      dispatch(view_all_priority(org_id));
    },
    view_all_milestone: (phase_id) => {
      dispatch(view_all_milestone(phase_id));
    },
    update_milestone_profile: (_id, title, desc, start_date, dead_line, status_id, priority, max_hr, attachment, phase_id) => {
      dispatch(update_milestone_profile(_id, title, desc, start_date, dead_line, status_id, priority, max_hr, attachment, phase_id));
    },
    CheckedMilestone: (index, checked, quantity, all_category) => {
      dispatch(CheckedMilestone(index, checked, quantity, all_category));
    },
    getallcheckedMilestone: (all_category) => {
      dispatch(getallcheckedMilestone(all_category));
    },
    allCheckMilestone: (checked, quantity, all_category) => {
      dispatch(allCheckMilestone(checked, quantity, all_category));
    },
    isCheckedIdMilestone: (all_category, phase_id) => {
      dispatch(isCheckedIdMilestone(all_category, phase_id));
    },
    search_Milestone: (milestone, e) => {
      dispatch(search_Milestone(milestone, e));
    },
    upload_upadate_request: (project_id, _id, change_title, desc, request_date, attachment, request_type, priority, attachments1) => {
      dispatch(upload_upadate_request(project_id, _id, change_title, desc, request_date, attachment, request_type, priority, attachments1))
    },
    // add_upload_addon: (phase_id, project_name, project_phase, desc, req_date, starting_date, res_date, attachment) => {
    //   dispatch(add_upload_addon(phase_id, project_name, project_phase, desc, req_date, starting_date, res_date, attachment));
    // },
    add_upload_request: (project_id, change_title, desc, request_date, attachment, request_type, priority) => {
      dispatch(add_upload_request(project_id, change_title, desc, request_date, attachment, request_type, priority))
    },
    add_testing: (test_case_type, testing_phase, phase_id) => {
      dispatch(add_testing(test_case_type, testing_phase, phase_id))
    },
    view_all_testing: (phase_id) => {
      dispatch(view_all_testing(phase_id))
    },
    // CheckedTesting: (index, checked, quantity, all_category) => {
    //   dispatch(CheckedTesting(index, checked, quantity, all_category));
    // },
    // getallchecked: (all_category) => {
    //   dispatch(getallchecked(all_category));
    // },
    // allCheck: (checked, quantity, all_category) => {
    //   dispatch(allCheck(checked, quantity, all_category));
    // },
    // isCheckedId: (phase_id, all_category) => {
    //   dispatch(isCheckedId(phase_id, all_category));
    // },
    add_Upload_Task: (milestone_id, title, desc, staart_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets) => {
      dispatch(add_Upload_Task(milestone_id, title, desc, staart_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets))
    },

    view_All_Task_By_Milestone: (milestone_id) => {
      dispatch(view_All_Task_By_Milestone(milestone_id))
    },

    update_Milestone_Task: (milestone_id, _id, title, desc, start_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets) => {
      dispatch(update_Milestone_Task(milestone_id, _id, title, desc, start_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets))
    },

    search_Task: (view_task, e) => {
      dispatch(search_Task(view_task, e))
    },


    CheckedTask: (index, checked, quantity, all_category) => {
      dispatch(CheckedTask(index, checked, quantity, all_category));
    },
    getallcheckedTask: (all_category) => {
      dispatch(getallcheckedTask(all_category));
    },
    allCheckTask: (checked, quantity, all_category) => {
      dispatch(allCheckTask(checked, quantity, all_category));
    },
    isCheckedTask: (org_id, all_category) => {
      dispatch(isCheckedTask(org_id, all_category));
    },

    addAddFollowUps: (milestone_id, co_ordinator, addressee, follow_up_type, follow_up_medium, follow_up_date, follow_up_time, follow_up_Desc) => {
      dispatch(addAddFollowUps(milestone_id, co_ordinator, addressee, follow_up_type, follow_up_medium, follow_up_date, follow_up_time, follow_up_Desc));
    },

    viewAllFollowByMilestone: (milestone_id) => {
      dispatch(viewAllFollowByMilestone(milestone_id));
    },

    updateFollowUp: (milestone_id, cntct_person, follow_date, project_type, desc, _id) => {
      dispatch(updateFollowUp(milestone_id, cntct_person, follow_date, project_type, desc, _id));
    },

    search_FollowUps: (follow_up_by_milestone, e) => {
      dispatch(search_FollowUps(follow_up_by_milestone, e));
    },

    CheckedFollowUp: (index, checked, quantity, followups) => {
      dispatch(CheckedFollowUp(index, checked, quantity, followups));
    },
    getallcheckedd: (followups) => {
      dispatch(getallcheckedd(followups));
    },
    allCheckk: (checked, quantity, followups) => {
      dispatch(allCheckk(checked, quantity, followups));
    },
    isCheckedId2: (milestone_id, followups) => {
      dispatch(isCheckedId2(milestone_id, followups));
    },

    add_milestone_member: (milestone_id, emp_id) => {
      dispatch(add_milestone_member(milestone_id, emp_id));
    },

    view_all_milestone_members: (milestone_id) => {
      dispatch(view_all_milestone_members(milestone_id));
    },
    update_milestone_members: (_id, milestone_id, emp_id, start_date, end_date, desc) => {
      dispatch(update_milestone_members(_id, milestone_id, emp_id, start_date, end_date, desc))
    },
    search_Milestone_Members: (milestone_members, e) => {
      dispatch(search_Milestone_Members(milestone_members, e))
    },
    addUploadMeetings: (milestone_id, date, time, agenda, attendees, attcah_files) => {
      dispatch(addUploadMeetings(milestone_id, date, time, agenda, attendees, attcah_files))
    },
    addUploadDocuments: (date, time, title, link, attach_files, access_id, milestone_id) => {
      dispatch(addUploadDocuments(date, time, title, link, attach_files, access_id, milestone_id))
    },
    addMeeting: (milestone_id, start_date, time, summary, members, desc) => {
      dispatch(addMeeting(milestone_id, start_date, time, summary, members, desc))
    },
    view_all_members_by_phase: (phase_id) => {
      dispatch(view_all_members_by_phase(phase_id))
    },
    viewAllMeetingByMilestone: (milestone_id) => {
      dispatch(viewAllMeetingByMilestone(milestone_id))
    },
    view_all_documnet_by_milestone: (milestone_id) => {
      dispatch(view_all_documnet_by_milestone(milestone_id))
    },
    create_new_access: (user_id, org_id, product) => {
      dispatch(create_new_access(user_id, org_id, product))
    },
    generate_task_repote_for_space: (space_id, start_date, end_date) => {
      dispatch(generate_task_repote_for_space(space_id, start_date, end_date))
    },

    add_requirement: (project_id, milestone_id, desc, request_date, attachment, request_type, title, request_status) => {
      dispatch(add_requirement(project_id, milestone_id, desc, request_date, attachment, request_type, title, request_status))
    },
    view_all_requirement_by_milestone: (milestone_id) => {
      dispatch(view_all_requirement_by_milestone(milestone_id))
    },
    update_requirement_status: (req_id, milestone_id, status) => {
      dispatch(update_requirement_status(req_id, milestone_id, status))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);