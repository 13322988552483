import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../containers/controller_cont";
import Login from "../../auth/container/logincont";
// import Dashboard from "../../pages/admin/dashboard/container/dashbordCont";
import Loginotp from "../../auth/container/loginotpcont";
import Drawer_option_con from "../containers/drawer_cont";
//Admin
import AdNav from "../../common/navbar/adNav";
import Addrawer from "../../router/components/addrawer";
import Project from "../../pages/Project/container/projectCont";
// import Projectdescription from "../../pages/admin/Project/component/projectdescription";
import Assets from "../../pages/Assets/container/assetsCont";
import Employee from "../../pages/Employee/container/empCont";
import Service from "../../pages/Services/container/serviceCont";
import Companyopen from "../../pages/Company/container/companyopenCont";
import Company from '../../pages/Company/container/companyCont';
import Companyphase from "../../pages/Company/container/CompanyphaseCont";
import Phase from "../../pages/Phase/container/phaseCont";
import TlMilestone from "../../pages/Milestone/container/TlMilestoneCont";
import Companymilestone from "../../pages/Company/container/CompanymilenstoneCont";
import Companymemberpage from "../../pages/Company/container/CompanymemberpageCont";
// import Adeditprofile from "../../router/components/adeditprofile";
import Addashboard from "../../pages/dashboard/component/addashboard";
import Clientashboard from "../../pages/dashboard/component/clientdashboard";
import Clientinvoice from "../../pages/Invoice/container/invoiceCont";
import ClientProject from "../../pages/Project/container/ClientprojectCont";
import Clientassets from "../../pages/Assets/container/clientassetsCont";
import Clientservice from "../../pages/Services/container/clientserviceCont";
import Clienttickets from "../../pages/Tickets/container/ClientTicketCont";
import Issue from "../../auth/container/issueCont";
import Vieworg from '../../auth/container/vieworgCont'
import Error from "../../404";
// import Milestone from "../../pages/admin/Project/component/Milestone";
// import Memberprogresspage from "../../pages/admin/Project/component/Memberprogresspage";


import Tickets from "../../pages/Tickets/container/AdminTicketCont"
import Arrow from "../../pages/Company/component/arrow"




//Employee
// import Empnav from "../../common/navbar/Empnav";
// import Empdrawer from "../../router/components/empdrwer";
// import Empproject from "../../pages/Emp/Components/Empproject"
// import Empprojectdescription from "../../pages/Emp/Components/Empprojectdescription"
// import Empmilestone from "../../pages/Emp/Components/Empmilestone"
// import Empmemberprogresspage from "../../pages/Emp/Components/Empmemberprogresspage"
// import Empdashboard from "../../pages/Emp/Components/empdashboard";
// // import Empmemberprogresspage from "../../pages/Emp/Components/Empmemberprogresspage";
// import Emptask from "../../pages/Emp/Components/Emptask";







// Client
// import Clientnav from "../../common/navbar/clientnav";
// import Clientdrawer from "../../router/components/clientdrawer";
// import Clientdashboard from "../../pages/Client/Components/clientdashboard";
// import Clientproject from "../../pages/Client/Components/Clientproject";
// import Clientphase from "../../pages/Client/Components/Clientphase";
// import Clientdoc from "../../pages/Client/Components/Clientdoc";

// import Clientservice from "../../pages/Client/Components/clientservice";
// import Clientassets from "../../pages/Client/Components/clientassets";
// import Clienttickets from "../../pages/Client/Components/clienttickets"
// import Clientinvoice from "../../pages/Client/Components/clientinvoice"


class Routers extends Component {
  render() {
    // console.log(this.props.login.isHome, this.props.login.orgHome)
    const { login, onLogout } = this.props;
    let body;

    // <Routes>
    {/* <Route exact path="/" element={<Login />} />
    <Route exact path="/loginotp" element={<Loginotp />} />
    <Route exact path="/checkstatus" element={<OrderStatus />} />
    <Route exact path="/checkstatusdownload" element={< OrderStatusdown />} /> */}
    {/* <Route exact path="*" element={<Error />} /> */ }
    if (login.isHome) {
      body = (
        <div style={{ background: "#FBF8FF" }}>
          <main
            style={{
              flexGrow: 1,
            }}
          >
            {/* <Controller /> */}
            <Routes>
              {/* <Route exact path="/" element={<Company />} /> */}
              {/* <Route exact path="/" element={<Login />} /> */}
              <Route exact path="/" element={<Login />} />
              <Route exact path="/verify/:id" element={<Login />} />
              {/* <Route exact path="/:id" element={<Login />} /> */}
              <Route exact path="/loginotp" element={<Loginotp />} />
              <Route exact path="/issues/:id/:ticket_id" element={<Issue />} />
              {/* <Route exact path="/checkstatus" element={<OrderStatus />} />
              <Route exact path="/checkstatusdownload" element={< OrderStatusdown />} /> */}
              {/* <Route exact path="*" element={<Error />} /> */}

            </Routes>

          </main>
        </div>
      );
    }
    else if (login.orgHome) {
      console.log(login.type)
      if (login.type === "Owner") {
        body = (
          <div
            style={{
              // display: "flex",

            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: "5%",
                // background: "#FBF8FF"
              }}
            >
              <Controller />
              <Routes>

                <Route exact path="/" element={<Addashboard />} />
                <Route exact path="/verify/:id" element={<Addashboard />} />

                //Admin

                <Route exact path="/project" element={<Project />} />
                {/* <Route exact path="/projectdescription" element={<><Projectdescription />} />
                <Route exact path="/milestone" element={<><Milestone />} />

                <Route exact path="/memberprogresspage" element={<><Memberprogresspage />} /> */}

                <Route exact path="/assets" element={<Assets />} />
                <Route exact path="/arrow" element={<Arrow />} />
                <Route exact path="/Employee" element={<Employee />} />
                <Route exact path="/service" element={<Service />} />
                {/* <Route exact path="/Employee" element={<Addrawer /><Employee />} /> */}
                <Route exact path="/Company" element={<Company />} />
                <Route exact path="/companyopen" element={<Companyopen />} />
                <Route exact path="/companyphase" element={<Companyphase />} />
                <Route exact path="/Companymilestone" element={<Companymilestone />} />
                <Route exact path="/Companymemberpage" element={<Companymemberpage />} />
                {/* <Route exact path="/companyopen" element={<Companyopen />} /> */}
                <Route exact path="/tickets" element={<Tickets type="d" />} />
                {/* <Route exact path="/Adeditprofile" element={<><Adeditprofile /></>} />
                {/* <Route exact path="/logout" element={<Logout />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      if (login.type === "AuthPerson") {
        body = (
          <div
            style={{
              // display: "flex",

            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: "5%",
                // background: "#FBF8FF"
              }}
            >
              <Controller />
              <Routes>

                <Route exact path="/" element={<Project />} />
                <Route exact path="/verify/:id" element={<Project />} />

                //Admin

                <Route exact path="/project" element={<Project />} />
                {/* <Route exact path="/projectdescription" element={<><Projectdescription />} />
                <Route exact path="/milestone" element={<><Milestone />} />

                <Route exact path="/memberprogresspage" element={<><Memberprogresspage />} /> */}

                <Route exact path="/assets" element={<Assets />} />
                <Route exact path="/arrow" element={<Arrow />} />
                <Route exact path="/Employee" element={<Employee />} />
                <Route exact path="/service" element={<Service />} />
                {/* <Route exact path="/Employee" element={<Addrawer /><Employee />} /> */}
                {/* <Route exact path="/Company" element={<Company />} /> */}
                <Route exact path="/companyopen" element={<Companyopen />} />
                <Route exact path="/companyphase" element={<Companyphase />} />
                <Route exact path="/Companymilestone" element={<Companymilestone />} />
                <Route exact path="/Companymemberpage" element={<Companymemberpage />} />
                {/* <Route exact path="/companyopen" element={<Companyopen />} /> */}
                <Route exact path="/tickets" element={<Tickets type="d" />} />
                {/* <Route exact path="/Adeditprofile" element={<><Adeditprofile /></>} />
                {/* <Route exact path="/logout" element={<Logout />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      if (login.type === "Finance") {
        body = (
          <div
            style={{
              // display: "flex",

            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: "5%",
                // background: "#FBF8FF"
              }}
            >
              <Controller />
              <Routes>

                <Route exact path="/" element={<Addashboard />} />
                <Route exact path="/verify/:id" element={<Addashboard />} />

                //Admin

                <Route exact path="/project" element={<Project />} />
                {/* <Route exact path="/projectdescription" element={<><Projectdescription />} />
                <Route exact path="/milestone" element={<><Milestone />} />

                <Route exact path="/memberprogresspage" element={<><Memberprogresspage />} /> */}

                <Route exact path="/assets" element={<Assets />} />
                <Route exact path="/arrow" element={<Arrow />} />
                <Route exact path="/Employee" element={<Employee />} />
                <Route exact path="/service" element={<Service />} />
                {/* <Route exact path="/Employee" element={<Addrawer /><Employee />} /> */}
                <Route exact path="/Company" element={<Company />} />
                <Route exact path="/companyopen" element={<Companyopen />} />
                <Route exact path="/companyphase" element={<Companyphase />} />
                <Route exact path="/Companymilestone" element={<Companymilestone />} />
                <Route exact path="/Companymemberpage" element={<Companymemberpage />} />
                {/* <Route exact path="/companyopen" element={<Companyopen />} /> */}
                <Route exact path="/tickets" element={<Tickets type="d" />} />
                {/* <Route exact path="/Adeditprofile" element={<><Adeditprofile /></>} />
                {/* <Route exact path="/logout" element={<Logout />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      if (login.type === "HumanResource") {
        body = (
          <div
            style={{
              // display: "flex",

            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: "5%",
                // background: "#FBF8FF"
              }}
            >
              <Controller />
              <Routes>

                <Route exact path="/" element={<Addashboard />} />
                <Route exact path="/verify/:id" element={<Addashboard />} />

                //Admin

                <Route exact path="/project" element={<Project />} />
                {/* <Route exact path="/projectdescription" element={<><Projectdescription />} />
                <Route exact path="/milestone" element={<><Milestone />} />

                <Route exact path="/memberprogresspage" element={<><Memberprogresspage />} /> */}

                <Route exact path="/assets" element={<Assets />} />
                <Route exact path="/arrow" element={<Arrow />} />
                <Route exact path="/Employee" element={<Employee />} />
                <Route exact path="/service" element={<Service />} />
                {/* <Route exact path="/Employee" element={<Addrawer /><Employee />} /> */}
                <Route exact path="/Company" element={<Company />} />
                <Route exact path="/companyopen" element={<Companyopen />} />
                <Route exact path="/companyphase" element={<Companyphase />} />
                <Route exact path="/Companymilestone" element={<Companymilestone />} />
                <Route exact path="/Companymemberpage" element={<Companymemberpage />} />
                {/* <Route exact path="/companyopen" element={<Companyopen />} /> */}
                <Route exact path="/tickets" element={<Tickets type="d" />} />
                {/* <Route exact path="/Adeditprofile" element={<><Adeditprofile /></>} />
                {/* <Route exact path="/logout" element={<Logout />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      if (login.type === "Executive") {
        body = (
          <div
            style={{
              // display: "flex",

            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: "5%",
                // background: "#FBF8FF"
              }}
            >
              <Controller />
              <Routes>

                <Route exact path="/verify/:id" element={<Addashboard />} />
                <Route exact path="/tlmilestone" element={<TlMilestone />} />

                //Admin

                {/* <Route exact path="/project" element={<Project />} /> */}
                {/* <Route exact path="/projectdescription" element={<><Projectdescription />} />
                <Route exact path="/milestone" element={<><Milestone />} />

                <Route exact path="/memberprogresspage" element={<><Memberprogresspage />} /> */}

                {/* <Route exact path="/assets" element={<Assets />} />  */}
                <Route exact path="/arrow" element={<Arrow />} />
                {/* <Route exact path="/Employee" element={<Employee />} />
                <Route exact path="/service" element={<Service />} /> */}
                {/* <Route exact path="/Employee" element={<Addrawer /><Employee />} /> */}
                {/* <Route exact path="/Company" element={<Company />} />
                <Route exact path="/companyopen" element={<Companyopen />} />
                <Route exact path="/companyphase" element={<Companyphase />} /> */}
                <Route exact path="/Companymilestone" element={<Companymilestone />} />
                <Route exact path="/Companymemberpage" element={<Companymemberpage />} />
                {/* <Route exact path="/companyopen" element={<Companyopen />} /> */}
                <Route exact path="/tickets" element={<Tickets type="d" />} />
                {/* <Route exact path="/Adeditprofile" element={<><Adeditprofile /></>} />
                {/* <Route exact path="/logout" element={<Logout />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      if (login.type === "TeamLead") {
        body = (
          <div
            style={{
              // display: "flex",

            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: "5%",
                // background: "#FBF8FF"
              }}
            >
              <Controller />
              <Routes>

                <Route exact path="/" element={<Addashboard />} />
                <Route exact path="/verify/:id" element={<Addashboard />} />

                //Admin

                <Route exact path="/tlmilestone" element={<TlMilestone />} />
                {/* <Route exact path="/projectdescription" element={<><Projectdescription />} />
                <Route exact path="/milestone" element={<><Milestone />} />

                <Route exact path="/memberprogresspage" element={<><Memberprogresspage />} /> */}

                <Route exact path="/assets" element={<Assets />} />
                <Route exact path="/arrow" element={<Arrow />} />
                <Route exact path="/Employee" element={<Employee />} />
                <Route exact path="/service" element={<Service />} />
                {/* <Route exact path="/Employee" element={<Addrawer /><Employee />} /> */}
                <Route exact path="/Company" element={<Company />} />
                <Route exact path="/companyopen" element={<Companyopen />} />
                <Route exact path="/companyphase" element={<Companyphase />} />
                <Route exact path="/Companymilestone" element={<Companymilestone />} />
                <Route exact path="/Companymemberpage" element={<Companymemberpage />} />
                {/* <Route exact path="/companyopen" element={<Companyopen />} /> */}
                <Route exact path="/tickets" element={<Tickets type="d" />} />
                {/* <Route exact path="/Adeditprofile" element={<><Adeditprofile /></>} />
                {/* <Route exact path="/logout" element={<Logout />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      if (login.type === "Manager") {
        body = (
          <div
            style={{
              // display: "flex",

            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: "5%",
                // background: "#FBF8FF"
              }}
            >
              <Controller />
              <Routes>

                <Route exact path="/" element={<Addashboard />} />
                <Route exact path="/verify/:id" element={<Addashboard />} />

                //Admin

                <Route exact path="/phase" element={<Phase />} />
                {/* <Route exact path="/projectdescription" element={<><Projectdescription />} />
                <Route exact path="/milestone" element={<><Milestone />} />

                <Route exact path="/memberprogresspage" element={<><Memberprogresspage />} /> */}

                {/* <Route exact path="/assets" element={<Assets />} />  */}
                <Route exact path="/arrow" element={<Arrow />} />
                {/* <Route exact path="/Employee" element={<Employee />} /> */}
                {/* <Route exact path="/service" element={<Service />} /> */}
                {/* <Route exact path="/Employee" element={<Addrawer /><Employee />} /> */}
                {/* <Route exact path="/Company" element={<Company />} /> */}
                {/* <Route exact path="/companyopen" element={<Companyopen />} /> */}
                {/* <Route exact path="/companyphase" element={<Companyphase />} /> */}
                <Route exact path="/Companymilestone" element={<Companymilestone />} />
                <Route exact path="/Companymemberpage" element={<Companymemberpage />} />
                {/* <Route exact path="/companyopen" element={<Companyopen />} /> */}
                {/* <Route exact path="/tickets" element={<Tickets />} /> */}
                {/* <Route exact path="/Adeditprofile" element={<><Adeditprofile /></>} />
                {/* <Route exact path="/logout" element={<Logout />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      if (login.type === "Consultant") {
        body = (
          <div
            style={{
              // display: "flex",

            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: "5%",
                // background: "#FBF8FF"
              }}
            >
              <Controller />
              <Routes>

                <Route exact path="/" element={<Addashboard />} />
                <Route exact path="/verify/:id" element={<Addashboard />} />

                //Admin

                <Route exact path="/project" element={<Project />} />
                {/* <Route exact path="/projectdescription" element={<><Projectdescription />} />
                <Route exact path="/milestone" element={<><Milestone />} />

                <Route exact path="/memberprogresspage" element={<><Memberprogresspage />} /> */}

                <Route exact path="/assets" element={<Assets />} />
                <Route exact path="/arrow" element={<Arrow />} />
                <Route exact path="/Employee" element={<Employee />} />
                <Route exact path="/service" element={<Service />} />
                {/* <Route exact path="/Employee" element={<Addrawer /><Employee />} /> */}
                <Route exact path="/Company" element={<Company />} />
                <Route exact path="/companyopen" element={<Companyopen />} />
                <Route exact path="/companyphase" element={<Companyphase />} />
                <Route exact path="/Companymilestone" element={<Companymilestone />} />
                <Route exact path="/Companymemberpage" element={<Companymemberpage />} />
                {/* <Route exact path="/companyopen" element={<Companyopen />} /> */}
                <Route exact path="/tickets" element={<Tickets type="d" />} />
                {/* <Route exact path="/Adeditprofile" element={<><Adeditprofile /></>} />
                {/* <Route exact path="/logout" element={<Logout />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      if (login.type === "QA") {
        body = (
          <div
            style={{
              // display: "flex",

            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: "5%",
                // background: "#FBF8FF"
              }}
            >
              <Controller />
              <Routes>

                <Route exact path="/" element={<Addashboard />} />
                <Route exact path="/verify/:id" element={<Addashboard />} />

                //Admin

                <Route exact path="/project" element={<Project />} />
                {/* <Route exact path="/projectdescription" element={<><Projectdescription />} />
                <Route exact path="/milestone" element={<><Milestone />} />

                <Route exact path="/memberprogresspage" element={<><Memberprogresspage />} /> */}

                <Route exact path="/assets" element={<Assets />} />
                <Route exact path="/arrow" element={<Arrow />} />
                <Route exact path="/Employee" element={<Employee />} />
                <Route exact path="/service" element={<Service />} />
                {/* <Route exact path="/Employee" element={<Addrawer /><Employee />} /> */}
                <Route exact path="/Company" element={<Company />} />
                <Route exact path="/companyopen" element={<Companyopen />} />
                <Route exact path="/companyphase" element={<Companyphase />} />
                <Route exact path="/Companymilestone" element={<Companymilestone />} />
                <Route exact path="/Companymemberpage" element={<Companymemberpage />} />
                {/* <Route exact path="/companyopen" element={<Companyopen />} /> */}
                <Route exact path="/tickets" element={<Tickets type="d" />} />
                {/* <Route exact path="/Adeditprofile" element={<><Adeditprofile /></>} />
                {/* <Route exact path="/logout" element={<Logout />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      else if (login.type == "E") {
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            {/* <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} /> */}
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                // marginLeft: 250
              }}
            >
              {/* <Controller /> */}
              {/* <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/" element={<><Empnav /><Empdrawer /><Empdashboard /></>} />
                //Employee
                <Route exact path="/empnav" element={<Empnav />} />
                <Route exact path="/empdrawer" element={<Empdrawer />} />
                <Route exact path="/Empdashboard" element={<><Empnav /><Empdrawer /><Empdashboard /></>} />
                <Route exact path="/empproject" element={<><Empnav /><Empdrawer /><Project /></>} />
                <Route exact path="/empprojectdescription" element={<><Empnav /><Empdrawer /><Empprojectdescription /></>} />
                <Route exact path="/empmilestone" element={<><Empnav /><Empdrawer /><Empmilestone /></>} />
                <Route exact path="/empmemberprogresspage" element={<><Empnav /><Empdrawer /><Empmemberprogresspage /></>} />
                <Route exact path="/Empproject" element={<><Empnav /><Empdrawer /><Empproject /></>} />
                <Route exact path="/Emptask" element={<><Empnav /><Empdrawer /><Emptask /></>} />
                <Route exact path="/logout" element={<Logout />} />
              </Routes> */}
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      else if (login.type == "C") {
        //Client
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: 20,
              }}
            >
              {/* <Controller /> */}
              <Routes>
                <Route exact path="/" element={<Clientashboard />} />
                <Route exact path="/verify/:id" element={<Clientashboard />} />
                <Route exact path="/project" element={<ClientProject />} />
                <Route exact path="/invoice" element={<Clientinvoice />} />
                <Route exact path="/assets" element={<Clientassets />} />
                <Route exact path="/service" element={<Clientservice />} />
                <Route exact path="/tickets" element={<Clienttickets />} />

              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }

      else if (login.type == "Support") {
        //Client
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            {/* <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} /> */}
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                // marginLeft: 250
              }}
            >
              <Controller />
              <Routes>
                <Route exact path="/" element={<Tickets type="d" />} />
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      // else if(login.type=="AuthPerson"){
      //   //Client
      //   body = (
      //     <div
      //       style={{
      //         // display: "flex",
      //       }}
      //     >
      //       {/* <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} /> */}
      //       <main
      //         style={{
      //           flexGrow: 1,
      //           marginTop: 20,
      //           // marginLeft: 250
      //         }}
      //       >
      //         <Controller />
      //         <Routes>
      //         <Route exact path="/" element={<Company />} />
      //         <Route exact path="/companyopen" element={<Companyopen />} />
      //         </Routes>
      //       </main>
      //       {/* <Footer /> */}
      //     </div>
      //   );
      // }
      // }
      // else {
      //   body = (
      //     <div
      //     >
      //       <main>
      //         {/* <Controller /> */}
      //         <Routes>
      //           {/* <Route exact path="/" element={<Login />} /> */}
      //           <Route exact path="/employee" element={<><AdNav /><Addrawer /><Employee /></>} />
      //           <Route exact path="/project" element={<><AdNav /><Addrawer /><Project /></>} />
      //           <Route exact path="/Projectdescription" element={<><AdNav /><Addrawer /><Projectdescription /></>} />
      //           <Route exact path="/milestone" element={<><AdNav /><Addrawer /><Milestone /></>} />
      //           <Route exact path="/memberprogresspage" element={<><AdNav /><Addrawer /><MemberProgressPage /></>} />
      //           {/* <Route exact path="/" element={<Login />} /> */}
      //           {/* <Route exact path="/" element={<Login />} /> */}
      //           <Route exact path="/adNav" element={<AdNav />} />
      //           //Employee
      //           {/* <Route exact path="/" element={<Login />} /> */}
      //           {/* 
      //            path="/organization" element={<><Organization /></>} /> */}
      //         </Routes>
      //       </main>
      //     </div>
      //   );

    }
    else if (login.orgHome || !login.orgHome || login.isHome || !login.isHome) {
      <Route exact path="/issues" element={<Issue />} />
    } else {
      body = (
        <div>
          <main>
            <Controller />
            <Routes>
              <Route exact path="/" element={<><Vieworg /></>} />
            </Routes>
          </main>
        </div>
      );
    }
    return <Router>{body}</Router>;
  }
}

export default Routers; 