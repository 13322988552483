import {
   VIEW_ALL_AUTH_PERSON,
    VIEW_ALL_AUTH_PERSON_BY_ID,
    SEARCH_AUTH_PERSON,
    VIEW_ALL_COMP_AUTH_PERSON
} from "./constant";
const initial_state = {
  authoperson:[],
  authopersonById:[],
  search_auth_person:[],
  compauthoperson:[],
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case VIEW_ALL_AUTH_PERSON:
      console.log("authresultt", action.payload)
      return state = { ...state, authoperson: action.payload ,search_auth_person:action.payload};
    case VIEW_ALL_COMP_AUTH_PERSON:
      console.log("authresultt", action.payload)
      return state = { ...state, compauthoperson: action.payload };
      case VIEW_ALL_AUTH_PERSON_BY_ID:
      return state = { ...state, authopersonById: action.payload };

      case SEARCH_AUTH_PERSON:
        return state = { ...state, search_auth_person: action.payload };
    default:
      return state;
  }
}
