import React, { Component } from 'react'
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography, Card, DialogContent
} from '@mui/material';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import "../../image/edit.png";
import Button from '@mui/material/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import edit from "../../image/edit.png";
import "../../Company/styles/company.css";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Textarea from '@mui/joy/Textarea';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Search from "../../image/vuesax-outline-search-normal.png";
import TextField from '@mui/material/TextField';
import paperclip from "../../image/paperclip-2.png";



export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};
class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {

            addCompany: false,
            editCompany: false,
            delete: false,
            test: true,
            company_name: "",
            owner_name: "",
            company_id: "",
            contact_no: "",
            email: "",
            gst_num: "",
            fix_Amount: "",
            comp_address: "",
            invoice: "",
            name: "",
            company_shortid: "",
            address: "",
            attachment: '',
            company_shortid: '',
            attachmnet: "",
            attachmnet1: "",
            editFormOpen: false,
            loading: true,
            _id: '',
            edit_show: false,
            edit_show_id: "",
            month: "0" + (new Date().getMonth() + 1),
            year: new Date().getFullYear(),
            year2: "",
            uniqueNo: Math.floor(Math.random() * (99 - 100) + 100),
            comp_no: "",
            credit_limit: "",

        }
    }

    componentDidMount() {
        console.log(this.props.login.org_id)
        if (this.props.login.type === "AuthPerson") {
            this.props.viewAllCompanyAccess(this.props.login.user_id);
        }
        else {
            this.props.viewAllCompany(this.props.login.org_id);
        }
        this.setState({ year2: ((this.state.year).toString().charAt(2)) + ((this.state.year).toString().charAt(3)) })
    }

    CheckedCompany(p, index, company) {
        if (p.target.checked) {
            this.props.CheckedCompany(index, true, 1, company)
        }
        else {
            this.props.CheckedCompany(index, false, 0, company)
        }
    }
    AllCheckedCompany(p, company) {
        if (p.target.checked) {
            this.props.allCheckCompany(true, 1, company)

        }
        else {
            this.props.allCheckCompany(false, 0, company)
        }

    }

    render() {
        const { company } = this.props
        if (this.props.loader.open) {
            return (
                <Grid>
                    <div style={{ width: "98%", marginTop: "85px", }}>
                        <Paper
                            component="form"
                            style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none" }}
                        >
                            <Skeleton variant="rectangular" width={"367px"} height={"43px"} animation="wave" />
                        </Paper>
                    </div>
                    <CardContent style={{ marginLeft: "1.5%", marginRight: "1.5%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                <Skeleton variant="rectangular" width={150} height={40} animation="wave" style={{ margin: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginRight: "3%", marginLeft: "1%", }} />
                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >

                                        <TableCell>
                                            <Skeleton variant="rectangular" animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation="pulse" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>

                            </Table>
                        </TableContainer>

                    </CardContent>

                </Grid>
            )
        }
        else
            return (
                <Grid>
                    <div style={{ marginRight: "1%", marginTop: "80px", }}>
                        <Paper component="form" style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}>
                            <IconButton
                                type="button"
                                style={{ p: "15px" }}
                                aria-label="search"
                            >
                                <img src={Search} />
                            </IconButton>
                            <InputBase
                                style={{ ml: 5, flex: 1, fontSize: "16px", fontFamily: "Montserrat", color: "#8C8C8C", fontWeight: "300" }}
                                placeholder="Search here"
                                inputProps={{ "aria-label": "Search here" }}
                                onChange={(e) => this.props.search_company(company.company, e.target.value)}
                            />
                        </Paper>
                    </div>
                    <CardContent style={{ marginLeft: "1.5%", marginRight: "1.5%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                <h2 style={{ marginLeft: "2%" }}>Companies</h2>
                               { this.props.login.type!=="Finance" &&<Button

                                    onClick={() => {
                                        this.setState({
                                            addCompany: true,
                                            comp_no: "C" + this.state.year2 + this.state.month + "0" + "0" + (Math.floor(Math.random() * (999 - 100)) + 100)
                                        })
                                    }}
                                    style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" stle> Add Company </Button>
                                }
                               {this.props.login.type!=="Finance" && <Button
                                    onClick={() => {
                                        this.setState({
                                            delete: true,

                                        })
                                    }}
                                    style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginRight: "3%", marginLeft: "1%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" startIcon={<DeleteOutlineOutlinedIcon style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                    Delete
                                </Button>}
                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#F1F1F1" }} >
                                    { this.props.login.type!=="Finance" &&<TableCell style={{ fontSize: "13px" }}><Checkbox
                                            checked={this.props.company.company.allCheck}
                                            onClick={(p) => {
                                                {
                                                    this.AllCheckedCompany(p, this.props.company.company)
                                                }
                                            }}
                                        /></TableCell>}
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Name</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Credit Limit</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Available Credit</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Pending Amount </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Projects</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Tickets </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Assets </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Services </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Invoice</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                {Array.isArray(this.props.company.serach_company) && this.props.company.serach_company.map((item, index) => {
                                    return (
                                        <TableRow hover={true}
                                            onMouseEnter={() => {
                                                this.setState({
                                                    edit_show: true,
                                                    edit_show_id: item._id
                                                })
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({
                                                    edit_show: false,
                                                    edit_show_id: ""
                                                })
                                            }}
                                            onClick={() => {
                                                this.props.setCompanyId(item._id);
                                                this.props.setCompanyName(item.name);
                                                this.props.setCompanyOwner(item.available_credit);
                                                this.props.setCompanyEmail(item.email);
                                                this.props.setCompanyContactNo(item.contact_no);
                                            }}
                                        >
                                         { this.props.login.type!=="Finance" &&<TableCell style={{ fontSize: "13px" }} ><Checkbox
                                                checked={item.checked}
                                                onClick={(p) => { this.CheckedCompany(p, index, this.props.company.company) }}
                                            /></TableCell>}
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }} onClick={() => { this.props.navigate('/companyopen') }}>{item.name}</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }} onClick={() => { this.props.navigate('/companyopen') }}>{parseInt(item.credit_limit)}</TableCell>
                                            <TableCell style={item.available_credit <= (parseInt(item.credit_limit) * 0.4) ? { color: "red", textAlign: "center", fontSize: "13px" } : { color: "blue", textAlign: "center", fontSize: "13px" }} onClick={() => { this.props.navigate('/companyopen') }}>{item.available_credit}</TableCell>
                                            <TableCell style={{ color: "blue", textAlign: "center", fontSize: "13px" }} onClick={() => { this.props.navigate('/companyopen') }}>{parseInt(item.credit_limit) - parseInt(item.available_credit)}</TableCell>
                                            {/* <TableCell style={{ textAlign: "center", fontSize: "13px" }} onClick={() => { this.props.navigate('/companyopen') }}>{item.contact_no} </TableCell> */}
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }} onClick={() => { this.props.navigate('/companyopen') }}>{item.project_length} </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}  >
                                                {/* <Link to="/companyopen" style={{ textDecoration: "none" }} >  */}
                                                <Button style={{
                                                    background: "linear-gradient(0deg, #FEA742, #FEA742)"
                                                    , border: "1px solid #FEA742", color: "black", fontSize: '13px'
                                                }} variant="outlined"

                                                    onClick={(e) => {
                                                        this.props.navigate('/companyopen')
                                                        this.props.setCompanyId(item._id);
                                                        this.props.setCompanyName(item.name);
                                                        this.props.setCompanyOwner(item.owner_name);
                                                        this.props.setCompanyEmail(item.email);
                                                        this.props.setCompanyContactNo(item.contact_no);
                                                    }}
                                                >
                                                    {item.ticket_length}
                                                </Button>
                                                {/* </Link>  */}
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }} onClick={() => { this.props.navigate('/companyopen') }}>{item.copm_assets_length}</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }} onClick={() => { this.props.navigate('/companyopen') }}>{item.company_service_length}</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }} onClick={() => { this.props.navigate('/companyopen') }}>  <Button style={{ backgroundColor: "lightblue", border: "1px solid #53A2FF", color: "black", fontSize: '13px' }} variant="outlined" >
                                                {item.invoice_length}
                                            </Button> </TableCell>
                                            { this.props.login.type!=="Finance" &&<TableCell style={{ textAlign: "center", fontSize: "13px" }}  >
                                                <Button onClick={() => {
                                                    this.setState({
                                                        editCompany: true,
                                                        name: item.name,
                                                        owner_name: item.owner_name,
                                                        attachmnet: item.attachmnet,
                                                        company_shortid: item.company_shortid,
                                                        contact_no: item.contact_no,
                                                        email: item.email,
                                                        gst_num: item.gst_num,
                                                        fix_Amount: item.credit_limit,
                                                        available_credit: item.available_credit,
                                                        address: item.address,
                                                        _id: item._id,
                                                    })
                                                }}
                                                >{this.state.edit_show && this.state.edit_show_id === item._id &&
                                                    <img style={{ color: "green", width: 25 }} src={edit} />} </Button>
                                            </TableCell>}
                                        </TableRow>
                                    )
                                })}


                            </Table>
                        </TableContainer>

                    </CardContent>
                    {/* ------------------------------------------------------------Add Company --------------------------- */}
                    <Dialog
                        open={this.state.addCompany}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                <Grid container>
                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Company</Typography></Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ addCompany: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Company Name<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    onChange={(e) => { this.setState({ name: e.target.value }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Company ID<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    color={'blue'}
                                                    fontWeight={'bolder'}
                                                // value={this.state.comp_no}
                                                // disabled
                                                >{this.state.comp_no}</Typography>
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Contact No.<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    onChange={(e) => this.setState({
                                                        contact_no: e.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Email ID<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    onChange={(e) => this.setState({
                                                        email: e.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    GST No. :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    onChange={(e) => this.setState({
                                                        gst_num: e.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Credit Limit<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    onChange={(e) => this.setState({
                                                        fix_Amount: e.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Address :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    placeholder='Address'
                                                    size='small'
                                                    fullWidth
                                                    multiline
                                                    onChange={(e) => this.setState({
                                                        address: e.target.value
                                                    })}
                                                    inputProps={{
                                                        style: {
                                                            height: 80,
                                                        },
                                                    }}

                                                />
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} spacing={2} mt={1}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Invoice :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9} style={{ border: "1px solid grey", borderRadius: 8, height: 80, }} >
                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}
                                                >
                                                    <img src={paperclip} style={{
                                                        width: "21.338px",
                                                        height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                    }} />
                                                    <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                    >
                                                        {this.state.attachment !== "" ? (this.state.attachment).name : "Attachment File"}
                                                    </Typography>

                                                </label>

                                                <input style={{ display: "none" }} type="file" id="file"
                                                    onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'center' }}>
                                <Button
                                    onClick={() => { this.setState({ addCompany: false }) }}
                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={(this.state.name === '') || (this.state.comp_no === '') || (this.state.contact_no === '') || (this.state.email === '') || (this.state.fix_Amount === '')}
                                    onClick={() => {
                                        this.setState({ addCompany: false, name: "", owner_name: "", company_shortid: "", contact_no: "", email: "", gst_num: "", fix_Amount: "", address: "", attachment: "" });

                                        this.props.add_upload_company(this.props.login.org_id, this.state.name, this.state.owner_name, this.state.comp_no, this.state.contact_no, this.state.email, this.state.gst_num, this.state.fix_Amount, this.state.address, this.state.attachment);
                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Add Company
                                </Button>
                            </DialogActions>

                        </Grid>
                    </Dialog >
                    {/* --------------------------------------Edit Comapny -------------------------------- */}
                    <Dialog
                        open={this.state.editCompany}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                <Grid container>
                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Company</Typography></Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ editCompany: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Company Name<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    value={this.state.name}
                                                    onChange={(e) => this.setState({
                                                        name: e.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Company ID<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    size='small'
                                                    fullWidth
                                                    color={'blue'}
                                                    fontWeight={'bolder'}
                                                // value={this.state.comp_no}
                                                // disabled
                                                >{this.state.company_shortid}</Typography>
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Contact No.<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    value={this.state.contact_no}
                                                    onChange={(e) => { this.setState({ contact_no: e.target.value }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Email ID<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    value={this.state.email}
                                                    onChange={(e) => this.setState({
                                                        email: e.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    GST No.<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    value={this.state.gst_num}
                                                    onChange={(e) => this.setState({
                                                        gst_num: e.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Credit Limit<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    value={this.state.fix_Amount}
                                                    onChange={(e) => this.setState({
                                                        fix_Amount: e.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Address :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    placeholder='Address'
                                                    size='small'
                                                    fullWidth
                                                    multiline
                                                    value={this.state.address}
                                                    onChange={(e) => this.setState({
                                                        address: e.target.value
                                                    })}
                                                    inputProps={{
                                                        style: {
                                                            height: 80,
                                                        },
                                                    }}

                                                />
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"} spacing={2} mt={1}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Invoice :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9} style={{ border: "1px solid grey", borderRadius: 8, height: 80, }} >
                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}
                                                >
                                                    <img src={paperclip} style={{
                                                        width: "21.338px",
                                                        height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                    }} />
                                                    <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                    >
                                                        {this.state.attachmnet !== "" && this.state.attachmnet1 === "" ? "File Uploded" : (this.state.attachmnet1).name}
                                                    </Typography>

                                                </label>

                                                <input style={{ display: "none" }} type="file" id="file"
                                                    onChange={(e) => { this.setState({ attachmnet1: e.target.files[0] }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'center' }}>
                                <Button
                                    onClick={() => { this.setState({ editCompany: false }) }}
                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                    Cancel
                                </Button>
                                <Button
                                    // disabled={(this.state.name === '') || (this.state.comp_no === '') || (this.state.contact_no === '') || (this.state.email === '') || (this.state.gst_num === '') || (this.state.fix_Amount === '')}
                                    onClick={() => {
                                        console.log(this.state.attachmnet1)
                                        this.props.update_company_profile(this.props.login.org_id, this.state._id, this.state.name, this.state.owner_name, this.state.company_shortid, this.state.contact_no, this.state.email, this.state.gst_num, this.state.fix_Amount, this.state.address, this.state.attachmnet, this.state.attachmnet1);
                                        this.setState({
                                            editCompany: false,
                                            attachmnet1: "",
                                        });
                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Update
                                </Button>
                            </DialogActions>

                        </Grid>
                    </Dialog >
                    {/* <Dialog
                        open={this.state.editCompany}
                        fullWidth
                        maxWidth="md"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{}}
                    >

                        <Grid>
                            <Grid style={{ marginTop: "2%", boxShadow: "none" }}>
                                <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1%" }}>
                                    <Grid container>
                                        <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Edit Company</Typography></Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => {
                                                this.setState({ editCompany: false });
                                            }}>
                                                <CloseOutlinedIcon style={{ color: "black" }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <center>
                                    <Typography style={{ fontSize: "15px", marginLeft: "5%", display: "flex", flexDirection: "row", marginTop: "3%" }}

                                    >
                                        Company Name:

                                        <FormControl sx={{
                                            '& > :not(style)': { width: '485px', height: '43px', marginLeft: "2%" },
                                        }}>
                                            <OutlinedInput
                                                type="text"
                                                value={this.state.name}
                                                onChange={(e) => { this.setState({ name: e.target.value }) }}


                                            />
                                        </FormControl>
                                    </Typography>
                                    <div style={{ display: "flex", flexDirection: "row", }}>

                                        <Typography style={{ fontSize: "15px", marginLeft: "5%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                            Owner:
                                        </Typography>
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            < PersonOutlineOutlinedIcon style={{ position: 'absolute', marginLeft: "220px", top: 15, width: 20, height: 20, marginTop: "5%" }} />

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '190px', height: '43px', marginLeft: "35%", marginTop: "7%" },
                                            }}>
                                                <OutlinedInput
                                                    type="text"

                                                    value={this.state.owner_name}
                                                    onChange={(e) => { this.setState({ owner_name: e.target.value }) }}

                                                />
                                            </FormControl>
                                        </div>


                                        <Typography style={{ fontSize: "15px", marginLeft: "12%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                            Company i'd:
                                        </Typography>

                                        <FormControl sx={{
                                            '& > :not(style)': { width: '190px', height: '43px', marginLeft: "3%", marginTop: "7%" },
                                        }}>
                                            <OutlinedInput
                                                type="text"
                                                value={this.state.company_shortid}
                                                onChange={(e) => { this.setState({ company_shortid: e.target.value }) }}

                                            />
                                        </FormControl>



                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", }}>

                                        <Typography style={{ fontSize: "15px", marginLeft: "5%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                            Contact NO:
                                        </Typography>

                                        <FormControl sx={{
                                            '& > :not(style)': { width: '190px', height: '43px', marginLeft: "18%", marginTop: "7%" },
                                        }}>
                                            <OutlinedInput
                                                type="number"

                                                value={this.state.contact_no}
                                                onChange={(e) => { this.setState({ contact_no: e.target.value }) }}


                                            />
                                        </FormControl>



                                        <Typography style={{ fontSize: "15px", marginLeft: "11%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                            Email ID:
                                        </Typography>

                                        <FormControl sx={{
                                            '& > :not(style)': { width: '190px', height: '43px', marginLeft: "5%", marginTop: "7%" },
                                        }}>
                                            <OutlinedInput
                                                type="text"
                                                value={this.state.email}
                                                onChange={(e) => { this.setState({ email: e.target.value }) }}

                                            />
                                        </FormControl>



                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", }}>

                                        <Typography style={{ fontSize: "15px", marginLeft: "5%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                            GST NO:
                                        </Typography>

                                        <FormControl sx={{
                                            '& > :not(style)': { width: '190px', height: '43px', marginLeft: "30%", marginTop: "7%" },
                                        }}>
                                            <OutlinedInput
                                                type="text"

                                                value={this.state.gst_num}
                                                onChange={(e) => { this.setState({ gst_num: e.target.value }) }}


                                            />
                                        </FormControl>



                                        <Typography style={{ fontSize: "15px", marginLeft: "12%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                            Fix Amount:
                                        </Typography>

                                        <FormControl sx={{
                                            '& > :not(style)': { width: '190px', height: '43px', marginLeft: "5%", marginTop: "7%" },
                                        }}>
                                            <OutlinedInput
                                                type="text"

                                                value={this.state.fix_Amount}
                                                onChange={(e) => { this.setState({ fix_Amount: e.target.value }) }}

                                            />
                                        </FormControl>



                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", }}>

                                        <Typography style={{ fontSize: "15px", marginLeft: "5%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                            Address:
                                        </Typography>

                                        <Textarea
                                            style={{ width: '500px', height: '103px', marginTop: "3%", marginLeft: "7%", border: "1px solid grey", backgroundColor: "#FCFCFC" }}
                                            name="Outlined"
                                            placeholder="Type in here…"
                                            variant="outlined"
                                            value={this.state.address}
                                            onChange={(e) => { this.setState({ address: e.target.value }) }}
                                        />


                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", marginTop: "3%" }}>

                                        <Typography style={{ fontSize: "15px", marginLeft: "5%", display: "flex", flexDirection: "row", }}>
                                            Invoice:
                                        </Typography>
                                        <Box style={{ width: '500px', marginRight: "2%", height: '103px', marginLeft: "8%", border: "1px solid grey", backgroundColor: "#FCFCFC" }}>


                                            <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                <Typography style={{ fontSize: "18px", marginTop: "3%" }}>
                                                    {this.state.attachmnet !== "" ? (this.state.attachmnet).name : "Attachment File"}
                                                </Typography>

                                            </label>


                                            <input style={{ display: "none" }} type="file" id="file" onChange={(e) => { this.setState({ attachmnet: e.target.files[0] }) }} />

                                        </Box>
                                    </div>




                                </center>
                                <DialogActions>
                                    <Button
                                        onClick={() => { this.setState({ editCompany: false }) }}
                                        style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            this.props.update_company_profile(this.props.login.org_id, this.state._id, this.state.name, this.state.owner_name, this.state.company_shortid, this.state.contact_no, this.state.email, this.state.gst_num, this.state.fix_Amount, this.state.address, this.state.attachmnet);
                                            this.setState({
                                                editCompany: false
                                            });

                                        }}
                                        // onClick={() => { this.setState({ add: false }) }}
                                        style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                        Save
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </Dialog> */}

                    <Dialog
                        open={this.state.delete}
                        onClose={() => {
                            this.setState({ delete: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ width: "100%", height: "100%" }}
                    >


                        <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                            <center>
                                <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                            </center>
                            <DialogActions style={{ marginTop: "40px" }}>

                                <Button variant="contained"
                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}

                                    onClick={() => { this.setState({ delete: false }) }}>

                                    Cancel
                                </Button>
                                <Button variant="contained"
                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                    onClick={() => {
                                        this.props.isCheckedIdCompany(this.props.login.org_id, this.props.company.company)
                                        console.log("kjhgf", this.props.company.company)
                                        this.setState({ delete: false });
                                    }}

                                >

                                    Confirm
                                </Button>


                            </DialogActions>
                        </Grid>






                    </Dialog>

                </Grid >

            )
    }
}
export default withRouter(Company);
