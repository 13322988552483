import {

    VIEW_ALL_COMPANY,
    VIEW_COMPANY_BY_ID,
    VIEW_ALL_COMPANY_ASSETS,
    VIEW_ALL_COMPANY_SERVICE,
    VIEW_COMPANY_SERVICE_BY_ID,
    VIEW_ALL_CHANGE_REQUEST,
    SEARCH_COMPANY,
    SET_PHASE_ID,
    SET_NAME,
    SET_OWNER_NAME,
    SET_COMPANY_SHORT_ID,
    SET_CCONTACT_NO,
    SET_EMAIL,
    SET_GST_NUM,
    SET_FIX_AMOUNT,
    SET_ADDRESS,
    SET_COMP_ID,
    SET_PURCHASE_DATE,
    SET_EXP_DATE,
    SET_DESC,
    SET_RELATED_TO,
    SET_COMPANY_ASSESTS,
    SET_COMP_SERVICE_ID,
    SET_COMP_SERVICE_NAME,
    SET_COMPANY_ID,
    VIEW_ALL_ASSETS_BY_COMPANY_ID,
    SEARCH_COMPANY_ASSETS, SEARCH_COMPANY_SERVICE,
    SEARCH_PROJECT_PHASE,
    SEARCH_CHANGE_REQUEST,
    SET_PHASE_NAME,
    SET_PROJECT_NAME,
    VIEW_ALL_TASK,
    SET_COMPANY_NAME,
    SET_COMPANY_EMAIL,
    SET_CONTACT_NUMBER,
    VIEW_ALL_REQIREMENT


} from "./constant";

import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";
import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { VIEW_ALL_REQUEST } from "../Request/constant";
//company action
export function add_upload_company(org_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet) {
    return dispatch => {
        dispatch(setLoader());
        if (attachmnet !== "") {
            const storageRef = ref(getStorage(), "/User_Profile/" + attachmnet + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachmnet);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachmnet) {
                        console.log(attachmnet)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_company(org_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(add_company(org_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet));
        }
    }
}
export function add_company(org_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet) {
    return (dispatch) => {
        console.log("add")
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "add_company", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                name: name,
                owner_name: owner_name,
                company_shortid: company_shortid,
                contact_no: contact_no,
                email: email,
                gst_num: gst_num,
                credit_limit: fix_Amount,
                address: address,
                attachmnet: attachmnet
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)
                    dispatch(viewAllCompany(org_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function viewAllCompany(org_id) {
    return (dispatch) => {
        console.log("viewall")
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_company", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },
        })

            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    console.log(responseJson)
                    console.log(responseJson.result)
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            dispatch({ type: VIEW_ALL_COMPANY, payload: responseJson.result.sort((a, b) => a.date < b.date ? 1 : -1) });
                        }
                    }

                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_COMPANY, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllCompanyAccess(user_id) {
    return (dispatch) => {
        console.log("viewall")
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_company_accessed", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                user_id: user_id,
            },
        })

            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    console.log(responseJson)
                    console.log(responseJson.result)
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            dispatch({ type: VIEW_ALL_COMPANY, payload: responseJson.result.sort((a, b) => a.date < b.date ? 1 : -1) });
                        }
                    }

                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_COMPANY, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function bulk_delete_company(org_id, company_id) {
    return (dispatch) => {
        console.log("bulk", company_id)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_company", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id

            },
            body: JSON.stringify({
                _id: company_id,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllCompany(org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function update_company_profile(org_id, _id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet, attachment1) {
    return dispatch => {
        console.log(attachment1)
        dispatch(setLoader(true));
        if (attachment1 == null) {
            dispatch(update_company(org_id, _id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet));

        } else {
            const storageRef = ref(getStorage(), "/Profile/" + attachment1 + ".png")

            // .put(image)
            // uploadBytes(storageRef, image).then((snapshot) => {
            //     console.log('Uploaded a blob or file!');
            //   });
            const uploadTask = uploadBytesResumable(storageRef, attachment1);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (attachment1) {
                        // console.log(downloadURL)
                        dispatch(update_company(org_id, _id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachment1));
                    });
                }
            );
        }
    }
}
export function update_company(org_id, _id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet) {
    return (dispatch) => {
        dispatch(setLoader());
        console.log(org_id, _id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet)
        return fetch(UNIVERSAL.BASEURL + "update_company", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                _id: _id,
                name: name,
                owner_name: owner_name,
                company_shortid: company_shortid,
                contact_no: contact_no,
                email: email,
                gst_num: gst_num,
                fix_Amount: fix_Amount,
                address: address,
                attachmnet: attachmnet

            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllCompany(org_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function view_company_by_id(comp_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "view_company_by_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                comp_id: comp_id
            },
            body: JSON.stringify({

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_COMPANY_BY_ID, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_COMPANY_BY_ID, payload: [] })
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function CheckedCompany(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheck = false
    // console.log(payload)
    return {
        type: VIEW_ALL_COMPANY,
        payload: payload
    }
};

export function isCheckedIdCompany(org_id, company) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        // console.log(all_category)

        // var payload = all_category;
        for (var i = 0; i <= company.length - 1; i++) {
            // console.log("asdgtfsdt",i)
            if (company[i].checked === true) {
                isChecked.push(company[i]._id)
                // console.log(all_category[i]._id)
            }
            count = i;
        }
        if (count === company.length - 1) {
            // console.log("asdgtfsdt",count)
            // console.log("hiiiiiiiiiiiiiiiiiiiii", isChecked)
            dispatch(bulk_delete_company(org_id, isChecked))
        }
    }

};

export function search_company(company, e) {
    return (dispatch) => {
        console.log(company)
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_COMPANY, payload: company });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = company.filter((el) => {
                console.log(el.name)
                return (el.name.toLowerCase().includes(e.toLowerCase()));
            })
            // const array = report.fil
            dispatch({ type: SEARCH_COMPANY, payload: newArray.sort((a, b) => a.date < b.date ? 1 : -1) })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}
//asset
export function add_company_assets(comp_id, purchase_date, assets_id, exp_date, desc, related_to) {
    return (dispatch) => {
        console.log("comppppiddddasiii", comp_id)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "add_company_assets", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                comp_id: comp_id
            },
            body: JSON.stringify({
                purchase_date: purchase_date,
                assets_id: assets_id,
                exp_date: exp_date,
                desc: desc,
                related_to: related_to,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_all_comp_assets(comp_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function view_all_assets(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_assets", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("res", responseJson)
                if (responseJson.status) {

                    dispatch({ type: VIEW_ALL_COMPANY_ASSETS, payload: responseJson.result });

                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_COMPANY_ASSETS, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function bulk_delete_comp_assets(com_assest) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_comp_assets", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",


            },
            body: JSON.stringify({
                com_assest: com_assest
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_all_assets());
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

//service
export function update_comp_service(_id, comp_id, purchase_date, service_id, exp_date, comp_service_name, desc, related_to) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_comp_service", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                _id: _id,
                comp_id: comp_id,
                purchase_date: purchase_date,
                service_id: service_id,
                exp_date: exp_date,
                comp_service_name: comp_service_name,
                desc: desc,
                related_to: related_to
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_all_comp_service(comp_id));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function add_company_service(comp_id, purchase_date, related_to, service_id, exp_date, attachment) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_company_service", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                comp_id: comp_id

            },
            body: JSON.stringify({


                purchase_date: purchase_date,
                related_to: related_to,
                service_id: service_id,
                exp_date: exp_date,
                attachment: attachment,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_all_comp_service(comp_id));

                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function add_upload_company_service(comp_id, purchase_date, related_to, service_id, exp_date, attachment) {
    return dispatch => {
        console.log("allserviceeeaddd", comp_id)
        dispatch(setLoader());
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/User_Profile/" + attachment + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachment);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachment) {
                        console.log(attachment)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_company_service(comp_id, purchase_date, related_to, service_id, exp_date, attachment));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(add_company_service(comp_id, purchase_date, related_to, service_id, exp_date, attachment));
        }
    }
}
export function view_all_comp_service(comp_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_comp_service", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                comp_id: comp_id,
            },


        })

            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            dispatch({ type: VIEW_ALL_COMPANY_SERVICE, payload: responseJson.result });
                        }
                    }

                    dispatch({ type: VIEW_ALL_COMPANY_SERVICE, payload: responseJson.result });

                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_COMPANY_SERVICE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function bulk_delete_comp_service(comp_id, isChecked) {
    return (dispatch) => {
        console.log("compserviceeiddd", isChecked)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "bulk_delete_comp_service", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",


            },
            body: JSON.stringify({

                comp_service_id: isChecked
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_all_comp_service(comp_id));

                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function CheckedService(index, checked, quantity, service) {
    var payload = service;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheckk = false
    // console.log(payload)
    return {
        type: VIEW_ALL_COMPANY_SERVICE,
        payload: payload
    }
};
export function getallcheckedd(service) {
    for (var i = 0; i <= service.length - 1; i++) {
        service[i].checked = true;
    }
    return {
        type: VIEW_ALL_COMPANY_SERVICE,
        // payload: payload

    }
};
export function allCheckk(checked, quantity, service) {
    var payload = service;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheckk = checked;
        if (i === payload.length - 1) {
            return {
                type: VIEW_ALL_COMPANY_SERVICE,
                payload: payload
            }
        }
    }
};
export function isCheckedId2(comp_id, service) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        console.log(service)

        // var payload = all_category;
        for (var i = 0; i <= service.length - 1; i++) {
            // console.log("asdgtfsdt",i)
            if (service[i].checked === true) {
                isChecked.push(service[i]._id)
                // console.log(all_category[i]._id)
            }
            count = i;
        }
        if (count === service.length - 1) {
            console.log(count, isChecked)
            // console.log("hiiiiiiiiiiiiiiiiiiiii", isChecked)
            dispatch(bulk_delete_comp_service(comp_id, isChecked))
        }
    }

};
export function getallchecked(all_category) {
    for (var i = 0; i <= all_category.length - 1; i++) {
        all_category[i].checked = true;
    }
    return {
        type: VIEW_ALL_COMPANY,
        // payload: payload

    }
};
// export function allCheck(checked, quantity, all_category) {
//     var payload = all_category;
//     for (var i = 0; i <= payload.length - 1; i++) {
//         payload[i].checked = checked;
//         payload[i].quantity = quantity;
//         payload.allCheck = checked;
//         if (i === payload.length - 1)
//             return {
//                 type: VIEW_ALL_COMPANY_SERVICE,
//                 payload: payload
//             }
//     }
// };
export function isCheckedIdd(comp_id, all_category) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        // console.log(all_category)

        // var payload = all_category;
        for (var i = 0; i <= all_category.length - 1; i++) {
            // console.log("asdgtfsdt",i)
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
                // console.log(all_category[i]._id)
            }
            count = i;
        }
        if (count === all_category.length - 1) {
            // console.log("asdgtfsdt",count)
            // console.log("hiiiiiiiiiiiiiiiiiiiii", isChecked)
            dispatch(bulk_delete_comp_service(comp_id, isChecked))
        }
    }

};

export function view_comp_service_by(comp_id, comp_service_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_comp_service_by", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",


            },
            body: JSON.stringify({

                comp_service_id: comp_service_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch({ type: VIEW_COMPANY_SERVICE_BY_ID, payload: responseJson.result });

                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_COMPANY_SERVICE_BY_ID, payload: [] })
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function update_comp_assets(purchase_date, comp_id, _id, assets_id, exp_date, assets_name, desc, related_to) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "update_comp_assets", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                purchase_date: purchase_date,
                _id: comp_id,
                _id: _id,
                assets_id: assets_id,
                exp_date: exp_date,
                assets_name: assets_name,
                desc: desc,
                related_to: related_to,
            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_all_assets());
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
//setter
export function setName(payload) {
    return {
        type: SET_NAME,
        payload: payload,
    };
}
export function setOwnerName(payload) {
    return {
        type: SET_OWNER_NAME,
        payload: payload,
    };
}
export function setCompanyShortid(payload) {
    return {
        type: SET_COMPANY_SHORT_ID,
        payload: payload,
    };
}
export function setContactNo(payload) {
    return {
        type: SET_CCONTACT_NO,
        payload: payload,
    };
}
export function setEmail(payload) {
    return {
        type: SET_EMAIL,
        payload: payload,
    };
}
export function setGstNum(payload) {
    return {
        type: SET_GST_NUM,
        payload: payload,
    };
}
export function setFixAmount(payload) {
    return {
        type: SET_FIX_AMOUNT,
        payload: payload,
    };
}
export function setAddress(payload) {
    return {
        type: SET_ADDRESS,
        payload: payload,
    };
}
export function setCompId(payload) {
    return {
        type: SET_COMP_ID,
        payload: payload,
    };
}
export function setPurchaseDate(payload) {
    return {
        type: SET_PURCHASE_DATE,
        payload: payload,
    };
}
export function setExpDate(payload) {
    return {
        type: SET_EXP_DATE,
        payload: payload,
    };
}
export function setDesc(payload) {
    return {
        type: SET_DESC,
        payload: payload,
    };
}
export function setRelatedTo(payload) {
    return {
        type: SET_RELATED_TO,
        payload: payload,
    };
}
export function setComAssest(payload) {
    return {
        type: SET_COMPANY_ASSESTS,
        payload: payload,
    };
}
export function setServiceId(payload) {
    return {
        type: SET_COMP_SERVICE_ID,
        payload: payload,
    };
}
export function setCompServiceName(payload) {
    return {
        type: SET_COMP_SERVICE_NAME,
        payload: payload,
    };
}

//-----------------------------------------Request----------------------------------------------------------//

export function add_upload_request(project_id, change_title, desc, request_date, attachment, request_type, priority) {
    return dispatch => {
        dispatch(setLoader());
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/Request/" + attachment + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachment);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachment) {
                        console.log(attachment)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_request(project_id, change_title, desc, request_date, attachment, request_type, priority));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(add_request(project_id, desc, attachment, request_type));
        }
    }
}
export function add_request(project_id, change_title, desc, request_date, attachment, request_type, priority) {
    return (dispatch) => {
        console.log("add", project_id, desc, attachment, request_type)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "add_request", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                project_id: project_id,
            },
            body: JSON.stringify({
                desc: desc,
                attachment: attachment,
                request_type: request_type,
                priority: priority,
                title: change_title,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllRequestByProjectAndTypeChange(project_id, request_type));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function add_requirement(project_id, milestone_id, desc, request_date, attachment, request_type, title, request_status) {
    return (dispatch) => {
        console.log("add", project_id, desc, attachment, request_type)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "add_requirement", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                project_id: project_id,
            },
            body: JSON.stringify({
                milestone_id: milestone_id,
                desc: desc,
                request_date: request_date,
                attachment: attachment,
                request_type: request_type,
                title: title,
                request_status: request_status
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_all_requirement_by_milestone(milestone_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch(view_all_requirement_by_milestone(milestone_id));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function update_requirement_status(req_id, milestone_id, status) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_requirement_status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                req_id:req_id,
                milestone_id:milestone_id,
                requirement_status:status
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_requirement_by_milestone(milestone_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };


}
export function view_all_requirement_by_milestone(milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_requirement_by_milestone", {
            method: "GET",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                milestone_id: milestone_id,
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            dispatch({ type: VIEW_ALL_REQIREMENT, payload: responseJson.result });
                        }
                    }
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_REQIREMENT, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllRequestByProjectAndTypeChange(project_id, request_type) {
    return (dispatch) => {
        console.log(project_id, request_type)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_request_by_project_&_type", {
            method: "GET",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                project_id: project_id,
                request_type: request_type,
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            dispatch({ type: VIEW_ALL_CHANGE_REQUEST, payload: responseJson.result });
                        }
                    }
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_CHANGE_REQUEST, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_all_request_by_project(project_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_request_by_project", {
            method: "GET",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                project_id: project_id,
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_REQUEST, payload: responseJson.result })
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_REQUEST, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function upload_upadate_request(project_id, _id, change_title, desc, request_date, attachment, request_type, priority, attachments1) {
    return dispatch => {
        console.log("adddonnprojectttt", project_id)
        dispatch(setLoader());
        if (attachments1 !== "") {
            const storageRef = ref(getStorage(), "/Request/" + attachments1 + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachments1);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachments1) {
                        console.log(attachments1)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(updateRequest(project_id, _id, change_title, desc, request_date, attachments1, request_type, priority));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(updateRequest(project_id, _id, change_title, desc, request_date, attachment, request_type, priority));

        }
        // dispatch(add_company(org_id, name,owner_name, company_shortid, contact_no, email, gst_num,fix_Amount, address, attachmnet));
    }
}
export function updateRequest(project_id, _id, change_title, desc, request_date, attachments1, request_type, priority) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_request", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                _id: _id,
                desc: desc,
                request_date: request_date,
                attachment: attachments1,
                priority: priority,
                title: change_title,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllRequestByProjectAndTypeChange(project_id, request_type));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };


}
export function updateRequestStatus(_id, request_status, project_id, request_type) {
    return (dispatch) => {
        console.log(_id, request_status, project_id, request_type)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_request_status", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                _id: _id,
                request_status: request_status,


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllRequestByProjectAndTypeChange(project_id, request_type));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch(viewAllRequestByProjectAndTypeChange(project_id, request_type));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };

}
export function CheckedChange(index, checked, quantity, phase) {
    var payload = phase;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    // payload.allCheck=false
    // console.log(payload)
    return {
        type: VIEW_ALL_CHANGE_REQUEST,
        payload: payload
    }
};
export function isCheckedIdChange(project_id, phase) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        // console.log(all_category)

        // var payload = all_category;
        for (var i = 0; i <= phase.length - 1; i++) {
            // console.log("asdgtfsdt",i)
            if (phase[i].checked === true) {
                isChecked.push(phase[i]._id)
                console.log(phase[i]._id)
            }
            count = i;
        }
        if (count === phase.length - 1) {
            console.log("asdgtfsdt", isChecked)
            console.log("hiiiiiiiiiiiiiiiiiiiii", isChecked)
            dispatch(bulkDeleteRequest(project_id, isChecked))
        }
    }

};
export function allCheckChange(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        // console.log(i)
        if (i === payload.length - 1) {
            // console.log(payload)
            return {
                type: VIEW_ALL_CHANGE_REQUEST,
                payload: payload
            }
        }
    }
};
export function bulkDeleteRequest(project_id, isChecked) {
    return (dispatch) => {
        console.log("changeerequesttt", isChecked)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_request", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                request_id: isChecked,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllRequestByProjectAndTypeChange(project_id, "change_request"));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function allCheckCompany(checked, quantity, comapany) {
    var payload = comapany;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: VIEW_ALL_COMPANY,
                payload: payload
            }
    }
};
export function setPhaseId(payload) {
    return {
        type: SET_PHASE_ID,
        payload: payload,
    };
}


export function setCompanyId(payload) {
    return {
        type: SET_COMPANY_ID,
        payload: payload,
    };
}

export function setCompanyName(payload) {
    return {
        type: SET_COMPANY_NAME,
        payload: payload,
    };
}

export function setCompanyEmail(payload) {
    return {
        type: SET_COMPANY_EMAIL,
        payload: payload,
    };
}


export function setCompanyOwner(payload) {

    return {

        type: SET_OWNER_NAME,
        payload: payload,
    };
}

export function setCompanyContactNo(payload) {

    return {

        type: SET_CONTACT_NUMBER,
        payload: payload,
    };
}


export function view_all_comp_assets(comp_id) {
    return (dispatch) => {
        console.log("viewallassetss", comp_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_comp_assets", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                comp_id: comp_id
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            dispatch({ type: VIEW_ALL_ASSETS_BY_COMPANY_ID, payload: responseJson.result });
                        }
                    }

                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_ASSETS_BY_COMPANY_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function search_company_assets(assetscompany, e) {
    return (dispatch) => {
        // console.log(e)
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_COMPANY_ASSETS, payload: assetscompany });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = assetscompany.filter((el) => {
                // console.log(el.first_name)
                return (el.assets_details.toLowerCase().includes(e.toLowerCase()));
            })
            // const array = report.fil
            dispatch({ type: SEARCH_COMPANY_ASSETS, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}



export function bulk_delete_company_assets(comp_id, isChecked) {
    return (dispatch) => {
        console.log("bulkkkdeleteee", isChecked)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_comp_assets", {

            method: "delete",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                assets_id: isChecked,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_comp_assets(comp_id));


                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function Checkedcompanyassets(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    // payload.allCheck=false
    // console.log(payload)
    return {
        type: VIEW_ALL_ASSETS_BY_COMPANY_ID,
        payload: payload
    }
};
export function getallcheckedassets(all_category) {
    for (var i = 0; i <= all_category.length - 1; i++) {
        all_category[i].checked = true;
    }
    return {
        type: VIEW_ALL_ASSETS_BY_COMPANY_ID,

    }
};

export function allCheckcompanyassets(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: VIEW_ALL_ASSETS_BY_COMPANY_ID,
                payload: payload
            }
    }
};

export function isCheckedIdassets(comp_id, all_category) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= all_category.length - 1; i++) {
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
            }
            count = i;
        }
        if (count === all_category.length - 1) {
            dispatch(bulk_delete_company_assets(comp_id, isChecked))
        }
    }

};



export function search_change_request(company_service, e) {
    return (dispatch) => {
        if (e === "" && "N") {
            dispatch({ type: SEARCH_COMPANY_SERVICE, payload: company_service });
        }
        else {
            const newArray = company_service.filter((el) => {
                return (el.priority.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_CHANGE_REQUEST, payload: newArray })
        }
    };
}



export function Filter_change_request(company_service, e) {
    return (dispatch) => {
        if (e === "N") {
            dispatch({ type: SEARCH_CHANGE_REQUEST, payload: company_service });
        }
        else {
            const newArray = company_service.filter((el) => {
                return (el.title.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_CHANGE_REQUEST, payload: newArray })
        }
    };
}







export function search_company_service(company_service, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_COMPANY_SERVICE, payload: company_service });
        }
        else {
            const newArray = company_service.filter((el) => {
                return (el.service_details.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_COMPANY_SERVICE, payload: newArray })
        }
    };
}
export function setPhaseName(payload) {
    return {
        type: SET_PHASE_NAME,
        payload: payload,
    };
}

//----------------------------------------- Task ----------------------------------------------------------//

export function convertRequest(milestone, request, org_id) {
    return (dispatch) => {
        console.log(milestone, request, org_id)
        dispatch(setLoader());
        console.log(request)
        request.milestone = milestone
        return fetch(UNIVERSAL.BASEURL + "convert_request", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                request: request,
                org_id: org_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllRequestByProjectAndTypeChange(request.project_id, request.request_type));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function view_all_task_by_milestone(milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_task_by_milestone",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    milestone_id: milestone_id
                },


            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log("view", responseJson)

                    dispatch({ type: VIEW_ALL_TASK, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_TASK, payload: [] })
                    // dispatch({ type: VIEW_ALL_TASK, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function generate_task_repote_for_space(space_id, start_date, end_date) {
    return (dispatch) => {
        console.log("called")
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "generate_task_repote_for_space",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

                body: JSON.stringify({
                    space_id: space_id,
                    start_date: start_date,
                    end_date: end_date
                })

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log("view", responseJson)

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_space(milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_space",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    milestone_id: milestone_id
                },


            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log("view", responseJson)

                    dispatch({ type: VIEW_ALL_TASK, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_TASK, payload: [] })
                    // dispatch({ type: VIEW_ALL_TASK, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


