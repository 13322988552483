import React, { Component } from 'react'
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineDown } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { FaUserCircle } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { AiOutlineUpload } from "react-icons/ai";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import "../../Company/styles/Companymemberpage.scss"
import file from "../../image/folder2.png"
import exp from "../../image/export.png"
import { ImCross } from "react-icons/im";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Textarea from '@mui/joy/Textarea';
import edit from "../../image/edit.png";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Chip from "@mui/material/Chip";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { DateRangePicker } from 'react-date-range';
import Popover from '@mui/material/Popover';
import {
    DialogActions,
    DialogTitle,
    DialogContentText, Avatar, AvatarGroup
} from '@mui/material';
import paperclip from "../../image/paperclip-2.png";

import {
    MenuItem,
    Dialog,
    Grid,
    Typography,
    FormControl,
    OutlinedInput,
    TextField,
    Box,
    CardContent,
    Select


} from '@mui/material';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Search from "../../image/vuesax-outline-search-normal.png";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import DialogContent from '@mui/material/DialogContent';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TableBody from "@mui/material/TableBody";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



// import { Button } from 'bootstrap';

export default class Companymemberpage extends Component {
    constructor(props) {
        super(props);

        this.state = {


            addMemberDialog: false,
            editTaskDialog: false,
            memberDeleteDialog: false,

            addTaskDialog: false,
            addMeetingDialog: false,
            taskDelelteDialog: false,
            meetingDeleteDialog: false,
            docDeleteDialog: false,
            requestDeleteDialog: false,
            follwoupsDeleteDialog: false,

            editMeegingDialog: false,
            addDocDialog: false,
            editDocDialog: false,
            addFollowUps: false,
            editFollowUps: false,

            addmemberopen: true,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addDocumentsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false,
            addFormOpen: false,
            addDeleteOpen: false,
            // dialog
            addMemberDialog: false,
            editMemberDialog: false,

            title: "",
            desc: "",
            staart_date: "",
            dead_line: "",
            // status_id: "N",
            // priority_id: "N",
            assign_to: "N",
            total_time: "",
            task_type: "",
            attachmnets: "",
            milestone_id: "",

            edit_show: false,
            edit_show_id: "",
            project_status: "N",
            project_priority: "N",
            start_date: "",
            cntct_person: "",
            follow_date: "",
            project_type: "",
            desc: "",
            _id: "",
            emp_id: "",
            desc: "",
            end_date: "",
            edit_show: false,
            edit_show_id: "",
            date: "",
            time: "",
            agenda: "",
            attendees: "",
            attcah_files: "",
            personName: [],
            batch_id: [],
            meeting_date: "",
            meeting_time: "",
            meeting_summary: "",
            meeting_desc: "",
            addSpaceOpen: false,
            accessModal: false,
            taskArray: [
                {
                    "_id": {
                        "$oid": "652632540df2c7e826c7cc88"
                    },
                    "org_id": "64f1e0fe796caeca50d6b5ee",
                    "space_name": "Niladri Saya",
                    "members": [
                        "652253c7494ff931507ec288"
                    ],
                    "desc": "complete the ui pages",
                    "date": "2023-10-11",
                    "time": "05:27:47.988Z"
                },
                {
                    "_id": {
                        "$oid": "652632a50df2c7e826c7cc8a"
                    },
                    "org_id": "64f1e0fe796caeca50d6b5ee",
                    "space_name": "Technoboot career Page",
                    "members": [
                        "65225687494ff931507ec28e",
                        "652254fa494ff931507ec28c"
                    ],
                    "desc": "na",
                    "date": "2023-10-11",
                    "time": "05:29:08.926Z"
                },
                {
                    "_id": {
                        "$oid": "65278cc994a6a253ddc5d4a8"
                    },
                    "org_id": "64f1e0fe796caeca50d6b5ee",
                    "space_name": "SRE OMS LEADS PAGE REDUX, UI, BAXKEND",
                    "members": [
                        "65225024494ff931507ec27e",
                        "65225297494ff931507ec284",
                        "652253c7494ff931507ec288"
                    ],
                    "desc": "NA",
                    "date": "2023-10-12",
                    "time": "06:06:01.105Z"
                },
                {
                    "_id": {
                        "$oid": "653c9a5ee8f366b350fc6da6"
                    },
                    "org_id": "64f1e0fe796caeca50d6b5ee",
                    "space_name": "EmailKaro Devlopment",
                    "members": [
                        "65225687494ff931507ec28e",
                        "652250ca494ff931507ec280",
                        "65225024494ff931507ec27e",
                        "65225297494ff931507ec284"
                    ],
                    "desc": null,
                    "date": "2023-10-28",
                    "time": "05:21:34.034Z"
                },
                {
                    "_id": {
                        "$oid": "653c9e9ae8f366b350fc6dc6"
                    },
                    "org_id": "64f1e0fe796caeca50d6b5ee",
                    "space_name": "LMS MOBILE APP",
                    "members": [
                        "652250ca494ff931507ec280",
                        "6522523a494ff931507ec282",
                        "65225297494ff931507ec284",
                        "65225024494ff931507ec27e",
                        "65225341494ff931507ec286",
                        "652253c7494ff931507ec288",
                        "652254fa494ff931507ec28c",
                        "65225687494ff931507ec28e"
                    ],
                    "desc": null,
                    "date": "2023-10-28",
                    "time": "05:39:38.032Z"
                },
                {
                    "_id": {
                        "$oid": "653cae075ef2b7282056bc45"
                    },
                    "org_id": "64f1e0fe796caeca50d6b5ee",
                    "space_name": "DESIGN FOR CRM",
                    "members": [
                        "652256fd494ff931507ec290"
                    ],
                    "desc": "NA",
                    "date": "2023-10-28",
                    "time": "06:45:27.682Z"
                },
                {
                    "_id": {
                        "$oid": "653cae555ef2b7282056bc47"
                    },
                    "org_id": "64f1e0fe796caeca50d6b5ee",
                    "space_name": "LMS MOBILE PROUDUCT",
                    "members": [
                        "6522578f494ff931507ec292"
                    ],
                    "desc": "NA",
                    "date": "2023-10-28",
                    "time": "06:46:45.249Z"
                },
                {
                    "_id": {
                        "$oid": "653caed85ef2b7282056bc49"
                    },
                    "org_id": "64f1e0fe796caeca50d6b5ee",
                    "space_name": "Prouduct page ui ",
                    "members": [
                        "6522594e494ff931507ec29a",
                        "65225896494ff931507ec298"
                    ],
                    "desc": "na",
                    "date": "2023-10-28",
                    "time": "06:48:56.482Z"
                },
                {
                    "_id": {
                        "$oid": "6540869b87970cb34076c8a7"
                    },
                    "org_id": "64f1e0fe796caeca50d6b5ee",
                    "space_name": "CRM devlopment",
                    "members": [
                        "6522545b494ff931507ec28a",
                        "652253c7494ff931507ec288",
                        "65225a57494ff931507ec29c",
                        "65225687494ff931507ec28e",
                        "652254fa494ff931507ec28c"
                    ],
                    "desc": null,
                    "date": "2023-10-31",
                    "time": "04:46:19.257Z"
                },
                {
                    "_id": {
                        "$oid": "654086cb87970cb34076c8a8"
                    },
                    "org_id": "64f1e0fe796caeca50d6b5ee",
                    "space_name": "TMS development",
                    "members": [
                        "65225024494ff931507ec27e",
                        "652253c7494ff931507ec288",
                        "6522545b494ff931507ec28a"
                    ],
                    "desc": null,
                    "date": "2023-10-31",
                    "time": "04:47:07.645Z"
                },
                {
                    "_id": {
                        "$oid": "65433ef65ad57dcee389aac4"
                    },
                    "org_id": "64f1e0fe796caeca50d6b5ee",
                    "space_name": "SSO",
                    "members": [
                        "652254fa494ff931507ec28c"
                    ],
                    "desc": "complete the given task",
                    "date": "2023-11-02",
                    "time": "06:17:25.899Z"
                },
                {
                    "_id": {
                        "$oid": "656830b0be93643c3af586e0"
                    },
                    "org_id": "65682b9cbe93643c3af586ce",
                    "space_name": "KARKHANA",
                    "members": [
                        "652250ca494ff931507ec280",
                        "65225024494ff931507ec27e",
                        "6522523a494ff931507ec282",
                        "65225341494ff931507ec286"
                    ],
                    "desc": "TEST",
                    "date": "2023-11-30",
                    "time": "06:50:24.389Z",
                    "timestamp": "2023-11-30T12:20:24+05:30"
                },
                {
                    "_id": {
                        "$oid": "6568310bbe93643c3af586e1"
                    },
                    "org_id": "65682b9cbe93643c3af586ce",
                    "space_name": "OLANGER",
                    "members": [
                        "65210b662591f98f2e324adc",
                        "65225341494ff931507ec286",
                        "65225687494ff931507ec28e",
                        "65225024494ff931507ec27e"
                    ],
                    "desc": "DEVELPOER",
                    "date": "2023-11-30",
                    "time": "06:51:55.292Z",
                    "timestamp": "2023-11-30T12:21:55+05:30"
                },
                {
                    "_id": {
                        "$oid": "65683bd10751d48db018be7b"
                    },
                    "org_id": "65682b9cbe93643c3af586ce",
                    "space_name": "Technoboot Task",
                    "members": [
                        "65225024494ff931507ec27e",
                        "652250ca494ff931507ec280",
                        "6522523a494ff931507ec282",
                        "65225341494ff931507ec286",
                        "65225687494ff931507ec28e",
                        "652256fd494ff931507ec290",
                        "65225896494ff931507ec298",
                        "65682e3bbe93643c3af586da",
                        "65682ed4be93643c3af586dd",
                        "65210b662591f98f2e324adc",
                        "652253c7494ff931507ec288",
                        "6522578f494ff931507ec292",
                        "6522594e494ff931507ec29a",
                        "652384129342410c70370cf7"
                    ],
                    "desc": "Technoboot Team",
                    "date": "2023-11-30",
                    "time": "07:37:53.116Z",
                    "timestamp": "2023-11-30T13:07:53+05:30"
                },
                {
                    "_id": {
                        "$oid": "65683c8d0751d48db018be7c"
                    },
                    "org_id": "65682b9cbe93643c3af586ce",
                    "space_name": "karkhana Development",
                    "members": [
                        "652250ca494ff931507ec280",
                        "65225341494ff931507ec286",
                        "65210b662591f98f2e324adc"
                    ],
                    "desc": "Developer",
                    "date": "2023-11-30",
                    "time": "07:41:01.252Z",
                    "timestamp": "2023-11-30T13:11:01+05:30"
                },
                {
                    "_id": {
                        "$oid": "65683cae0751d48db018be7d"
                    },
                    "org_id": "65682b9cbe93643c3af586ce",
                    "space_name": "Olanger Development",
                    "members": [
                        "65225024494ff931507ec27e",
                        "652250ca494ff931507ec280",
                        "65210b662591f98f2e324adc"
                    ],
                    "desc": "Developer",
                    "date": "2023-11-30",
                    "time": "07:41:34.558Z",
                    "timestamp": "2023-11-30T13:11:34+05:30"
                },
                {
                    "_id": {
                        "$oid": "65683cd60751d48db018be7e"
                    },
                    "org_id": "65682b9cbe93643c3af586ce",
                    "space_name": "C.A",
                    "members": [
                        "6522523a494ff931507ec282",
                        "652253c7494ff931507ec288",
                        "65225687494ff931507ec28e",
                        "65210b662591f98f2e324adc"
                    ],
                    "desc": "Developer",
                    "date": "2023-11-30",
                    "time": "07:42:14.890Z",
                    "timestamp": "2023-11-30T13:12:14+05:30"
                },
                {
                    "_id": {
                        "$oid": "65683cf00751d48db018be7f"
                    },
                    "org_id": "65682b9cbe93643c3af586ce",
                    "space_name": "Payment",
                    "members": [
                        "65210b662591f98f2e324adc",
                        "652253c7494ff931507ec288",
                        "65225687494ff931507ec28e"
                    ],
                    "desc": "Developer",
                    "date": "2023-11-30",
                    "time": "07:42:39.956Z",
                    "timestamp": "2023-11-30T13:12:39+05:30"
                },
                {
                    "_id": {
                        "$oid": "65683d080751d48db018be80"
                    },
                    "org_id": "65682b9cbe93643c3af586ce",
                    "space_name": "Testing",
                    "members": [
                        "65210b662591f98f2e324adc",
                        "652384129342410c70370cf7"
                    ],
                    "desc": "Tester",
                    "date": "2023-11-30",
                    "time": "07:43:03.940Z",
                    "timestamp": "2023-11-30T13:13:03+05:30"
                }
            ],
            selectionRange: {
                startDate: new Date() - 1,
                endDate: new Date(),
                key: 'selection',
            },
            doctitle: "",
            doclink: "",
            docattached: "",
            coOrdinator: "",
            addresse: "",
            follow_type: "",
            follow_medium: "",
            follow_date: "",
            follow_time: "",
            follow_desc: "",
            openPopover: false,
            anchorEl: null,
            addRequests: false,
            request_title: "",
            request_desc: "",
            request_type: "",
            req_id: "",
            follow_on: "",
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChangessss = (event) => {
        const { value } = event.target;
        console.log(event)
        this.setState({
            personName: typeof value === 'string' ? value.split(',') : value,
        });
    };
    handlePopoverClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };
    handlePopoverCloseClick = (event) => {
        this.setState({
            anchorEl: null
        });
    };
    Employeeid(new_value) {
        // Get the current valueArray from state
        const { employee_id } = this.state;

        // Define the new value
        const newValue = new_value;

        // Check if the new value already exists in the array
        if (employee_id.includes(newValue)) {
            // If it exists, remove it from the array
            const newValueArray = employee_id.filter((value) => value !== newValue);
            this.setState({ employee_id: newValueArray });
        } else {
            // If it doesn't exist, add it to the array
            const newValueArray = [...employee_id, newValue];
            this.setState({ employee_id: newValueArray });
        }
    };
    // componentDidMount() {
    //     document.body.style.backgroundColor = "#f5edff";
    //     this.props.view_all_task_by_milestone(this.props.milestone.milestone_id)
    // // }

    handelmemberOpen = () => {
        console.log("MEMBER", this.state.addTaskOpen)
        console.log("TASK", this.state.addTaskOpen)
        // console.log("MEETING", this.state.addTaskOpen)
        // console.log("DOCUMENTS", this.state.addTaskOpen)
        // console.log("REQUEST", this.state.addTaskOpen)
        // console.log("FOLLOWUP", this.state.addTaskOpen)
        // console.log("TIMESHEET", this.state.addTaskOpen)
        this.setState(prevState => ({
            addmemberopen: true,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addDocumentsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false,
            addSpaceOpen: false,

        }));
    }
    handelSpaceOpen = () => {
        console.log("MEMBER", this.state.addTaskOpen)
        console.log("TASK", this.state.addTaskOpen)
        this.props.view_All_Task_By_Milestone(this.props.milestone.milestone_id);
        // console.log("MEETING", this.state.addTaskOpen)
        // console.log("DOCUMENTS", this.state.addTaskOpen)
        // console.log("REQUEST", this.state.addTaskOpen)
        // console.log("FOLLOWUP", this.state.addTaskOpen)
        // console.log("TIMESHEET", this.state.addTaskOpen)
        this.setState(prevState => ({
            addTaskOpen: false,
            addmemberopen: false,
            addMeetingsOpen: false,
            addDocumentsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false,
            addSpaceOpen: true
        }));
    }
    handelTaskOpen = () => {
        console.log("MEMBER", this.state.addTaskOpen)
        console.log("TASK", this.state.addTaskOpen)
        // console.log("MEETING", this.state.addTaskOpen)
        // console.log("DOCUMENTS", this.state.addTaskOpen)
        // console.log("REQUEST", this.state.addTaskOpen)
        // console.log("FOLLOWUP", this.state.addTaskOpen)
        // console.log("TIMESHEET", this.state.addTaskOpen)
        this.setState(prevState => ({
            addTaskOpen: true,
            addmemberopen: false,
            addMeetingsOpen: false,
            addDocumentsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false,
            addSpaceOpen: false
        }));
    }
    handelmeetingOpen = () => {
        this.setState(prevState => ({
            addMeetingsOpen: true,
            addmemberopen: false,
            addTaskOpen: false,
            addDocumentsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false,
            addSpaceOpen: false,
        }));

    }
    handeldocumentsOpen = () => {
        this.setState({

            addDocumentsOpen: true,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false,
            addSpaceOpen: false,

        })
        this.props.view_all_documnet_by_milestone(this.props.milestone.milestone_id)

    }

    handelRequestsOpen = () => {
        this.props.view_all_requirement_by_milestone(this.props.milestone.milestone_id)
        this.setState({

            addRequestsOpen: true,
            addDocumentsOpen: false,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false,
            addSpaceOpen: false
        })


    }

    handelfollowupOpen = () => {
        this.props.view_all_requirement_by_milestone(this.props.milestone.milestone_id)
        this.setState({
            addFollowupsOpen: true,
            addDocumentsOpen: false,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addRequestsOpen: false,
            addTimesheetOpen: false,
            addSpaceOpen: false,
        })
    }

    handelTimesheetOpen = () => {

        this.setState({
            addDocumentsOpen: false,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: true,
            addSpaceOpen: false,
        })

    }
    handelOpen = () => {
        this.setState({
            addFormOpen: true,

        })
    }

    handleDeleteOpen = () => {
        this.setState({
            addDeleteOpen: true
        })
    }
    modaloff = () => {
        this.setState({
            addFormOpen: false,

        })
    }
    deleteModalOff = () => {
        this.setState({
            addDeleteOpen: false
        })
    }


    componentDidMount() {
        this.props.viewAllMeetingByMilestone(this.props.milestone.milestone_id)
        this.props.view_all_employee(this.props.login.org_id);
        this.props.view_all_members_by_phase(this.props.phase.phase_id);
        this.props.viewAllFollowByMilestone(this.props.milestone.milestone_id);
        this.props.view_all_milestone_members(this.props.milestone.milestone_id);
        console.log(this.props.milestoneMembers.search_milestone_members)
    }


    CheckedTask(p, index, view_task) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedTask(index, true, 1, view_task)
        }
        else {
            this.props.CheckedTask(index, false, 0, view_task)
        }
    }
    AllCheckedMilestoneTask(p, view_task) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckTask(true, 1, view_task)
        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheckTask(false, 0, view_task)
        }

    }



    CheckedFollowUp(p, index, follow_up_by_milestone) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedFollowUp(index, true, 1, follow_up_by_milestone)
        }
        else {
            this.props.CheckedFollowUp(index, false, 0, follow_up_by_milestone)
        }
    }

    AllCheckedFollowUp(p, follow_up_by_milestone) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckk(true, 1, follow_up_by_milestone)

        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheckk(false, 0, follow_up_by_milestone)
        }

    }
    handleChange(event) {
        const { value } = event.target;
        console.log(event)
        this.setState({
            personName: typeof value === 'string' ? value.split(',') : value,
        });
    }
    addBatchId(new_value) {
        // Get the current valueArray from state
        const { batch_id } = this.state;

        // Define the new value
        const newValue = new_value;

        // Check if the new value already exists in the array
        if (batch_id.includes(newValue)) {
            // If it exists, remove it from the array
            const newValueArray = batch_id.filter((value) => value !== newValue);
            this.setState({ batch_id: newValueArray });
        } else {
            // If it doesn't exist, add it to the array
            const newValueArray = [...batch_id, newValue];
            this.setState({ batch_id: newValueArray });
        }
    };

    handleSelect(date) {
        console.log(date)

    }

    render() {
        const open = Boolean(this.state.anchorEl);
        const toDate = new Date();
        const id = open ? 'simple-popover' : undefined;
        const { task, milestone, all_project, followup, milestoneMembers } = this.props
        return (
            <Grid style={{ marginTop: "80px" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between", marginLeft: "2%", marginRight: "1%" }}>
                    <div style={{ display: "flex", flexDirection: "row", }}>
                        {(this.props.login.type !== "Executive" && this.props.login.type !== "TeamLead" && this.props.login.type !== "Manager") && <Link style={{ textDecoration: "none" }} to="/company">
                            <Button style={{ color: "#7A7E86" }}>
                                Company
                            </Button>
                        </Link>
                        }
                        {(this.props.login.type !== "Executive" && this.props.login.type !== "TeamLead" && this.props.login.type !== "Manager") && <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />}
                        {(this.props.login.type !== "Executive" && this.props.login.type !== "TeamLead" && this.props.login.type !== "Manager") && <Link style={{ textDecoration: "none" }} to="/companyopen">
                            <Button style={{ color: "#7A7E86" }}>
                                Projects
                            </Button>
                        </Link>}
                        {(this.props.login.type !== "Executive" && this.props.login.type !== "TeamLead" && this.props.login.type !== "Manager") && <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />}
                        {(this.props.login.type !== "Executive" || this.props.login.type !== "TeamLead") && <Link style={{ textDecoration: "none" }} to={this.props.login.type === "Manager"?"/phase":"/companyphase"}>
                            <Button style={{ color: "#7A7E86" }}>
                                Phase
                            </Button>
                        </Link>}
                        {(this.props.login.type !== "Executive" && this.props.login.type !== "TeamLead") && <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />}

                        <Link style={{ textDecoration: "none" }} to="/Companymilestone">
                            <Button style={{ color: "#7A7E86" }}>
                                Milestone
                            </Button>
                        </Link>
                        <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
                        <Link style={{ textDecoration: "none" }} to="">
                            <Button style={{ color: "black" }}>
                                Members

                            </Button>
                        </Link>


                    </div>
                    <div>
                        <Paper
                            component="form"
                            style={{ width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}
                        >
                            <IconButton
                                type="button"
                                style={{ p: "15px" }}
                                aria-label="search"
                            >
                                <img src={Search} />
                            </IconButton>
                            <InputBase
                                style={{ ml: 5, flex: 1, fontSize: "16px", fontFamily: "Montserrat", color: "#8C8C8C", fontWeight: "300" }}
                                placeholder="Search here"
                                inputProps={{ "aria-label": "Search here" }}

                                onChange={e => {
                                    this.props.search_Task(task.view_task, e.target.value);
                                    this.props.search_FollowUps(followup.follow_up_by_milestone, e.target.value);
                                    this.props.search_Milestone_Members(milestoneMembers.milestone_members, e.target.value)


                                }}
                            />
                        </Paper>
                    </div>
                </div>
                <div className="companymemberpage">
                    <div className="left">
                        <div className="title">
                            <h2>
                                {this.props.milestone.title}
                            </h2>
                            {/* <p>
                                created by  <span>
                                    sudipta
                                </span>  on 9 jun 2023 at 9 pm
                            </p> */}
                        </div>
                        <div className="list">
                            <ul>
                                <li style={this.state.addmemberopen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelmemberOpen} >Members </li>
                                {/* <li style={this.state.addTaskOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelTaskOpen}>Tasks</li> */}
                                <li style={this.state.addSpaceOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelSpaceOpen}>Space</li>
                                <li style={this.state.addMeetingsOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelmeetingOpen}>Meetings</li>
                                <li style={this.state.addDocumentsOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handeldocumentsOpen}>Documents</li>
                                <li style={this.state.addRequestsOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelRequestsOpen}>Requests</li>
                                <li style={this.state.addFollowupsOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelfollowupOpen}>Follow-ups</li>
                                {this.props.login.type !== "AuthPerson" && <li style={this.state.addTimesheetOpen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelTimesheetOpen}>Timesheet</li>}
                            </ul>
                        </div>
                        {/*MEMBER */}
                        {this.state.addmemberopen &&
                            <>

                                <div className="header">
                                    <div className="title">

                                        All Members
                                    </div>

                                    {(this.props.login.type !== "Executive" || this.props.login.type !== "AuthPerson") && <div className="button">
                                        <button
                                            onClick={() => {
                                                this.setState({
                                                    addMemberDialog: true,
                                                })
                                            }}
                                            className="add-btn" >
                                            Add Member
                                        </button>
                                        <button
                                            onClick={() => {
                                                this.setState({
                                                    memberDeleteDialog: true,
                                                })
                                            }}
                                            className="del-btn"  >
                                            <MdDeleteOutline
                                                className='del-icon'
                                            /> Delete
                                        </button>
                                    </div>
                                    }
                                </div>
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <th>
                                                <Checkbox
                                                // checked={this.props.members.members.allCheck}
                                                // onClick={(p) => {
                                                //     {
                                                //         this.AllCheckedMembers(p, this.props.members.members)
                                                //     }
                                                // }}
                                                />
                                            </th>
                                            <th> Name  </th>

                                            {/* <th>Status</th> */}
                                            <th>Department</th>
                                            {/* <th>Deadline</th> */}
                                            <th></th>
                                        </tr>

                                    </thead>

                                    {Array.isArray(this.props.milestoneMembers.search_milestone_members) && this.props.milestoneMembers.search_milestone_members.map((item, index) => {
                                        return (
                                            <tbody>
                                                <tr
                                                    hover={true}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            edit_show: true,
                                                            edit_show_id: item._id
                                                        })
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            edit_show: false,
                                                            edit_show_id: ""
                                                        })
                                                    }}
                                                >
                                                    <td>
                                                        <Checkbox
                                                        // checked={e.checked}
                                                        // onClick={(p) => { this.CheckedPhaseMember(p, index, this.props.members.members) }}
                                                        />
                                                    </td>
                                                    {/* <td>{e.emp_details.first_name}</td> */}
                                                    <td>{item.first_name}</td>
                                                    {/* <td className='open'>
                                                        <Link >
                                                            <span> */}
                                                    {/* {e.milestone_details.status} */}
                                                    {/* </span>
                                                        </Link>
                                                    </td> */}

                                                    <td>{item.position_details}</td>
                                                    {/* <td>{item.end_date}</td> */}
                                                    {(this.props.login.type !== "Executive" || this.props.login.type !== "AuthPerson") && <td style={{ textAlign: "center", fontSize: "13px" }}>
                                                        <Button onClick={() => {
                                                            this.setState({
                                                                editMemberDialog: true,
                                                                _id: item._id,
                                                                emp_id: item.emp_id,
                                                                start_date: item.start_date,
                                                                end_date: item.end_date,
                                                                desc: item.desc
                                                            })
                                                        }}
                                                        >{this.state.edit_show && this.state.edit_show_id === item._id &&
                                                            <img style={{ color: "green", width: 25 }} src={edit} />} </Button>
                                                    </td>}
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>


                                <Dialog
                                    open={this.state.addMemberDialog}
                                    fullWidth
                                    maxWidth="md"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid black" }}>

                                            <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>Add Members</h4>

                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ addMemberDialog: false })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>


                                        <div style={{ display: "flex" }}>


                                            <Typography style={{ fontSize: "19px", marginLeft: "10%", marginTop: "3%", fontWeight: "500" }}>
                                                Select Employee <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "15%", marginTop: "10%" },
                                            }}>
                                                <TextField
                                                    style={{ width: '220px', height: '43px', borderRadius: "6px", marginLeft: "15%", marginTop: "10%" }}
                                                    id="outlined-select-currency-native"
                                                    select
                                                    value={this.state.emp_id}
                                                    onChange={(e) => {
                                                        this.setState({ emp_id: e.target.value })

                                                    }}
                                                >
                                                    <MenuItem value={"N"}>{"Select Employee"}</MenuItem>
                                                    {Array.isArray(this.props.members.members) && this.props.members.members.map((e) => (
                                                        <MenuItem value={e.emp_details._id}>{e.emp_details.first_name} {e.last_name}</MenuItem>
                                                    ))}

                                                </TextField>

                                            </FormControl>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", gap: '2rem', justifyContent: "center", marginTop: "2%", marginBottom: "1%" }}>
                                            <div><button onClick={() => {
                                                this.setState({ addMemberDialog: false, milestone_id: "", emp_id: "", start_date: "", end_date: "", desc: "" })
                                            }} style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#D9D9D9", color: "#8C8C8C", fontSize: "14px" }}>Cancel</button></div>
                                            <div><button style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#7900FF", color: "#FFFFFF", fontSize: "14px" }}

                                                onClick={() => {
                                                    this.props.add_milestone_member(this.props.milestone.milestone_id, this.state.emp_id);
                                                    this.setState({
                                                        addMemberDialog: false,
                                                    })

                                                }}
                                            >Save</button></div>
                                        </div>
                                    </Grid>

                                </Dialog>

                                <Dialog
                                    open={this.state.editMemberDialog}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >
                                    <Grid style={{ minWidth: "1000px", height: "600px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid black" }}>

                                            <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>Edit Members</h4>

                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ editMemberDialog: false })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>


                                        <div style={{ display: "flex" }}>
                                            <Typography style={{ fontSize: "19px", marginLeft: "10%", marginTop: "3%", fontWeight: "500" }}>
                                                Select Employee <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "15%", marginTop: "10%" },
                                            }}>
                                                <TextField
                                                    style={{ width: '220px', height: '43px', borderRadius: "6px", marginLeft: "15%", marginTop: "10%" }}
                                                    id="outlined-select-currency-native"
                                                    select
                                                    value={this.state.emp_id}
                                                    onChange={(e) => {
                                                        this.setState({ emp_id: e.target.value })

                                                    }}
                                                >
                                                    <MenuItem value={"N"}>{"Select Employee"}</MenuItem>
                                                    {Array.isArray(this.props.employee.employee) && this.props.employee.employee.map((e) => (
                                                        <MenuItem value={e._id}>{e.first_name} {e.last_name}</MenuItem>
                                                    ))}

                                                </TextField>

                                            </FormControl>

                                        </div>





                                        <div style={{ display: "flex" }}>
                                            <Typography style={{ fontSize: "19px", marginLeft: "10%", marginTop: "3%", fontWeight: "500" }}>
                                                Start Date<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <Typography>
                                                <input type="date" style={{ width: '220px', height: '43px', borderRadius: "6px", marginLeft: "42%", marginTop: "10%" }}
                                                    value={this.state.start_date}
                                                    onChange={(e) => { this.setState({ start_date: e.target.value }) }}
                                                />
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Typography style={{ fontSize: "19px", marginLeft: "10%", marginTop: "3%", fontWeight: "500" }}>
                                                Deadline<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: '220px', height: '43px', borderRadius: "6px", marginLeft: "47%", marginTop: "10%" }}
                                                    value={this.state.end_date}
                                                    onChange={(e) => { this.setState({ end_date: e.target.value }) }}
                                                />
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "19px", marginLeft: "10%", marginTop: "3%", fontWeight: "500" }}>
                                                Description<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography style={{ marginLeft: "6%" }}>
                                                <textarea style={{ borderRadius: "8px", border: "1px solid gray" }} name="" id="" cols="88" rows="10"
                                                    value={this.state.desc}
                                                    onChange={(e) => { this.setState({ desc: e.target.value }) }}
                                                ></textarea>
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", gap: '2rem', justifyContent: "center", marginTop: "2rem" }}>
                                            <div><button onClick={() => {
                                                this.setState({ editMemberDialog: false })
                                            }} style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#D9D9D9", color: "#8C8C8C", fontSize: "14px" }}>Cancel</button></div>
                                            <div><button style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#7900FF", color: "#FFFFFF", fontSize: "14px" }}
                                                onClick={() => {
                                                    this.props.update_milestone_members(this.state._id, this.props.milestone.milestone_id, this.state.emp_id, this.state.start_date, this.state.end_date, this.state.desc);
                                                    this.setState({
                                                        editMemberDialog: false,
                                                    })

                                                }}
                                            >Update</button></div>
                                        </div>
                                    </Grid>

                                </Dialog>

                                <Dialog
                                    open={this.state.memberDeleteDialog}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >

                                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                                        <center>
                                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                                        </center>
                                        <DialogActions style={{ marginTop: "42px" }}>

                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}
                                                onClick={() => { this.setState({ memberDeleteDialog: false }) }}>

                                                Cancel
                                            </button>
                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                                onClick={() => { this.setState({ memberDeleteDialog: false }) }}>
                                                Confirm
                                            </button>


                                        </DialogActions>
                                    </Grid>
                                </Dialog>
                            </>
                        }

                        {/* TASK */}
                        {
                            this.state.addTaskOpen &&
                            <Grid>

                                {/* <div className="header">
                                    <div className="title">

                                        All Task <AiOutlineDown className='downArrow' />
                                    </div>
                                    <div className="button">
                                        <button className="add-btn" onClick={() => {
                                            this.setState({
                                                addTaskDialog: true
                                            });
                                        }}>
                                            Add  Task
                                        </button>
                                        <button className="del-btn"

                                            onClick={() => {
                                                this.setState({
                                                    taskDelelteDialog: true
                                                });
                                            }}



                                        >
                                            <MdDeleteOutline className='del-icon' /> Delete
                                        </button>
                                    </div>
                                </div>
                                <table className="student-table" >
                                    <thead>
                                        <tr>




                                            <th></th>
                                            <th> Task Name  </th>
                                            <th>Time</th>
                                            <th>Priority</th>
                                            <th>Assignee</th>
                                            <th>Start Date</th>
                                            <th>Deadline</th>
                                            <th></th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr >
                                            <td>
                                                <input type="checkbox"
                                                    name="" id="" />
                                            </td>
                                            <td>TASK 1 </td>
                                            <td>21hrs</td>
                                            <td className='high'>
                                                <span>   <RxDotFilled /></span> <p>
                                                    High
                                                </p>
                                            </td>

                                            <td>Sudipta</td>

                                            <td>10-06-2023</td>

                                            <td>13-06-2023</td>

                                            <td>
                                                <img
                                                    onClick={() => {
                                                        this.setState({ editTaskDialog: true });
                                                    }}
                                                    style={{ height: "20px" }} src={edit} />
                                            </td>

                                        </tr>

                                    </tbody>
                                </table> */}
                                <div>
                                    <div style={{ display: "flex", flexdirection: "row", marginBottom: "20px" }}>

                                        <FormControl variant="standard" sx={{ marginTop: "1.5%", border: "none", marginLeft: "5px" }}>
                                            {/* <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.filter_status}
                                             
                                                onChange={(e) => {
                                               
                                                    this.setState({ filter_status: e.target.value })
                                                }}
                                                label="Age"
                                                style={{ minWidth: 0 }}
                                            >
                                               
                                                <MenuItem ></MenuItem>
                                             
                                            </Select> */}
                                        </FormControl>
                                        <Button

                                            onClick={() => {
                                                this.setState({
                                                    addTaskDialog: true,

                                                })
                                            }}

                                            style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "1.5%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined"> Add Task </Button>
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    taskDelelteDialog: true,

                                                })

                                            }}
                                            style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "1.5%", marginRight: "3%", marginLeft: "1%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" startIcon={<DeleteOutlineOutlinedIcon style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                            Delete
                                        </Button>
                                    </div>
                                    <Table>
                                        <TableHead >
                                            <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                                <TableCell style={{ fontSize: "16px", width: "5%" }}><Checkbox
                                                    checked={this.props.task.search_task.allCheckTask}
                                                    onClick={(p) => {
                                                        {
                                                            this.AllCheckedMilestoneTask(p, this.props.task.search_task)
                                                        }
                                                    }}
                                                /></TableCell>
                                                <TableCell style={{ fontSize: "16px", textAlign: "center", color: "rgba(0, 0, 0, 0.4)" }}>Task Name</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "16px", color: "rgba(0, 0, 0, 0.4)" }}>Time</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "16px", color: "rgba(0, 0, 0, 0.4)" }}>Priority</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "16px", color: "rgba(0, 0, 0, 0.4)" }}>Assign to</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "16px", color: "rgba(0, 0, 0, 0.4)" }}>Start Date</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "16px", color: "rgba(0, 0, 0, 0.4)" }}>Deadline</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "16px", color: "rgba(0, 0, 0, 0.4)" }}> </TableCell>
                                            </TableRow>
                                        </TableHead>


                                        {Array.isArray(this.props.task.search_task) && this.props.task.search_task.map((item, index) => {
                                            return (
                                                <TableRow hover={true}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            edit_show: true,
                                                            edit_show_id: item._id
                                                        })
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            edit_show: false,
                                                            edit_show_id: ""
                                                        })
                                                    }}

                                                >
                                                    <TableCell style={{ fontSize: "13px" }} ><Checkbox

                                                        checked={item.checked}

                                                        onClick={(p) => { this.CheckedTask(p, index, this.props.task.search_task) }}
                                                    /></TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.title}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>8hrs</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.priority_id.name}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}></TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.staart_date}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.dead_line}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}  >
                                                        <Button
                                                            onClick={() => {
                                                                this.setState({
                                                                    editTaskDialog: true,
                                                                    title: item.title,
                                                                    desc: item.desc,
                                                                    start_date: item.start_date,
                                                                    dead_line: item.dead_line,

                                                                    project_status: item.status_id,
                                                                    project_priority: item.priority_id,

                                                                    assign_to: item.assign_to,
                                                                    total_time: item.total_time,
                                                                    task_type: item.task_type,
                                                                    attachmnets: item.attachmnets,
                                                                    _id: item._id
                                                                })
                                                            }}
                                                        >{this.state.edit_show && this.state.edit_show_id === item._id &&
                                                            <img style={{ color: "green", width: 25 }} src={edit} />} </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}


                                    </Table>
                                </div>

                                {/*-------------------------------------------------------- Add Task ---------------------------------------------------------------*/}
                                <Dialog
                                    open={this.state.addTaskDialog}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid style={{ minWidth: "1000px", height: "1000px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid black" }}>

                                            <h2 style={{ marginLeft: "7%", marginTop: "5%" }}>Add Task</h2>

                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ addTaskDialog: false })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>


                                        <div style={{ display: "flex" }}>

                                            <Typography style={{ fontSize: "18px", marginLeft: "10%", marginTop: "3%", fontWeight: "700px" }}>
                                                Title<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "15%", marginTop: "4%" },
                                            }}>
                                                <input type="text" style={{ width: "40rem", height: "3rem", borderRadius: "6px", border: "2px solid #939090" }}
                                                    onChange={(e) => this.setState({ title: e.target.value })}
                                                />

                                            </FormControl>

                                        </div>




                                        <div style={{ display: "flex", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "18px", marginLeft: "10%", display: "flex", fontWeight: "700px" }}>
                                                Description<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <Typography style={{ marginLeft: "3.5%" }}>
                                                <textarea style={{ borderRadius: "8px", border: "1px solid gray" }} name="" id="" cols="84" rows="7"
                                                    onChange={(e) => this.setState({ desc: e.target.value })}
                                                ></textarea>
                                            </Typography>

                                        </div>


                                        <div style={{ display: "flex", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "18px", marginLeft: "10%", fontWeight: "700px" }}>
                                                Start Date<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: "15rem", height: "2.5rem", borderRadius: "6px", marginLeft: "3.5rem" }}
                                                    onChange={(e) => this.setState({ staart_date: e.target.value })} />
                                            </Typography>

                                            <Typography style={{ fontSize: "18px", marginLeft: "4.5%", fontWeight: "700px" }}>
                                                Deadline<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: "15rem", height: "2.5rem", borderRadius: "6px", marginLeft: "1rem" }}
                                                    onChange={(e) => this.setState({ dead_line: e.target.value })}
                                                />
                                            </Typography>
                                        </div>




                                        <div style={{ display: "flex", marginTop: "1%" }}>
                                            {/* <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Status</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <select name="" id="" style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "6.6rem" }}
                                                    onChange={(e) => {
                                                        this.setState({ status_id: e.target.value })
                                                    }}
                                                >
                                                    <option value={"N"}>{"Select Status"}</option>
                                                    {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                                        <option value={e._id}>{e.name}</option>
                                                    ))}
                                                </select>
                                            </Typography> */}

                                            <Typography style={{ fontSize: "18px", marginLeft: "10%", fontWeight: "700px" }}>
                                                Status<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <TextField
                                                style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", marginLeft: "5.4rem" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.project_status}
                                                onChange={(e) => {
                                                    this.setState({ project_status: e.target.value })
                                                    // console.log(all_category)
                                                }}

                                            >
                                                <MenuItem value={"N"}>{"Select Status"}</MenuItem>
                                                {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                                    <MenuItem value={e._id}>{e.name}</MenuItem>
                                                ))}

                                            </TextField>




                                            <Typography style={{ fontSize: "18px", marginLeft: "5%", fontWeight: "700px" }}>
                                                Priority<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <TextField
                                                style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "1.5rem" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.project_priority}
                                                onChange={(e) => {
                                                    this.setState({ project_priority: e.target.value })
                                                    // console.log(all_category)
                                                }}
                                            >
                                                <MenuItem value={"N"}>{"Select Priority"}</MenuItem>
                                                {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e) => (
                                                    <MenuItem value={e._id}>{e.name}</MenuItem>
                                                ))}


                                            </TextField>
                                        </div>



                                        <div style={{ display: "flex", marginTop: "1%" }}>
                                            {/* <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Assign To</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <select name="" id="" style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "1.5rem" }}
                                                    onChange={(e) => {
                                                        this.setState({ assign_to: e.target.value })
                                                    }}
                                                >
                                                    <option value={"N"}>{"Select Employee"}</option>
                                                    {Array.isArray(this.props.employee.employee) && this.props.employee.employee.map((e) => (
                                                        <option value={e._id}>{e.first_name} {e.last_name}</option>
                                                    ))}
                                                </select>
                                            </Typography> */}

                                            <Typography style={{ fontSize: "18px", marginLeft: "10%", fontWeight: "700px" }}>
                                                Employee<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <TextField
                                                style={{ width: "15rem", height: "2.5rem", borderRadius: "6px", borderColor: "#939090", marginLeft: "5%" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.assign_to}
                                                onChange={(e) => {
                                                    this.setState({ assign_to: e.target.value })
                                                }}

                                            >
                                                <MenuItem value={"N"}>{"Select Employee"}</MenuItem>
                                                {Array.isArray(this.props.employee.employee) && this.props.employee.employee.map((e) => (

                                                    <MenuItem value={e._id}>{e.first_name} {e.last_name}</MenuItem>
                                                ))}

                                            </TextField>


                                            <Typography style={{ fontSize: "15px", marginLeft: "6%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Task Type</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="text" style={{ width: "15rem", height: "2.5rem", borderRadius: "6px", borderColor: "#939090", marginLeft: "0.4rem" }}
                                                    onChange={(e) => {
                                                        this.setState({ task_type: e.target.value })
                                                    }}
                                                />
                                            </Typography>
                                        </div>





                                        <div style={{ display: "flex", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "18px", marginLeft: "10%", fontWeight: "700px" }}>
                                                Total Time: <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="text" placeholder='Before 5min' style={{ width: "15rem", height: "2.5rem", borderRadius: "6px", borderColor: "#939090", marginLeft: "15%" }}
                                                    onChange={(e) => {
                                                        this.setState({ total_time: e.target.value })
                                                    }}
                                                />
                                            </Typography>
                                        </div>



                                        <div style={{ fontSize: "15px", display: "flex", marginTop: "1%", marginLeft: "8%", fontSize: "18px" }}>
                                            Attachment:
                                            <Box style={{ width: '638px', height: '80px', marginLeft: "8%", border: "2px solid   #C0ADAD", borderRadius: "8px", backgroundColor: "#FCFCFC" }}>
                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                    <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                    <Typography style={{ fontSize: "18px", marginTop: "3%", overflow: "hidden" }}>
                                                        {this.state.attachmnets !== "" ? (this.state.attachmnets).name : "Attachment File"}
                                                    </Typography>
                                                </label>
                                                <input style={{ display: "none" }} type="file" id="file"
                                                    onChange={(e) => { this.setState({ attachmnets: e.target.files[0] }) }}
                                                />
                                            </Box>
                                        </div>




                                        <div style={{ display: "flex", flexDirection: "row", gap: '2rem', justifyContent: "center", marginTop: "2rem" }}>
                                            <div>
                                                <button onClick={() => {
                                                    this.setState({ addTaskDialog: false })
                                                }}
                                                    style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#D9D9D9", color: "#8C8C8C", fontSize: "14px" }}>Cancel</button>
                                            </div>
                                            <div>
                                                <button
                                                    disabled={(this.state.title === '') || (this.state.desc === '') || (this.state.staart_date === '') || (this.state.dead_line === '') || (this.state.project_status === '') || (this.state.project_priority === '') || (this.state.assign_to === '') || (this.state.total_time === '') || (this.state.task_type === '')}

                                                    onClick={() => {
                                                        this.setState({ addTaskDialog: false, milestone_id: "", title: "", desc: "", staart_date: "", dead_line: "", project_status: "", project_priority: "", assign_to: "", total_time: "", task_type: "", attachmnets: "" });
                                                        this.props.add_Upload_Task(this.props.milestone.milestone_id, this.state.title, this.state.desc, this.state.staart_date, this.state.dead_line, this.state.project_status, this.state.project_priority, this.state.assign_to, this.state.total_time, this.state.task_type, this.state.attachmnets)
                                                    }}

                                                    style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#7900FF", color: "#FFFFFF", fontSize: "14px" }}>Save</button>
                                            </div>
                                        </div>
                                    </Grid>

                                </Dialog>

                                <Dialog
                                    open={this.state.editTaskDialog}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >
                                    <Grid style={{ minWidth: "1000px", height: "1000px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid black" }}>

                                            <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>Edit Task</h4>

                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ editTaskDialog: false })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>


                                        <div style={{ display: "flex", flexDirection: "row", }}>

                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                <strong>Title</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "15%", marginTop: "4%" },
                                            }}>
                                                <input type="text" placeholder='ABC' style={{ width: "40rem", height: "2rem", borderRadius: "8px", border: "2px solid #939090" }}
                                                    value={this.state.title}
                                                    onChange={(e) => { this.setState({ title: e.target.value }) }}
                                                />

                                            </FormControl>

                                        </div>




                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Description</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <Typography style={{ marginLeft: "5%" }}>
                                                <textarea style={{ borderRadius: "8px", border: "1px solid gray" }} name="" id="" cols="84" rows="7"
                                                    value={this.state.desc}
                                                    onChange={(e) => { this.setState({ desc: e.target.value }) }}
                                                ></textarea>
                                            </Typography>

                                        </div>


                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Start Date</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "5rem" }}
                                                    value={this.state.start_date}
                                                    onChange={(e) => { this.setState({ start_date: e.target.value }) }}
                                                />
                                            </Typography>

                                            <Typography style={{ fontSize: "15px", marginLeft: "6%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Deadline</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "1rem" }}
                                                    value={this.state.dead_line}
                                                    onChange={(e) => { this.setState({ dead_line: e.target.value }) }}
                                                />
                                            </Typography>
                                        </div>




                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Status</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <TextField
                                                style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "6.6rem" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.project_status}
                                                onChange={(e) => {
                                                    this.setState({ project_status: e.target.value })
                                                    // console.log(all_category)
                                                }}

                                            >
                                                <MenuItem value={"N"}>{"Select Status"}</MenuItem>
                                                {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                                    <MenuItem value={e._id}>{e.name}</MenuItem>
                                                ))}

                                            </TextField>

                                            <Typography style={{ fontSize: "15px", marginLeft: "6%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Priority</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>


                                            <TextField
                                                style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "1.5rem" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.project_priority}
                                                onChange={(e) => {
                                                    this.setState({ project_priority: e.target.value })
                                                    // console.log(all_category)
                                                }}
                                            >
                                                <MenuItem value={"N"}>{"Select Priority"}</MenuItem>
                                                {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e) => (
                                                    <MenuItem value={e._id}>{e.name}</MenuItem>
                                                ))}


                                            </TextField>
                                        </div>



                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Employee</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <TextField
                                                style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "5.1rem" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.assign_to}
                                                onChange={(e) => {
                                                    this.setState({ assign_to: e.target.value })
                                                }}

                                            >
                                                <MenuItem value={"N"}>{"Select Employee"}</MenuItem>
                                                {Array.isArray(this.props.employee.employee) && this.props.employee.employee.map((e) => (

                                                    <MenuItem value={e._id}>{e.first_name} {e.last_name}</MenuItem>
                                                ))}

                                            </TextField>

                                            <Typography style={{ fontSize: "15px", marginLeft: "6%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Task Type</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="text" style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "0.4rem" }}
                                                    value={this.state.task_type}
                                                    onChange={(e) => { this.setState({ task_type: e.target.value }) }}
                                                />
                                            </Typography>
                                        </div>





                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Total Time:</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="text" placeholder='Before 5min' style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "1.5rem" }}
                                                    value={this.state.total_time}
                                                    onChange={(e) => { this.setState({ total_time: e.target.value }) }}
                                                />
                                            </Typography>
                                        </div>



                                        <div style={{ fontSize: "15px", display: "flex", flexDirection: "row", marginTop: "1%", marginLeft: "8%" }}>
                                            <strong>Attachment:</strong>
                                            <Box style={{ width: '638px', height: '80px', marginLeft: "8%", border: "2px solid   #C0ADAD", borderRadius: "8px", backgroundColor: "#FCFCFC" }}>
                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                    <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                    <Typography style={{ fontSize: "18px", marginTop: "3%", overflow: "hidden" }}>
                                                        Attach File
                                                    </Typography>
                                                </label>
                                                <input style={{ display: "none" }} type="file" id="file"
                                                    onChange={(e) => { this.setState({ attachmnets: e.target.files[0] }) }}
                                                />
                                            </Box>
                                        </div>




                                        <div style={{ display: "flex", flexDirection: "row", gap: '2rem', justifyContent: "center", marginTop: "2rem" }}>
                                            <div><button onClick={() => {
                                                this.setState({ editTaskDialog: false })
                                            }} style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#D9D9D9", color: "#8C8C8C", fontSize: "14px" }}>Cancel</button></div>
                                            <div><button style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#7900FF", color: "#FFFFFF", fontSize: "14px" }}
                                                onClick={() => {
                                                    this.setState({ editTaskDialog: false, milestone_id: "", title: "", desc: "", start_date: "", dead_line: "", project_status: "", project_priority: "", assign_to: "", total_time: "", task_type: "", attachmnets: "" });
                                                    this.props.update_Milestone_Task(this.props.milestone.milestone_id, this.state._id, this.state.title, this.state.desc, this.state.start_date, this.state.dead_line, this.state.project_status, this.state.project_priority, this.state.assign_to, this.state.total_time, this.state.task_type, this.state.attachmnets)
                                                }}
                                            >Update</button></div>
                                        </div>
                                    </Grid>

                                </Dialog>

                                <Dialog
                                    open={this.state.taskDelelteDialog}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >

                                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                                        <center>
                                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                                        </center>
                                        <DialogActions style={{ marginTop: "42px" }}>

                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}
                                                onClick={() => { this.setState({ taskDelelteDialog: false }) }}>

                                                Cancel
                                            </button>
                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}

                                                onClick={() => {
                                                    this.setState({ taskDelelteDialog: false })
                                                    this.props.isCheckedTask(this.props.milestone.milestone_id, this.props.task.view_task)

                                                }}
                                            >
                                                Confirm
                                            </button>


                                        </DialogActions>
                                    </Grid>
                                </Dialog>

                            </Grid>
                        }


                        {
                            this.state.addSpaceOpen &&
                            <Grid container>
                                {this.props.login.tms ?
                                    <Grid item xs={12}>
                                        {/* <iframe
                                        title="Embedded Website"
                                        width="100%"
                                        height="400%"
                                        src="https://flowtracker-d4465.web.app"
                                        allowFullScreen
                                    /> */}
                                        <Box>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="customized table">

                                                    <TableHead style={{ background: "#B5E4D5" }}>
                                                        <TableRow >

                                                            <TableCell align='center' style={{ color: "#2E384D", fontSize: "16px" }}>Space Name</TableCell>
                                                            <TableCell align='center' style={{ color: "#2E384D", fontSize: "16px" }}> Description</TableCell>
                                                            <TableCell align='center' style={{ color: "#2E384D", fontSize: "16px" }}>Team Members</TableCell>
                                                            <TableCell align='center' style={{ color: "#2E384D", fontSize: "16px" }}>Action</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {Array.isArray(this.props.company.view_task) && this.props.company.view_task.
                                                            sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                                            .map((item, index) => {
                                                                return (


                                                                    <TableRow >
                                                                        <Link style={{ display: "contents", textDecoration: "none", cursor: "pointer" }}
                                                                            onClick={(e) => {
                                                                                this.props.SetSpace(item)
                                                                            }}
                                                                        >
                                                                            <TableCell align='center' className='userrr' style={{ whiteSpace: "nowrap" }}> {item.space_name}</TableCell>
                                                                            <TableCell align='center' className="des">{item.desc}</TableCell>
                                                                            <TableCell style={{ alignItems: "center", marginLeft: "-400px" }}>
                                                                                <center>
                                                                                    <AvatarGroup
                                                                                        renderSurplus={(surplus) => <span>+{surplus.toString()[0]}k</span>}
                                                                                        total={Array.isArray(item.members) && item.members.length}
                                                                                        align="center"
                                                                                        max={Array.isArray(item.users) ? item.users.length > 2 ? item.users.length - 2 : item.users.length : 0} >
                                                                                        {Array.isArray(item.users) && item.users.map((item1, index) => {

                                                                                            return (

                                                                                                <Avatar src={item1.profile_pic} />
                                                                                            )
                                                                                        })}
                                                                                    </AvatarGroup>

                                                                                </center>
                                                                            </TableCell>
                                                                        </Link>

                                                                        {this.props.login.type !== "Executive" && <TableCell align='center'>

                                                                            <SystemUpdateAltIcon
                                                                                style={{ cursor: "pointer" }}

                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        dateModal: true,
                                                                                        space_id: item._id.toString()
                                                                                    })
                                                                                }}



                                                                            />
                                                                        </TableCell>}

                                                                    </TableRow>


                                                                )
                                                            })}

                                                    </TableBody>

                                                </Table>

                                            </TableContainer>
                                        </Box>
                                    </Grid>
                                    : (this.props.login.type !== "Executive" || this.props.login.type !== "AuthPerson") &&
                                    <Grid>

                                        <Typography>You do not have access to task </Typography>
                                        <Button
                                            onClick={() => {
                                                this.setState({ accessModal: true })
                                            }}
                                        >
                                            Click to Access
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        }


                        {/* MEETINGS */}
                        {
                            this.state.addMeetingsOpen &&
                            <>
                                <div className="header">
                                    <div className="title">

                                        All Meeting <AiOutlineDown className='downArrow' />
                                    </div>
                                    {(this.props.login.type !== "Executive" || this.props.login.type !== "AuthPerson") && <div className="button">
                                        <button className="add-btn" onClick={() => {
                                            this.setState({ addMeetingDialog: true })
                                        }}>
                                            Schedule
                                        </button>
                                        <button
                                            className="del-btn"
                                            onClick={() => {
                                                this.setState({ meetingDeleteDialog: true });
                                            }}>
                                            <MdDeleteOutline className='del-icon' /> Delete
                                        </button>
                                    </div>}
                                </div>
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <th> Date </th>
                                            <th>Time</th>
                                            <th>Meeting Link</th>
                                            <th>Agenda</th>
                                            <th>Attendees</th>
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    {Array.isArray(this.props.meeting.meeting) && this.props.meeting.meeting.map((item, index) => {
                                        return (
                                            <tbody>
                                                <tr >
                                                    <td>{item.schedule_date} </td>
                                                    <td>{item.schedule_time}</td>
                                                    <td className='link'>
                                                        {item.meeting_link}
                                                    </td>

                                                    <td className='open'>
                                                        {item.agenda}
                                                    </td>

                                                    <td className='attendees'>
                                                        <FaUserCircle />
                                                        <h2>

                                                            {item.attendees.length}
                                                        </h2>
                                                    </td>
                                                    {/* <td>
                                                        <img
                                                            onClick={() => {
                                                                this.setState({ editMeegingDialog: true });
                                                            }}
                                                            style={{ height: "20px" }} src={edit} />
                                                    </td> */}

                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>
                                <Dialog
                                    open={this.state.addMeetingDialog}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Meeting</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ addMeetingDialog: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} spacing={2} mt={0.5}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Start Date<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='date'
                                                                onChange={(e) => this.setState({
                                                                    meeting_date: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Time<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='time'
                                                                onChange={(e) => this.setState({
                                                                    meeting_time: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Select Employee :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                fullWidth
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                multiple
                                                                value={this.state.personName}
                                                                onChange={(e) => {
                                                                    this.handleChange(e)
                                                                    console.log(this.state.batch_id)
                                                                }}
                                                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                        {selected.map((value) => (
                                                                            <Chip key={value} label={value} />
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {Array.isArray(this.props.employee.employee) && this.props.employee.employee.map((item) => (
                                                                    <MenuItem value={item.first_name + item.last_name}
                                                                        onClick={() => {
                                                                            this.addBatchId(item)
                                                                        }}
                                                                    >{item.first_name}{item.last_name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Summary :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                onChange={(e) => this.setState({
                                                                    meeting_summary: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Description :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                placeholder='Description'
                                                                size='small'
                                                                fullWidth
                                                                multiline
                                                                onChange={(e) => this.setState({
                                                                    meeting_desc: e.target.value
                                                                })}
                                                                inputProps={{
                                                                    style: {
                                                                        height: 50,
                                                                    },
                                                                }}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ addMeetingDialog: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                // disabled={()}
                                                onClick={() => {
                                                    this.setState({ addMeetingDialog: false })
                                                    this.props.addMeeting(this.props.milestone.milestone_id, this.state.meeting_date, this.state.meeting_time, this.state.meeting_summary, this.state.batch_id, this.state.meeting_desc)
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Add Meeting
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >


                                <Dialog
                                    open={this.state.editMeegingDialog}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >
                                    <Grid style={{ minWidth: "1000px", height: "1000px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid black" }}>

                                            <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>Edit Meeting</h4>

                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ editMeegingDialog: false })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>


                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong> Start Date</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "5rem" }} />
                                            </Typography>

                                            <Typography style={{ fontSize: "15px", marginLeft: "6%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong> Select Time</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "1rem" }} />
                                            </Typography>
                                        </div>



                                        <div style={{ display: "flex", flexDirection: "row", }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                <strong>Agenda</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "15%", marginTop: "4%" },
                                            }}>
                                                <input type="text" style={{ width: "40rem", height: "2rem", borderRadius: "8px", border: "2px solid #939090" }} />
                                            </FormControl>
                                        </div>





                                        <div style={{ display: "flex", flexDirection: "row", }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                <strong>Meeting Link</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "9%", marginTop: "4%" },
                                            }}>
                                                <input type="text" style={{ width: "40rem", height: "2rem", borderRadius: "8px", border: "2px solid #939090" }} />
                                            </FormControl>
                                        </div>





                                        <div style={{ display: "flex", flexDirection: "row", }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                <strong>Attendees</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "12%", marginTop: "4%" },
                                            }}>
                                                <input type="text" placeholder='Secect Employee' style={{ width: "40rem", height: "2rem", borderRadius: "8px", border: "2px solid #939090" }} />
                                            </FormControl>
                                        </div>




                                        <div style={{ display: "flex", flexDirection: "row", gap: "1rem", position: "relative", left: "13rem", padding: "2rem" }}>
                                            <div class="company-member-page-6t5-meeting">
                                                <span><img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="" /></span>
                                                <span>Ashutos</span>
                                                <span><CloseOutlinedIcon /></span>
                                            </div>
                                            <div class="company-member-page-6t5-meeting">
                                                <span><img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="" /></span>
                                                <span>Ashutos</span>
                                                <span><CloseOutlinedIcon /></span>
                                            </div>
                                            <div class="company-member-page-6t5-meeting">
                                                <span><img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="" /></span>
                                                <span>Ashutos</span>
                                                <span><CloseOutlinedIcon /></span>
                                            </div>
                                            <div class="company-member-page-6t5-meeting">
                                                <span><img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="" /></span>
                                                <span>Ashutos</span>
                                                <span><CloseOutlinedIcon /></span>
                                            </div>
                                            <div class="company-member-page-6t5-meeting">
                                                <span><img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="" /></span>
                                                <span>Ashutos</span>
                                                <span><CloseOutlinedIcon /></span>
                                            </div>

                                        </div>




                                        <div style={{ fontSize: "15px", display: "flex", flexDirection: "row", marginTop: "1%", marginLeft: "8%" }}>
                                            <strong>Attachment:</strong>
                                            <Box style={{ width: '638px', height: '80px', marginLeft: "8%", border: "2px solid   #C0ADAD", borderRadius: "8px", backgroundColor: "#FCFCFC" }}>
                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                    <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                    <Typography style={{ fontSize: "18px", marginTop: "3%", overflow: "hidden" }}>
                                                        Attach File
                                                    </Typography>
                                                </label>
                                                <input style={{ display: "none" }} type="file" id="file" />
                                            </Box>
                                        </div>




                                        <div style={{ display: "flex", flexDirection: "row", gap: '2rem', justifyContent: "center", marginTop: "2rem" }}>
                                            <div><button onClick={() => {
                                                this.setState({ editMeegingDialog: false })
                                            }} style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#D9D9D9", color: "#8C8C8C", fontSize: "14px" }}>Cancel</button></div>
                                            <div><button style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#7900FF", color: "#FFFFFF", fontSize: "14px" }}>Update</button></div>
                                        </div>
                                    </Grid>

                                </Dialog>


                                <Dialog
                                    open={this.state.meetingDeleteDialog}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >

                                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                                        <center>
                                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                                        </center>
                                        <DialogActions style={{ marginTop: "42px" }}>

                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}
                                                onClick={() => { this.setState({ meetingDeleteDialog: false }) }}>

                                                Cancel
                                            </button>
                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                                onClick={() => { this.setState({ meetingDeleteDialog: false }) }}>
                                                Confirm
                                            </button>


                                        </DialogActions>
                                    </Grid>
                                </Dialog>
                            </>
                        }

                        {/* DOCUMENTS */}
                        {
                            this.state.addDocumentsOpen &&
                            <>

                                <div className="header">
                                    <div className="title">

                                        All DOCUMENTS <AiOutlineDown className='downArrow' />
                                    </div>
                                    {(this.props.login.type !== "Executive" || this.props.login.type !== "AuthPerson") && <div className="button">
                                        <button className="upload-btn" onClick={() => {
                                            this.setState({ addDocDialog: true });
                                        }}>
                                            <AiOutlineUpload className=' uploadbtn' />
                                            Upload
                                        </button>
                                        <button
                                            className="del-btn"
                                            onClick={() => {
                                                this.setState({ docDeleteDialog: true });
                                            }}>
                                            <MdDeleteOutline className='del-icon' /> Delete
                                        </button>
                                    </div>}
                                </div>
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th> Title  </th>
                                            <th>File or Links</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(this.props.document.document) && this.props.document.document.map((item) => {
                                            return (
                                                <tr >
                                                    <td>
                                                        <input type="checkbox"
                                                            name="" id="" />
                                                    </td>
                                                    <td>{item.title}</td>
                                                    <td className='pdf'>
                                                        Attachment
                                                    </td>


                                                    {(this.props.login.type !== "Executive" || this.props.login.type !== "AuthPerson") && <td>
                                                        <img
                                                            onClick={() => {
                                                                this.setState({ editDocDialog: true });
                                                            }}
                                                            style={{ height: "20px", position: "relative", right: "2rem" }} src={edit} />
                                                    </td>}



                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                                <Dialog
                                    open={this.state.addDocDialog}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Document</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ addDocDialog: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Title :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                placeholder='Title'
                                                                size='small'
                                                                fullWidth
                                                                multiline
                                                                onChange={(e) => this.setState({
                                                                    doctitle: e.target.value
                                                                })}
                                                                inputProps={{
                                                                    style: {
                                                                        height: 50,
                                                                    },
                                                                }}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2} mt={1}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Link :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                placeholder='Link'
                                                                size='small'
                                                                fullWidth
                                                                multiline
                                                                onChange={(e) => this.setState({
                                                                    doclink: e.target.value
                                                                })}
                                                                inputProps={{
                                                                    style: {
                                                                        height: 50,
                                                                    },
                                                                }}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2} mt={0.5}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Attachment :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9} style={{ border: "1px solid grey", borderRadius: 8, height: 50, }} >
                                                            <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}
                                                            >
                                                                <img src={paperclip} style={{
                                                                    width: "21.338px",
                                                                    height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                }} />
                                                                <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                >
                                                                    {this.state.docattached !== "" ? (this.state.docattached).name : "Attachment File"}
                                                                </Typography>

                                                            </label>

                                                            <input style={{ display: "none" }} type="file" id="file"
                                                                onChange={(e) => { this.setState({ docattached: e.target.files[0] }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ addDocDialog: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                // disabled={()}
                                                onClick={() => {
                                                    this.setState({ addDocDialog: false })
                                                    this.props.addUploadDocuments("date", "time", this.state.doctitle, this.state.doclink, this.state.docattached, this.state.employee_id, this.props.milestone.milestone_id);
                                                    // this.props.addMeeting(this.props.milestone.milestone_id, this.state.meeting_date, this.state.meeting_time, this.state.meeting_summary, this.state.batch_id, this.state.meeting_desc)
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Add Document
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >

                                <Dialog
                                    open={this.state.editDocDialog}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >
                                    <Grid style={{ minWidth: "1000px", height: "1000px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid black" }}>

                                            <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>Edit File</h4>

                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ editDocDialog: false })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>



                                        <div style={{ display: "flex", flexDirection: "row", }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                <strong>Title</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "15%", marginTop: "4%" },
                                            }}>
                                                <input type="text" style={{ width: "40rem", height: "2rem", borderRadius: "8px", border: "1px solid #939090" }} />
                                            </FormControl>
                                        </div>





                                        <div style={{ display: "flex", flexDirection: "row", }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                <strong>Link</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "15%", marginTop: "4%" },
                                            }}>
                                                <input type="text" style={{ width: "40rem", height: "2rem", borderRadius: "8px", border: "1px solid #939090" }} />
                                            </FormControl>
                                        </div>



                                        <div style={{ fontSize: "15px", display: "flex", flexDirection: "row", marginTop: "1%", marginLeft: "8%" }}>
                                            <strong>Attach File:</strong>
                                            <Box style={{ width: '638px', height: '80px', marginLeft: "6%", border: "1px solid   #C0ADAD", borderRadius: "8px", backgroundColor: "#FCFCFC" }}>
                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                    <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                    <Typography style={{ fontSize: "18px", marginTop: "3%", overflow: "hidden" }}>
                                                        Attach File
                                                    </Typography>
                                                </label>
                                                <input style={{ display: "none" }} type="file" id="file" />
                                            </Box>
                                        </div>




                                        <div style={{ display: "flex", flexDirection: "row", }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                <strong>Give Access To</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "6%", marginTop: "4%" },
                                            }}>
                                                {/* <input type="text" placeholder='Secect Employee' style={{width:"40rem", height:"2rem", borderRadius:"8px", position:"relative", right:"1.5rem", border:"1px solid #939090"}} /> */}
                                                <select name="" id="" style={{ width: "40rem", height: "2rem", borderRadius: "8px", position: "relative", right: "1.5rem", border: "1px solid #939090" }}>
                                                    <option value="Select Employee">Select Employee</option>
                                                </select>
                                            </FormControl>
                                        </div>




                                        <div style={{ display: "flex", flexDirection: "row", gap: "1rem", position: "relative", left: "11.3rem", padding: "2rem" }}>
                                            <div class="company-member-page-6t5-meeting">
                                                <span><img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="" /></span>
                                                <span>Ashutos</span>
                                                <span><CloseOutlinedIcon /></span>
                                            </div>
                                            <div class="company-member-page-6t5-meeting">
                                                <span><img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="" /></span>
                                                <span>Ashutos</span>
                                                <span><CloseOutlinedIcon /></span>
                                            </div>
                                            <div class="company-member-page-6t5-meeting">
                                                <span><img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="" /></span>
                                                <span>Ashutos</span>
                                                <span><CloseOutlinedIcon /></span>
                                            </div>
                                            <div class="company-member-page-6t5-meeting">
                                                <span><img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="" /></span>
                                                <span>Ashutos</span>
                                                <span><CloseOutlinedIcon /></span>
                                            </div>
                                            <div class="company-member-page-6t5-meeting">
                                                <span><img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="" /></span>
                                                <span>Ashutos</span>
                                                <span><CloseOutlinedIcon /></span>
                                            </div>

                                        </div>






                                        <div style={{ display: "flex", flexDirection: "row", gap: '2rem', justifyContent: "center", marginTop: "2rem" }}>
                                            <div><button onClick={() => {
                                                this.setState({ editDocDialog: false })
                                            }} style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#D9D9D9", color: "#8C8C8C", fontSize: "14px" }}>Cancel</button></div>

                                            <div><button style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#7900FF", color: "#FFFFFF", fontSize: "14px" }}>Update</button></div>
                                        </div>
                                    </Grid>

                                </Dialog>


                                <Dialog
                                    open={this.state.docDeleteDialog}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >

                                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                                        <center>
                                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                                        </center>
                                        <DialogActions style={{ marginTop: "42px" }}>

                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}
                                                onClick={() => { this.setState({ docDeleteDialog: false }) }}>

                                                Cancel
                                            </button>
                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                                onClick={() => { this.setState({ docDeleteDialog: false }) }}>
                                                Confirm
                                            </button>


                                        </DialogActions>
                                    </Grid>
                                </Dialog>
                            </>
                        }

                        {/* Requests */}
                        {
                            this.state.addRequestsOpen &&
                            <>

                                <div className="header">
                                    <div className="title">

                                        All Requests <AiOutlineDown className='downArrow' />
                                    </div>
                                    {this.props.login.type !== "Executive" && <div className="button">
                                        <button className="add-btn" onClick={() => {
                                            this.setState({ addRequests: true });
                                        }}>
                                            Add Request
                                        </button>
                                        <button
                                            className="del-btn"
                                            onClick={() => {
                                                this.setState({ requestDeleteDialog: true });
                                            }}>
                                            <MdDeleteOutline className='del-icon' /> Delete
                                        </button>
                                    </div>}
                                </div>
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th> Date  </th>
                                            <th>Requests</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            <th>Action</th>

                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(this.props.company.requirement) && this.props.company.requirement.map((item) => {
                                            return (


                                                <tr >
                                                    <td>
                                                        <input type="checkbox"
                                                            name="" id="" />
                                                    </td>
                                                    <td>
                                                        {item.requirement_date}
                                                    </td>
                                                    <td className='req'>
                                                        <RxDotFilled style={{ width: "2rem", height: "2rem", position: "relative", top: "-2px" }} />{item.title}

                                                    </td>
                                                    <td>
                                                        {item.desc}
                                                    </td>
                                                    <td style={{ fontWeight: 800, color: "red" }}>
                                                        {item.requirement_status === "register" ? "Registered" : item.requirement_status === "accepted" ? "Accepted" : "Declined"}
                                                    </td>

                                                    {this.props.login.type !== "Executive" && <td className='action' style={{ justifyContent: "center" }}>
                                                        <span class="material-symbols-outlined"
                                                            onClick={(e) => {
                                                                this.handlePopoverClick(e)
                                                                this.setState({ req_id: item._id })
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            action_key

                                                        </span>
                                                        <Popover

                                                            id={id}
                                                            open={open}
                                                            anchorEl={this.state.anchorEl}
                                                            onClose={() => { this.handlePopoverCloseClick() }}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            <Button style={{ backgroundColor: "green", color: "white", margin: "10px" }}
                                                                onClick={() => {
                                                                    this.props.update_requirement_status(this.state.req_id, this.props.milestone.milestone_id, "accepted")
                                                                    this.handlePopoverCloseClick()
                                                                }}
                                                            >Accept</Button>
                                                            <Button style={{ backgroundColor: "red", color: "white", margin: "10px" }}
                                                                onClick={() => {
                                                                    this.props.update_requirement_status(this.state.req_id, this.props.milestone.milestone_id, "declined")
                                                                    this.handlePopoverCloseClick()
                                                                }}
                                                            >Declined</Button>
                                                        </Popover>
                                                        {/* <span class="material-symbols-outlined"

                                                        >
                                                            contact_support
                                                        </span> */}

                                                    </td>}

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <Dialog
                                    open={this.state.addRequests}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Request</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ addRequests: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} spacing={2} mt={0.5}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Title:- <strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='text'
                                                                onChange={(e) => this.setState({
                                                                    request_title: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Request Type <strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                fullWidth
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                value={this.state.request_type}
                                                                onChange={(e) => {
                                                                    this.setState({ request_type: e.target.value })

                                                                }}
                                                            >
                                                                <MenuItem value="Project Progress">Project Progress</MenuItem>
                                                                <MenuItem value="Meeting">Meeting</MenuItem>
                                                                <MenuItem value="Technical Support">Technical Support</MenuItem>
                                                                <MenuItem value="Sales/Propsal">Sales/Propsal</MenuItem>
                                                                <MenuItem value="Networking">Networking</MenuItem>
                                                                <MenuItem value="Requirments">Requirments</MenuItem>
                                                                <MenuItem value="Other">Other</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Description :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                placeholder='Description'
                                                                size='small'
                                                                fullWidth
                                                                multiline
                                                                onChange={(e) => this.setState({
                                                                    request_desc: e.target.value
                                                                })}
                                                                inputProps={{
                                                                    style: {
                                                                        height: 50,
                                                                    },
                                                                }}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ addRequests: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                // disabled={()}
                                                onClick={() => {
                                                    this.setState({ addRequests: false })
                                                    this.props.add_requirement(all_project.project_id, this.props.milestone.milestone_id, this.state.request_desc, new Date(), "", this.state.request_type, this.state.request_title, "register")
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Add Request
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >

                                <Dialog
                                    open={this.state.requestDeleteDialog}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >

                                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                                        <center>
                                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                                        </center>
                                        <DialogActions style={{ marginTop: "42px" }}>

                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}
                                                onClick={() => { this.setState({ requestDeleteDialog: false }) }}>

                                                Cancel
                                            </button>
                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                                onClick={() => { this.setState({ requestDeleteDialog: false }) }}>
                                                Confirm
                                            </button>


                                        </DialogActions>
                                    </Grid>
                                </Dialog>

                            </>
                        }

                        {/* Follow-ups */}
                        {
                            this.state.addFollowupsOpen &&
                            <>

                                <div className="header">
                                    <div className="title">

                                        All Follow-ups <AiOutlineDown className='downArrow' />
                                    </div>
                                    {(this.props.login.type !== "Executive" || this.props.login.type !== "AuthPerson") && <div className="button">
                                        <button className="add-btn" onClick={() => {
                                            this.setState({ addFollowUps: true });
                                        }}>
                                            Add Follow-ups
                                        </button>
                                        <button
                                            className="del-btn"
                                            onClick={() => {
                                                this.setState({ follwoupsDeleteDialog: true });
                                            }}>
                                            <MdDeleteOutline className='del-icon' /> Delete
                                        </button>
                                    </div>}
                                </div>
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <Checkbox
                                                checked={this.props.followup.search_followups.allCheckk}
                                                onClick={(p) => {
                                                    {
                                                        this.AllCheckedFollowUp(p, this.props.followup.search_followups)
                                                    }
                                                }}

                                            />
                                            <th className='followUp'> Co-Ordinator </th>
                                            <th className='followUp'>Addressee</th>
                                            <th className='followUp'>Follow-On</th>
                                            <th className='followUp'>Follow-ups date</th>
                                            <th className='followUp'>Follow-Up-Time</th>
                                            <th className='followUp'>Follow-Up-Medium</th>
                                            <th></th>
                                            {/* <th className='followUp'>Follow-ups Type</th>
                                            <th className='followUp'>Follow-ups by</th>
                                            <th></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {/* <tr >
                                            <td>
                                                <input type="checkbox"
                                                    name="" id="" />
                                            </td>
                                            <td>Sudipta </td>
                                            <td>39092492492849</td>
                                            <td >
                                                Project Name

                                            </td>

                                            <td >
                                                12-06-2023

                                            </td>

                                            <td>Meeting</td>

                                            <td>Raju Srivastav</td>
                                            <td>
                                                <img
                                                    onClick={() => {
                                                        this.setState({ editFollowUps: true });
                                                    }}
                                                    src={edit} alt=""
                                                    style={{ width: "20px" }}
                                                />
                                            </td>

                                        </tr> */}

                                        {Array.isArray(this.props.followup.search_followups) && this.props.followup.search_followups.map((item, index) => {
                                            return (
                                                <tr hover={true}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            edit_show: true,
                                                            edit_show_id: item._id
                                                        })
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            edit_show: false,
                                                            edit_show_id: ""
                                                        })
                                                    }}

                                                >
                                                    <td>
                                                        <Checkbox

                                                            checked={item.checked}

                                                            onClick={(p) => { this.CheckedFollowUp(p, index, this.props.followup.search_followups) }}
                                                        />
                                                    </td>
                                                    <td style={{ textAlign: "center", fontSize: "13px" }}>   {item.co_ordinator}</td>
                                                    <td>  {item.addressee} </td>
                                                    <td>  {item.requirement.title} </td>
                                                    <td>  {item.follow_up_date} </td>
                                                    <td>  {item.follow_up_time} </td>
                                                    <td>  {item.follow_up_medium} </td>
                                                    {(this.props.login.type !== "Executive" || this.props.login.type !== "AuthPerson") && <td style={{ textAlign: "center", fontSize: "13px" }}  >
                                                        <Button

                                                            onClick={() => {
                                                                this.setState({
                                                                    editFollowUps: true,
                                                                    follow_date: item.follow_date,
                                                                    desc: item.desc,
                                                                    _id: item._id,
                                                                    cntct_person: item.cntct_person,
                                                                    project_type: item.project_type,


                                                                })
                                                            }}
                                                        >{this.state.edit_show && this.state.edit_show_id === item._id &&
                                                            <img style={{ color: "green", width: 25 }} src={edit} />} </Button>
                                                    </td>}
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </table>
                                <Dialog
                                    open={this.state.addFollowUps}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Follow-Up</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ addFollowUps: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} spacing={2} mt={0.5}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Co_ordinator<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='text'
                                                                onChange={(e) => this.setState({
                                                                    coOrdinator: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Addressee<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='text'
                                                                onChange={(e) => this.setState({
                                                                    addresse: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid><Grid container direction={"column"} spacing={2} mt={0.5}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Follow-Up-On<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                fullWidth
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                value={this.state.follow_on}
                                                                onChange={(e) => {
                                                                    this.setState({ follow_on: e.target.value })

                                                                }}
                                                            >
                                                                {Array.isArray(this.props.company.requirement) && this.props.company.requirement.map((item) => {
                                                                    return (
                                                                        <MenuItem value={item._id}>{item.title}</MenuItem>
                                                                    )
                                                                })}

                                                            </Select>
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Follow-Up-Medium<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                fullWidth
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                value={this.state.follow_medium}
                                                                onChange={(e) => {
                                                                    this.setState({ follow_medium: e.target.value })
                                                                }}
                                                            >
                                                                <MenuItem value="Email">Email</MenuItem>
                                                                <MenuItem value="Phone Call">Phone Call</MenuItem>
                                                                <MenuItem value="Video Conference">Video Conference</MenuItem>
                                                                <MenuItem value="Chat">Chat</MenuItem>
                                                                <MenuItem value="In-Person Meeting">In-Person Meeting</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2} mt={0.5}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Follow-Up Date<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='date'
                                                                onChange={(e) => this.setState({
                                                                    follow_date: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Follow-Up-Time<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='time'
                                                                onChange={(e) => this.setState({
                                                                    follow_time: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Description :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                placeholder='Description'
                                                                size='small'
                                                                fullWidth
                                                                multiline
                                                                onChange={(e) => this.setState({
                                                                    follow_desc: e.target.value
                                                                })}
                                                                inputProps={{
                                                                    style: {
                                                                        height: 50,
                                                                    },
                                                                }}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ addFollowUps: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                // disabled={()}
                                                onClick={() => {
                                                    this.setState({ addFollowUps: false })
                                                    this.props.addAddFollowUps(this.props.milestone.milestone_id, this.state.coOrdinator, this.state.addresse, this.state.follow_on, this.state.follow_medium, this.state.follow_date, this.state.follow_time, this.state.follow_desc)
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Add FollowUps
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >


                                <Dialog
                                    open={this.state.editFollowUps}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >
                                    <Grid style={{ minWidth: "950px", height: "500px", display: "flex", flexDirection: "column", gap: "2rem" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid black" }}>

                                            <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>Edit Follow-ups</h4>

                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ editFollowUps: false })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>



                                        {/* <div style={{ display: "flex", flexDirection: "row", }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "8%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                <strong>Company Name</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "2%", marginTop: "4%" },
                                            }}>
                                                <input type="text" placeholder='Company Name' style={{ width: "42.5rem", height: "2.4rem", borderRadius: "8px", border: "1px solid #939090" }} />
                                            </FormControl>
                                        </div> */}




                                        <div style={{ display: "flex", marginTop: "1%" }}>
                                            {/* <Typography style={{ fontSize: "19px", marginLeft: "8%", display: "flex", }}>
                                                <strong>Owner</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="text" placeholder='Select Owner' style={{ width: "15rem", height: "2.5rem", borderRadius: "6px", borderColor: "#939090", marginLeft: "2rem" }}
                                                  
                                                />
                                            </Typography> */}

                                            <Typography style={{ fontSize: "19px", marginLeft: "6%", display: "flex", }}>
                                                <strong>Follow-ups Date</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="date" style={{ width: "15rem", height: "2.5rem", borderRadius: "6px", borderColor: "#939090", marginLeft: "1rem" }}
                                                    value={this.state.follow_date}
                                                    onChange={(text) => this.setState({ follow_date: text.target.value })}
                                                />
                                            </Typography>
                                        </div>






                                        {/* <div style={{ display: "flex", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "19px", marginLeft: "8%", display: "flex" }}>
                                                <strong>Project Type</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                                <input type="text" style={{ width: "15rem", height: "2.5rem", borderRadius: "6px", borderColor: "#939090", marginLeft: "1rem" }}
                                                    onChange={(e) => this.setState({ project_type: e.target.value })}
                                                />
                                            </Typography> */}

                                        {/* <Typography style={{ fontSize: "15px", marginLeft: "6%", display: "flex", flexDirection: "row", marginTop: "", }}>
                                                <strong>Follow-ups Type</strong><strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <Typography>
                                              
                                                <select name="" id="" style={{ width: "15rem", height: "2.5rem", borderRadius: "8px", borderColor: "#939090", marginLeft: "1rem" }}>
                                                    <option value=""></option>
                                                </select>
                                            </Typography> */}
                                        {/* </div> */}
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
                                            <Typography style={{ fontSize: "19px", marginLeft: "8%", display: "flex" }}>
                                                <strong>Description</strong> <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <Typography style={{ marginLeft: "3%" }}>
                                                <textarea style={{ borderRadius: "6px", border: "1px solid gray" }} name="" id="" cols="84" rows="7"
                                                    value={this.state.desc}
                                                    onChange={(text) => this.setState({ desc: text.target.value })}
                                                ></textarea>
                                            </Typography>

                                        </div>






                                        <div style={{ display: "flex", gap: '2rem', justifyContent: "center" }}>
                                            <div><button onClick={() => {
                                                this.setState({ editFollowUps: false })
                                            }} style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#D9D9D9", color: "#8C8C8C", fontSize: "14px" }}>Cancel</button></div>
                                            <div><button style={{ minWidth: "7rem", height: "3rem", borderRadius: "8px", backgroundColor: "#7900FF", color: "#FFFFFF", fontSize: "14px" }}
                                                onClick={() => {
                                                    this.setState({ editFollowUps: false });
                                                    this.props.updateFollowUp(this.props.milestone.milestone_id, this.state.cntct_person, this.state.follow_date, this.state.project_type, this.state.desc, this.state._id)
                                                }}
                                            >Update</button></div>
                                        </div>
                                    </Grid>

                                </Dialog>



                                <Dialog
                                    open={this.state.follwoupsDeleteDialog}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{}}
                                >

                                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                                        <center>
                                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                                        </center>
                                        <DialogActions style={{ marginTop: "42px" }}>

                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}
                                                onClick={() => { this.setState({ follwoupsDeleteDialog: false }) }}>

                                                Cancel
                                            </button>
                                            <button variant="contained"
                                                style={{ border: "none", borderRadius: "8px", backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                                onClick={() => {
                                                    this.setState({ follwoupsDeleteDialog: false });
                                                    this.props.isCheckedId2(this.props.milestone.milestone_id, this.props.followup.search_followups)
                                                }}>
                                                Confirm
                                            </button>


                                        </DialogActions>
                                    </Grid>
                                </Dialog>


                            </>
                        }
                        {/* Timesheet */}
                        {this.props.login.type !== "AuthPerson" &&
                            this.state.addTimesheetOpen &&
                            <>

                                {this.props.login.type !== "Executive" && <div className="header">
                                    <div className="title">

                                        All Timesheet <AiOutlineDown className='downArrow' />
                                    </div>
                                    <div className="button">

                                        <button className='export'>
                                            <img src={exp} alt="" srcset="" /> Export
                                        </button>
                                    </div>
                                </div>}
                                <table className="student-table" >
                                    <thead>
                                        <tr>

                                            <th className='timeSheet'> Member  </th>
                                            <th className='timeSheet'>10-06-2023</th>
                                            <th className='timeSheet'>11-06-2023</th>
                                            <th className='timeSheet'>12-06-2023</th>
                                            <th className='timeSheet'>13-06-2023 </th>
                                            <th className='timeSheet'>14-06-2023</th>

                                            <th className='TimeSheet'  >Total Time Spend</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr >

                                            <td className='timeSheet'>Sudipta </td>
                                            <td className='timeSheet'>2 hr</td>
                                            <td className='timeSheet'>
                                                2hr
                                            </td>

                                            <td className='timeSheet'>
                                                2hr

                                            </td>

                                            <td className='timeSheet'>2hr</td>
                                            <td className='timeSheet'>2hr</td>

                                            <td className='TimeSheet' >10 hr</td>

                                        </tr>

                                    </tbody>
                                </table>
                            </>
                        }

                    </div>
                    <div className="right">
                        <div className="upper">

                            <div className="title">
                                <p>Project Name:</p>
                                <span>
                                    {all_project.project_title}
                                </span>
                            </div>
                            <div className="pid">
                                <p>ProjectId:</p>
                                <span>
                                    {all_project.project_id}
                                </span>


                            </div>

                            <div className="title">
                                <p>Phase:</p>
                                <span>
                                    {this.props.company.title}
                                </span>
                            </div>
                            <div className="status">
                                <p>Status:</p>
                                <span>
                                    <RxDotFilled />
                                    Open
                                </span>
                            </div>
                            <div className="priority">
                                <p>

                                    Priority:
                                </p>
                                <span>   <RxDotFilled className='dotIcon' />High</span>


                            </div>
                            <div className="Authorizedperoson">
                                <p>Authorized Person:</p>
                                <span>
                                    sudipta
                                </span>
                            </div>
                            <div className="progress">
                                Progress:
                                <div className='progressbar'>

                                    <div className='innerBorder'></div>
                                </div>
                                <span>

                                    5%
                                </span>
                            </div>

                        </div>
                        <div className="middel">
                            <div className="title">
                                Task
                            </div>
                            <div className="task">
                                <div className='first'>
                                    <span>0</span>
                                    <p>completed</p>
                                </div>
                                <div className='second'>
                                    <span>0</span>
                                    <p>pending</p>
                                </div>
                                <div className='third'>
                                    <span>0</span>
                                    <p>total</p>
                                </div>
                            </div>
                        </div>
                        <div className="down">
                            <div className="req">
                                <p>Request:</p>
                                <span>0</span>
                            </div>
                            <div className="req">
                                <p>change Request:</p>
                                <span>0</span>
                            </div>
                            <div className="req">
                                <p>Adds on:</p>
                                <span>0</span>
                            </div>
                        </div>

                    </div>
                </div>
                <Dialog open={this.state.accessModal}
                    onClose={() => {
                        this.setState({ accessModal: false })
                    }}>
                    <DialogTitle>Terms and Conditions</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please read and accept the terms and conditions before proceeding.
                        </DialogContentText>
                        <Checkbox

                            checked={this.state.accepted}
                            onChange={() => {
                                this.setState({ accepted: true })
                            }}
                            color="primary"
                        />
                        <label> I accept the terms and conditions</label>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ accessModal: false }) }} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                this.props.create_new_access(this.props.login.user_id, this.props.login.org_id, 'tms')
                            }}
                            color="primary"
                            disabled={!this.state.accepted}
                        >
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.dateModal}
                    onClose={() => {
                        this.setState({ dateModal: false })
                    }}>
                    <DateRangePicker
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        // months={2}
                        direction="horizontal"
                        ranges={[this.state.selectionRange]}
                        onChange={(e) => {
                            this.setState({ selectionRange: e.selection, dateModal: false })
                            this.props.generate_task_repote_for_space(this.state.space_id, e.selection.startDate, e.selection.endDate)
                        }}
                    />
                </Dialog>
            </Grid>
        )
    }
}
