import {
  
  VIEW_ALL_TESTING,
  SEARCH_TESTING
} from "./constant";
const initial_state = {
  testing: [],
  search_testing:[]
 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {



      case VIEW_ALL_TESTING:
       console.log("alltestinggg", action.payload)
      return state = { ...state, testing: action.payload, search_testing:action.payload};


      case SEARCH_TESTING:
        return {
          ...state,
          search_testing: action.payload
        };

    default:
      return state;
  }
}
