import { InlineIcon } from '@iconify/react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Assets from "../pages/image/assest.png"
import Employee from "../pages/image/employee.png";
import logout from "../pages/image/logout.png"
import Project from "../pages/image/project.png"
import Tickets from "../pages/image/ticket.png"
import Services from "../pages/image/service.png"
import Company from "../pages/image/companies.png"
import { Style } from '@material-ui/icons';


export const all_admin_option = [
    { id: 0, name: "Dashboard", icon: <DashboardOutlinedIcon />, link: "/" },
    { id: 1, name: "Companies", icon: <img src={Company} />, link: "/company" },
    { id: 2, name: "Project", icon: <img src={Project} />, link: "/project" },
    { id: 3, name: "Services", icon: <img src={Services} />, link: "/service" },
    { id: 4, name: "Assets", icon: <img src={Assets} />, link: "/assets" },
    { id: 5, name: "Tickets", icon: <img src={Tickets} />, link: "/tickets" },
    { id: 6, name: "Employee", icon: <img src={Employee} />, link: "/Employee" },


];

// airport_shuttle

export const all_super_admin_options = [
    // { id: 0, name: "Dashboard", icon: "home", link: "/" },
    // { id: 6, name: "Admins", icon: "admin_panel_settings", link: "/adminuser" },
    // { id: 7, name: "Employees", icon: "people", link: "/employee" },
    // {id: 1, name: "Departments", icon: "meeting_room", link: "/department"},
    // {id: 2, name: "Event", icon: "event", link: "/event"},
    // {id: 3, name: "Speaker", icon: "speaker", link: "/speaker"},
    // {id: 3, name: "Applicants", icon: "person", link: "/applicants"},
    // {id: 4, name: "Participants", icon: "people", link: "/"},
];
export const all_client_options = [
    { id: 0, name: "Dashboard", icon: <DashboardOutlinedIcon />, link: "/" },
    { id: 1, name: "Project", icon: <img src={Project} />, link: "/project" },
    { id: 2, name: "Assets", icon: <img src={Assets} />, link: "/assets" },
    { id: 3, name: "Services", icon: <img src={Services} />, link: "/service" },
    { id: 4, name: "Tickets", icon: <img src={Tickets} />, link: "/tickets" },
    { id: 5, name: "Invoice", icon: "receiptoutlinedicon", link: "/invoice" },
];
export const all_auth_person = [
    { id: 0, name: "Home", icon: <img src={Project} />, link: "/" },
    // { id: 1, name: "Project", icon: <img src={Project} />, link: "/project" },
    // { id: 2, name: "Assets", icon: <img src={Assets} />, link: "/assets" },
    // { id: 3, name: "Services", icon: <img src={Services} />, link: "/service" },
    // { id: 4, name: "Tickets", icon: <img src={Tickets} />, link: "/tickets" },
    // { id: 5, name: "Invoice", icon: "receiptoutlinedicon", link: "/invoice" },
];
export const all_finance_options = [
    { id: 0, name: "Dashboard", icon: <DashboardOutlinedIcon />, link: "/" },
    { id: 1, name: "Companies", icon: <img src={Company} />, link: "/company" },
    // { id: 2, name: "Project"  , icon: <img src={Project} />, link: "/project" },
    { id: 3, name: "Services", icon: <img src={Services} />, link: "/service" },
    { id: 4, name: "Assets", icon: <img src={Assets} />, link: "/assets" },
];
export const all_humanresource_options = [
    { id: 0, name: "Dashboard", icon: <DashboardOutlinedIcon />, link: "/" },
    { id: 1, name: "Companies", icon: <img src={Company} />, link: "/company" },

    { id: 2, name: "Employee", icon: <img src={Employee} />, link: "/Employee" },

];
export const all_consultant_options = [
    { id: 0, name: "Dashboard", icon: <DashboardOutlinedIcon />, link: "/" },
    { id: 1, name: "Companies", icon: <img src={Company} />, link: "/company" },
    { id: 2, name: "Project", icon: <img src={Project} />, link: "/project" },
    { id: 3, name: "Services", icon: <img src={Services} />, link: "/service" },
    { id: 4, name: "Assets", icon: <img src={Assets} />, link: "/assets" },
    { id: 5, name: "Tickets", icon: <img src={Tickets} />, link: "/tickets" },


];
export const all_Manager_options = [
    { id: 0, name: "Dashboard", icon: <DashboardOutlinedIcon />, link: "/" },
    { id: 1, name: "Phase", icon: <img src={Project} />, link: "/phase" },
    // { id: 2, name: "Tickets", icon: <img src={Tickets} />, link: "/tickets" },
];
export const all_TeamLead_options = [
    { id: 0, name: "Dashboard", icon: <DashboardOutlinedIcon />, link: "/" },
    { id: 1, name: "Milestone", icon: <img src={Project} />, link: "/tlmilestone" },
];
export const all_Executive_options = [
    { id: 0, name: "Dashboard", icon: <DashboardOutlinedIcon />, link: "/" },
    { id: 1, name: "Milestone", icon: <img src={Project} />, link: "/tlmilestone" },
];


