import {
  
  VIEW_ALL_EMPLOYEE,
  SEARCH_EMPLOYEE,
  VIEW_ALL_EMPLOYEE_BY_DEPT_ID
} from "./constant";
const initial_state = {
  employee: [],
  search_employee:[],
  employee_by_dept_id:[]
 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

      case VIEW_ALL_EMPLOYEE:
            return {
                ...state,
                employee: action.payload, search_employee: action.payload
            };

      case SEARCH_EMPLOYEE:
        return {
            ...state,
            search_employee: action.payload

        };


        case VIEW_ALL_EMPLOYEE_BY_DEPT_ID:
          console.log("depttiddd",action.payload)
          return {
              ...state,
              employee_by_dept_id: action.payload, 
          };

    default:
      return state;
  }
}
