import React, { Component } from "react";
import { connect } from "react-redux";
import Employee from "../Components/AdminEmployee"
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  bulk_delete_employee,
  add_employee,
  view_all_employee,
  update_employee,
  add_upload_employee,
  isCheckedId,
  update_emp_profile,
  search_employee,
  deleteEmployee,
  CheckedEmployee,
  getallchecked,
  allCheck,
  filter_employee
} from "../action";
import {
  view_all_department
} from "../../../auth/actions";
export class Controller extends Component {
  render() {
    return (
      <Employee {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    employee: store.employee,
    user: store.user,
    one_user: store.one_user,
    snackbar: store.snackbar,
    login: store.login,
    loader: store.loader


  };
};
export const mapDispatchToProps = dispatch => {
  return {
    bulk_delete_employee: (empployee_id) => {
      dispatch(bulk_delete_employee(empployee_id))
    },
    update_emp_profile: (org_id, _id, profile_pic, first_name, last_name,
      emp_id, position, joining_date, mobile, email_id, profile_pic2, access) => {
      dispatch(update_emp_profile(org_id, _id, profile_pic, first_name, last_name,
        emp_id, position, joining_date, mobile, email_id, profile_pic2, access))
    },

    deleteEmployee: (org_id, employee_id) => {
      dispatch(deleteEmployee(org_id, employee_id))
    },

    // add_employee:(profile_pic, first_name, last_name, emp_id, position, joining_date,_id) => {
    //   dispatch(add_employee(profile_pic, first_name, last_name,emp_id, position, joining_date) )
    // },
    view_all_employee: (org_id) => {
      dispatch(view_all_employee(org_id))
    },
    update_employee: (profile_pic, first_name, last_name,
      emp_id, position, joining_date, _id) => {
      dispatch(update_employee(profile_pic, first_name, last_name,
        emp_id, position, joining_date, _id))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    CheckedEmployee: (index, checked, quantity, all_category) => {
      dispatch(CheckedEmployee(index, checked, quantity, all_category));
    },
    getallchecked: (all_category) => {
      dispatch(getallchecked(all_category));
    },
    allCheck: (checked, quantity, all_category) => {
      dispatch(allCheck(checked, quantity, all_category));
    },
    isCheckedId: (org_id, all_category) => {
      dispatch(isCheckedId(org_id, all_category));
    },
    search_employee: (employee, e) => {
      dispatch(search_employee(employee, e));
    },
    filter_employee: (employee, e) => {
      dispatch(filter_employee(employee, e))
    },
    view_all_department: (org_id) => {
      dispatch(view_all_department(org_id));
    },
    add_upload_employee: (org_id, profile_pic, first_name, last_name,
      emp_id, position, joining_date, mobile, email_id, access) => {
      dispatch(add_upload_employee(org_id, profile_pic, first_name, last_name,
        emp_id, position, joining_date, mobile, email_id, access));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);