export const VIEW_PROJECT="  VIEW_PROJECT";
export const SET_PROJECT_ID="  SET_PROJECT_ID";
export const SET_PROJECT_NAME="  SET_PROJECT_NAME";
export const VIEW_PROJECT_BY_ORG_ID="  VIEW_PROJECT_BY_ORG_ID";
export const VIEW_PROJECT_BY_COMP_ID="  VIEW_PROJECT_BY_COMP_ID";
export const SET_PROJECT_STATUS="  SET_PROJECT_STATUS";
export const SET_PROJECT_PRIORITY="  SET_PROJECT_PRIORITY";
export const SET_PROJECT_AUTH_PRSN="  SET_PROJECT_AUTH_PRSN";

export const SEARCH_PROJECT="  SEARCH_PROJECT";

export const SEARCH_COMPANY_PROJECT="  SEARCH_COMPANY_PROJECT";

// export const SET_PROJECT_BY_ORG_ID="SET_PROJECT_BY_COMP_ID";
