import {

    VIEW_ALL_PHASE,
    PHASE_DETAILS,
    SET_PHASE_ID,
    SEARCH_PROJECT_PHASE,
    SEARCH_PHASE
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import { set_snack_bar } from "../../common/snackbar/action";

import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";




export function add_upload_phase(project_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments) {
    return dispatch => {
        dispatch(setLoader());
        if (attcahments !== "") {
            const storageRef = ref(getStorage(), "/Phase/" + attcahments + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attcahments);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attcahments) {
                        console.log(attcahments)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_phase(project_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(add_phase(project_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments));
        }
    }
}
export function add_phase(project_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments) {
    return (dispatch) => {
        console.log(project_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_phase", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                project_id: project_id
            },
            body: JSON.stringify({
                title: title,
                desc: desc,
                start_date: start_date,
                dead_line: dead_line,
                status_id: status_id,
                priority_id: priority_id,
                max_hr: max_hr,
                attcahments: attcahments,


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_phase(project_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(true, responseJson.message));

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function update_upload_phase(project_id, _id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments, attachments1) {
    return dispatch => {
        dispatch(setLoader());
        if (attachments1 !== "") {
            const storageRef = ref(getStorage(), "/Phase/" + attachments1 + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachments1);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachments1) {
                        console.log(attachments1)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(update_phasee(_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attachments1, project_id));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(update_phasee(_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments, project_id));
        }
    }
}
export function view_all_phase(project_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_phase", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                project_id: project_id
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            // console.log(responseJson.result)
                            dispatch({ type: VIEW_ALL_PHASE, payload: responseJson.result });
                        }
                    }
                    console.log(responseJson)
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_PHASE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_phase_by_id(phase_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_phase_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                phase_id: phase_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: PHASE_DETAILS, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: PHASE_DETAILS, payload: "" });
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());

                console.error(error);
            });


    };
}
export function bulk_delete_phase(project_id, isChecked) {
    return (dispatch) => {
        console.log("phaseeedeleteee", isChecked)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_phase", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({

                phase_id: isChecked
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(view_all_phase(project_id));
                    dispatch(set_snack_bar(true, responseJson.message));

                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());

                console.error(error);
            });
    };
}
export function CheckedPhase(index, checked, quantity, phase) {
    var payload = phase;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheck = false
    // console.log(payload)
    return {
        type: VIEW_ALL_PHASE,
        payload: payload
    }
};

export function getallchecked(phase) {
    for (var i = 0; i <= phase.length - 1; i++) {
        phase[i].checked = true;
    }
    return {
        type: VIEW_ALL_PHASE,

    }
};


export function allCheck(checked, quantity, phase) {
    var payload = phase;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: VIEW_ALL_PHASE,
                payload: payload
            }
    }
};
export function isCheckedId(project_id, phase) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        // console.log(all_category)

        // var payload = all_category;
        for (var i = 0; i <= phase.length - 1; i++) {
            // console.log("asdgtfsdt",i)
            if (phase[i].checked === true) {
                isChecked.push(phase[i]._id)
                console.log(phase[i]._id)
            }
            count = i;
        }
        if (count === phase.length - 1) {
            console.log("asdgtfsdt", isChecked)
            // console.log("hiiiiiiiiiiiiiiiiiiiii", isChecked)
            dispatch(bulk_delete_phase(project_id, isChecked))
        }
    }

};
export function update_phasee(_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments, project_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_phase", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
                title: title,
                desc: desc,
                start_date: start_date,
                dead_line: dead_line,
                status_id: status_id,
                priority_id: priority_id,
                max_hr: max_hr,
                attcahments: attcahments

            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_phase(project_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}
export function update_phase_status(id, status_id, project_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_phase_status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,
                status_id: status_id

            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_phase(project_id));
                    dispatch(set_snack_bar(true, responseJson.message));

                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}
export function setPhaseId(payload) {
    return {
        type: SET_PHASE_ID,
        payload: payload,
    };
}
export function search_project_phase(company_service, e) {
    return (dispatch) => {
        console.log(e)
        if (e === "") {
            dispatch({ type: SEARCH_PROJECT_PHASE, payload: company_service });
        }
        else {
            const newArray = company_service.filter((el) => {
                return (el.status_id.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PROJECT_PHASE, payload: newArray })
        }
    };
}




export function search_phase(phase, e, type) {
    return (dispatch) => {
        console.log(e)
        if (e === "") {
            dispatch({ type: SEARCH_PHASE, payload: phase });
        }
        else {
            const newArray = phase.filter((el) => {
                return (el.title.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PHASE, payload: newArray })
        }
    };
}

export function Filter_phase(phase, e, type) {
    return (dispatch) => {
        console.log(e)
        if (e === "") {
            dispatch({ type: SEARCH_PHASE, payload: phase });
        }
        else {
            const newArray = phase.filter((el) => {
                return (el.status_id.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PHASE, payload: newArray })
        }
    };
}

export function view_all_phase_by_member(emp_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_phase_by_member", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                emp_id: emp_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_PHASE, payload: responseJson.result })
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_PHASE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function search_phase2(all_project, e, type) {
    return (dispatch) => {
        if (e === "" || e === "N") {
            dispatch({ type: SEARCH_PHASE, payload: all_project });
        }
        else if (type == "search") {
            const newArray = all_project.filter((el) => {
                return (el.project_title.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PHASE, payload: newArray })
        }
        else if (type == "status") {
            const newArray = all_project.filter((el) => {
                return (el.status_id.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PHASE, payload: newArray })
        }
    };
}



