import React, { Component } from "react";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import Box from "@mui/material/Box";
import "../styles/addashboard.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

export default class Addashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  componentDidMount() {
    document.body.style.backgroundColor = "#f5edff";
  }

  render() {
    return (
      <div id="employee-dashboard" >
        <div className="task-nav">
          <Box className="completed">
            <div id="tick-icon" className="icon">
              <VerifiedRoundedIcon />
            </div>
            <div className="task-nav-line-space">
              <h6 style={{ fontWeight: 200 }}>Completed</h6>
              <h4 style={{ fontWeight: 450 }}>10 Projects</h4>
            </div>
          </Box>
          <Box className="completed">
            <div id="access-icon" className="icon">
              <AccessTimeRoundedIcon />
            </div>
            <div className="task-nav-line-space">
              <h6 style={{ fontWeight: 200 }}>In Progress</h6>
              <h4 style={{ fontWeight: 450 }}>3 Projects</h4>
            </div>
          </Box>
          <Box className="completed">
            <div id="hours-icon" className="icon">
              <HourglassEmptyOutlinedIcon />
            </div>
            <div className="task-nav-line-space">
              <h6 style={{ fontWeight: 200 }}>Pipeline</h6>
              <h4 style={{ fontWeight: 450 }}>2 Projects</h4>
            </div>
          </Box>
          <Box className="completed">
            <div id="pending-icon" className="icon">
              <PendingActionsIcon />
            </div>
            <div className="task-nav-line-space">
              <h6 style={{ fontWeight: 200 }}>On Hold</h6>
              <h4 style={{ fontWeight: 450 }}>3 Projects</h4>
            </div>
          </Box>
        </div>

        <div className="my-projects">
          <div className="my-projects-bar">
            <span id='task-icon'><StickyNote2OutlinedIcon /></span>
            <span id="task-text">Projects</span>
          </div>
          <div className="projects-details">
            <span>1. Project Name</span>
            <span>Phase-1</span>
            {/* ------- */}
            <span id="project-details-mg">
              <img src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"></img>
              <span>S.K Hussain</span>
            </span>
            <span id="project-details-txt">
              <div id="project-details-red-poing"></div>
              <span>High</span>
            </span>
            {/* --------- */}
            <span id="project-open">
              <div id="open-point" style={{ display: "flex" }}></div>Open
            </span>
            <span>20% complete</span>
          </div>
          <div className="projects-details">
            <span>1. Project Name</span>
            <span>Phase-1</span>
            <span id="project-details-mg">
              <img src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"></img>
              <span>S.K Hussain</span>
            </span>
            <span id="project-details-txt">
              <div id="project-details-red-poing"></div>
              <span>High</span>
            </span>
            <span id="project-open">
              <div id="open-point" style={{ display: "flex" }}></div>Open
            </span>
            <span>20% complete</span>
          </div>
          <div className="projects-details">
            <span>1. Project Name</span>
            <span>Phase-1</span>
            <span id="project-details-mg">
              <img src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"></img>
              <span>S.K Hussain</span>
            </span>
            <span id="project-details-txt">
              <div id="project-details-red-poing"></div>
              <span>High</span>
            </span>
            <span id="project-open">
              <div id="open-point" style={{ display: "flex" }}></div>Open
            </span>
            <span>20% complete</span>
          </div>
          <div className="projects-details">
            <span>1. Project Name</span>
            <span>Phase-1</span>
            <span id="project-details-mg">
              <img src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"></img>
              <span>S.K Hussain</span>
            </span>
            <span id="project-details-txt">
              <div id="project-details-red-poing"></div>
              <span>High</span>
            </span>
            <span id="project-open">
              <div id="open-point" style={{ display: "flex" }}></div>Open
            </span>
            <span>20% complete</span>
          </div>
        </div>


        <div className="recent-activity">
          <div className="recent-activity-box">
            <div id="activity-icon">
              <AutorenewIcon />
            </div>
            <div>Recent Activity</div>
          </div>

          <div id="activity-row">
            <div id="meeting-text">M</div>
            <div id="meeting">Meeting</div>
            <div>27-05-23</div>
            <div>09.10 AM</div>
            <div id="meet-link">
              <a href="www.meet.google.com">www.meet.google.com</a>
            </div>
            <div>Development Meetings</div>
            <div id="mom">Mom.pdf</div>
          </div>

          <div id="project-row">
            <div id="project-text">P</div>
            <div id="project">Project Phase</div>
            <div id="projetct-name">Project Name</div>
            <div id="open-button">
              <div id="open-point" style={{ display: "flex" }}></div>
              <a>Open</a>
            </div>
            <div id="progress-box" >
              <div id="progress-flow" ></div>
            </div>
            <span id="percentage">10%</span>
            <div>May 10,2023</div>
            <div>----------</div>
          </div>

          <div id="project-row">
            <div id="project-text">T</div>
            <div id="project">Task</div>
            <div id="projetct-name">Project Name</div>
            <div>2hrs</div>
            <span id="project-details-mg-mg">
              <span id="project-details-txt-txt">
                <div id="project-details-red-poing-t"></div>
                <span>High</span>
                <img src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"></img>
                <span>S.K Hussain</span>
              </span>
            </span>
            <span id="percentage">30%</span>
            <div>27-5-23</div>
            <div>----------</div>
          </div>
        </div>


        <div className="notifications" >
          <div className="notification-bar">
            <div id="side-notification-icon"><NotificationsNoneOutlinedIcon /></div>
            <div id="side-notification-text"><span>Notifications</span></div>
            {/* <div id="side-notifiation-search"><SearchOutlinedIcon/></div> */}
          </div>
          <div id="all-archive-bar">
            <Typography style={{ fontSize: "0.7rem" }} id="all-bar">All</Typography>
            <Typography style={{ fontSize: "0.7rem" }} id="archive-bar">Archive</Typography>
            <Typography style={{ position: "relative", left: "14rem", fontWeight: "bold", fontSize: "0.7rem", color: "red" }} id="archive-bar">Clear All</Typography>
          </div>
          <div id="meeting-details">
            <div id="meeting-details-bar">
              <div id="host-dp"><img src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"></img></div>
              <div id='host-name'><span>S.K Hussain</span></div>
              <div id="hosting-status"><span>Requested</span></div>
              <div id="company-tag"><span>ABC Project</span></div>
              <div id="meeting-tag"><span>meeting</span></div>
              <div id="logo-section">
                <span id="delete-icon" className="icons-bg"><DeleteOutlinedIcon style={{ fontSize: "20px" }} /></span>
                <span id="cam-icon" className="icons-bg"><CameraAltOutlinedIcon style={{ fontSize: "20px" }} /></span>
              </div>
            </div>
            <div id="meeting-times-bar">
              <div id="meeting-times"><span>2hrs ago</span></div>
              <div id="meeting-or">
                <div id="bhg"></div><span>Meeting</span>
              </div>
            </div>
          </div>
          <div id="meeting-details">
            <div id="meeting-details-bar">
              <div id="host-dp"><img src="https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"></img></div>
              <div id='host-name'><span id="pp-bg">Sourav Dash</span></div>
              <div id="hosting-status"><span>Completed</span></div>
              <div id="company-tag"><span>ABC Project</span></div>
              <div id="meeting-tag"><span>Task</span></div>
              <div id="logo-section">
                <span id="delete-icon" className="icons-bg"><DeleteOutlinedIcon style={{ fontSize: "20px" }} /></span>
                <span id="cam-icon" className="icons-bg"><CameraAltOutlinedIcon style={{ fontSize: "20px" }} /></span>
              </div>
            </div>
            <div id="meeting-times-bar">
              <div id="meeting-times"><span>2hrs ago</span></div>
              <div id="meeting-or">
                <div id="bhg"></div><span>Task</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
