import {
    VIEW_ALL_INVOICE,
    VIEW_ALL_INVOICE_BY_ID,
    SEARCH_INVOICE
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";

import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";



export function addInvoice(comp_id, title, project_id, total_amount, paid_amount, pending_amount, due_date, invoice_status, attachment) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "add_invoice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                comp_id: comp_id,
            },
            body: JSON.stringify({
                title: title,
                project_id: project_id,
                total_amount: total_amount,
                paid_amount: paid_amount,
                pending_amount: pending_amount,
                due_date: due_date,
                invoice_status: invoice_status,
                attachment: attachment,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllInvoice(comp_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function add_upload_invoice(comp_id, title, project_id, total_amount, paid_amount, pending_amount, due_date, invoice_status, attachment) {
    console.log("assssssssssssssssssssssssssiiiiiiiiiillaa", comp_id)
    return dispatch => {
        dispatch(setLoader());
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/User_Profile/" + attachment + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachment);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachment) {
                        console.log(attachment)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(addInvoice(comp_id, title, project_id, total_amount, paid_amount, pending_amount, due_date, invoice_status, attachment));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else{
            dispatch(addInvoice(comp_id, title, project_id, total_amount, paid_amount, pending_amount, due_date, invoice_status, attachment));
        }

    }
}
export function viewAllInvoice(comp_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_invoice", {
            method: "GET",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                comp_id: comp_id,
            },


        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("asiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", responseJson.result)
                if (responseJson.status) {
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            dispatch({ type: VIEW_ALL_INVOICE, payload: responseJson.result });
                        }
                    }
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_INVOICE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllInvoiceById(invoice_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_invoice_by_id", {
            method: "POST",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                invoice_id: invoice_id,
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_INVOICE_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_INVOICE_BY_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function bulkDeleteInvoice(comp_id, isChecked) {
    console.log("invoiceeeeeeeeeee", comp_id)
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_invoice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                invoice_id: isChecked,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllInvoice(comp_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function CheckedInvoice(index, checked, quantity, invoice) {
    var payload = invoice;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheck = false
    // console.log(payload)
    return {
        type: VIEW_ALL_INVOICE,
        payload: payload
    }
};
export function getallchecked(invoice) {
    for (var i = 0; i <= invoice.length - 1; i++) {
        invoice[i].checked = true;
    }
    return {
        type: VIEW_ALL_INVOICE,
        // payload: payload

    }
};
export function allCheck(checked, quantity, invoice) {
    var payload = invoice;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1) {
            return {
                type: VIEW_ALL_INVOICE,
                payload: payload
            }
        }
    }
};
export function isCheckedId1(comp_id, invoice) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        console.log(invoice)

        // var payload = all_category;
        for (var i = 0; i <= invoice.length - 1; i++) {
            // console.log("asdgtfsdt",i)
            if (invoice[i].checked === true) {
                isChecked.push(invoice[i]._id)
                // console.log(all_category[i]._id)
            }
            count = i;
        }
        if (count === invoice.length - 1) {
            console.log(count, isChecked)
            // console.log("hiiiiiiiiiiiiiiiiiiiii", isChecked)
            dispatch(bulkDeleteInvoice(comp_id, isChecked))
        }
    }

};

export function updateInvoice(_id, comp_id, title, project_id, total_amount, paid_amount, pending_amount, due_date, invoice_status, attachment) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_invoice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                _id: _id,
                title: title,
                project_id: project_id,
                total_amount: total_amount,
                paid_amount: paid_amount,
                pending_amount: pending_amount,
                due_date: due_date,
                invoice_status: invoice_status,
                attachment: attachment,


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllInvoice(comp_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };


}
export function updateAmountInvoice(_id, comp_id, paid_amount, pending_amount, amount) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_invoice_amount", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                _id: _id,
                total_paid: paid_amount,
                pending_amount: pending_amount,
                comp_id: comp_id,
                paid_amount: amount,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllInvoice(comp_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };


}



export function update_Invoice_profile(_id, comp_id, title, project_id, total_amount, paid_amount, pending_amount, due_date, invoice_status, attachment) {
    return dispatch => {
        console.log("invoiceeupdateee", project_id)
        dispatch(setLoader(true));
        if (attachment == null) {
            dispatch(updateInvoice(_id, comp_id, title, project_id, total_amount, paid_amount, pending_amount, due_date, invoice_status, attachment));

        } else {
            const storageRef = ref(getStorage(), "/Profile/" + _id + ".png")

            // .put(image)
            // uploadBytes(storageRef, image).then((snapshot) => {
            //     console.log('Uploaded a blob or file!');
            //   });
            const uploadTask = uploadBytesResumable(storageRef, attachment);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic) {
                        // console.log(downloadURL)
                        dispatch(updateInvoice(_id, comp_id, title, project_id, total_amount, paid_amount, pending_amount, due_date, invoice_status, attachment));
                    });
                }
            );
        }
    }
}




export function search_Invoice(invoice, e) {
    return (dispatch) => {
        console.log(invoice)
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_INVOICE, payload: invoice });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = invoice.filter((el) => {
                console.log(el.invoice_status)
                return (el.invoice_status.toLowerCase().includes(e.toLowerCase()));
            })
            // const array = report.fil
            dispatch({ type: SEARCH_INVOICE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}
export function search_Invoice_by_date(invoice, e) {
    return (dispatch) => {
        console.log(invoice)
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_INVOICE, payload: invoice });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = invoice.filter((el) => {
                console.log(el.date)
                return (el.date.toLowerCase().includes(e.toLowerCase()));
            })
            // const array = report.fil
            dispatch({ type: SEARCH_INVOICE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}