const UNIVERSAL = {
  //  BASEURL: "https://oms-backend-7wtf5c6yua-el.a.run.app/"
  // BASEURL: "https://c894-45-114-51-246.ngrok-free.app/",
  // BASEURL: "https://pms-backend-7wtf5c6yua-el.a.run.app/",
  BASEURL: "https://production-backend-rmmb4coura-el.a.run.app/",
  // URL: https://oms-backend-7wtf5c6yua-el.a.run.app,
  // SSO_URL: "https://4756-103-39-242-181.ngrok-free.app/",
  SSO_URL: "https://ssoapi.technoboot.co/",

};

export default UNIVERSAL;
