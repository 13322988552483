import React, { Component } from 'react';
import { Button, TextField, Typography, List, ListItem, ListItemText, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import img from "../../pages/image/logoTechnoboot.jpeg"
import { ListHeader } from 'semantic-ui-react';
import { useParams } from "react-router-dom";


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


class IssuePage extends Component {
    state = {
        issues: [
            { id: 1, name: 'Issue 1', type: 'Type A' },
            { id: 2, name: 'Issue 2', type: 'Type B' },
            { id: 3, name: 'Issue 3', type: 'Type C' },
            // Add more issues as needed
        ],
        client_remark:"",
    };
    componentDidMount() {
        // let id = this.props.params;
        this.props.view_ticket_id(this.props.params.ticket_id);
        console.log(this.props.login.ticket)
    }
    render() {
        return (
            <div style={{ textAlign: 'center', marginTop: "5%" }}>
                {/* Logo */}
                <img src={img} alt="Logo" style={{ width: '20%', height: 'auto' }} />
                {/* Selected Issue */}
                <Typography variant="h6" style={{ margin: '20px 0' }}>
                    Project :- {this.props.login.ticket &&this.props.login.ticket.project_details && this.props.login.ticket.project_details.project_title}
                </Typography>
                <Typography variant="h5" style={{ margin: '20px 0' }}>
                    Isuues in Ticket-Short-Id - {this.props.login.ticket && this.props.login.ticket.ticketshort_Id}
                </Typography>
                {/* List of Issues */}
                <TableContainer component={Paper} style={{ maxWidth: '600px', margin: '20px auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>NAME</TableCell>
                                <TableCell>TYPE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.login.ticket && this.props.login.ticket.request && this.props.login.ticket.request.map((issue) => (
                                <TableRow key={issue._id}>
                                    <TableCell>{issue.desc}</TableCell>
                                    <TableCell>{issue.request_type.charAt(0).toUpperCase() + issue.request_type.slice(1)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


                {/* Response Text Field */}
                <TextField
                    id="response"
                    label="Response"
                    variant="outlined"
                    multiline
                    rows={4}
                    style={{ width: '80%', maxWidth: '600px', marginBottom: "1%" }}
                    value={this.state.client_remark}
                    onChange={(e) => {
                        this.setState({ client_remark: e.target.value })
                    }}
                />
                {/* Buttons */}
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
                    <Button variant="contained" color="primary"
                        disabled={this.props.login.response}
                        onClick={() => {
                            this.props.update_ticket_response(this.props.login.ticket, "approved", this.state.client_remark)
                            this.setState({client_remark:""})
                        }}
                    >
                        Approve
                    </Button>
                    <Button variant="contained" color="secondary"
                        disabled={this.props.login.response}
                        onClick={() => {
                            this.props.update_ticket_response(this.props.login.ticket, "rejected", this.state.client_remark)
                            this.setState({client_remark:""})
                        }}
                    >
                        Reject
                    </Button>
                </div>


            </div>
        );
    }
}
export default withParams(IssuePage);
