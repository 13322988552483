import {

    VIEW_ALL_TASK,
    VIEW_ALL_TASK_BY_ID,
    VIEW_ALL_TASK_BY_EMP_ID,
    VIEW_ALL_TASK_BY_STATUS,
    SEARCH_TASK
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";
import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export function add_task(milestone_id, title, desc, staart_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets) {
    return (dispatch) => {
       
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_tasK", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                milestone_id: milestone_id
            },
            body: JSON.stringify({

                title: title,
                desc: desc,
                staart_date: staart_date,
                dead_line: dead_line,
                status_id: status_id,
                priority_id: priority_id,
                assign_to: assign_to,
                total_time: total_time,
                task_type: task_type,
                attachmnets: attachmnets
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_All_Task_By_Milestone(milestone_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



export function add_Upload_Task(milestone_id, title, desc, staart_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets) {
    return dispatch => {
        console.log("addtaskkkk", milestone_id, title, desc, staart_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets)
        dispatch(setLoader());
        if (attachmnets !== "") {
            const storageRef = ref(getStorage(), "/User_Profile/" + attachmnets + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachmnets);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachmnets) {
                        console.log(attachmnets)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_task(milestone_id, title, desc, staart_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(add_task(milestone_id, title, desc, staart_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets));
        }
    }
}






export function view_All_Task_By_Milestone(milestone_id) {
    return (dispatch) => {
        console.log("all taskkk",milestone_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_task_by_milestone", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                milestone_id: milestone_id
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)

                    dispatch({ type: VIEW_ALL_TASK, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_TASK, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_task_by_id(task_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_task_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                task_id: task_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_TASK_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_TASK_BY_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });

    };
}
export function bulk_delete_task(isChecked,milestone_id) {
    return (dispatch) => {
        console.log("bulkdeletetask",isChecked)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_task", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({

                task_id: isChecked
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_All_Task_By_Milestone(milestone_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());

                console.error(error);
            });
    };
}

export function CheckedTask(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheck=false
    return {
        type: VIEW_ALL_TASK,
        payload: payload
    }
};

export function getallcheckedTask( all_category) {
    for(var i =0;i<=all_category.length -1;i++){
        all_category[i].checked= true;
    }
    return {
        type: VIEW_ALL_TASK,
        
    }
};

export function allCheckTask(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: VIEW_ALL_TASK,
                payload: payload
            }
    }
};

export function isCheckedTask(milestone_id, all_category) {
    var count=0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= all_category.length - 1; i++) {
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
            }
            count=i;
        }
         if (count === all_category.length - 1) {
            dispatch(bulk_delete_task(isChecked,milestone_id))
        }
    }

};
export function update_task(milestone_id,_id, title, desc, start_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_task", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
                title: title,
                desc: desc,
                start_date: start_date,
                dead_line: dead_line,
                status_id: status_id,
                priority_id: priority_id,
                assign_to: assign_to,
                total_time: total_time,
                task_type: task_type,
                attachmnets: attachmnets

            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_All_Task_By_Milestone(milestone_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}


export function update_Milestone_Task(milestone_id,_id, title, desc, start_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets) {
    return dispatch => {
          console.log("updatetaskkk",milestone_id,_id, title, desc, start_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets)
        dispatch(setLoader(true));
        if (attachmnets == null) {
            dispatch(update_task(milestone_id,_id, title, desc, start_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets));

        } else {
            const storageRef = ref(getStorage(), "/Profile/" + attachmnets + ".png")

            // .put(image)
            // uploadBytes(storageRef, image).then((snapshot) => {
            //     console.log('Uploaded a blob or file!');
            //   });
            const uploadTask = uploadBytesResumable(storageRef, attachmnets);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (attachmnets) {
                        // console.log(downloadURL)
                        dispatch(update_task(milestone_id,_id, title, desc, start_date, dead_line, status_id, priority_id, assign_to, total_time, task_type, attachmnets));
                    });
                }
            );
        }
    }
}


export function update_phase_status(id, status_id, milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_phase_status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,
                status_id: status_id

            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_All_Task_By_Milestone(milestone_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}
export function add_task_time(emp_id, task_id, start_time, status, milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_task_time", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                emp_id: emp_id,

            },
            body: JSON.stringify({

                task_id: task_id,
                start_time: start_time,
                status: status,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_All_Task_By_Milestone(milestone_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function update_task_time(task_id, stop_time, status, milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_task_time", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                task_id: task_id,
            },
            body: JSON.stringify({
                stop_time: stop_time,
                status: status,


            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_All_Task_By_Milestone(milestone_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}

export function view_all_task_by_emp_id(emp_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_task_by_emp_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                emp_id: emp_id,
            },


        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_TASK_BY_EMP_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_TASK_BY_EMP_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });


    };
}

export function view_all_task_by_status(status_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_task_by_status", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                status_id: status_id,
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_TASK_BY_STATUS, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_TASK_BY_STATUS, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });


    };
}



export function search_Task(view_task, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_TASK, payload: view_task });
        }
        else {
            const newArray = view_task.filter((el) => {
                return (el.title.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_TASK, payload: newArray })
        }
    };
}



