import {

    VIEW_ALL_EMPLOYEE,
    SEARCH_EMPLOYEE,
    VIEW_ALL_EMPLOYEE_BY_DEPT_ID
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";



export function add_employee(org_id, profile_pic, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access) {
    return (dispatch) => {
        console.log(org_id, profile_pic, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_employee", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                profile_pic: profile_pic,
                first_name: first_name,
                last_name: last_name,
                emp_id: emp_id,
                position: position,
                joining_date: joining_date,
                mobile: mobile,
                email_id: email_id,
                access: access,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(view_all_employee(org_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function add_upload_employee(org_id, profile_pic, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access) {
    return dispatch => {
        dispatch(setLoader());
        if (profile_pic !== "") {
            const storageRef = ref(getStorage(), "/User_Profile/" + profile_pic + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, profile_pic);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (profile_pic) {
                        // console.log(attachmnet)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_employee(org_id, profile_pic, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(add_employee(org_id, profile_pic, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access));
        }
    }
}
export function view_all_employee(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_employee",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_EMPLOYEE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_EMPLOYEE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function update_emp_profile(org_id, _id, profile_pic, profile_pic2, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access) {
    return dispatch => {
        //   console.log(Emp_Id)
        dispatch(setLoader(true));
        if (profile_pic2 == null) {
            dispatch(update_employee(org_id, _id, profile_pic, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access));

        } else {
            const storageRef = ref(getStorage(), "/Profile/" + _id + Math.random() + ".png")
            const uploadTask = uploadBytesResumable(storageRef, profile_pic2);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic2) {
                        // console.log(downloadURL)
                        if (getDownloadURL(uploadTask.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(update_employee(org_id, _id, profile_pic2, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
    }
}
export function update_employee(org_id, _id, profile_pic, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access) {
    return (dispatch) => {
        
        // console.log("editemp", this.props.login.org_id, this.state.profile_pic, this.state.first_name, this.state.last_name, this.state.emp_id, this.state.position, this.state.joining_date, this.state.mobile, this.state.email_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_employee", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",//

            },
            body: JSON.stringify({
                employee_id: _id,
                profile_pic: profile_pic,
                first_name: first_name,
                last_name: last_name,
                emp_id: emp_id,
                position: position,
                joining_date: joining_date,
                mobile: mobile,
                email_id: email_id,
                access:access

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("editres", responseJson)
                if (responseJson.status) {
                    dispatch(view_all_employee(org_id));
                    dispatch(unsetLoader());
                } else {
                    dispatch(view_all_employee(org_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function deleteEmployee(org_id, employee_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_assets_by_id", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                employee_id: employee_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_employee(org_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
                console.error(error);
            });
    };
}
export function bulk_delete_employee(org_id, isChecked) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "bulk_delete_employee", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                employee_id: isChecked

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_employee(org_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function CheckedEmployee(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheck = false
    return {
        type: VIEW_ALL_EMPLOYEE,
        payload: payload
    }
};

export function getallchecked(all_category) {
    for (var i = 0; i <= all_category.length - 1; i++) {
        all_category[i].checked = true;
    }
    return {
        type: VIEW_ALL_EMPLOYEE,

    }
};

export function allCheck(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: VIEW_ALL_EMPLOYEE,
                payload: payload
            }
    }
};
export function isCheckedId(org_id, all_category) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= all_category.length - 1; i++) {
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
            }
            count = i;
        }
        if (count === all_category.length - 1) {
            dispatch(bulk_delete_employee(org_id, isChecked))
        }
    }

};

export function search_employee(employee, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_EMPLOYEE, payload: employee });
        }
        else {
            const newArray = employee.filter((el) => {
                return (el.first_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_EMPLOYEE, payload: newArray })
        }
    };
}


export function filter_employee(employee, e) {
    return (dispatch) => {
        if (e === "All Employee") {
            dispatch({ type: SEARCH_EMPLOYEE, payload: employee });
        }
        else {
            const newArray = employee.filter((el) => {
                return (el.department.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_EMPLOYEE, payload: newArray })
        }
    };
}





export function view_All_Employee_By_Dept_Id(department_id) {
    return (dispatch) => {
        console.log("deptttiddd", department_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_employee_department",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",

                },
                body: JSON.stringify({
                    department_id: department_id
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("viewres", responseJson)
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_EMPLOYEE_BY_DEPT_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_EMPLOYEE_BY_DEPT_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}




