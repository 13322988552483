import * as React from 'react';
import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { green, pink } from '@mui/material/colors';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
// import LG_Cont from "../../router/containers/Lg_Cont";
// import "../styles/drawer.css";
import { useLocation } from 'react-router-dom'
import AdNav from "../../common/navbar/adNav";
import PMS from "../../pages/image/PMS.png"
import LGCont from '../containers/Lg_Cont'
// import Logo from "../../pages/admin/images/logo.png"
import {
  all_admin_option,
  all_super_admin_options,
  all_client_options,
  all_finance_options,
  all_humanresource_options,
  all_consultant_options,
  all_Manager_options,
  all_TeamLead_options,
  all_Executive_options,
  all_auth_person
  // all_tourism_options
  // all_manager_options,

} from "../constant";
import { red } from '@mui/material/colors';
import { Avatar, Badge, Grid, Modal, Button } from '@mui/material';
const drawerWidth = 87;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 50,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    // backgroundColor:'red',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const useStyles = (theme) => ({
  root: {
    display: "flex",

  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: "#FF7000"
    background: "#fadde1"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    backgroundColor: "#11101d",

  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#11101d",


    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    // backgroundColor: '#FF7000',
    backgroundColor: "#11101d",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  iconStyle: {
    position: "relative",
    left: 8,
    top: 3,
    fontSize: 12,
    marginRight: 5,
    //   color:'red'
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  grow: {
    flexGrow: 1,
  },
  Icon: {
    //   color:"red",
    position: "relative",
    left: "10px"
  },

  ListItem: {
    border: "1px solid red",

    "&:hover": {
      borderRadius: "3px",
      backgroundColor: "#08019f",
      // color:'red'
    }
  }



});


export default function MiniDrawer(props) {
  const classes = useTheme();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  function sideBar(type, open) {
    if (type === "Owner") {
      return (
        all_admin_option.map((option) => (

          location.pathname === option.link ? <center style={{ background: 'rgb(255,255,255)', background: 'linear-gradient(102deg, rgba(255,255,255,1) 0%, rgba(205,159,255,1) 1%, rgba(122,0,255,1) 99%, rgba(252,248,255,1) 99%, rgba(252,248,255,1) 100%)', verticalAlign: 'middle' }}>
            <ListItemIcon >
              <Icon className="drawer_icon">{option.icon}</Icon>
            </ListItemIcon>

            <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

              <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                {option.name}
              </Typography>

            </ListItem>
          </center>

            :
            <Link key={option.link} to={option.link} style={{ textDecoration: "none" }}   >
              <center ><ListItemIcon >
                <Icon className="drawer_icon">{option.icon}</Icon>
              </ListItemIcon>

                <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

                  <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                    {option.name}
                  </Typography>

                </ListItem>
              </center>
            </Link>
        ))

      );
    }
    if (type === "AuthPerson") {
      return (
        all_auth_person.map((option) => (

          location.pathname === option.link ? <center style={{ background: 'rgb(255,255,255)', background: 'linear-gradient(102deg, rgba(255,255,255,1) 0%, rgba(205,159,255,1) 1%, rgba(122,0,255,1) 99%, rgba(252,248,255,1) 99%, rgba(252,248,255,1) 100%)', verticalAlign: 'middle' }}>
            <ListItemIcon >
              <Icon className="drawer_icon">{option.icon}</Icon>
            </ListItemIcon>

            <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

              <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                {option.name}
              </Typography>

            </ListItem>
          </center>

            :
            <Link key={option.link} to={option.link} style={{ textDecoration: "none" }}   >
              <center ><ListItemIcon >
                <Icon className="drawer_icon">{option.icon}</Icon>
              </ListItemIcon>

                <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

                  <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                    {option.name}
                  </Typography>

                </ListItem>
              </center>
            </Link>
        ))

      );
    }
    if (type === "C") {
      return (
        all_client_options.map((option) => (

          location.pathname === option.link ? <center style={{ background: 'rgb(255,255,255)', background: 'linear-gradient(102deg, rgba(255,255,255,1) 0%, rgba(205,159,255,1) 1%, rgba(122,0,255,1) 99%, rgba(252,248,255,1) 99%, rgba(252,248,255,1) 100%)', verticalAlign: 'middle' }}>
            <ListItemIcon >
              <Icon className="drawer_icon">{option.icon}</Icon>
            </ListItemIcon>

            <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

              <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                {option.name}
              </Typography>

            </ListItem>
          </center>

            :
            <Link key={option.link} to={option.link} style={{ textDecoration: "none" }}   >
              <center ><ListItemIcon >
                <Icon className="drawer_icon">{option.icon}</Icon>
              </ListItemIcon>

                <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

                  <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                    {option.name}
                  </Typography>

                </ListItem>
              </center>
            </Link>
        ))

      );
    }
    if (type === "Finance") {
      return (
        all_finance_options.map((option) => (

          location.pathname === option.link ? <center style={{ background: 'rgb(255,255,255)', background: 'linear-gradient(102deg, rgba(255,255,255,1) 0%, rgba(205,159,255,1) 1%, rgba(122,0,255,1) 99%, rgba(252,248,255,1) 99%, rgba(252,248,255,1) 100%)', verticalAlign: 'middle' }}>
            <ListItemIcon >
              <Icon className="drawer_icon">{option.icon}</Icon>
            </ListItemIcon>

            <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

              <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                {option.name}
              </Typography>

            </ListItem>
          </center>

            :
            <Link key={option.link} to={option.link} style={{ textDecoration: "none" }}   >
              <center ><ListItemIcon >
                <Icon className="drawer_icon">{option.icon}</Icon>
              </ListItemIcon>

                <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

                  <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                    {option.name}
                  </Typography>

                </ListItem>
              </center>
            </Link>
        ))

      );
    }
    if (type === "HumanResource") {
      return (
        all_humanresource_options.map((option) => (

          location.pathname === option.link ? <center style={{ background: 'rgb(255,255,255)', background: 'linear-gradient(102deg, rgba(255,255,255,1) 0%, rgba(205,159,255,1) 1%, rgba(122,0,255,1) 99%, rgba(252,248,255,1) 99%, rgba(252,248,255,1) 100%)', verticalAlign: 'middle' }}>
            <ListItemIcon >
              <Icon className="drawer_icon">{option.icon}</Icon>
            </ListItemIcon>

            <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

              <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                {option.name}
              </Typography>

            </ListItem>
          </center>

            :
            <Link key={option.link} to={option.link} style={{ textDecoration: "none" }}   >
              <center ><ListItemIcon >
                <Icon className="drawer_icon">{option.icon}</Icon>
              </ListItemIcon>

                <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

                  <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                    {option.name}
                  </Typography>

                </ListItem>
              </center>
            </Link>
        ))

      );
    }
    if (type === "Consultant") {
      return (
        all_consultant_options.map((option) => (

          location.pathname === option.link ? <center style={{ background: 'rgb(255,255,255)', background: 'linear-gradient(102deg, rgba(255,255,255,1) 0%, rgba(205,159,255,1) 1%, rgba(122,0,255,1) 99%, rgba(252,248,255,1) 99%, rgba(252,248,255,1) 100%)', verticalAlign: 'middle' }}>
            <ListItemIcon >
              <Icon className="drawer_icon">{option.icon}</Icon>
            </ListItemIcon>

            <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

              <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                {option.name}
              </Typography>

            </ListItem>
          </center>

            :
            <Link key={option.link} to={option.link} style={{ textDecoration: "none" }}   >
              <center ><ListItemIcon >
                <Icon className="drawer_icon">{option.icon}</Icon>
              </ListItemIcon>

                <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

                  <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                    {option.name}
                  </Typography>

                </ListItem>
              </center>
            </Link>
        ))

      );
    }
    if (type === "Manager") {
      return (
        all_Manager_options.map((option) => (

          location.pathname === option.link ? <center style={{ background: 'rgb(255,255,255)', background: 'linear-gradient(102deg, rgba(255,255,255,1) 0%, rgba(205,159,255,1) 1%, rgba(122,0,255,1) 99%, rgba(252,248,255,1) 99%, rgba(252,248,255,1) 100%)', verticalAlign: 'middle' }}>
            <ListItemIcon >
              <Icon className="drawer_icon">{option.icon}</Icon>
            </ListItemIcon>

            <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

              <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                {option.name}
              </Typography>

            </ListItem>
          </center>

            :
            <Link key={option.link} to={option.link} style={{ textDecoration: "none" }}   >
              <center ><ListItemIcon >
                <Icon className="drawer_icon">{option.icon}</Icon>
              </ListItemIcon>

                <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

                  <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                    {option.name}
                  </Typography>

                </ListItem>
              </center>
            </Link>
        ))

      );
    }
    if (type === "TeamLead") {
      return (
        all_TeamLead_options.map((option) => (

          location.pathname === option.link ? <center style={{ background: 'rgb(255,255,255)', background: 'linear-gradient(102deg, rgba(255,255,255,1) 0%, rgba(205,159,255,1) 1%, rgba(122,0,255,1) 99%, rgba(252,248,255,1) 99%, rgba(252,248,255,1) 100%)', verticalAlign: 'middle' }}>
            <ListItemIcon >
              <Icon className="drawer_icon">{option.icon}</Icon>
            </ListItemIcon>

            <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

              <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                {option.name}
              </Typography>

            </ListItem>
          </center>

            :
            <Link key={option.link} to={option.link} style={{ textDecoration: "none" }}   >
              <center ><ListItemIcon >
                <Icon className="drawer_icon">{option.icon}</Icon>
              </ListItemIcon>

                <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

                  <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                    {option.name}
                  </Typography>

                </ListItem>
              </center>
            </Link>
        ))

      );
    }
    if (type === "Executive") {
      return (
        all_Executive_options.map((option) => (

          location.pathname === option.link ? <center style={{ background: 'rgb(255,255,255)', background: 'linear-gradient(102deg, rgba(255,255,255,1) 0%, rgba(205,159,255,1) 1%, rgba(122,0,255,1) 99%, rgba(252,248,255,1) 99%, rgba(252,248,255,1) 100%)', verticalAlign: 'middle' }}>
            <ListItemIcon >
              <Icon className="drawer_icon">{option.icon}</Icon>
            </ListItemIcon>

            <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

              <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                {option.name}
              </Typography>

            </ListItem>
          </center>

            :
            <Link key={option.link} to={option.link} style={{ textDecoration: "none" }}   >
              <center ><ListItemIcon >
                <Icon className="drawer_icon">{option.icon}</Icon>
              </ListItemIcon>

                <ListItem style={{ display: "flex", justifyContent: "center" }} button key={option.name} className="drawer_text" >

                  <Typography style={{ fontSize: "12px", marginTop: "-10px" }}>
                    {option.name}
                  </Typography>

                </ListItem>
              </center>
            </Link>
        ))

      );
    }
    else if (type === "E") {
      return (
        <div style={{ marginTop: 1 }}>
          {all_super_admin_options.map((option) => (
            <Link key={option.link} to={option.link} style={{ textDecoration: "none", marginTop: "-20px" }}   >
              <ListItem button key={option.name} className="drawer_text">

                <ListItemIcon ><Icon className="drawer_icon">{option.icon}</Icon></ListItemIcon>

                <span>{option.name}</span>
              </ListItem>
            </Link>
          ))}
        </div>
      );
    }
  }
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar style={{ backgroundColor: "white", position: "fixed" }}>
        <Toolbar style={{ justifyContent: "right" }}>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <img className='logo' style={{ height: "40px" }} src={PMS} />
          <Avatar
            alt="User Avatar"
            src={props.login.profile_pic}
            sx={{ width: 50, height: 50, mb: 0 }}
            style={{ cursor: "pointer", marginLeft: '10px' }}
            onClick={openModal}
          />
        </Toolbar>
        <Drawer variant="permanent" open={true}>
          <Grid style={{ backgroundColor: '#7900FF', height: '100%', flexDirection: 'column', justifyContent: 'space-around', display: 'flex', alignItems: 'center' }}>
            <center>
              <Avatar
                alt={localStorage.getItem("name")} src={props.login.profile_pic}
                style={{ width: 60, height: 60 }} >
                {localStorage.getItem("profile_pic") == null ? "" : localStorage.getItem("name").substring(0, 1).toUpperCase()}
              </Avatar>
            </center>
            <List>
              {sideBar(props.type, open)}
            </List>
            <center>
              {/* <LG_Cont /> */}
            </center>
          </Grid>
        </Drawer>
      </AppBar>

      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '80px', // Adjust as needed for your layout
            right: '50px', // Adjust as needed for your layout
            width: 300,
            backgroundColor: 'white',
            padding: 2,
            borderRadius: 2,
          }}
        >
          <center>
            <Typography variant="h6">{props.login.email}</Typography>
            {/* <Typography variant="h6">kumar.deepak@technoboot.co</Typography> */}
            <Avatar
              alt="User Avatar"
              src={props.login.profile_pic}
              sx={{ width: 75, height: 75, mb: 2 }}
            />
            <Typography variant="subtitle1">{props.login.user_name}</Typography>
            {/* <Typography variant="subtitle1">Deepak</Typography> */}
          </center>
          <center>
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
              }}
            >

              <LGCont />
              <Link to="/">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 1,
                    backgroundColor: '#3D3B3B',
                    width: 150,
                    height: 40,
                    color: 'white',
                  }}
                  onClick={() => {
                    props.logOutByToken(props.login.token)
                  }}
                >
                  Logout
                </Button>
              </Link>
            </Box>
          </center>
        </Box>
      </Modal>

    </Box>
  );
}
