import React, { Component } from "react";
import { connect } from "react-redux";
import Vieworg from "../components/issues";
import {
  close_snack_bar
} from "../../common/snackbar/action";
import {
  clear_login,
  viewAllOrgbyMobile,
  viewUserOrgDetails,
  setOrgId,
  view_ticket_id,
  update_ticket_response
} from "../actions";
export class Controller extends Component {
  render() {
    return (
      <Vieworg {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    clear_login: () => {
      dispatch(clear_login())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    view_ticket_id: (ticket_id) => {
      dispatch(view_ticket_id(ticket_id));
    },
    update_ticket_response: (ticket, client_response, client_remark) => {
      dispatch(update_ticket_response(ticket, client_response, client_remark));
    },


    setOrgId: (org_id) => {
      dispatch(setOrgId(org_id));
    },
     
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);