import {
    VIEW_ALL_MEETING_BY_MILESTONE,
    VIEW_ALL_MEETING
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { set_snack_bar } from "../../common/snackbar/action";

export function addMeeting(milestone_id, start_date, time, summary, members, desc) {
    return (dispatch) => {
        dispatch(setLoader());
        var state = JSON.stringify({
            milestone_id: milestone_id,
            summary: summary,
            schedule_date: start_date,
            schedule_time: time,
            agenda: desc,
            attendees: members,
        })
        console.log(state)
        return fetch(UNIVERSAL.BASEURL + "create_link", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                state:state
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllMeetingByMilestone(milestone_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function addUploadMeetings(milestone_id, date, time, agenda, attendees, attcah_files) {
    return dispatch => {
        dispatch(setLoader());
        if (attcah_files !== "") {
            const storageRef = ref(getStorage(), "/User_Profile/" + attcah_files + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attcah_files);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attcah_files) {
                        console.log(attcah_files)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            dispatch(addMeeting(milestone_id, date, time, agenda, attendees, attcah_files));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(addMeeting(milestone_id, date, time, agenda, attendees, attcah_files));
        }

    }
}
export function viewAllMeetingByMilestone(milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_meeting_by_milestone", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                milestone_id: milestone_id
            },


        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)

                    dispatch({ type: VIEW_ALL_MEETING_BY_MILESTONE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_MEETING_BY_MILESTONE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function bulkDeleteMeeting(meeting_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "bulk_delete_meeting", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                meeting_id: meeting_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllMeetingByMilestone(meeting_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function viewMeetingById(meeting_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_meeting_by_id", {
            method: "POST",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                meeting_id: meeting_id,
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_MEETING, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_MEETING, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function updateMeeting(date, time, agenda, attendees, attcah_files, _id, milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_meeting", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                date: date,
                time: time,
                agenda: agenda,
                attendees: attendees,
                attcah_files: attcah_files,
                _id: _id,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllMeetingByMilestone(milestone_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

