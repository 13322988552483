import React, { Component } from 'react'

export default class arrow extends Component {
  render() {
    return (
      <div
      style={{marginLeft:"-62%", cursor:'pointer'}}
      ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
      
      >
      <g clip-path="url(#clip0_3599_2525)">
        <path d="M18 9.99997L16.59 8.58997L12 13.17L7.41 8.58997L6 9.99997L12 16L18 9.99997Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_3599_2525">
          <rect width="24" height="24" fill="white" transform="matrix(0 1 -1 0 24 0)"/>
        </clipPath>
      </defs>
    </svg>
    </div>
    )
  }
}
