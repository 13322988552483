import React, { Component } from "react";
import { connect } from "react-redux";
import Companyopen from "../component/companyopen";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    view_all_status,
    view_all_priority,
    view_all_department,
    // view_all_project_type
} from "../../../auth/actions";


import {
    // setCompanyId,
    // setCompanyName,
    // setCompanyOwner,
    // setCompanyEmail,
    // setCompanyContactNo,
    add_company_service,
    
    isCheckedIdd,
    isCheckedId2,
    CheckedService,
    getallcheckedd,
    allCheckk,



} from "../action"
import {viewAllService} from '../../Services/action'
import {add_upload_invoice,viewAllInvoice,isCheckedId1,CheckedInvoice,getallchecked,allCheck,update_Invoice_profile,search_Invoice, updateAmountInvoice, search_Invoice_by_date} from '../../Invoice/action'
import {add_upload_project, isCheckedId, CheckedProject, view_all_project_by_comp_id,
    setProjectId,
    setProjectName,
    setProjectStatus,
    setProjectPriority,
    setProjectAuthPrsn,update_project_profile,CheckedCompanyProject,isCheckedIdproject,allCheckproject,getallcheckedproject,search_company_project, view_all_project_by_comp_id_accessed} from "../../Project/Actions"
    import {add_company_assets,view_all_assets,view_all_comp_assets,search_company_assets,isCheckedIdassets,allCheckcompanyassets,getallcheckedassets,Checkedcompanyassets,add_upload_company_service,view_all_comp_service,search_company_service} from "../../Company/action"
export class Controller extends Component {
    render() {
        return (
            <Companyopen {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {

        // companyassets: store.companyassets,
        loader:store.loader,
        snackbar: store.snackbar,
        login: store.login,
        company: store.company,
        service: store.service,
        all_project: store.all_project,
        assets: store.assets,
        invoice: store.invoice,
        


    };
};
export const mapDispatchToProps = dispatch => {
    return {

        add_upload_project: (org_id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date,end_date, max_hr, per_hr_cost, desc, attachment) => {
            dispatch(add_upload_project(org_id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date,end_date, max_hr, per_hr_cost, desc, attachment))
        },

        view_all_comp_service: (comp_id) => {
            dispatch(view_all_comp_service(comp_id))
        },
        update_Invoice_profile: (_id,comp_id,title, project_id, total_amount, paid_amount,pending_amount, due_date, invoice_status, attachment) => {
          dispatch(update_Invoice_profile(_id,comp_id,title, project_id, total_amount, paid_amount,pending_amount, due_date, invoice_status, attachment))
      },

        



        // update_comp_service: (_id, comp_id, purchase_date, service_id, exp_date, comp_service_name, desc, related_to) => {
        //     dispatch(update_comp_service(_id, comp_id, purchase_date, service_id, exp_date, comp_service_name, desc, related_to));
        // },


        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        view_all_status: (org_id) => {
            dispatch(view_all_status(org_id));
        },
        isCheckedId: (org_id, all_project) => {
            dispatch(isCheckedId(org_id, all_project));
        },

        isCheckedId1: (comp_id, invoice) => {
            dispatch(isCheckedId1(comp_id, invoice));
        },

        isCheckedId2: (comp_id, service) => {
            dispatch(isCheckedId2(comp_id, service));
        },
        CheckedProject: (index, checked, quantity, all_project) => {
            dispatch(CheckedProject(index, checked, quantity, all_project));
        },
        view_all_priority: (org_id) => {
            dispatch(view_all_priority(org_id));
        },
       
        
        view_all_department: (org_id) => {
            dispatch(view_all_department(org_id));
        },


        // view_all_project_type: (org_id) => {
        //     dispatch(view_all_project_type(org_id));
        // },
        view_all_project_by_comp_id: (comp_id) => {
            dispatch(view_all_project_by_comp_id(comp_id));
        },
        view_all_project_by_comp_id_accessed: (user_id, comp_id) => {
            dispatch(view_all_project_by_comp_id_accessed(user_id, comp_id));
        },
        setProjectId: (payload) => {
            dispatch(setProjectId(payload))
        },
        setProjectName: (payload) => {
            dispatch(setProjectName(payload))
        },


        add_upload_invoice: (comp_id,title, project_id, total_amount, paid_amount,pending_amount, due_date, invoice_status, attachment) => {
            dispatch(add_upload_invoice(comp_id,title, project_id, total_amount, paid_amount,pending_amount, due_date, invoice_status, attachment))
        },


        view_all_assets: (org_id) => {
            dispatch(view_all_assets(org_id))
        },


        viewAllInvoice: (comp_id) => {
            dispatch(viewAllInvoice(comp_id))
        },
        setProjectStatus: (payload) => {
            dispatch(setProjectStatus(payload))
        },
        setProjectPriority: (payload) => {
            dispatch(setProjectPriority(payload))
        },
        setProjectAuthPrsn: (payload) => {
            dispatch(setProjectAuthPrsn(payload))
        },
        viewAllService: (org_id) => {
            dispatch(viewAllService(org_id))
          },



          CheckedInvoice: (index, checked, quantity, invoice) => {
            dispatch(CheckedInvoice(index, checked, quantity, invoice));
        },
        CheckedService: (index, checked, quantity, service) => {
            dispatch(CheckedService(index, checked, quantity, service));
        },

          isCheckedIdd: (comp_id, all_category) => {
            dispatch(isCheckedIdd(comp_id, all_category))
          },


          add_upload_invoice: (comp_id,title, project_id, total_amount,paid_amount, pending_amount, due_date, invoice_status, attachment) => {
            dispatch(add_upload_invoice(comp_id,title, project_id, total_amount, paid_amount,pending_amount, due_date, invoice_status, attachment))
          },



          add_company_assets: (comp_id, purchase_date, assets_id, exp_date,  desc, related_to) => {
            dispatch(add_company_assets(comp_id, purchase_date, assets_id, exp_date,  desc, related_to))
          },
          view_all_comp_assets: (comp_id) => {
            dispatch(view_all_comp_assets(comp_id))
          },

          search_company_assets: (assetscompany, e) => {
            dispatch(search_company_assets(assetscompany, e))
          },

          search_Invoice_by_date: (invoice, e) => {
            dispatch(search_Invoice_by_date(invoice, e))
          },



          Checkedcompanyassets: (index, checked, quantity, all_category) => {
            dispatch(Checkedcompanyassets(index, checked, quantity, all_category));
          },
          getallcheckedassets: ( all_category) => {
            dispatch(getallcheckedassets(all_category));
          },
          allCheckcompanyassets: (checked, quantity, all_category) => {
            dispatch(allCheckcompanyassets(checked, quantity,all_category));
          },
          isCheckedIdassets: (org_id,all_category) => {
            dispatch(isCheckedIdassets(org_id,all_category) );
          },



          add_upload_company_service: (comp_id, purchase_date, related_to,service_id, exp_date, attachment) => {
            dispatch(add_upload_company_service(comp_id, purchase_date, related_to,service_id, exp_date, attachment) );
          },




          



        //   CheckedInvoice: (index, checked, quantity, invoice) => {
        //     dispatch(CheckedInvoice(index, checked, quantity, invoice));
        //   },

         


          add_company_service: (comp_id,purchase_date, service_id, exp_date, comp_service_name, desc, related_to) => {
            dispatch(add_company_service(comp_id,purchase_date, service_id, exp_date, comp_service_name, desc, related_to));
        },
        getallchecked: ( invoice) => {
            dispatch(getallchecked(invoice));
          },
          allCheck: (checked, quantity, invoice) => {
            dispatch(allCheck(checked, quantity, invoice));
          },


          getallcheckedd: ( service) => {
            dispatch(getallcheckedd(service));
          },
          allCheckk: (checked, quantity, service) => {
            dispatch(allCheckk(checked, quantity, service));
          },


          update_project_profile: ( org_id, _id,  comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date,max_hr, per_hr_cost, desc, attachment, attachment1) => {
            dispatch(update_project_profile(org_id, _id,  comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date,max_hr, per_hr_cost, desc, attachment, attachment1));
          },



          CheckedCompanyProject: (index, checked, quantity, all_category) => {
            dispatch(CheckedCompanyProject(index, checked, quantity, all_category));
          },
          getallcheckedproject: ( all_category) => {
            dispatch(getallcheckedproject(all_category));
          },
          allCheckproject: (checked, quantity, all_category) => {
            dispatch(allCheckproject(checked, quantity,all_category));
          },
          isCheckedIdproject: (comp_id, isChecked,org_id, page) => {
            dispatch(isCheckedIdproject(comp_id, isChecked,org_id, page) );
          },


          search_company_service: (company_service, e) => {
            dispatch(search_company_service(company_service, e) );
          },

          search_company_project: (project_comp_id, e) => {
            dispatch(search_company_project(project_comp_id, e))
          },


          search_Invoice: (invoice, e) => {
            dispatch(search_Invoice(invoice, e))
          },
          updateAmountInvoice: (_id, comp_id, paid_amount, pending_amount, amount) => {
            dispatch(updateAmountInvoice(_id, comp_id, paid_amount, pending_amount, amount))
          },

        
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);