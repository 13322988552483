import {
  VIEW_ALL_COMPANY,
  VIEW_COMPANY_BY_ID,
  VIEW_ALL_COMPANY_ASSETS,
  VIEW_ALL_COMPANY_SERVICE,
  VIEW_COMPANY_SERVICE_BY_ID,
  VIEW_ALL_CHANGE_REQUEST,

  SET_PHASE_ID,
  SET_NAME,
  SET_OWNER_NAME,
  SET_COMPANY_SHORT_ID,
  SET_CCONTACT_NO,
  SET_EMAIL,
  SET_GST_NUM,
  SET_FIX_AMOUNT,
  SET_ADDRESS,
  SET_COMP_ID,
  SET_PURCHASE_DATE,
  SET_EXP_DATE,
  SET_DESC,
  SET_RELATED_TO,
  SET_COMPANY_ASSESTS,
  SET_COMP_SERVICE_ID,
  SET_COMP_SERVICE_NAME,
  SEARCH_COMPANY,
  SET_COMPANY_ID,
  VIEW_ALL_ASSETS_BY_COMPANY_ID,
  SEARCH_COMPANY_ASSETS,
  SEARCH_COMPANY_SERVICE,
  SEARCH_COMPANY_PROJECT,
  SEARCH_PROJECT_PHASE,
  SEARCH_CHANGE_REQUEST,
  SET_PHASE_NAME,
  SET_PROJECT_NAME,
  VIEW_ALL_TASK,
  SET_COMPANY_NAME,
  SET_COMPANY_EMAIL,
  SET_CONTACT_NUMBER,
  VIEW_ALL_REQIREMENT

} from "./constant";
const initial_state = {

  company_service_by_id: [],
  company: [],
  view_company_by_id: [],
  companyassets: [],
  view_company_assets_by_id: [],
  company_service: [],
  // company_id: "",
  // company_contact: "",
  // company_email: "",
  // company_owner: "",
  //company_name: "",
  request: [],
  change_request: [],
  service_id: "",
  phase_id: "",

  setName: "",
  owner_name: "",
  setCompanyShortid: "",
  setContactNo: "",
  setEmail: "",
  setGstNum: "",
  setFixAmount: "",
  setAddress: "",
  setCompId: "",
  setPurchaseDate: "",
  setExpDate: "",
  setDesc: "",
  setRelatedTo: "",

  setServiceId: "",
  setCompServiceName: "",
  setComAssest: "",
  serach_company: [],
  company_id: "",
  assetscompany: [],
  search_comp_assets: [],
  search_comp_service: [],
  search_comp_project: [],
  search_project_phase: [],
  search_change_rquest: [],

  view_task: [],
  name:"",
  email:"",
  contact_no:"",
  title:"",
  requirement:[],


};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case VIEW_ALL_COMPANY:
      // console.log(action.payload)
      return state = {
        ...state,
        company: action.payload, serach_company: action.payload
      };

    case SEARCH_COMPANY:
      // console.log(action.payload)
      return state = { ...state, serach_company: action.payload };

    case VIEW_COMPANY_BY_ID:
      // console.log(action.payload)
      return state = { ...state, view_company_by_id: action.payload };


    case VIEW_ALL_COMPANY_ASSETS:
      return state = { ...state, companyassets: action.payload };


    case VIEW_COMPANY_SERVICE_BY_ID:
      return state = { ...state, company_service_by_id: action.payload };



    case VIEW_ALL_CHANGE_REQUEST:
      console.log("addonnnsss",action.payload)
      return {
        
        ...state,
        change_request: action.payload, search_change_rquest: action.payload
      };


    case SET_PHASE_ID:
      return {
        ...state,
        phase_id: action.payload
      };

      case SET_COMPANY_NAME:
      return {
        ...state,
        name: action.payload
      };

      case SET_COMPANY_EMAIL:
      return {
        ...state,
        email: action.payload
      };


      case SET_CONTACT_NUMBER:
      return {
        ...state,
        contact_no: action.payload
      };




    case SET_COMPANY_ID:
      return {
        ...state,
        company_id: action.payload
      };



    case SET_NAME:
      return {
        ...state,
        setName: action.payload
      };


    case SET_OWNER_NAME:
      console.log("name", action.payload)
      return state = { ...state, owner_name: action.payload };

    case SET_COMPANY_SHORT_ID:
      return {
        ...state,
        setCompanyShortid: action.payload
      };
    case SET_CCONTACT_NO:
      return {
        ...state,
        setContactNo: action.payload
      };
    case SET_EMAIL:
      return {
        ...state,
        setEmail: action.payload
      };

    case SET_GST_NUM:
      return {
        ...state,
        setGstNum: action.payload
      };

    case SET_FIX_AMOUNT:
      return {
        ...state,
        setFixAmount: action.payload
      };
    case SET_ADDRESS:
      return {
        ...state,
        setAddress: action.payload
      };
    case SET_COMP_ID:
      return {
        ...state,
        setCompId: action.payload
      };
    case SET_PURCHASE_DATE:
      return {
        ...state,
        setPurchaseDate: action.payload
      };
    case SET_EXP_DATE:
      return {
        ...state,
        setExpDate: action.payload
      };
    case SET_DESC:
      return {
        ...state,
        setDesc: action.payload
      };

    case SET_RELATED_TO:
      return {
        ...state,
        setRelatedTo: action.payload
      };

    case SET_COMPANY_ASSESTS:
      return {
        ...state,
        setComAssest: action.payload
      };
    case SET_COMP_SERVICE_ID:
      return {
        ...state,
        setServiceId: action.payload
      };

    case SET_COMP_SERVICE_NAME:
      return {
        ...state,
        setCompServiceName: action.payload
      };







    case VIEW_ALL_ASSETS_BY_COMPANY_ID:
      return {
        ...state,
        assetscompany: action.payload, search_comp_assets: action.payload
      };


    case SEARCH_COMPANY_ASSETS:
      return {
        ...state,
        search_comp_assets: action.payload

      };




    case VIEW_ALL_COMPANY_SERVICE:
      return {
        ...state,
        company_service: action.payload, search_comp_service: action.payload
      };



    case SEARCH_COMPANY_SERVICE:
      return {
        ...state,
        search_comp_service: action.payload

      };
    case SEARCH_CHANGE_REQUEST:
      return {
        ...state,
        search_change_rquest: action.payload

      };
    case SET_PHASE_NAME:
      return state = {
        ...state,
        title: action.payload
      };


      case VIEW_ALL_TASK:
        return state = { ...state, view_task: action.payload };
      case VIEW_ALL_REQIREMENT:
        return state = { ...state, requirement: action.payload };












    default:
      return state;
  }
}

