import React, { Component } from 'react'
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineDown } from "react-icons/ai";
import { RxDotFilled } from "react-icons/rx";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Textarea from '@mui/joy/Textarea';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography, Card, Tooltip
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ImCross } from "react-icons/im";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import "../../Company/styles/companyopen.scss";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineRounded';
import edit from "../../image/edit.png";
import Snackbar from "../../../common/snackbar/components/snackbar";
// import { Select, MenuItem } from "@mui/material";
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';
import ProjectTicket from '../../Tickets/container/AdminTicketCont';
import TableContainer from '@mui/material/TableContainer';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Search from "../../image/vuesax-outline-search-normal.png";
import Trash from "../../image/trash.png";
import paperclip from "../../image/paperclip-2.png";
import DialogContent from '@mui/material/DialogContent';
import PaidIcon from '@mui/icons-material/Paid';
import moment from 'moment/moment';

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};
class Companyopen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addnewproject: false,
            editnewproject: false,
            deletenewproject: false,


            addassets: false,
            deleteassets: false,

            addservice: false,
            deleteservice: false,


            adddTicket1: false,
            editTicket1: false,
            addmorenotes1: false,
            deleteTicket1: false,
            editmorenotes: false,


            addupload: false,
            uploaddelete: false,


            Projectopen: true,
            editinvoice: false,
            holdsssss: false,
            // Assetsopen: false,
            // Serviceopen: false,
            // Ticketsopen: false,
            // invoiceopen: false,



            // projectaddFormOpen: false,
            // projecteditFormOpen: false,
            // projectviewFormOpen: false,

            // addDeleteOpen: false,
            assets_id: "N",
            project_title: "",
            project_priority: "N",
            project_department: "N",
            project_name: "N",
            project_auth_person: "",
            project_maxhr: "",
            project_id: "",
            project_status: "Pending",
            project_type: "N",
            project_startdate: "",
            project_hrcost: "",
            project_desc: "",
            project_atch: "",
            attachmnet: "",
            project_assets: "N",
            purchase_date: "",

            all_check: false,
            chk: false,
            isChecked: [],

            assets_comp_name: "",
            assets_pursh_name: "",
            assets_name: "",
            assets_expdate: "",
            assets_desc: "",
            service_name: "N",
            related_to: "N",
            service_id: "N",
            end_date: "",
            comp_service_name: "",
            desc: "",

            title: "",

            total_amount: "",
            pending_amount: 0,
            due_date: "",
            status_id: "",
            attachment: "",
            attachment1: "",
            invoice_status: "",
            paid_amount: 0,
            comp_id: "",
            Individual: "O",
            project: "N",
            edit_show: false,
            edit_show_id: "",
            _id: '',
            test: true,
            owner_name: "",
            month: "0" + (new Date().getMonth() + 1),
            year: new Date().getFullYear(),
            year2: "",
            uniqueNo: Math.floor(Math.random() * (99 - 100) + 100),
            project_no: "",
            page: "C",
            amount: false,
            paid: 0,
        }
    }
    handelProjectopen = () => {
        this.setState({
            Projectopen: true,
            Assetsopen: false,
            Serviceopen: false,
            Ticketsopen: false,
            invoiceopen: false,
        })

    }
    handelAssetsopen = () => {
        this.setState({

            Projectopen: false,
            Assetsopen: true,
            Serviceopen: false,
            Ticketsopen: false,
            invoiceopen: false,
        })

    }
    handelServiceopen = () => {
        this.setState({
            Projectopen: false,
            Assetsopen: false,
            Serviceopen: true,
            Ticketsopen: false,
            invoiceopen: false,
        })

    }
    handelTicketsopen = () => {
        this.setState({
            Projectopen: false,
            Assetsopen: false,
            Serviceopen: false,
            Ticketsopen: true,
            invoiceopen: false
        })

    }

    handelInvoiceOpen = () => (
        this.setState({
            Projectopen: false,
            Assetsopen: false,
            Serviceopen: false,
            Ticketsopen: false,
            invoiceopen: true
        })
    )

    handelOpen = () => {
        this.setState({
            projectaddFormOpen: true,

        })
    }

    handleDeleteOpen = () => {
        this.setState({
            addDeleteOpen: true
        })
    }
    modaloff = () => {
        this.setState({
            projectaddFormOpen: false,

        })
    }
    deleteModalOff = () => {
        this.setState({
            addDeleteOpen: false
        })
    }



    handelOpen = () => {
        this.setState({
            projectaddFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            projectaddFormOpen: false,

        })
    }


    handelOpen = () => {
        this.setState({
            projecteditFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            projecteditFormOpen: false,

        })
    }


    handelOpen = () => {
        this.setState({
            projectviewFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            projectviewFormOpen: false,

        })
    }


    handelmemberOpen = () => {
        console.log("MEMBER", this.state.addTaskOpen)
        console.log("TASK", this.state.addTaskOpen)
        // console.log("MEETING", this.state.addTaskOpen)
        // console.log("DOCUMENTS", this.state.addTaskOpen)
        // console.log("REQUEST", this.state.addTaskOpen)
        // console.log("FOLLOWUP", this.state.addTaskOpen)
        // console.log("TIMESHEET", this.state.addTaskOpen)
        this.setState(prevState => ({
            addmemberopen: true,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addDocumentsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false

        }));
    }
    handelTaskOpen = () => {
        console.log("MEMBER", this.state.addTaskOpen)
        console.log("TASK", this.state.addTaskOpen)
        // console.log("MEETING", this.state.addTaskOpen)
        // console.log("DOCUMENTS", this.state.addTaskOpen)
        // console.log("REQUEST", this.state.addTaskOpen)
        // console.log("FOLLOWUP", this.state.addTaskOpen)
        // console.log("TIMESHEET", this.state.addTaskOpen)
        this.setState(prevState => ({
            addTaskOpen: true,
            addmemberopen: false,
            addMeetingsOpen: false,
            addDocumentsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false
        }));
    }
    handelmeetingOpen = () => {
        this.setState(prevState => ({
            addMeetingsOpen: true,
            addmemberopen: false,
            addTaskOpen: false,
            addDocumentsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false
        }));

    }
    handeldocumentsOpen = () => {
        this.setState({

            addDocumentsOpen: true,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false
        })


    }

    handelRequestsOpen = () => {
        this.setState({

            addRequestsOpen: true,
            addDocumentsOpen: false,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: false
        })


    }

    handelfollowupOpen = () => {
        this.setState({
            addFollowupsOpen: true,
            addDocumentsOpen: false,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addRequestsOpen: false,
            addTimesheetOpen: false
        })
    }

    handelTimesheetOpen = () => {

        this.setState({
            addDocumentsOpen: false,
            addmemberopen: false,
            addTaskOpen: false,
            addMeetingsOpen: false,
            addRequestsOpen: false,
            addFollowupsOpen: false,
            addTimesheetOpen: true
        })

    }
    handelAuthopen = () => {
        this.setState({
            authPerson: true,
            Projectopen: false,
            Assetsopen: true,
            Serviceopen: false,
            Ticketsopen: false,
            invoiceopen: false,
        })

    }
    componentDidMount() {
        document.body.style.backgroundColor = "#f5edff";
        // console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii",this.props.login.org_id);
        // this.props.view_all_assets(this.props.login.org_id);
        // console.log("project details",this.props.company.company_id)
        // console.log(this.props.login.org_id);
        if (this.props.login.type === "AuthPerson") {
            this.props.view_all_project_by_comp_id_accessed(this.props.login.user_id, this.props.company.company_id)
        }
        else {
            this.props.view_all_status(this.props.login.org_id);
            this.props.view_all_priority(this.props.login.org_id);
            this.props.view_all_department(this.props.login.org_id);
            this.props.view_all_project_by_comp_id(this.props.company.company_id);
            this.props.view_all_assets(this.props.login.org_id);
            this.props.viewAllService(this.props.login.org_id);
            this.props.view_all_comp_service(this.props.company.company_id);
            this.props.viewAllInvoice(this.props.company.company_id);
            this.props.view_all_comp_assets(this.props.company.company_id);
            this.setState({ year2: ((this.state.year).toString().charAt(2)) + ((this.state.year).toString().charAt(3)) })
            console.log(this.props.company.company_id)
        }
    }
    CheckedCompanyProject(p, index, project_comp_id) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedCompanyProject(index, true, 1, project_comp_id)
        }
        else {
            this.props.CheckedCompanyProject(index, false, 0, project_comp_id)
        }
    }
    AllCheckedProject(p, project_comp_id) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckproject(true, 1, project_comp_id)

        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheckproject(false, 0, project_comp_id)
        }

    }

    CheckedInvoice(p, index, invoice) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedInvoice(index, true, 1, invoice)
        }
        else {
            this.props.CheckedInvoice(index, false, 0, invoice)
        }
    }

    AllCheckedInvoice(p, invoice) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheck(true, 1, invoice)

        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheck(false, 0, invoice)
        }

    }


    CheckedService(p, index, service) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedService(index, true, 1, service)
        }
        else {
            this.props.CheckedService(index, false, 0, service)
        }
    }

    AllCheckedService(p, service) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckk(true, 1, service)

        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheckk(false, 0, service)
        }

    }


    Checkedcompanyassets(p, index, assetscompany) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.Checkedcompanyassets(index, true, 1, assetscompany)
        }
        else {
            this.props.Checkedcompanyassets(index, false, 0, assetscompany)
        }
    }
    AllCheckedAssets(p, assetscompany) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckcompanyassets(true, 1, assetscompany)
        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheckcompanyassets(false, 0, assetscompany)
        }

    }
    render() {
        const { company, service, invoice, all_project, snackbar, close_snack_bar
        } = this.props

        return (
            <Grid>
                <div style={{ display: "flex", flexDirection: "row", marginTop: "5%", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginTop: "2%", marginLeft: "4%" }}>
                        <Link style={{ textDecoration: "none" }} to="/company">
                            <Button style={{ marginLeft: "1%", color: "#7A7E86", height: "25px" }}>
                                Company

                            </Button>
                        </Link>
                        <ArrowForwardIosIcon style={{ marginTop: "3%", fontSize: "17px", }} />
                        <Typography style={{ marginLeft: "1%", }}>
                            Projects

                        </Typography>

                    </div>

                    <div style={{ marginRight: "2%", marginTop: "2%", }}>

                        <Paper
                            component="form"
                            style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}
                        >
                            <IconButton
                                type="button"
                                style={{ p: "15px" }}
                                aria-label="search"
                            >
                                <img src={Search} />
                            </IconButton>
                            <InputBase
                                style={{ ml: 5, flex: 1, fontSize: "16px", fontFamily: "Montserrat", color: "#8C8C8C", fontWeight: "300" }}
                                placeholder="Search here"
                                inputProps={{ "aria-label": "Search here" }}
                                onChange={e => { this.props.search_company_assets(company.assetscompany, e.target.value); this.props.search_company_service(company.company_service, e.target.value); this.props.search_company_project(all_project.project_comp_id, e.target.value); this.props.search_Invoice(invoice.invoice, e.target.value) }}
                            />
                        </Paper>

                    </div>
                </div>
                <div className="companyopenPage" >
                    <Card style={{ width: "100%", margin: "2% 0% 0% 4%", padding: "2%" }}>
                        <Grid container direction={"row"}>
                            <Grid item xs={0.3}></Grid>
                            <Grid item xs={7.7}>
                                <Typography variant='h4'>{this.props.company.name}</Typography>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Grid container direction={"row"}>
                                    <Grid item xs={5}>
                                        <Typography>Credit Limit :</Typography>
                                    </Grid>
                                    <Grid item xs={7} >
                                        <Typography textAlign={"center"} fontWeight={"bolder"} >{this.props.company.owner_name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Typography>Email Id :</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography textAlign={"center"} fontWeight={"bolder"} color={"blue"}>{this.props.company.email}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"}>
                                    <Grid item xs={4}>
                                        <Typography>Phone No :</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography textAlign={"center"} fontWeight={"bolder"} >{this.props.company.contact_no}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className="list">
                            <ul>
                                <li style={this.state.Projectopen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelProjectopen}>Project</li>
                                <li style={this.state.Assetsopen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelAssetsopen}>Assets</li>
                                <li style={this.state.Serviceopen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelServiceopen}>Services</li>
                                <li style={this.state.Ticketsopen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelTicketsopen}>Tickets</li>
                                <li style={this.state.invoiceopen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelInvoiceOpen}>Invoice</li>
                            </ul>
                        </div>

                        {/* Project */}
                        {this.state.Projectopen &&
                            <Grid>
                                <Grid>
                                    <div style={{ display: "flex", flexdirection: "row" }}>
                                        <Typography style={{ margin: "2%", fontSize: "20px" }}> All Projects</Typography>
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    addnewproject: true,
                                                    project_no: "P" + this.state.year2 + this.state.month + "0" + "0" + (Math.floor(Math.random() * (999 - 100)) + 100)
                                                })
                                            }}

                                            style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" stle> Add Project </Button>


                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    deletenewproject: true,

                                                })

                                            }}
                                            style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginLeft: "1%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" startIcon={<img src={Trash} style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                            Delete
                                        </Button>
                                    </div>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead >
                                            <TableRow style={{ backgroundColor: "#F1F1F1" }} >
                                                <TableCell style={{ fontSize: "13px" }}><Checkbox
                                                    checked={this.props.all_project.project_comp_id.allCheck}
                                                    onClick={(p) => {
                                                        {
                                                            this.AllCheckedProject(p, this.props.all_project.project_comp_id)
                                                        }
                                                    }}
                                                /></TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Name</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Priority</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Status </TableCell>
                                                {/* <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Progrss </TableCell> */}
                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Start Date</TableCell>
                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }}>End Date </TableCell>

                                                <TableCell style={{ textAlign: "center", fontSize: "13px" }}></TableCell>
                                            </TableRow>

                                        </TableHead>
                                        {Array.isArray(this.props.all_project.search_comp_project) && this.props.all_project.search_comp_project.sort((a, b) => moment(a.start_date).isBefore(moment(b.start_date)) ? -1 : moment(a.start_date).isAfter(moment(b.start_date)) ? 1 : 0).map((e, index) => {
                                            return (

                                                <TableRow hover={true}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            edit_show: true,
                                                            hold: true,
                                                            edit_show_id: e._id,
                                                            edit_hold: e._id
                                                        })
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            edit_show: false,
                                                            hold: false,
                                                            edit_show_id: "",
                                                            edit_hold: ""
                                                        })
                                                    }} onClick={() => {
                                                        this.props.setProjectId(e._id);
                                                        this.props.setProjectName(e.project_title);
                                                        this.props.setProjectStatus(e.status_id);
                                                        this.props.setProjectPriority(e.priority_id);
                                                        this.props.setProjectAuthPrsn(e.project_auth_person);
                                                    }} >

                                                    <TableCell style={{ fontSize: "13px", cursor: "pointer" }} ><Checkbox
                                                        checked={e.checked}
                                                        onClick={(p) => { this.CheckedCompanyProject(p, index, this.props.all_project.project_comp_id) }}
                                                    /></TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px", cursor: "pointer" }} onClick={() => {
                                                        this.props.navigate('/companyphase')
                                                        this.props.setProjectId(e._id);
                                                        this.props.setProjectName(e.project_title);
                                                        this.props.setProjectStatus(e.status_id);
                                                        this.props.setProjectPriority(e.priority_id);
                                                        this.props.setProjectAuthPrsn(e.project_auth_person);
                                                    }}>{index + 1}.{e.project_title}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px", cursor: "pointer" }} onClick={() => {
                                                        this.props.navigate('/companyphase')
                                                        this.props.setProjectId(e._id);
                                                        this.props.setProjectName(e.project_title);
                                                        this.props.setProjectStatus(e.status_id);
                                                        this.props.setProjectPriority(e.priority_id);
                                                        this.props.setProjectAuthPrsn(e.project_auth_person);
                                                    }}>{e.priority_id}</TableCell>


                                                    {/* <TableCell style={{ color: "blue", textAlign: "center", fontSize: "13px" }} onClick={() => { this.props.navigate('/companyphase') }}>{e.status_id}</TableCell> */}

                                                    <TableCell style={{ textAlign: "center", fontSize: "15px", cursor: "pointer" }} onClick={() => {
                                                        this.props.navigate('/companyphase')
                                                        this.props.setProjectId(e._id);
                                                        this.props.setProjectName(e.project_title);
                                                        this.props.setProjectStatus(e.status_id);
                                                        this.props.setProjectPriority(e.priority_id);
                                                        this.props.setProjectAuthPrsn(e.project_auth_person);
                                                    }}>
                                                        <Button style={{
                                                            backgroundColor: e.status_id === 'Closed' ? 'rgba(255, 84, 84, 0.40)' : e.status_id === 'On Hold' ? 'rgba(252, 255, 84, 0.40)' : e.status_id === 'Open' ? 'rgba(84, 140, 255, 0.40)' : e.status_id === 'Pending' ? 'rgba(255, 84, 217, 0.40)' : 'rgba(82, 183, 73, 0.40)',
                                                            border: e.status_id === 'Closed' ? '0.4px solid #C91E1E' : e.status_id === 'On Hold' ? '0.4px solid #C9B81E' : e.status_id === 'Open' ? '0.4px solid #1E57C9' : e.status_id === 'Pending' ? '0.4px solid #C91EA3' : '0.4px solid #008000',
                                                            color: e.status_id === 'Closed' ? '#C91E1E' : e.status_id === 'On Hold' ? '#C9B81E' : e.status_id === 'Open' ? '#1E57C9' : e.status_id === 'Pending' ? '#C91EA3' : '#008000', borderRadius: "0.8125rem", height: "1.75rem"
                                                        }}
                                                            variant="outlined" >
                                                            <RxDotFilled />
                                                            {e.status_id}
                                                        </Button>
                                                    </TableCell>



                                                    {/* <TableCell className='progress'>
                                                            10% Completed
                                                        </TableCell> */}
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px", cursor: "pointer" }} onClick={() => {
                                                        this.props.navigate('/companyphase')
                                                        this.props.setProjectId(e._id);
                                                        this.props.setProjectName(e.project_title);
                                                        this.props.setProjectStatus(e.status_id);
                                                        this.props.setProjectPriority(e.priority_id);
                                                        this.props.setProjectAuthPrsn(e.project_auth_person);
                                                    }}>{moment(e.start_date).format('ll')}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "13px", cursor: "pointer" }} onClick={() => {
                                                        this.props.navigate('/companyphase')
                                                        this.props.setProjectId(e._id);
                                                        this.props.setProjectName(e.project_title);
                                                        this.props.setProjectStatus(e.status_id);
                                                        this.props.setProjectPriority(e.priority_id);
                                                        this.props.setProjectAuthPrsn(e.project_auth_person);
                                                    }}>{moment(e.end_date).format('ll')}</TableCell>




                                                    <TableCell style={{ textAlign: "center", fontSize: "13px", cursor: "pointer" }}  >
                                                        <Tooltip>


                                                            <IconButton onClick={() => {
                                                                this.setState({
                                                                    editnewproject: true,
                                                                    _id: e._id,
                                                                    project_title: e.project_title,
                                                                    project_id: e.project_id,
                                                                    project_priority: e.priority_id,
                                                                    project_status: e.status_id,
                                                                    project_department: e.department_id,
                                                                    project_type: e.project_type,
                                                                    project_startdate: e.start_date,
                                                                    project_maxhr: e.max_hr,
                                                                    project_hrcost: e.per_hr_cost,
                                                                    project_desc: e.desc,
                                                                    attachment: e.attachment,
                                                                    end_date: e.end_date,

                                                                })
                                                            }}
                                                            >{this.state.edit_show && this.state.edit_show_id === e._id &&
                                                                <img style={{ color: "green", width: 25 }} src={edit} />} </IconButton>
                                                        </Tooltip>
                                                        <IconButton onClick={() => {
                                                            this.setState({
                                                                holdsssss: true,


                                                            })
                                                        }}
                                                        >{this.state.hold && this.state.edit_hold === e._id &&
                                                            <HourglassEmptyRoundedIcon style={{ color: "red" }} />} </IconButton>
                                                    </TableCell>



                                                </TableRow>


                                            )
                                        })}
                                    </Table>
                                </Grid>

                                {/* ------------------------------------Add Project ----------------------------------------------- */}
                                <Dialog
                                    open={this.state.addnewproject}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Project</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ addnewproject: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Project Name<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                onChange={(e) => { this.setState({ project_title: e.target.value }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Project ID<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography
                                                                size='small'
                                                                fullWidth
                                                                color={'blue'}
                                                                fontWeight={'bolder'}
                                                            // value={this.state.comp_no}
                                                            // disabled
                                                            >{this.state.project_no}</Typography>
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Priority.<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                id="outlined-select-currency-native"
                                                                size='small'
                                                                fullWidth
                                                                select
                                                                placeholder='Select Priority'
                                                                value={this.state.project_priority}
                                                                onChange={(e) => {
                                                                    this.setState({ project_priority: e.target.value })
                                                                    // console.log(all_category)
                                                                }}
                                                            >
                                                                <MenuItem value={"N"}>{"Select Priority"}</MenuItem>
                                                                {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e) => (
                                                                    <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                ))}


                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Status <strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                disabled={true}
                                                                defaultValue={"Pending"}
                                                                size='small'
                                                                fullWidth
                                                                id="outlined-select-currency-native"
                                                                select
                                                                // value={this.state.project_status}
                                                                onChange={(e) => {
                                                                    this.setState({ project_status: e.target.value })
                                                                    // console.log(all_category)
                                                                }}
                                                            >
                                                                <MenuItem value={"N"}>{"Select Priority"}</MenuItem>
                                                                {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                                                    <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                ))}


                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2} mt={0.5}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Start Date<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='date'
                                                                onChange={(e) => this.setState({
                                                                    project_startdate: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Dead Line<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='date'
                                                                onChange={(e) => this.setState({
                                                                    end_date: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Maximum Hours :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                onChange={(e) => this.setState({
                                                                    project_maxhr: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Per Hour Cost :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                onChange={(e) => this.setState({
                                                                    project_hrcost: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Description :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                placeholder='Description'
                                                                size='small'
                                                                fullWidth
                                                                multiline
                                                                onChange={(e) => this.setState({
                                                                    project_desc: e.target.value
                                                                })}
                                                                inputProps={{
                                                                    style: {
                                                                        height: 50,
                                                                    },
                                                                }}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2} mt={0.5}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Attachment :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9} style={{ border: "1px solid grey", borderRadius: 8, height: 50, }} >
                                                            <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}
                                                            >
                                                                <img src={paperclip} style={{
                                                                    width: "21.338px",
                                                                    height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                }} />
                                                                <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                >
                                                                    {this.state.attachment !== "" ? (this.state.attachment).name : "Attachment File"}
                                                                </Typography>

                                                            </label>

                                                            <input style={{ display: "none" }} type="file" id="file"
                                                                onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ addnewproject: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                disabled={(this.state.project_title === '') || (this.state.project_no === '') || (this.state.project_department === '') || (this.state.project_startdate === '') || (this.state.end_date === '')}
                                                onClick={() => {
                                                    this.setState({ addnewproject: false, project_title: '', project_id: "", project_priority: "", project_status: "", project_department: "", project_type: "", project_startdate: "", end_date: "", project_maxhr: "", project_hrcost: "", project_desc: "", attachment: "" });

                                                    this.props.add_upload_project(this.props.login.org_id, company.company_id, this.state.project_title, this.state.project_no, this.state.project_priority, this.state.project_status, this.state.project_department, this.state.project_type, this.state.project_startdate, this.state.end_date, this.state.project_maxhr, this.state.project_hrcost, this.state.project_desc, this.state.attachment)

                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Add Project
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >

                                {/* hold */}
                                <Dialog
                                    open={this.state.holdsssss}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}> Hold Project</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ holdsssss: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>



                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Description :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                placeholder='Description'
                                                                size='small'
                                                                fullWidth
                                                                multiline
                                                                onChange={(e) => this.setState({
                                                                    project_desc: e.target.value
                                                                })}
                                                                inputProps={{
                                                                    style: {
                                                                        height: 50,
                                                                    },
                                                                }}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ holdsssss: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    this.setState({ holdsssss: false, })
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Hold Project
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >

                                {/* ------------------------------------Edit Project ---------------------------------------------- */}
                                <Dialog
                                    open={this.state.editnewproject}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Project</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ editnewproject: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Project Name<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                value={this.state.project_title}
                                                                onChange={(e) => { this.setState({ project_title: e.target.value }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Project ID<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography
                                                                size='small'
                                                                fullWidth
                                                                color={'blue'}
                                                                fontWeight={'bolder'}
                                                            // value={this.state.comp_no}
                                                            // disabled
                                                            >{this.state.project_id}</Typography>
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Priority.<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                id="outlined-select-currency-native"
                                                                size='small'
                                                                fullWidth
                                                                select
                                                                placeholder='Select Priority'
                                                                value={this.state.project_priority}
                                                                onChange={(e) => {
                                                                    this.setState({ project_priority: e.target.value })
                                                                    // console.log(all_category)
                                                                }}
                                                            >
                                                                <MenuItem value={"N"}>{"Select Priority"}</MenuItem>
                                                                {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e) => (
                                                                    <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                ))}


                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Status <strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                id="outlined-select-currency-native"
                                                                select
                                                                value={this.state.project_status}
                                                                onChange={(e) => {
                                                                    this.setState({ project_status: e.target.value })
                                                                    // console.log(all_category)
                                                                }}
                                                            >
                                                                <MenuItem value={"N"}>{"Select Priority"}</MenuItem>
                                                                {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                                                    <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                ))}


                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2} mt={0.5}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Start Date<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='date'
                                                                value={this.state.project_startdate}
                                                                onChange={(e) => this.setState({
                                                                    project_startdate: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Dead Line<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='date'
                                                                value={this.state.end_date}
                                                                onChange={(e) => this.setState({
                                                                    end_date: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Maximum Hours :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                value={this.state.project_maxhr}
                                                                onChange={(e) => this.setState({
                                                                    project_maxhr: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Per Hour Cost :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                value={this.state.project_hrcost}
                                                                onChange={(e) => this.setState({
                                                                    project_hrcost: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Description :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                placeholder='Description'
                                                                size='small'
                                                                fullWidth
                                                                multiline
                                                                value={this.state.project_desc}
                                                                onChange={(e) => this.setState({
                                                                    project_desc: e.target.value
                                                                })}
                                                                inputProps={{
                                                                    style: {
                                                                        height: 50,
                                                                    },
                                                                }}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2} mt={0.5}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Attachment :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9} style={{ border: "1px solid grey", borderRadius: 8, height: 50, }} >
                                                            <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}
                                                            >
                                                                <img src={paperclip} style={{
                                                                    width: "21.338px",
                                                                    height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                }} />
                                                                <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                >
                                                                    {this.state.attachment !== "" && this.state.attachment1 === "" ? "File Uploaded" : (this.state.attachment1).name}
                                                                </Typography>

                                                            </label>

                                                            <input style={{ display: "none" }} type="file" id="file"
                                                                onChange={(e) => { this.setState({ attachment1: e.target.files[0] }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ editnewproject: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                // disabled={(this.state.project_title === '') || (this.state.project_no === '') || (this.state.project_department === '') || (this.state.project_startdate === '') || (this.state.end_date === '')}
                                                onClick={() => {
                                                    this.setState({ editnewproject: false, attachment1: "" });
                                                    this.props.update_project_profile(this.props.login.org_id, this.state._id, company.company_id, this.state.project_title, this.state.project_id, this.state.project_priority, this.state.project_status, this.state.project_department, this.state.project_type, this.state.project_startdate, this.state.end_date, this.state.project_maxhr, this.state.project_hrcost, this.state.project_desc, this.state.attachment, this.state.attachment1)
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Update
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >
                                {/* ------------------------------------Delete Project ----------------------------------------------- */}
                                <Dialog
                                    fullWidth
                                    maxWidth="xs"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                    open={this.state.deletenewproject}
                                    onClose={() => {
                                        this.setState({ deletenewproject: false });
                                    }}
                                >
                                    <Grid textAlign={"center"} mt={3}>
                                        <DialogTitle>
                                            <ErrorOutlineOutlinedIcon style={{ fontSize: "66px", color: "#FF0000" }} />
                                            <Typography style={{ fontSize: "20px", color: "#FF0000", fontWeight: 700 }}>Are You Sure ?</Typography >
                                            <Typography mt={1} style={{ fontSize: "16px" }}>You want to Delete ?</Typography >
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container >
                                                <Grid item xs={6}
                                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black", cursor: "pointer", borderRadius: "0px 0px 0px 10px" }}
                                                    onClick={() => { this.setState({ deletenewproject: false }) }}>
                                                    <Typography style={{ fontWeight: 500, fontSize: "16px", marginTop: "revert" }}>Cancel</Typography >
                                                </Grid>
                                                <Grid item xs={6}
                                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px", cursor: "pointer", borderRadius: "0px 0px 10px 0px" }}
                                                    onClick={() => {
                                                        this.setState({ deletenewproject: false })
                                                        console.log(this.state.page)
                                                        this.props.isCheckedIdproject(this.props.login.org_id, this.props.all_project.project_comp_id, company.company_id, this.state.page)
                                                    }}
                                                >
                                                    <Typography style={{ fontWeight: 500, fontSize: "16px", color: "white", marginTop: "revert" }}>Confirm</Typography >
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Grid>
                                </Dialog>
                            </Grid>
                        }
                        {/* Assets */}
                        {this.state.Assetsopen &&
                            <Grid>
                                <Grid>
                                    {/* <TableContainer component={Paper}> */}
                                    <div style={{ display: "flex", flexdirection: "row" }}>
                                        <Typography style={{ margin: "2%", fontSize: "20px" }}>All Assets</Typography>
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    addassets: true,
                                                })
                                            }}

                                            style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" stle> Add Asset </Button>


                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    deleteassets: true,

                                                })

                                            }}
                                            style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginLeft: "1%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" startIcon={<img src={Trash} style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                            Delete
                                        </Button>
                                    </div>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead >
                                            <TableRow style={{ backgroundColor: "#F1F1F1" }} >
                                                <TableCell style={{ fontSize: "13px" }}><Checkbox
                                                    checked={this.props.company.assetscompany.allCheckcompanyassets}
                                                    onClick={(p) => {
                                                        {
                                                            this.AllCheckedAssets(p, this.props.company.assetscompany)
                                                        }
                                                    }}
                                                /></TableCell>
                                                <TableCell>Purchase Date</TableCell>
                                                <TableCell>Assets</TableCell>
                                                <TableCell>Related to</TableCell>
                                                <TableCell>Expriry Date</TableCell>
                                            </TableRow>

                                        </TableHead>
                                        {Array.isArray(this.props.company.search_comp_assets) && this.props.company.search_comp_assets.map((item, index) => {
                                            return (
                                                <TableRow hover={true}>
                                                    <TableCell>
                                                        <Checkbox

                                                            checked={item.checked}

                                                            onClick={(p) => { this.Checkedcompanyassets(p, index, this.props.company.assetscompany) }}
                                                        />
                                                    </TableCell>

                                                    <TableCell>{item.purchase_date}</TableCell>
                                                    <TableCell>{item.assets_details}</TableCell>
                                                    <TableCell>{item.related_to}</TableCell>
                                                    <TableCell>
                                                        {item.exp_date}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </Table>
                                    {/* </TableContainer> */}
                                </Grid>

                                {/* ------------------------------------Add Asset ----------------------------------------------- */}
                                <Dialog
                                    open={this.state.addassets}
                                    fullWidth
                                    maxWidth="md"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Asset</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ addassets: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black", justifyContent: "right" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Assets<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                // placeholder='Select assets'
                                                                value={this.state.assets_id}
                                                                onChange={(e) => {
                                                                    this.setState({ assets_id: e.target.value })
                                                                    console.log(service)
                                                                }}

                                                            >

                                                                <MenuItem value={"N"}>{"Select assets"}</MenuItem>
                                                                {Array.isArray(this.props.assets.assets) && this.props.assets.assets.map((e) => (
                                                                    <MenuItem value={e._id}>{e.assets_name}</MenuItem>
                                                                ))}

                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Related To<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="outlined-select-currency-native"
                                                                select
                                                                value={this.state.related_to}
                                                                onChange={(e) => {
                                                                    this.setState({ related_to: e.target.value })
                                                                }}

                                                            >
                                                                <MenuItem value={"N"}>{"Related To"}</MenuItem>
                                                                <MenuItem value={"Project"}>{"Project"}</MenuItem>
                                                                <MenuItem value={"Individual"}>{"Individual"}</MenuItem>
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Purchase Date<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='date'
                                                                onChange={(e) => {
                                                                    this.setState({ purchase_date: e.target.value })
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Expiry Date<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='date'
                                                                onChange={(e) => {
                                                                    this.setState({ exp_date: e.target.value })
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Description :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                placeholder='Description'
                                                                size='small'
                                                                fullWidth
                                                                multiline
                                                                onChange={(e) => this.setState({
                                                                    desc: e.target.value
                                                                })}
                                                                inputProps={{
                                                                    style: {
                                                                        height: 50,
                                                                    },
                                                                }}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ addassets: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                disabled={(this.state.purchase_date === '') || (this.state.assets_id === '') || (this.state.exp_date === '') || (this.state.related_to === '')}
                                                onClick={() => {
                                                    this.setState({ addassets: false, purchase_date: "", assets_id: "", exp_date: "", desc: "", related_to: "" })
                                                    this.props.add_company_assets(company.company_id, this.state.purchase_date, this.state.assets_id, this.state.exp_date, this.state.desc, this.state.related_to)
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Add Asset
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >

                                {/* ------------------------------------Add Delete ----------------------------------------------- */}
                                <Dialog
                                    fullWidth
                                    maxWidth="xs"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                    open={this.state.deleteassets}
                                    onClose={() => {
                                        this.setState({ deleteassets: false });
                                    }}
                                >
                                    <Grid textAlign={"center"} mt={3}>
                                        <DialogTitle>
                                            <ErrorOutlineOutlinedIcon style={{ fontSize: "66px", color: "#FF0000" }} />
                                            <Typography style={{ fontSize: "20px", color: "#FF0000", fontWeight: 700 }}>Are You Sure ?</Typography >
                                            <Typography mt={1} style={{ fontSize: "16px" }}>You want to Delete ?</Typography >
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container >
                                                <Grid item xs={6}
                                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black", cursor: "pointer", borderRadius: "0px 0px 0px 10px" }}
                                                    onClick={() => { this.setState({ deleteassets: false }) }}>
                                                    <Typography style={{ fontWeight: 500, fontSize: "16px", marginTop: "revert" }}>Cancel</Typography >
                                                </Grid>
                                                <Grid item xs={6}
                                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px", cursor: "pointer", borderRadius: "0px 0px 10px 0px" }}
                                                    onClick={() => {
                                                        this.setState({ deleteassets: false })
                                                        console.log(this.state.page)
                                                        this.props.isCheckedIdassets(company.company_id, this.props.company.assetscompany)
                                                    }}
                                                >
                                                    <Typography style={{ fontWeight: 500, fontSize: "16px", color: "white", marginTop: "revert" }}>Confirm</Typography >
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Grid>
                                </Dialog>
                            </Grid>
                        }

                        {/* Services */}
                        {this.state.Serviceopen &&
                            <Grid>
                                <Grid>
                                    <div style={{ display: "flex", flexdirection: "row" }}>
                                        <Typography style={{ margin: "2%", fontSize: "20px" }}>All Services</Typography>
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    addservice: true,
                                                })
                                            }}

                                            style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" stle> Add Service</Button>


                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    deleteservice: true,

                                                })

                                            }}
                                            style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginLeft: "1%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" startIcon={<img src={Trash} style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                            Delete
                                        </Button>
                                    </div>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead >
                                            <TableRow style={{ backgroundColor: "#F1F1F1" }} >
                                                <TableCell>
                                                    <Checkbox
                                                        checked={this.props.company.company_service.allCallCheckkheck}
                                                        onClick={(p) => {
                                                            {
                                                                this.AllCheckedService(p, this.props.company.company_service)
                                                            }
                                                        }}

                                                    />
                                                </TableCell>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Service</TableCell>


                                                <TableCell>Related to</TableCell>

                                                <TableCell>Expriry Date</TableCell>

                                            </TableRow>

                                        </TableHead>
                                        {Array.isArray(this.props.company.search_comp_service) && this.props.company.search_comp_service.map((e, index) => (
                                            <TableRow hover={true}>
                                                <TableCell>
                                                    <Checkbox

                                                        checked={e.checked}

                                                        onClick={(p) => { this.CheckedService(p, index, this.props.company.company_service) }}
                                                    />
                                                </TableCell>
                                                <TableCell>{e.purchase_date}</TableCell>
                                                <TableCell>{e.service_details}</TableCell>


                                                <TableCell>
                                                    <span className='relatedTo' style={{ color: "#1E57C9" }}>
                                                        <RxDotFilled />{e.related_to}
                                                    </span>
                                                </TableCell>


                                                <TableCell>
                                                    {e.exp_date}
                                                </TableCell>





                                            </TableRow>
                                        ))}



                                    </Table>
                                </Grid>
                                {/*------------------------------------ ADD Service ---------------------------------------- */}
                                <Dialog
                                    open={this.state.addservice}
                                    fullWidth
                                    maxWidth="md"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Service</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ addservice: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black", justifyContent: "right" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Service<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                value={this.state.service_id}
                                                                onChange={(e) => {
                                                                    this.setState({ service_id: e.target.value })
                                                                    console.log(service)
                                                                }}

                                                            >

                                                                <MenuItem value={"N"}>{"Select Service"}</MenuItem>
                                                                {Array.isArray(this.props.service.service) && this.props.service.service.map((e) => (
                                                                    <MenuItem value={e._id}>{e.service_name}</MenuItem>
                                                                ))}

                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Related To<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="outlined-select-currency-native"
                                                                select
                                                                value={this.state.related_to}
                                                                onChange={(e) => {
                                                                    this.setState({ related_to: e.target.value })
                                                                }}

                                                            >
                                                                <MenuItem value={"N"}>{"Related To"}</MenuItem>
                                                                <MenuItem value={"Project"}>{"Project"}</MenuItem>
                                                                <MenuItem value={"Individual"}>{"Individual"}</MenuItem>
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Purchase Date<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='date'
                                                                onChange={(e) => {
                                                                    this.setState({ purchase_date: e.target.value })
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Expiry Date<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='date'
                                                                onChange={(e) => {
                                                                    this.setState({ exp_date: e.target.value })
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Invoice
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9} style={{ border: "1px solid grey", borderRadius: 8, height: 50, }} >
                                                            <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}
                                                            >
                                                                <img src={paperclip} style={{
                                                                    width: "21.338px",
                                                                    height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                }} />
                                                                <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                >
                                                                    {this.state.attachment !== "" ? (this.state.attachment).name : "Attachment File"}
                                                                </Typography>

                                                            </label>

                                                            <input style={{ display: "none" }} type="file" id="file"
                                                                onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ addservice: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                disabled={(this.state.purchase_date === '') || (this.state.related_to === '') || (this.state.service_id === '') || (this.state.exp_date === '')}
                                                onClick={() => {
                                                    this.props.add_upload_company_service(company.company_id, this.state.purchase_date, this.state.related_to, this.state.service_id, this.state.exp_date, this.state.attachment);
                                                    this.setState({ addservice: false, purchase_date: "", related_to: "", service_id: "", exp_date: "", attachment: "" });

                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Add Service
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >
                                {/*------------------------------------ Delete Service ---------------------------------------- */}
                                <Dialog
                                    fullWidth
                                    maxWidth="xs"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                    open={this.state.deleteservice}
                                    onClose={() => {
                                        this.setState({ deleteservice: false });
                                    }}
                                >
                                    <Grid textAlign={"center"} mt={3}>
                                        <DialogTitle>
                                            <ErrorOutlineOutlinedIcon style={{ fontSize: "66px", color: "#FF0000" }} />
                                            <Typography style={{ fontSize: "20px", color: "#FF0000", fontWeight: 700 }}>Are You Sure ?</Typography >
                                            <Typography mt={1} style={{ fontSize: "16px" }}>You want to Delete ?</Typography >
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container >
                                                <Grid item xs={6}
                                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black", cursor: "pointer", borderRadius: "0px 0px 0px 10px" }}
                                                    onClick={() => { this.setState({ deleteservice: false }) }}>
                                                    <Typography style={{ fontWeight: 500, fontSize: "16px", marginTop: "revert" }}>Cancel</Typography >
                                                </Grid>
                                                <Grid item xs={6}
                                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px", cursor: "pointer", borderRadius: "0px 0px 10px 0px" }}
                                                    onClick={() => {
                                                        this.setState({ deleteservice: false })
                                                        console.log(this.state.page)
                                                        this.props.isCheckedId2(company.company_id, this.props.company.company_service)
                                                    }}
                                                >
                                                    <Typography style={{ fontWeight: 500, fontSize: "16px", color: "white", marginTop: "revert" }}>Confirm</Typography >
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Grid>
                                </Dialog>
                            </Grid>
                        }
                        {/* Tickets */}
                        {this.state.Ticketsopen &&
                            <ProjectTicket type="c" />
                        }
                        {/* Invoice */}
                        {this.state.invoiceopen &&
                            <Grid>
                                <Grid>
                                    <div style={{ display: "flex", flexdirection: "row" }}>
                                        <Typography style={{ margin: "2%", fontSize: "20px" }}>All Invoice</Typography>
                                        <TextField
                                            style={{ marginTop: "2%" }}
                                            size='small'
                                            type='date'
                                            onChange={(e) => { console.log(e.target.value); this.props.search_Invoice_by_date(invoice.invoice, (e.target.value).toString()) }}
                                        />
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    addupload: true,
                                                })
                                            }}
                                            style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" stle> Add Invoice</Button>
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    uploaddelete: true,
                                                })
                                            }}
                                            style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginLeft: "1%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" startIcon={<img src={Trash} style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                            Delete
                                        </Button>
                                    </div>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead >
                                            <TableRow style={{ backgroundColor: "#F1F1F1" }} >
                                                <TableCell> <Checkbox
                                                    checked={this.props.invoice.invoice.allCheck}
                                                    onClick={(p) => {
                                                        {
                                                            this.AllCheckedInvoice(p, this.props.invoice.invoice)
                                                        }
                                                    }}

                                                /></TableCell>
                                                <TableCell>Title</TableCell>
                                                <TableCell>Due Date</TableCell>
                                                <TableCell>Pending Amount</TableCell>
                                                <TableCell>Paid Amount</TableCell>
                                                <TableCell>Total Amount</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>

                                        </TableHead>
                                        {Array.isArray(this.props.invoice.search_invoice) && this.props.invoice.search_invoice.sort((a, b) => moment(a.date).isAfter(moment(b.date)) ? -1 : moment(a.date).isBefore(moment(b.date)) ? 1 : 0).map((e, index) => {
                                            return (

                                                <TableRow hover={true}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            edit_show: true,
                                                            edit_show_id: e._id
                                                        })
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            edit_show: false,
                                                            edit_show_id: ""
                                                        })
                                                    }}>

                                                    <TableCell>
                                                        <Checkbox

                                                            checked={e.checked}

                                                            onClick={(p) => { this.CheckedInvoice(p, index, this.props.invoice.invoice) }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{e.title}</TableCell>
                                                    <TableCell>{moment(e.due_date).format('ll')}</TableCell>
                                                    <TableCell>{e.pending_amount}</TableCell>
                                                    <TableCell>{e.paid_amount}</TableCell>
                                                    <TableCell>{e.total_amount}</TableCell>
                                                    <TableCell style={{ textAlign: "center", fontSize: "15px" }} onClick={() => { this.props.navigate('/companyphase') }}>
                                                        <Button style={{

                                                            color: e.invoice_status === 'Unpaid' ? '#C91E1E' : e.invoice_status === 'Partially Paid' ? '#C9B81E' : '#1E57C9',
                                                        }}
                                                        >
                                                            <RxDotFilled />
                                                            {e.invoice_status}
                                                        </Button>
                                                    </TableCell>



                                                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}  >
                                                        <Tooltip title="Update Amount">
                                                            <Button
                                                                onClick={() => {
                                                                    this.setState({
                                                                        amount: true,
                                                                        _id: e._id,
                                                                        total_amount: e.total_amount,
                                                                        pending_amount: e.pending_amount,
                                                                        paid: e.paid_amount
                                                                    })
                                                                }}
                                                            >{this.state.edit_show && this.state.edit_show_id === e._id && <PaidIcon />}</Button>
                                                        </Tooltip>
                                                        {/* <Tooltip title="Edit">
                                                            <Button onClick={() => {
                                                                this.setState({
                                                                    editinvoice: true,
                                                                    _id: e._id,
                                                                    title: e.title,
                                                                    project_name: e.project_id,
                                                                    total_amount: e.total_amount,
                                                                    paid_amount: e.paid_amount,
                                                                    pending_amount: e.pending_amount,
                                                                    due_date: e.due_date,
                                                                    project_status: e.invoice_status,
                                                                    attachment: e.attachment,

                                                                })
                                                            }}
                                                            >{this.state.edit_show && this.state.edit_show_id === e._id &&
                                                                <img style={{ color: "green", width: 25 }} src={edit} />} </Button>
                                                        </Tooltip> */}
                                                    </TableCell>



                                                </TableRow>


                                            )
                                        })}


                                    </Table>
                                </Grid>
                                {/* ---------------------------------------- Add Invoice ---------------------------------------- */}
                                <Dialog
                                    open={this.state.addupload}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>New Invoice</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ addupload: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Title<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                onChange={(e) => { this.setState({ title: e.target.value }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Due Date<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='date'
                                                                onChange={(e) => this.setState({
                                                                    due_date: e.target.value
                                                                })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Project Name<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                fullWidth
                                                                id="outlined-select-currency-native"
                                                                select
                                                                value={this.state.project_name}
                                                                onChange={(e) => {
                                                                    this.setState({ project_name: e.target.value })
                                                                }}

                                                            >
                                                                <MenuItem value={"N"}>{"Select Project"}</MenuItem>
                                                                {Array.isArray(this.props.all_project.search_comp_project) && this.props.all_project.search_comp_project.map((e) => (
                                                                    <MenuItem value={e._id}>{e.project_title}</MenuItem>
                                                                ))}

                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Total Amount<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='number'
                                                                onChange={(e) => {
                                                                    this.setState({ total_amount: e.target.value })
                                                                    // console.log(all_category)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Invoice :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9} style={{ border: "1px solid grey", borderRadius: 8, height: 50, }} >
                                                            <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}
                                                            >
                                                                <img src={paperclip} style={{
                                                                    width: "21.338px",
                                                                    height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                }} />
                                                                <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                >
                                                                    {this.state.attachment !== "" ? (this.state.attachment).name : "Attachment File"}
                                                                </Typography>

                                                            </label>

                                                            <input style={{ display: "none" }} type="file" id="file"
                                                                onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ addupload: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                disabled={(this.state.title === '') || (this.state.project_name === '') || (this.state.total_amount === '') || (this.state.due_date === '')}
                                                onClick={() => {
                                                    this.props.add_upload_invoice(company.company_id, this.state.title, this.state.project_name, this.state.total_amount, this.state.paid_amount, this.state.pending_amount, this.state.due_date, this.state.project_status, this.state.attachment);
                                                    this.setState({ addupload: false, title: "", project_name: "", total_amount: "", paid_amount: "", pending_amount: "", due_date: "", project_status: "", attachment: "" });
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Add Invoice
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >

                                {/* ---------------------------------------- Edit Invoice ---------------------------------------- */}
                                <Dialog
                                    open={this.state.editinvoice}
                                    maxWidth="xl"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ marginTop: "-5%" }}
                                >
                                    <Grid style={{ minWidth: "1000px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <h4 style={{ marginLeft: "7%", marginTop: "5%" }}>Invoice</h4>
                                            <CloseOutlinedIcon onClick={() => {
                                                this.setState({ editinvoice: false })
                                            }} style={{ marginRight: "5%", marginTop: "4%", cursor: "pointer" }} />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", }}>
                                            <Typography style={{ fontSize: "15px", marginLeft: "13%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                Title<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <FormControl sx={{
                                                '& > :not(style)': { width: '650px', height: '43px', marginLeft: "8%", marginTop: "3%" },
                                            }}>
                                                <OutlinedInput
                                                    value={this.state.title}
                                                    onChange={(e) => { this.setState({ title: e.target.value }) }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>

                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                Project Name<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <TextField
                                                style={{ width: '223px', height: '2vh', marginTop: "2%", marginLeft: "3%" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.project_name}
                                                onChange={(e) => {
                                                    this.setState({ project_name: e.target.value })
                                                }}
                                            >
                                                <MenuItem value={"N"}>{"Select Project"}</MenuItem>
                                                {Array.isArray(this.props.all_project.search_comp_project) && this.props.all_project.search_comp_project.map((e) => (
                                                    <MenuItem value={e._id}>{e.project_title}</MenuItem>
                                                ))}
                                            </TextField>
                                            <Typography style={{ fontSize: "15px", marginLeft: "14.5%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                Due Date<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>
                                            <input type="date" name="begin"
                                                min="1997-01-01" max="2030-12-31" style={{ border: "1px solid rgba(0, 0, 0, 0.3)", height: "6vh", width: "217px", marginLeft: "20px", marginTop: "2%", borderRadius: "5px" }}
                                                value={this.state.due_date}
                                                onChange={(e) => { this.setState({ due_date: e.target.value }) }}
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", }}>

                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                Total Amount<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "14%", marginTop: "7%" },
                                            }}>
                                                <OutlinedInput
                                                    value={this.state.total_amount}
                                                    onChange={(e) => { this.setState({ total_amount: e.target.value }) }}
                                                />
                                            </FormControl>



                                            <Typography style={{ fontSize: "15px", marginLeft: "15%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                Total Paid<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "4%", marginTop: "7%" },
                                            }}>
                                                <OutlinedInput
                                                    value={this.state.paid_amount}
                                                    onChange={(e) => { this.setState({ paid_amount: e.target.value }) }}
                                                />
                                            </FormControl>


                                        </div>


                                        <div style={{ display: "flex", flexDirection: "row", }}>

                                            <Typography style={{ fontSize: "15px", marginLeft: "10%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                Pending Amount <strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <FormControl sx={{
                                                '& > :not(style)': { width: '220px', height: '43px', marginLeft: "5%", marginTop: "7%" },
                                            }}>
                                                <OutlinedInput
                                                    value={this.state.pending_amount}
                                                    onChange={(e) => { this.setState({ pending_amount: e.target.value }) }}
                                                />
                                            </FormControl>



                                            <Typography style={{ fontSize: "15px", marginLeft: "13%", display: "flex", flexDirection: "row", marginTop: "3%" }}>
                                                Status<strong style={{ color: "red" }}>*</strong>:
                                            </Typography>

                                            <TextField
                                                style={{ width: '223px', height: '2vh', marginTop: "2%", marginLeft: "3%" }}
                                                id="outlined-select-currency-native"
                                                select
                                                value={this.state.project_status}
                                                onChange={(e) => {
                                                    this.setState({ project_status: e.target.value })
                                                }}

                                            >
                                                <MenuItem value={"N"}>{"Select Status"}</MenuItem>
                                                {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                                    <MenuItem value={e.name}>{e.name}</MenuItem>
                                                ))}

                                            </TextField>
                                        </div>
                                        <Typography style={{ fontSize: "15px", display: "flex", flexDirection: "row", marginTop: "3%", marginLeft: "10%" }}>
                                            Invoice:
                                            <Box style={{ width: '638px', height: '103px', marginLeft: "10%", border: "2px solid   #C0ADAD", backgroundColor: "#FCFCFC" }}>
                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                    <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                    <Typography style={{ fontSize: "18px", marginTop: "3%", overflow: "hidden" }}>

                                                        {this.state.attachment !== "" ? (this.state.attachment).name : "Attachment File"}
                                                    </Typography>

                                                </label>

                                                <input style={{ display: "none" }} type="file" id="file"
                                                    //  value={this.state.attachment}
                                                    onChange={(e) => { this.setState({ attachment: e.target.value }) }}
                                                />
                                            </Box>
                                        </Typography>
                                        <div style={{ display: "flex", flexdirection: "row", marginTop: "3%", }}>
                                            <Button
                                                onClick={() => {
                                                    this.setState({
                                                        editinvoice: false,
                                                    })
                                                }}
                                                style={{ backgroundColor: "#D9D9D9", width: "148px", color: "#8C8C8C", height: "44px", marginLeft: "35%" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    this.props.update_Invoice_profile(this.state._id, company.company_id, this.state.title, this.state.project_name, this.state.total_amount, this.state.paid_amount, this.state.pending_amount, this.state.due_date, this.state.project_status, this.state.attachment)
                                                    this.setState({ editinvoice: false });
                                                }}
                                                style={{ backgroundColor: "#7900FF", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Update
                                            </Button>
                                        </div>
                                        <div style={{ marginBottom: "3%" }}>
                                        </div>
                                    </Grid>
                                </Dialog>

                                {/* ---------------------------------------- Delete Invoice ---------------------------------------- */}
                                <Dialog
                                    fullWidth
                                    maxWidth="xs"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                    open={this.state.uploaddelete}
                                    onClose={() => {
                                        this.setState({ uploaddelete: false });
                                    }}
                                >
                                    <Grid textAlign={"center"} mt={3}>
                                        <DialogTitle>
                                            <ErrorOutlineOutlinedIcon style={{ fontSize: "66px", color: "#FF0000" }} />
                                            <Typography style={{ fontSize: "20px", color: "#FF0000", fontWeight: 700 }}>Are You Sure ?</Typography >
                                            <Typography mt={1} style={{ fontSize: "16px" }}>You want to Delete ?</Typography >
                                        </DialogTitle>
                                        <DialogActions>
                                            <Grid container >
                                                <Grid item xs={6}
                                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black", cursor: "pointer", borderRadius: "0px 0px 0px 10px" }}
                                                    onClick={() => { this.setState({ uploaddelete: false }) }}>
                                                    <Typography style={{ fontWeight: 500, fontSize: "16px", marginTop: "revert" }}>Cancel</Typography >
                                                </Grid>
                                                <Grid item xs={6}
                                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px", cursor: "pointer", borderRadius: "0px 0px 10px 0px" }}
                                                    onClick={() => {
                                                        this.setState({ uploaddelete: false })
                                                        console.log(this.state.page)
                                                        this.props.isCheckedId1(company.company_id, this.props.invoice.invoice)
                                                    }}
                                                >
                                                    <Typography style={{ fontWeight: 500, fontSize: "16px", color: "white", marginTop: "revert" }}>Confirm</Typography >
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </Grid>
                                </Dialog>
                                {/* --------------------------------------------Update Amount------------------------------------------- */}
                                <Dialog
                                    open={this.state.amount}
                                    fullWidth
                                    maxWidth="md"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Update Amount</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ amount: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Paid Amount <strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                type='number'
                                                                disabled={parseFloat(this.state.pending_amount) === 0}
                                                                onChange={(e) =>

                                                                    this.setState({ paid_amount: e.target.value })
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Pending Amount<strong style={{ color: "red" }}>*</strong>:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size='small'
                                                                fullWidth
                                                                value={parseFloat(this.state.pending_amount) - parseFloat(this.state.paid_amount)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ amount: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                disabled={(this.state.paid_amount === 0)}
                                                onClick={() => {
                                                    this.props.updateAmountInvoice(this.state._id, company.company_id, parseFloat(this.state.paid_amount) + parseFloat(this.state.paid), parseFloat(this.state.pending_amount) - parseFloat(this.state.paid_amount), parseFloat(this.state.paid_amount));
                                                    this.setState({ paid_amount: 0, amount: false });
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Update Invoice
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >
                            </Grid>
                        }
                    </Card>
                </div >

                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />

            </Grid >
        )
    }
}
export default withRouter(Companyopen);
