import React, { Component } from "react";
import { connect } from "react-redux";

import Assets from "../Components/clientAssets";

import {
    addUploadAsset,
    addAsset,
    viewAllAssets,
    view_assets_by_id,
    bulk_delete_assets,
    updateAssets,
   
    deleteAsset,
    CheckedAssets,
    getallchecked,
    allCheck,
    isCheckedId,
    uploadUpdateAssets,
    setAssetsName,
    setDuration,
    setAttachmnet,
    setAssetsId,
    setRenewalDuration,
    search_asserts
  } from "../actions"
 



import {
  close_snack_bar
} from "../../../common/snackbar/action";
import {
  onLogout
} from "../../../auth/actions";

export class Controller extends Component {
  render() {
    return (
      <Assets {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    assets: store.assets,
    snackbar: store.snackbar,
    login: store.login,
    loader:store.loader
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    addAsset:(org_id,assets_name, duration, attachmnet)  => {
      dispatch(addAsset(org_id,assets_name, duration, attachmnet) )
    },
    addUploadAsset:(org_id,assets_name, duration, attachmnet)  => {
      dispatch(addUploadAsset(org_id,assets_name, duration, attachmnet) )
    },
    viewAllAssets:(org_id)  =>{
      dispatch(  viewAllAssets(org_id)  )
    
    },
    view_assets_by_id:(assets_id)  =>{
      dispatch( view_assets_by_id(assets_id) )
    
    },
    bulk_delete_assets:(org_id, isCheckedId) => {
      dispatch( bulk_delete_assets(org_id, isCheckedId))
    },
    deleteAsset:(org_id, assets_id) => {
      dispatch( deleteAsset(org_id, assets_id))
    },
    uploadUpdateAssets: (org_id, assets_id, assets_name, renewal_duration, attachmnet) => {
      dispatch(uploadUpdateAssets(org_id, assets_id, assets_name, renewal_duration, attachmnet))
    },   
    updateAssets: (org_id, assets_id, assets_name, renewal_duration, attachmnet)=> {
      dispatch(updateAssets(org_id, assets_id, assets_name, renewal_duration, attachmnet))
    },    
    onLogout: () => {
      dispatch(onLogout());
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    CheckedAssets: (index, checked, quantity, all_category) => {
      dispatch(CheckedAssets(index, checked, quantity, all_category));
    },
    getallchecked: ( all_category) => {
      dispatch(getallchecked(all_category));
    },
    allCheck: (checked, quantity, all_category) => {
      dispatch(allCheck(checked, quantity,all_category));
    },
    isCheckedId: (org_id,all_category) => {
      dispatch(isCheckedId(org_id,all_category) );
    },

    setAssetsName:(payload)=> {
      dispatch(setAssetsName(payload));
    },
    setDuration:(payload)  => {
      dispatch(setDuration(payload) );
    },
    setAttachmnet:(payload) => {
      dispatch(setAttachmnet(payload));
    },
    setAssetsId:(payload)  => {
      dispatch(setAssetsId(payload) );
    },
    setRenewalDuration:(payload) => {
      dispatch(setRenewalDuration(payload));
    },

    search_asserts: (assets, e) => {
      dispatch(search_asserts(assets, e));
    },


  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);