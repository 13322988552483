import {
   
    VIEW_ALL_DOC ,
    VIEW_ALL_DOC_ID 
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";
import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";




export function addUploadDocuments(date, time, title, link, attach_files, access_id,milestone_id) {
    return dispatch => {
        console.log("asdfghj")
        dispatch(setLoader());
        if (attach_files !== "") {
            const storageRef = ref(getStorage(), "/Doucuments/" + attach_files + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attach_files);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attach_files) {
                        console.log(attach_files)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            console.log("Adsdsad")
                            dispatch(add_document(date, time, title, link, attach_files, access_id,milestone_id));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else{
            dispatch(add_document(date, time, title, link, attach_files, access_id,milestone_id));
        }
       
    }
}
export function add_document(date, time, title, link, attach_files, access_id,milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_document", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                milestone_id : milestone_id
           },
            body: JSON.stringify({
                date:date, 
                time:time, 
                title: title, 
                link:link, 
                attach_files:attach_files, 
                emp_id:access_id
                
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_documnet_by_milestone(milestone_id));
                   
                    dispatch(unsetLoader());
                } else {
                   
                     dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function view_all_documnet_by_milestone(milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_document_by_milestone", 
        {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                milestone_id:milestone_id
            },
           

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)
                 
                    dispatch({ type:   VIEW_ALL_DOC, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    
                    dispatch({ type:  VIEW_ALL_DOC , payload: [] })
                    
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function bulk_delete_document(document_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "bulk_delete_document", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                
           },
            body: JSON.stringify({
                document_id :document_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(view_all_documnet_by_milestone(milestone_id));
                   
                    dispatch(unsetLoader());
                } else {
                   
                     dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function view_documnet_id(document_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_documnet_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                
           },
            body: JSON.stringify({
                document_id :document_id
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {

            if (responseJson.status) {
                console.log(responseJson)
             
                dispatch({ type:   VIEW_ALL_DOC_ID, payload: responseJson.result });
                dispatch(set_snack_bar(responseJson.status, "Some message"));
                dispatch(unsetLoader());
            } else {
                
                dispatch({ type:  VIEW_ALL_DOC_ID , payload: [] })
                
            }
            dispatch(unsetLoader());
        })
        .catch((error) => {
            console.error(error);
        });
    };
}
export function update_documnet(date, time, title, link, attach_files, access_id, _id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_documnet", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                
           },
            body: JSON.stringify({
                date:date , 
                time : time, 
                title:title , 
                link : link, 
                attach_files : attach_files , 
                access_id :  access_id,
                 _id:_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(view_all_documnet_by_milestone(milestone_id));
                   
                    dispatch(unsetLoader());
                } else {
                   
                     dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

