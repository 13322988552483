import React, { Component } from 'react'
import { BsFillPersonFill } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography, TextField
} from '@mui/material';
import { Table, Card } from '@mui/material/';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
// import "../../../../pages/admin/image/edit.png";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
// import edit from "../../../../pages/admin/image/edit.png";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom"
import "../../Project/styles/project.scss";
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Search from "../../image/vuesax-outline-search-normal.png";
import edit from "../../image/edit.png";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import Textarea from '@mui/joy/Textarea';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
    DialogContent
} from '@mui/material';
import paperclip from "../../image/paperclip-2.png";
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';


export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};

class Project extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editproject: false,
            addFormOpen: false,
            addDeleteOpen: false,
            edit_show: false,
            edit_show_id: "",
            filter_status: "N",
            comp_id: "",
            project_title: "",
            project_id: "",
            project_priority: "",
            project_status: "",
            project_department: "",
            project_type: "",
            start_date: "",
            max_hr: "",
            per_hr_cost: "",
            desc: "",
            attachment: "",
            end_date: "",
            holdsssss: false,



        };
    }


    componentDidMount() {
        // console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii",this.props.login.org_id);
        // this.props.view_all_assets(this.props.login.org_id);
        document.body.style.backgroundColor = "#f5edff";
        this.props.view_all_phase_by_member(this.props.login.user_id)
        // this.props.view_all_status(this.props.login.org_id);
        console.log("viewall", this.props.phase)

    }
    handleClick = () => {
        this.setState(prevState => ({
            open: !prevState.open,
        }));
    };
    handleClicks = () => {
        this.setState(prevState => ({
            opens: !prevState.opens,
        }));
    };
    handelOpen = () => {
        this.setState({
            addFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            addFormOpen: false,

        })
    }
    handleDeleteOpen = () => {
        this.setState({
            addDeleteOpen: true
        })
    }

    deleteModalOff = () => {
        this.setState({
            addDeleteOpen: false
        })
    }


    CheckedProject(p, index, all_project) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedProject(index, true, 1, all_project)
        }
        else {
            this.props.CheckedProject(index, false, 0, all_project)
        }
    }
    AllCheckedProjects(p, all_project) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheck(true, 1, all_project)
        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheck(false, 0, all_project)
        }

    }


    render() {
        const { open } = this.state;
        const {
            all_project,
            login,
            close_snack_bar,
            snackbar,
        } = this.props;
        if (this.props.loader.open) {
            return (
                <Grid>
                    <div style={{ marginTop: "85px", marginRight: "20px" }}>

                        <Paper
                            component="form"
                            style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}
                        >
                            <Skeleton variant="rectangular" width={"367px"} height={"43px"} animation="wave" />
                        </Paper>

                    </div>

                    <CardContent style={{ marginLeft: "2.5%", marginRight: "1%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                {/* <h2 style={{ marginLeft: "2%" }}>Companies</h2> */}
                                <Skeleton variant="rectangular" width={150} height={40} animation="wave" style={{ margin: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginRight: "3%", marginLeft: "1%", }} />
                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >

                                        <TableCell>
                                            <Skeleton variant="rectangular" animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation="pulse" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>

                            </Table>
                        </TableContainer>

                    </CardContent>

                </Grid>
            )
        }
        else

            return (
                <Grid>

                    <div style={{ marginRight: "1%", marginTop: "80px", }}>

                        {/* <Paper
                            component="form"
                            style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}
                        >
                            <IconButton
                                type="button"
                                style={{ p: "15px" }}
                                aria-label="search"
                            >
                                <img src={Search} />
                            </IconButton>
                            <InputBase
                                style={{ ml: 5, flex: 1, fontSize: "16px", fontFamily: "Montserrat", color: "#8C8C8C", fontWeight: "300" }}
                                placeholder="Search here"
                                inputProps={{ "aria-label": "Search here" }}
                                onChange={(e) => this.props.search_project(all_project.all_project, e.target.value, "search")}

                            />
                        </Paper> */}

                    </div>


                    <CardContent style={{ marginLeft: "2.5%", marginRight: "1%" }}>
                        <TableContainer component={Paper}>

                            <Typography style={{ marginLeft: "2%", marginTop: "1%", fontSize: "27px", fontWeight: "600" }}>
                                Phase
                            </Typography>
                            <Divider style={{ marginLeft: "1.5%", marginRight: "1.5%" }} />


                            <div style={{ display: "flex", flexdirection: "row", justifyContent: "space-between", margin: "2%" }}>
                                <FormControl sx={{ width: "15%" }}>
                                    <Select
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        value={this.state.filter_status}
                                        onChange={(e) => {
                                            this.props.search_phase2(this.props.phase.phase, e.target.value, "status")
                                            this.setState({ filter_status: e.target.value })
                                        }}
                                        style={{ minWidth: 0 }}
                                    >
                                        <MenuItem value={"N"}>{"Select Status"}</MenuItem>
                                        {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                            <MenuItem value={e.name}>{e.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {this.props.login.type === "Owner" &&
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                delete: true,

                                            })

                                        }}
                                        style={{ height: "2.576rem", color: "rgba(0, 0, 0, 0.4)", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" startIcon={<DeleteOutlineOutlinedIcon style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                        Delete
                                    </Button>}
                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                        <TableCell style={{ textAlign: "left", fontSize: "13px" }}>Phase Name</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: "13px" }}>Project Name</TableCell>
                                        {/* <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Authorized person</TableCell> */}
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Status</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Priority </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Estimated Hours </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Start Date </TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Deadline</TableCell>
                                        {/* <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Action</TableCell> */}
                                    </TableRow>

                                </TableHead>
                                {Array.isArray(this.props.phase.search_phase) && this.props.phase.search_phase.map((item, index) => {
                                    return (
                                        <TableRow hover={true}
                                            onMouseEnter={() => {
                                                this.setState({
                                                    edit_show: true,
                                                    edit_show_id: item._id
                                                })
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({
                                                    edit_show: false,
                                                    edit_show_id: ""
                                                })
                                            }} onClick={() => {

                                            }}
                                        >
                                            <TableCell style={{ textAlign: "left", fontSize: "15px" }} onClick={() => {
                                                this.props.navigate('/Companymilestone');
                                                this.props.setPhaseId(item._id);
                                                this.props.setPhaseName(item.title);
                                                this.props.setProjectId(item.project_details?._id);
                                                this.props.setProjectName(item.project_details?.project_title);
                                                this.props.setProjectStatus(item.project_details?.status_id);
                                                this.props.setProjectPriority(item.project_details?.priority_id);
                                                this.props.setProjectAuthPrsn(item.project_details?.project_auth_person);
                                            }}>{item.title}</TableCell>
                                            <TableCell style={{ textAlign: "left", fontSize: "15px" }} onClick={() => {
                                                this.props.navigate('/Companymilestone');
                                                this.props.setPhaseId(item._id);
                                                this.props.setPhaseName(item.title);
                                                this.props.setProjectId(item.project_details?._id);
                                                this.props.setProjectName(item.project_details?.project_title);
                                                this.props.setProjectStatus(item.project_details?.status_id);
                                                this.props.setProjectPriority(item.project_details?.priority_id);
                                                this.props.setProjectAuthPrsn(item.project_details?.project_auth_person);
                                            }}>{item.project_details?.project_title}</TableCell>
                                            {/* <TableCell style={{ textAlign: "center", fontSize: "13px" }}> <BsFillPersonFill style={{ diplay: "flex", flexDirection: "row" }} />{item.auth_person}</TableCell> */}
                                            {/* <TableCell style={{ textAlign: "left", fontSize: "15px", color: "blue" }} onClick={() => { this.props.navigate('/companyphase') }}>{item.comp_id.email}</TableCell> */}
                                            <TableCell style={{ textAlign: "center", fontSize: "15px" }} onClick={() => {
                                                this.props.navigate('/Companymilestone');
                                                this.props.setPhaseId(item._id);
                                                this.props.setPhaseName(item.title);
                                                this.props.setProjectId(item.project_details?._id);
                                                this.props.setProjectName(item.project_details?.project_title);
                                                this.props.setProjectStatus(item.project_details?.status_id);
                                                this.props.setProjectPriority(item.project_details?.priority_id);
                                                this.props.setProjectAuthPrsn(item.project_details?.project_auth_person);
                                            }}>
                                                <Button style={{
                                                    backgroundColor: item.status_id === 'Closed' ? 'rgba(255, 84, 84, 0.40)' : item.status_id === 'On Hold' ? 'rgba(252, 255, 84, 0.40)' : item.status_id === 'Open' ? 'rgba(84, 140, 255, 0.40)' : item.status_id === 'Pending' ? 'rgba(255, 84, 217, 0.40)' : 'rgba(82, 183, 73, 0.40)',
                                                    border: item.status_id === 'Closed' ? '0.4px solid #C91E1E' : item.status_id === 'On Hold' ? '0.4px solid #C9B81E' : item.status_id === 'Open' ? '0.4px solid #1E57C9' : item.status_id === 'Pending' ? '0.4px solid #C91EA3' : '0.4px solid #008000',
                                                    color: item.status_id === 'Closed' ? '#C91E1E' : item.status_id === 'On Hold' ? '#C9B81E' : item.status_id === 'Open' ? '#1E57C9' : item.status_id === 'Pending' ? '#C91EA3' : '#008000', borderRadius: "0.8125rem", height: "1.75rem"
                                                }}
                                                    variant="outlined" >
                                                    <RxDotFilled />
                                                    {item.status_id}
                                                </Button>
                                            </TableCell>

                                            <TableCell style={{ fontSize: "15px" }} onClick={() => {
                                                this.props.navigate('/Companymilestone');
                                                this.props.setPhaseId(item._id);
                                                this.props.setPhaseName(item.title);
                                                this.props.setProjectId(item.project_details?._id);
                                                this.props.setProjectName(item.project_details?.project_title);
                                                this.props.setProjectStatus(item.project_details?.status_id);
                                                this.props.setProjectPriority(item.project_details?.priority_id);
                                                this.props.setProjectAuthPrsn(item.project_details?.project_auth_person);
                                            }}>
                                                <Typography style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                                                    <FiberManualRecordIcon style={{
                                                        color: item.priority_id === 'High' ? 'rgba(255, 84, 84, 0.40)' : item.priority_id === 'Medium' ? 'rgba(252, 255, 84, 0.40)' : 'rgba(84, 140, 255, 0.40)',
                                                        border: item.priority_id === 'High' ? '0.4px solid #C91E1E' : item.priority_id === 'Medium' ? '0.4px solid #C9B81E' : '0.4px solid #1E57C9', borderRadius: "50px", marginRight: "5px",
                                                        fontSize: "18px"
                                                    }} />
                                                    {item.priority_id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "15px" }} onClick={() => {
                                                this.props.navigate('/Companymilestone');
                                                this.props.setPhaseId(item._id);
                                                this.props.setPhaseName(item.title);
                                                this.props.setProjectId(item.project_details?._id);
                                                this.props.setProjectName(item.project_details?.project_title);
                                                this.props.setProjectStatus(item.project_details?.status_id);
                                                this.props.setProjectPriority(item.project_details?.priority_id);
                                                this.props.setProjectAuthPrsn(item.project_details?.project_auth_person);
                                            }}>{item.max_hr !== null ? item.max_hr : "- - - - - "}hrs</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "15px" }} onClick={() => {
                                                this.props.navigate('/Companymilestone');
                                                this.props.setPhaseId(item._id);
                                                this.props.setPhaseName(item.title);
                                                this.props.setProjectId(item.project_details?._id);
                                                this.props.setProjectName(item.project_details?.project_title);
                                                this.props.setProjectStatus(item.project_details?.status_id);
                                                this.props.setProjectPriority(item.project_details?.priority_id);
                                                this.props.setProjectAuthPrsn(item.project_details?.project_auth_person);
                                            }}>{item.start_date !== null ? item.start_date : "- - - - - - - -"}</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "15px" }} onClick={() => {
                                                this.props.navigate('/Companymilestone');
                                                this.props.setPhaseId(item._id);
                                                this.props.setPhaseName(item.title);
                                                this.props.setProjectId(item.project_details?._id);
                                                this.props.setProjectName(item.project_details?.project_title);
                                                this.props.setProjectStatus(item.project_details?.status_id);
                                                this.props.setProjectPriority(item.project_details?.priority_id);
                                                this.props.setProjectAuthPrsn(item.project_details?.project_auth_person);
                                            }}>{item.dead_line !== null ? item.dead_line : "- - - - - - - -"}</TableCell>
                                            {/* {this.props.login.type !== "E" &&
                                                <TableCell style={{ textAlign: "center", fontSize: "15px" }} >
                                                    <IconButton onClick={() => {
                                                        this.setState({
                                                            editproject: true,
                                                            _id: item._id,
                                                            comp_id: item.comp_id._id,
                                                            project_title: item.project_title,
                                                            project_id: item.project_id,
                                                            project_priority: item.priority_id,
                                                            project_status: item.status_id,
                                                            project_department: item.department_id,
                                                            project_type: item.project_type,
                                                            start_date: item.start_date,
                                                            max_hr: item.max_hr,
                                                            per_hr_cost: item.per_hr_cost,
                                                            desc: item.desc,
                                                            attachment: item.attachment,
                                                            end_date: item.end_date,

                                                        })
                                                    }}
                                                    >{this.state.edit_show && this.state.edit_show_id === item._id &&
                                                        <img style={{ color: "green", width: 25 }} src={edit} />} </IconButton>
                                                    <IconButton
                                                        style={{ width: 40 }}
                                                        onClick={() => {
                                                            this.setState({
                                                                holdsssss: true,


                                                            })
                                                        }}
                                                    >{this.state.edit_show && this.state.edit_show_id === item._id &&
                                                        <HourglassEmptyRoundedIcon style={{ color: "red" }} />} </IconButton>

                                                </TableCell>
                                            } */}

                                        </TableRow>)
                                })}
                            </Table>
                        </TableContainer>

                    </CardContent>
                    {/*------------------------------------------------------------ Delete ----------------------------------------------------------------*/}
                    <Dialog
                        open={this.state.delete}
                        onClose={() => {
                            this.setState({ delete: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ width: "100%", height: "100%", }}
                    >
                        {/* <IconButton
                    > */}

                        <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                            <center>
                                <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                            </center>
                            <DialogActions style={{ marginTop: "42px" }}>

                                <Button variant="contained"
                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}
                                    onClick={() => { this.setState({ delete: false }) }}>

                                    Cancel
                                </Button>
                                <Button variant="contained"
                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}

                                    onClick={() => {
                                        this.setState({ delete: false })
                                        this.props.isCheckedId(this.props.login.org_id, this.props.all_project.all_project)

                                    }}
                                >

                                    Confirm
                                </Button>


                            </DialogActions>
                        </Grid>






                    </Dialog>
                    {/* hold */}
                    <Dialog
                        open={this.state.holdsssss}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                <Grid container>
                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}> Hold Project</Typography></Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ holdsssss: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>



                                <Grid container direction={"column"} spacing={2}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Description :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    placeholder='Description'
                                                    size='small'
                                                    fullWidth
                                                    multiline
                                                    onChange={(e) => this.setState({
                                                        project_desc: e.target.value
                                                    })}
                                                    inputProps={{
                                                        style: {
                                                            height: 50,
                                                        },
                                                    }}

                                                />
                                            </Grid>
                                            <Grid item xs={0.5}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'center' }}>
                                <Button
                                    onClick={() => { this.setState({ holdsssss: false }) }}
                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.setState({ holdsssss: false, })
                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Hold Project
                                </Button>
                            </DialogActions>

                        </Grid>
                    </Dialog >
                    {/*------------------------------------------------------------ Update ----------------------------------------------------------------*/}
                    <Dialog
                        open={this.state.editproject}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1.5%" }}>
                                <Grid container>
                                    <Grid item xs={11} >
                                        <Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>
                                            Edit Project
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ editproject: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black", display: "flex", justifyContent: "right" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent style={{ flexDirection: "row" }}>
                                <Grid container direction={"column"} spacing={1}>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Title<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    value={this.state.project_title}
                                                    onChange={(text) => this.setState({ project_title: text.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>

                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Project ID<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    value={this.state.project_id}
                                                    onChange={(text) => this.setState({ project_id: text.target.value })}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Priority:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    select
                                                    value={this.state.project_priority}
                                                    onChange={(e) => {
                                                        this.setState({ project_priority: e.target.value })
                                                    }}
                                                >
                                                    <MenuItem value={"N"}>{"Select Priority"}</MenuItem>
                                                    {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e) => (
                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                    ))}

                                                </TextField>
                                            </Grid>
                                            <Grid item xs={1}></Grid>

                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Status:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    select
                                                    value={this.state.project_status}
                                                    onChange={(e) => {
                                                        this.setState({ project_status: e.target.value })
                                                        // console.log(all_category)
                                                    }}

                                                >
                                                    <MenuItem value={"N"}>{"Select Status"}</MenuItem>
                                                    {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                    ))}

                                                </TextField>

                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Department<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    select
                                                    value={this.state.project_department}
                                                    onChange={(e) => {
                                                        this.setState({ project_department: e.target.value })
                                                    }}

                                                ><MenuItem value={"N"}>{"Select Department"}</MenuItem>
                                                    {Array.isArray(this.props.login.all_department) && this.props.login.all_department.map((e) => (
                                                        <MenuItem value={e._id}>{e.name}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={1}></Grid>

                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Project Type<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    select
                                                    value={this.state.project_type}
                                                    onChange={(e) => {
                                                        this.setState({ project_type: e.target.value })
                                                    }}

                                                >

                                                    <MenuItem value={"N"}>{"Select Type"}</MenuItem>
                                                    {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e) => (
                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                    ))}

                                                </TextField>


                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Start Date<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    type='date'
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    value={this.state.start_date}
                                                    onChange={(e) => this.setState({ start_date: e.target.value })}
                                                    date
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>

                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Exp Date<strong style={{ color: "red" }}>*</strong>:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    size='small'
                                                    type='date'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    value={this.state.end_date}
                                                    onChange={(e) => {
                                                        this.setState({ end_date: e.target.value })
                                                    }}
                                                />

                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Maximum Hours:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    value={this.state.max_hr}
                                                    onChange={(text) => this.setState({ max_hr: text.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>

                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Per Hour Cost:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5} height={40}>
                                                <TextField
                                                    size='small'
                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                    id="outlined-select-currency-native"
                                                    value={this.state.per_hr_cost}
                                                    onChange={(text) => this.setState({ per_hr_cost: text.target.value })}

                                                />

                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Description:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} height={125}>
                                                <TextField
                                                    multiline
                                                    rows={4}
                                                    style={{ width: '100%', height: "166.628px" }}
                                                    id="outlined-select-currency-native"
                                                    value={this.state.desc}
                                                    onChange={(text) => this.setState({ desc: text.target.value })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid container>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{ fontSize: 'large' }}>
                                                    Attachments:
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80, }} >



                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}

                                                >
                                                    <img src={paperclip} style={{
                                                        width: "21.338px",
                                                        height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                    }} />
                                                    <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                    //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                    >
                                                        {this.state.attachment !== "" ? (this.state.attachment).name : "Attachment File"}
                                                    </Typography>

                                                </label>

                                                <input style={{ display: "none" }} type="file" id="file"
                                                    onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }}
                                                />

                                                {/* </Box> */}

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            editproject: false,
                                        })
                                    }}
                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.props.update_project_profile(this.props.login.org_id, this.state._id, this.state.comp_id._id, this.state.project_title, this.state.project_id, this.state.project_priority, this.state.project_status, this.state.project_department, this.state.project_type, this.state.start_date, this.state.end_date, this.state.max_hr, this.state.project_hrcost, this.state.project_desc, this.state.attachment)
                                        this.setState({
                                            editproject: false,
                                        });

                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Update
                                </Button>
                            </DialogActions>

                        </Grid>




                    </Dialog>

                </Grid>
            )
    }
}
export default withRouter(Project);

