import {
  
  VIEW_ALL_MEETING_BY_MILESTONE,
  VIEW_ALL_MEETING
} from "./constant";
const initial_state = {
  meeting: [],
 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case VIEW_ALL_MEETING_BY_MILESTONE:
      return state = { ...state, meeting: action.payload };

      case VIEW_ALL_MEETING:
      return state = { ...state, meeting: action.payload };

    default:
      return state;
  }
}
