import React, { Component } from "react";
import { connect } from "react-redux";
import Ticket from "../Components/AdminTickets";
import {
    close_snack_bar
} from "../../../common/snackbar/action";
import {
    addTicket,
    searchTicket,
    viewAllTickets,
    CheckedTicket,
    updateTicket,
    bulkDeleteTicket,
    allCheck,
    isCheckedId,
    getallchecked,

    viewAllRequestbByproject,
    request,
    view_company_tickets,
    updateTicketStatus,
    filterTicket,
    searchTicketDate

} from "../action";
import {
    add_request,
    add_upload_request,
} from "../../Request/action";
import {
    view_all_project_by_org_id,
    view_all_project_by_comp_id,
} from "../../Project/Actions";
import { viewAllCompany,  } from "../../Company/action";
import {
    view_all_phase
} from "../../Phase/action"
import{viewAllAuthPerson} from "../../authperson/action"
// import { phone_login } from '../../../../auth/actions'

export class Controller extends Component {
    render() {
        return (
            <Ticket {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        all_project: store.all_project,
        request: store.request,
        ticket: store.ticket,
        user: store.user,
        one_user: store.one_user,
        snackbar: store.snackbar,
        login: store.login,
        loader: store.loader,
        phase: store.phase,
        company: store.company,
        authperson: store.authperson



    };
};
export const mapDispatchToProps = dispatch => {
    return {
        addTicket: (org_id, ticketshort_Id, ticket_date, project_id, phase_id, start_date, resolve_date, note_desc, type, auth_person) => {
            dispatch(addTicket(org_id, ticketshort_Id, ticket_date, project_id, phase_id, start_date, resolve_date, note_desc, type, auth_person))
        },
        searchTicket: (ticket, e) => {
            dispatch(searchTicket(ticket, e))
        },
        filterTicket: (ticket, e) => {
            dispatch(filterTicket(ticket, e))
        },
        // add_upload_service: (org_id, service_name, duration, attachment) => {
        //   dispatch(add_upload_service(org_id, service_name, duration, attachment))
        // },
        viewAllTickets: (org_id) => {
            dispatch(viewAllTickets(org_id))
        },
        view_company_tickets: (org_id, company_id) => {
            dispatch(view_company_tickets(org_id, company_id))
        },
        // viewAllRequestbByproject: (project_id) => {
        //     dispatch(viewAllRequestbByproject(project_id))
        // },
        bulkDeleteTicket: (org_id, isChecked) => {
            dispatch(bulkDeleteTicket(org_id, isChecked))
        },
        updateTicket: (org_id, ticket_id, ticketshort_Id, ticket_date, project_id, phase_id, start_date, resolve_date, note_desc) => {
            dispatch(updateTicket(org_id, ticket_id, ticketshort_Id, ticket_date, project_id, phase_id, start_date, resolve_date, note_desc))
        },
        updateTicketStatus: (org_id, comp_id, ticket_id, cstatus, type, auth_person, ticketshort_Id, company_shortid) => {
            dispatch(updateTicketStatus(org_id, comp_id, ticket_id, cstatus, type, auth_person, ticketshort_Id, company_shortid))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        CheckedTicket: (index, checked, quantity, all_category) => {
            dispatch(CheckedTicket(index, checked, quantity, all_category));
        },
        getallchecked: (all_category) => {
            dispatch(getallchecked(all_category));
        },
        allCheck: (checked, quantity, all_category) => {
            dispatch(allCheck(checked, quantity, all_category));
        },
        isCheckedId: (org_id, all_category) => {
            dispatch(isCheckedId(org_id, all_category));
        },
        add_request: (project_id, desc, request_date, attachment, request_type, request_status) => {
            dispatch(add_request(project_id, desc, request_date, attachment, request_type, request_status))
        },
        add_upload_request: (project_id, desc, request_date, attachment, request_type, request_status) => {
            dispatch(add_upload_request(project_id, desc, request_date, attachment, request_type, request_status))
        },
        view_all_project_by_org_id: (org_id) => {
            dispatch(view_all_project_by_org_id(org_id))
        },
        view_all_project_by_comp_id: (comp_id) => {
            dispatch(view_all_project_by_comp_id(comp_id))
        },
        viewAllCompany: (org_id) => {
            dispatch(viewAllCompany(org_id))
        },
        view_all_phase: (project_id) => {
            dispatch(view_all_phase(project_id))
        },
        request: (new_request) => {
            dispatch(request(new_request))
        },
        viewAllAuthPerson: (project_id) => {
            dispatch(viewAllAuthPerson(project_id));
        },
        searchTicketDate: (ticket, e) => {
            dispatch(searchTicketDate(ticket, e));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);