import React, { Component } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography, DialogContent
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Badge from '@mui/material/Badge';
import edit from "../../image/edit.png";
import Clip from "../../../pages/image/paperclip-2.png"
import "../../Assets/style/Assets.css";
import Skeleton from '@mui/material/Skeleton';
import Search from "../../image/vuesax-outline-search-normal.png";

export default class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {

            delete: false,
            deleteall: false,
            edit: false,

            add: false,
            service_name: '',
            duration: '',
            attachment: '',
            _id: '',
            org_id: '',
            service_id: '',
        }
    }
    componentDidMount() {
        document.body.style.backgroundColor = "#f5edff";
        this.props.viewAllService(this.props.login.org_id);
    }

    CheckedService(p, index, service) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedService(index, true, 1, service)
        }
        else {
            this.props.CheckedService(index, false, 0, service)
        }
    }
    AllCheckedService(p, service) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheck(true, 1, service)
        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheck(false, 0, service)
        }

    }

    render() {
        const { service } = this.props
        if (this.props.loader.open) {
            return (
                <Grid>
                    <div style={{ marginTop: "85px", marginRight: "20px" }}>

                        <Paper
                            component="form"
                            style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}
                        >
                            <Skeleton variant="rectangular" width={"367px"} height={"43px"} animation="wave" />
                        </Paper>

                    </div>

                    <CardContent style={{ marginLeft: "2.5%", marginRight: "1%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                {/* <h2 style={{ marginLeft: "2%" }}>Companies</h2> */}
                                <Skeleton variant="rectangular" width={150} height={40} animation="wave" style={{ margin: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginRight: "3%", marginLeft: "1%", }} />
                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >

                                        <TableCell>
                                            <Skeleton variant="rectangular" animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation="pulse" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>

                            </Table>
                        </TableContainer>

                    </CardContent>

                </Grid>
            )
        }
        else
            return (
                <Grid>
                    <div style={{ marginRight: "1%", marginTop: "80px", }}>

                        <Paper
                            component="form"
                            style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}
                        >
                            <IconButton
                                type="button"
                                style={{ p: "15px" }}
                                aria-label="search"
                            >
                                <img src={Search} />
                            </IconButton>
                            <InputBase
                                style={{ ml: 5, flex: 1, fontSize: "16px", fontFamily: "Montserrat", color: "#8C8C8C", fontWeight: "300" }}
                                placeholder="Search here"
                                inputProps={{ "aria-label": "Search here" }}
                                onChange={(e) => {
                                    this.props.search_service(service.service, e.target.value)
                                    console.log("search", e.target.value)
                                }}


                            />
                        </Paper>

                    </div>


                    <CardContent style={{ marginLeft: "2.5%", marginRight: "1%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row",  margin: "2%"}}>
                                <Typography style={{fontSize: "27px", fontWeight: "600"}}>
                                    All Services
                                </Typography>
                                {this.props.login.type !== "E" && this.props.login.type!=="Finance" &&
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                add: true,
                                            })
                                        }}
                                        style={{ marginLeft: "auto", height: "2.576rem", color: "rgba(0, 0, 0, 0.4)", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" stle>
                                        Add Services
                                    </Button>}
                                {this.props.login.type !== "E" && this.props.login.type!=="Finance" &&
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                deleteall: true,

                                            })

                                        }}
                                        style={{height: "2.576rem", color: "rgba(0, 0, 0, 0.4)", border: "1px solid rgba(0, 0, 0, 0.4)", marginLeft: "1%"}} variant="outlined"
                                        startIcon={<DeleteOutlineOutlinedIcon style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                        Delete
                                    </Button>}
                            </div>
                            <Table style={{ minWidth: 600 }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                       {this.props.login.type!=="Finance" && <TableCell style={{ fontSize: "13px" }}><Checkbox
                                            checked={this.props.service.service.allCheck}
                                            onClick={(p) => {
                                                {
                                                    this.AllCheckedService(p, this.props.service.service)
                                                }
                                            }}
                                        /></TableCell>}
                                        <TableCell style={{ textAlign: "left", fontSize: "13px" }}>Sl no</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: "13px" }}>Service Name</TableCell>

                                        <TableCell style={{ textAlign: "left", fontSize: "13px" }}>Duration</TableCell>
                                        <TableCell style={{ textAlign: "right", fontSize: "13px" }}></TableCell>
                                    </TableRow>
                                </TableHead>

                                {Array.isArray(this.props.service.search_service) && this.props.service.search_service.map((item, index) => {
                                    return (
                                        <TableRow hover={true}>
                                           {this.props.login.type!=="Finance" && <TableCell style={{ fontSize: "15px" }}><Checkbox
                                                // value={this.props.assets.assets.assets_id}
                                                checked={item.checked}
                                                // {...checked===true?this.state.isChecked.push(item.assets_id):false}
                                                onClick={(p) => { this.CheckedService(p, index, this.props.service.service) }}
                                            /></TableCell>}
                                            <TableCell style={{ textAlign: "left", fontSize: "15px" }}>{index + 1}</TableCell>
                                            <TableCell style={{ textAlign: "left", fontSize: "15px" }}>{item.service_name}</TableCell>

                                            <TableCell style={{ textAlign: "left", fontSize: "15px" }}>{item.renewal_duration}</TableCell>
                                           { this.props.login.type!=="Finance" &&<TableCell style={{ textAlign: "right", fontSize: "15px" }}>


                                                <Button 
                                                  style={{ backgroundColor: "#81FF7E", width: "5px", border: "2px solid #038300" }}
                                                onClick={() => {
                                                    this.setState({
                                                        edit: true,
                                                        service_name: item.service_name,
                                                        duration: item.renewal_duration,
                                                        // editattachment: item.attachmnet.substring(8, 16),
                                                        attachmnet_old:item.attachmnet_old,
                                                        _id: item._id
                                                    })


                                                }}>
                                                    <img style={{ width: 20, height: 20 }} className="hide" src={edit} /> </Button>
                                                <Button  style={{ backgroundColor: "#FF9696", marginLeft: "3%", border: "2px solid #850000" }}variant="outlined" startIcon={<DeleteOutlineOutlinedIcon onClick={() => {
                                                    this.setState({
                                                        delete: true,
                                                        service_id: item._id

                                                    })

                                                }} style={{ width: 20, height: 20, color: "#850000" }} />}></Button>
                                            </TableCell>}
                                        </TableRow>

                                    )
                                })}


                            </Table>
                        </TableContainer>

                    </CardContent>

                    <Dialog
                        open={this.state.add}

                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={
                            {

                            }}
                    >
                        <Grid
                            style={{

                                minWidth: "600px",
                                // height: "380px"

                            }}
                        >
                            <Grid>
                                <DialogTitle>
                                    <Grid container>
                                        <Grid item xs={11} />
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => {
                                                this.setState({ add: false });
                                            }}>
                                                <CloseOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container direction={"column"} spacing={2}>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Services Name<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} height={40}>

                                                    <TextField
                                                        style={{ width: '253px', }}

                                                        id="outlined-select-currency-native"

                                                        onChange={(e) => { this.setState({ service_name: e.target.value }) }}
                                                    />



                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Duration<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} height={40}>
                                                    <TextField
                                                        style={{ width: '253px', }}
                                                        id="outlined-select-currency-native"
                                                        select
                                                        onChange={(e) => { this.setState({ duration: e.target.value }) }}
                                                    >
                                                        <MenuItem value={'Yearly'}>
                                                            yearly
                                                        </MenuItem>
                                                        <MenuItem value={'Monthly'}>
                                                            Monthly
                                                        </MenuItem>
                                                        <MenuItem value={'Packages'}>
                                                            Packages
                                                        </MenuItem>

                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Attachment:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80 }} >



                                                    <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}

                                                    >
                                                        <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                        <Typography style={{ fontSize: "18px", marginTop: "3%" }}
                                                        //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                        >
                                                            Attach File
                                                        </Typography>

                                                    </label>

                                                    <input style={{ display: "none" }} type="file" id="file"
                                                        onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }}
                                                    />

                                                    {/* </Box> */}

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => { this.setState({ add: false }) }}
                                        style={{ backgroundColor: "#D9D9D9", marginTop: "4%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                        Cancel
                                    </Button>
                                    <Button
                                      disabled={(this.state.service_name === '') || (this.state.duration === '')}
                                        onClick={() => {
                                            this.props.add_upload_service(this.props.login.org_id, this.state.service_name, this.state.duration, this.state.attachment);
                                            this.setState({ add: false, service_name: "", duration: "", attachment: "" });

                                        }}
                                        // onClick={() => { this.setState({ add: false }) }}
                                        style={{ backgroundColor: "#7900FF", marginTop: "4%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                        Save
                                    </Button>
                                </DialogActions>


                            </Grid>




                        </Grid>
                    </Dialog >



                    <Dialog
                        open={this.state.edit}

                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                       
                    >
                        <Grid
                            style={{

                                minWidth: "600px",
                                // height: "380px"

                            }}
                        >
                            <Grid>
                                <DialogTitle>
                                    <Grid container>
                                        <Grid item xs={11} />
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => {
                                                this.setState({ edit: false });
                                            }}>
                                                <CloseOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container direction={"column"} spacing={2}>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Services Name<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} height={40}>

                                                    <TextField
                                                        style={{ width: '253px', }}

                                                        id="outlined-select-currency-native"

                                                        value={this.state.service_name}
                                            onChange={(e) => { this.setState({ service_name: e.target.value }) }}
                                                    />



                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Duration<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} height={40}>
                                                    <TextField
                                                        style={{ width: '253px', }}
                                                        id="outlined-select-currency-native"
                                                        select
                                                        value={this.state.duration}
                                                        onChange={(e) => { this.setState({ duration: e.target.value }) }}
                                                    >
                                                        <MenuItem value={'Yearly'}>
                                                            yearly
                                                        </MenuItem>
                                                        <MenuItem value={'Monthly'}>
                                                            Monthly
                                                        </MenuItem>
                                                        <MenuItem value={'Packages'}>
                                                            Packages
                                                        </MenuItem>

                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Attachment:
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80 }} >



                                                    <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}

                                                    >
                                                        <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                        <Typography style={{ fontSize: "18px", marginTop: "3%" }}
                                                        //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                        >
                                                           {this.state.attachment !== "" ? this.state.attachment : "Attach File"}
                                                        </Typography>

                                                    </label>

                                                    <input style={{ display: "none" }} type="file" id="file"
                                                       onChange={(e) => { this.setState({ attachmnet_old: e.target.files[0] }) }}
                                                    />

                                                    {/* </Box> */}

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => { this.setState({ edit: false }) }}
                                        style={{ backgroundColor: "#D9D9D9", marginTop: "4%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                        Cancel
                                    </Button>
                                    <Button                                   
                                        onClick={() => {
                                            this.props.update_service_profile(this.props.login.org_id, this.state._id, this.state.service_name, this.state.duration, this.state.attachmnet_old);
                                            this.setState({ edit: false, service_name: "", duration: "", attachment: "",_id:"" });
                                        }}                                     
                                        style={{ backgroundColor: "#7900FF", marginTop: "4%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                        Save
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </Dialog >
                    <Dialog
                        open={this.state.delete}
                        onClose={() => {
                            this.setState({ delete: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ width: "100%", height: "100%", }}
                    >
                        {/* <IconButton
                    > */}

                        <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                            <center>
                                <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                            </center>
                            <DialogActions style={{ marginTop: "42px" }}>

                                <Button variant="contained"
                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px" }}
                                    onClick={() => { this.setState({ delete: false }) }}>

                                    Cancel
                                </Button>
                                <Button variant="contained"
                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                    // onClick={() => { this.setState({ delete: false }) }}
                                    onClick={() => {
                                        // this.props.bulk_delete_service(this.state.id);
                                        this.props.deleteService(this.props.login.org_id, this.state.service_id)
                                        this.setState({ delete: false });
                                    }}>

                                    Confirm
                                </Button>


                            </DialogActions>
                        </Grid>
                    </Dialog>

                    <Dialog
                        open={this.state.deleteall}
                        onClose={() => {
                            this.setState({ deleteall: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ width: "100%", height: "100%", }}
                    >
                        {/* <IconButton
                    > */}

                        <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                            <center>
                                <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                            </center>
                            <DialogActions style={{ marginTop: "42px" }}>

                                <Button variant="contained"
                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px" }}
                                    onClick={() => { this.setState({ deleteall: false }) }}>

                                    Cancel
                                </Button>
                                <Button variant="contained"
                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                    // onClick={() => { this.setState({ delete: false }) }}
                                    onClick={() => {
                                        // this.props.bulk_delete_service(this.state.id);
                                        this.props.isCheckedId(this.props.login.org_id, this.props.service.service)

                                        this.setState({ deleteall: false });
                                    }}>

                                    Confirm
                                </Button>


                            </DialogActions>
                        </Grid>
                    </Dialog>
                </Grid >

            )
    }
}