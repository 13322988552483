import React, { Component } from "react";
import { connect } from "react-redux";
import Service from "../component/Clientphase";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {

  view_all_phase_by_member,
  search_phase2,
  setPhaseId
} from "../action";
import {
  setPhaseName
} from "../../Company/action"
import {
  setProjectId,
  setProjectName,
  setProjectStatus,
  setProjectPriority,
  setProjectAuthPrsn

} from "../../Project/Actions"
export class Controller extends Component {
  render() {
    return (
      <Service {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    phase: store.phase,
    user: store.user,
    one_user: store.one_user,
    snackbar: store.snackbar,
    login: store.login,
    all_project: store.all_project,
    loader: store.loader


  };
};
export const mapDispatchToProps = dispatch => {
  return {

    view_all_phase_by_member: (emp_id) => {
      dispatch(view_all_phase_by_member(emp_id))
    },
    search_phase2: (payload, e, type) => {
      dispatch(search_phase2(payload, e, type));
    },
    setPhaseId: (payload) => {
      dispatch(setPhaseId(payload));
    },
    setPhaseName: (payload) => {
      dispatch(setPhaseName(payload));
    },
    setProjectId: (payload) => {
      dispatch(setProjectId(payload));
    },
    setProjectStatus: (payload) => {
      dispatch(setProjectStatus(payload));
    },
    setProjectName: (payload) => {
      dispatch(setProjectName(payload));
    },
    setProjectPriority: (payload) => {
      dispatch(setProjectPriority(payload));
    },
    setProjectAuthPrsn: (payload) => {
      dispatch(setProjectAuthPrsn(payload));
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);