import {
    VIEW_ALL_REQUEST_BY_PROJECT_AND_TYPE,
    VIEW_ALL_REQUEST,
    VIEW_ALL_REQUEST_BY_PROJECT,
    VIEW_ALL_REQUEST_BY_ID
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import 'firebase/storage';

export function add_upload_request(project_id,desc, request_date, attachment, request_type, request_status) {
    return dispatch => {
        dispatch(setLoader());
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/Request/" + attachment + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachment);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachment) {
                        console.log(attachment)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_request(project_id,desc, request_date, attachment, request_type, request_status));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else{
            dispatch(add_request(project_id,desc, request_date, attachment, request_type, request_status));
        }
    }
}
export function add_request(project_id,desc, request_date, attachment, request_type, request_status)  {
    return (dispatch) => {

        dispatch(setLoader());
       
        return fetch(UNIVERSAL.BASEURL + "add_request", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                project_id:project_id,
            },
            body: JSON.stringify({
                desc : desc, 
                request_date : request_date, 
                attachment : attachment, 
                request_type : request_type, 
                request_status:request_status,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log("request", responseJson)
                    dispatch(viewAllRequestByProjectAndType(project_id, request_type));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
            });
    };
}
export function viewAllRequestByProjectAndType(project_id, request_type) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_request_by_project_&_type", {
            method: "GET",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                project_id  : project_id,
                request_type: request_type,
            },
          
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_REQUEST_BY_PROJECT_AND_TYPE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {                
                    dispatch({ type: VIEW_ALL_REQUEST_BY_PROJECT_AND_TYPE , payload: [] })
                   
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllRequestByType(request_type) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_request_by_type", {
            method: "GET",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                request_type:request_type,
            },
          

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type:VIEW_ALL_REQUEST, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {                
                    dispatch({ type:VIEW_ALL_REQUEST , payload: [] })
                   
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllRequestbByproject(project_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_request_by_project", {
            method: "GET",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                project_id:project_id
            },
        
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type:VIEW_ALL_REQUEST_BY_PROJECT, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {                
                    dispatch({ type:VIEW_ALL_REQUEST_BY_PROJECT , payload: [] })
                   
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function bulkDeleteRequest(project_id, request_type, request_id) {
    return (dispatch) => {
        dispatch(setLoader());
      
        return fetch(UNIVERSAL.BASEURL + "bulk_delete_request", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
               
            },
            body: JSON.stringify({
                request_id :request_id , 
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllRequestByProjectAndType(project_id, request_type));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {          
                  
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function viewRequestById(request_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_request_by_id", {
            method: "POST",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                
            },
            body: JSON.stringify({
                request_id:request_id,
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type:VIEW_ALL_REQUEST_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {                
                    dispatch({ type:VIEW_ALL_REQUEST_BY_ID , payload: [] })
                   
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function updateRequestStatus(_id, request_status,project_id, request_type) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_request_status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
               
            },
            body: JSON.stringify({
                _id:_id,
                request_status:request_status,
               

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllRequestByProjectAndType(project_id, request_type));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                   
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };


}
export function updateRequest(desc, request_date, attachment,_id, project_id, request_type ) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_request", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
               
            },
            body: JSON.stringify({
                desc:  desc, 
                request_date: request_date, 
                attachment:attachment,
                _id: _id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllRequestByProjectAndType(project_id, request_type));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                   
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };


}
