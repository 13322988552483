import {
    VIEW_ALL_FOLLOW_UP_BY_MILESTONE,
    VIEW_ALL_FOLLOW_UP_ID,
    SEARCH_FOLLOW_UPS
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";
export function addAddFollowUps(milestone_id, co_ordinator, addressee, follow_up_type, follow_up_medium, follow_up_date, follow_up_time, follow_up_Desc) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_follow_ups", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                milestone_id: milestone_id
            },
            body: JSON.stringify({
                co_ordinator: co_ordinator,
                addressee: addressee,
                follow_on: follow_up_type,
                follow_up_medium: follow_up_medium,
                follow_up_date: follow_up_date,
                follow_up_time: follow_up_time,
                follow_up_Desc: follow_up_Desc,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllFollowByMilestone(milestone_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function viewAllFollowByMilestone(milestone_id) {
    return (dispatch) => {
        console.log("viewfollowups", milestone_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_follow_by_milestone", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                milestone_id: milestone_id
            },


        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)

                    dispatch({ type: VIEW_ALL_FOLLOW_UP_BY_MILESTONE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_FOLLOW_UP_BY_MILESTONE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function bulkDeleteFollowUps(isChecked, milestone_id) {
    return (dispatch) => {
        console.log("bulkdeletefollups", isChecked)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "bulk_delete_follow_ups", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                follow_ups_id: isChecked

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllFollowByMilestone(milestone_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function viewFollowUpId(follow_up_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_follow_up_id", {
            method: "POST",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                follow_up_id: follow_up_id,
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_FOLLOW_UP_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_FOLLOW_UP_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}



export function updateFollowUp(milestone_id, cntct_person, follow_date, project_type, desc, _id) {
    return (dispatch) => {
        console.log("updatefollowups", milestone_id, _id)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "update_follow_ups", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                cntct_person: cntct_person,
                follow_date: follow_date,
                project_type: project_type,
                desc: desc,
                _id: _id,

            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllFollowByMilestone(milestone_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function search_FollowUps(follow_up_by_milestone, e) {
    return (dispatch) => {

        if (e === "") {
            dispatch({ type: SEARCH_FOLLOW_UPS, payload: follow_up_by_milestone });
        }
        else {
            const newArray = follow_up_by_milestone.filter((el) => {
                return (el.desc.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_FOLLOW_UPS, payload: newArray })
        }
    };
}





export function CheckedFollowUp(index, checked, quantity, followups) {
    var payload = followups;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheckk = false
    // console.log(payload)
    return {
        type: VIEW_ALL_FOLLOW_UP_BY_MILESTONE,
        payload: payload
    }
};
export function getallcheckedd(followups) {
    for (var i = 0; i <= followups.length - 1; i++) {
        followups[i].checked = true;
    }
    return {
        type: VIEW_ALL_FOLLOW_UP_BY_MILESTONE,
        // payload: payload

    }
};
export function allCheckk(checked, quantity, followups) {
    var payload = followups;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheckk = checked;
        if (i === payload.length - 1) {
            return {
                type: VIEW_ALL_FOLLOW_UP_BY_MILESTONE,
                payload: payload
            }
        }
    }
};
export function isCheckedId2(milestone_id, followups) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        console.log(followups)

        // var payload = all_category;
        for (var i = 0; i <= followups.length - 1; i++) {
            // console.log("asdgtfsdt",i)
            if (followups[i].checked === true) {
                isChecked.push(followups[i]._id)
                // console.log(all_category[i]._id)
            }
            count = i;
        }
        if (count === followups.length - 1) {
            console.log(count, isChecked)
            // console.log("hiiiiiiiiiiiiiiiiiiiii", isChecked)
            dispatch(bulkDeleteFollowUps(isChecked, milestone_id))
        }
    }

};

