import {
  
  VIEW_ALL_MILESTONE,
    VIEW_ALL_MILESTONE_BY_ID,
    SEARCH_MILESTONE,
    SET_MILESTONE_ID,
    SET_MILESTONE_NAME
} from "./constant";
const initial_state = {
  milestone: [],
  milestone_by_id:[],
  search_milestone:[],
  milestone_id: "",
  title:""
 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {



    
      case VIEW_ALL_MILESTONE:
        console.log("milestonedetails",action.payload)
        return {
            ...state,
            milestone: action.payload, search_milestone: action.payload
        };
      case VIEW_ALL_MILESTONE_BY_ID:
      return state = { ...state, milestone_by_id: action.payload };

      case SEARCH_MILESTONE:
        return {
            ...state,
            search_milestone: action.payload

        };

        case SET_MILESTONE_ID:
        return {
            ...state,
            milestone_id: action.payload

        };

        case SET_MILESTONE_NAME:
        return {
            ...state,
            title: action.payload

        };

    default:
      return state;
  }
}
