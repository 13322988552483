import {
  
  VIEW_ALL_MEMBERS,
    VIEW_MEMBERS_BY_ID,
    SEARCH_MEMBERS
} from "./constant";
const initial_state = {
  members: [],
  search_members:[]
 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {



      case VIEW_ALL_MEMBERS:
        console.log("viewmemebers", action.payload)
      return state = { ...state, members: action.payload ,search_members: action.payload };
      case VIEW_MEMBERS_BY_ID:
        return state = { ...state, members: action.payload };

        case SEARCH_MEMBERS:
        return state = { ...state, search_members: action.payload };

    default:
      return state;
  }
}
