import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Facebook from "../../pages/image/facebook.png"
import Google from "../../pages/image/google.png"
import LoaderCon from "../../common/loader/containers/loader_cont";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import OtpInput from 'react-otp-input';
import vdo from "../../pages/image/vdo.mp4";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import UNIVERSAL from "../../config/config";
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            otp: "",
            phone: "",
            contact_num: "",
            otpCode: '',
            change_num: false,
            enter_num: false,
            bt_type: true,
        };
    }

    handleOnChange = (value, data, event, formattedValue) => {
        this.setState({
            contact_num: value,
        });
    }
    componentDidMount() {
        document.body.style.backgroundColor = "var(--bg, #FBF8FF)";
        console.log(this.props.params)
        if (this.props.params.id === undefined) {
            console.log("called")
            const payload = {
                product: "66450425135987f133808493",
            };
            fetch("https://ssoapi.technoboot.co/api/v1/loginActivity/createLogin", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.statusCode === 201 || data.statusCode === 200) {
                        this.setState({
                            data: data,
                            isLoading: false,
                            openErrorSnackbar: true,
                            message: "Otp Verified",
                            org: true,
                            orgData: data.result,
                        });
                        console.log(data);
                        window.location.href = "https://sso.technoboot.co/" + data.data._id
                        console.log(this.state.orgData)

                    }
                    else {
                        this.setState({
                            data: data,
                            isLoading: false,
                            openErrorSnackbar: true,
                            message: "Some Error Ocured"
                        });
                    }

                })
                .catch((error) => {
                    console.error(error);
                    this.setState({ openErrorSnackbar: true, message: "Soome Error Occured" })
                });
        } else {
            this.props.fetchToken(this.props.params.id);
        }
    }
    handleChangeNumber = (event) => {
        const inputValue = event.target.value;
        const numberValue = Number(inputValue);
        if (!isNaN(numberValue) && numberValue.toString().length <= 10) {
            this.props.onChange(numberValue);
        }
    };

    handlenumChange = (value) => {
        // const inputValue = event.target.value;
        // const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
        // const truncatedValue = numericValue.slice(0, 10); // Limit to 10 digits
        this.setState({ contact_num: value, });
    }
    handleChange = (otp) => this.setState({ otp });
    render() {
        // const handleChange = otpCode => {
        //     this.setState({ otpCode });
        // };
        return (

            <Grid>
                <Stack sx={{ width: '100%', marginTop: "20%", justifyContent: 'center', alignItems: 'center' }} spacing={2}>
                    <CircularProgress style={{ width: "100px", height: "100px" }} />
                </Stack>
            </Grid>
            // <div className='mainpage'>
            //     <Card style={{ height: "100vh", width: "40vw" }}>

            //         <video className='videos' src={vdo} loop="true" autoPlay muted>
            //             {/* <source src={vdo} type="video/mp4"/> */}
            //         </video>
            //     </Card>
            //     <Card className='logincard' style={{ height: "100vh", width: "60vw" }}>

            //         <Typography className='sign' style={{ fontSize: "22px", marginTop: "20%", marginLeft: "35%", fontWeight: 500 }}>
            //             Sign in
            //         </Typography>

            //         <Box style={{ marginLeft: "35%", marginTop: "5%" }} >
            //             <PhoneInput

            //                 country={'in'}
            //                 // onChange={this.handleOnChange}
            //                 onChange={this.handlenumChange}
            //                 inputStyle={{ backgroundColor: "#F0EFFF" }}
            //                 buttonStyle={{ backgroundColor: "#f0efff" }}
            //                 disabled={this.state.change_num}
            //                 inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', }}
            //             />
            //         </Box>
            //         {/* </center> */}


            //         {this.state.enter_num && <div>
            //             <Typography style={{ fontSize: "15px", marginTop: "3%", marginLeft: "35%", fontWeight: 400, color: "#B5B5B5" }}>
            //                 Please  Enter Your OTP  <Button
            //                     onClick={(e) => {
            //                         this.setState({
            //                             change_num: !this.state.change_num,
            //                             enter_num: !this.state.enter_num,
            //                             bt_type: !this.state.bt_type
            //                         })
            //                     }}
            //                 ><strong style={{ color: "#7900FF", fontSize: '15px', marginLeft: "20px" }}>Change Number</strong></Button>
            //             </Typography>




            //             <Box style={{ marginLeft: "34%", marginTop: "2%" }} >
            //                 <OtpInput
            //                     inputStyle={{
            //                         width: '2rem',
            //                         height: '2rem',
            //                         marginLeft: "3%",
            //                         fontSize: '2rem',

            //                         border: '1px solid rgba(0, 0, 0, 0.2)',
            //                         boxShadow: " 2px 2px 6px rgba(0, 0, 0, 0.25)"
            //                     }}
            //                     onChange={this.handleChange}
            //                     numInputs={5}
            //                     value={this.state.otp}
            //                     renderSeparator={<span></span>}
            //                     renderInput={(props) => <input {...props} />}
            //                 />
            //             </Box>





            //             <Typography style={{ fontSize: "15px", marginTop: "3%", fontWeight: 400, color: "#B5B5B5", marginLeft: "35%", }}>
            //                 If you didn’t receive a code<Button><strong style={{ color: "#FF0000" }}> Resend OTP</strong></Button>
            //             </Typography>

            //         </div>}

            //         <Button style={{ backgroundColor: "#7900FF", height: "49px", width: "300px", marginTop: "3%", marginLeft: "35%" }} variant="contained"
            //             // disabled={this.state.phone.length!==10}
            //             disabled={this.state.contact_num.length < 12}
            //             onClick={(e) => {
            //                 this.setState({
            //                     change_num: !this.state.change_num,
            //                     enter_num: !this.state.enter_num,
            //                     bt_type: !this.state.bt_type
            //                 })
            //                 if (this.state.bt_type) {
            //                     this.props.phone_login(this.state.contact_num)
            //                 }
            //                 if (!this.state.bt_type) {
            //                     this.props.verify_otp(this.state.contact_num, this.state.otp)
            //                 }
            //             }}
            //         >{this.state.bt_type ? "Send OTP" : "Verify OTP"}</Button>

            //         {/* <center>
            //             <Typography style={{ fontSize: "15px", marginTop: "5%", fontWeight: 400, color: "#B5B5B5" }}>
            //                 or continue with
            //             </Typography>
            //         </center> */}


            //         {/* <div style={{ display: "flex", flexDirection: "row", marginTop: "7%", marginLeft: "43%" }}>

            //             <img
            //                 height={30}
            //                 src={Facebook}
            //                 alt="logo_img"
            //                 onClick={() => this.props.facebookLogin()} />
            //             <img
            //                 height={30}
            //                 src={Google}
            //                 alt="logo_img"
            //                 style={{ marginLeft: "5%" }}
            //                 onClick={() => this.props.googleLogin()}
            //             />
            //         </div> */}




            //     </Card>
            //     <LoaderCon />
            // </div>



        )
    }
}
export default withParams(login)
