import {

  TICKETS,

  SEARCH_TICKET,

  VIEW_ALL_REQUEST_BY_PROJECT,
  REQUEST,

} from "./constant";
const initial_state = {
  ticket: [],
  search_ticket: [],
  request:[],

};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case TICKETS:
      console.log("redtic",  action.payload)
      return {
        ...state,
        ticket: action.payload, search_ticket: action.payload
      };
    case SEARCH_TICKET:
      return {
        ...state,
        search_ticket: action.payload

      };

    case REQUEST:
      return state = { ...state, request: action.payload };
    default:
      return state;
  }
}
