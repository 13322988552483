import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import Reducer from './src/screens/redux/Reducers';
import login from './auth/reducer';
import snackbar from './common/snackbar/reducer';
import loader from './common/loader/reducer';
import service from './pages/Services/reducer';
import company from './pages/Company/reducer';
import all_project from "./pages/Project/reducer";
import phase from "./pages/Phase/reducer";
import milestone from "./pages/Milestone/reducer"
// import members from "./pages/Members/"
import task from "./pages/Task/reducer";
import employee from "./pages/Employee/reducer";
import testing from "./pages/Testing/reducer";
// import document from "./pages/Members/document/reducer";
// import milestone_members from "./pages/Members/milestoneMembers/reducer";
import followup from "./pages/FollowUps/reducer";
import meeting from "./pages/Meeting/reducer";

import ticket from "./pages/Tickets/reducer";
import assets from "./pages/Assets/reducer";
import invoice from "./pages/Invoice/reducer";
import request from "./pages/Request/reducer";
import authperson from "./pages/authperson/reducer";
import members from './pages/Members/reducer';
import milestoneMembers from './pages/MilestoneMembers/reducer';
import document from './pages/Document/reducer';
// import authperson from "./pages/Phase/Auth_Person/reducer";
// import testcase from "./pages/admin/Test_Case/reducer";







const persistConfig = {
  key: 'root',
  storage,
}

//import banner from "./src/screens/Home/Reducers";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
  snackbar,
  loader,
  login,
  service,
  company,

  all_project,
  phase,
  milestone,
  // members,
  task,
  employee,
  testing,
  // document,
  // milestone_members,
  followup,
  meeting,
  assets,
  invoice,
  ticket,
  request,
  authperson,
  members,
  milestoneMembers,
  document,
  // request,
  // authperson,
  // testcase,
})

const persistedReducer = persistReducer(persistConfig, reducer)
const configureStore = (initialState) => createStoreWithMiddleware(persistedReducer, initialState);
const store = configureStore();
// const persistor = persistStore(store)
// let store = createStore(persistedReducer)
let persistor = persistStore(store)
export default { store, persistor }

