import {
  VIEW_ALL_INVOICE ,
    VIEW_ALL_INVOICE_BY_ID,
    SEARCH_INVOICE
} from "./constant";
const initial_state = {
  
  invoice:[],
  search_invoice:[]
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

      case  VIEW_ALL_INVOICE_BY_ID:
        return state = { ...state, invoice: action.payload };


        case VIEW_ALL_INVOICE:
          return {
            ...state,
            invoice: action.payload, search_invoice: action.payload
          };

        case SEARCH_INVOICE:
          return {
            ...state,
            search_invoice: action.payload
    
          };

    default:
      return state;
  }
}
