import {
    ASSETS,
    ASSETS_ID,
    SET_ASSETS_NAME,
    SET_DURATION,
    SET_ATTACHMNET,
    SET_ASSETS_ID,
    SET_RENEWAL_DURATION,
    SEARCH_ASSERTS

} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import DocViewer from "react-doc-viewer";
import 'firebase/storage';


export function addUploadAsset(org_id, assets_name, duration, attachmnet) {
    return dispatch => {
        console.log("asdfghj")
        dispatch(setLoader());
        if (attachmnet !== "") {
            const storageRef = ref(getStorage(), "/User_Profile/" + attachmnet + Math.random() + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachmnet);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachmnet) {
                        console.log(attachmnet)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            console.log("Adsdsad")
                            dispatch(addAsset(org_id, assets_name, duration, attachmnet));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else{
            dispatch(addAsset(org_id,assets_name, duration, attachmnet));
        }
       
    }
}
export function addAsset(org_id, assets_name, duration, attachmnet) {
    console.log("asiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", attachmnet)
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "add_assets", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },
            body: JSON.stringify({
                assets_name: assets_name,
                renewal_duration: duration,
                attachmnet: attachmnet,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.status) {

                    dispatch(viewAllAssets(org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function viewAllAssets(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_assets", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            dispatch({ type: ASSETS, payload: responseJson.result });
                        }
                    }

                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: ASSETS, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_assets_by_id( assets_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_assets_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                assets_id: assets_id,
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch({ type: ASSETS_ID, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: ASSETS_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function bulk_delete_assets(org_id, isChecked) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "bulk_delete_assets", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                assets_id: isChecked,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllAssets(org_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function uploadUpdateAssets(org_id, assets_id, assets_name, renewal_duration, attachmnet) {
    return dispatch => {
        //   console.log(Emp_Id)
        dispatch(setLoader(true));
        if (attachmnet == null) {
            dispatch(updateAssets(org_id, assets_id, assets_name, renewal_duration, attachmnet));

        } else {
            const storageRef = ref(getStorage(), "/Profile/" + assets_id + ".png")

            // .put(image)
            // uploadBytes(storageRef, image).then((snapshot) => {
            //     console.log('Uploaded a blob or file!');
            //   });
            const uploadTask = uploadBytesResumable(storageRef, attachmnet);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic) {
                        // console.log(downloadURL)
                        dispatch(updateAssets(org_id, assets_id, assets_name, renewal_duration, attachmnet));
                    });
                }
            );
        }
    }
}
export function updateAssets(org_id, assets_id, assets_name, renewal_duration, attachmnet) {
    return (dispatch) => {
        console.log("asfbhn", assets_id, renewal_duration)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "update_assets", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                org_id: org_id,
                assets_id: assets_id,
                assets_name:  assets_name,
                renewal_duration: renewal_duration,
                attachmnet: attachmnet,

            })
        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAllAssets(org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function deleteAsset(org_id, assets_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_assets_by_id", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                assets_id: assets_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllAssets(org_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
                console.error(error);
            });
    };
}
export function CheckedAssets(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheck=false
    return {
        type: ASSETS,
        payload: payload
    }
};
export function getallchecked( all_category) {
    for(var i =0;i<=all_category.length -1;i++){
        all_category[i].checked= true;
    }
    return {
        type: ASSETS,
        
    }
};
export function allCheck(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: ASSETS,
                payload: payload
            }
    }
};
export function isCheckedId(org_id, all_category) {
    var count=0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= all_category.length - 1; i++) {
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
            }
            count=i;
        }
         if (count === all_category.length - 1) {
            dispatch(bulk_delete_assets(org_id, isChecked))
        }
    }

};

export function setAssetsName(payload) {
    return {
        type: SET_ASSETS_NAME,
        payload: payload,
    };
}
export function setDuration(payload) {
    return {
        type: SET_DURATION,
        payload: payload,
    };
}
export function setAttachmnet(payload) {
    return {
        type: SET_ATTACHMNET,
        payload: payload,
    };
}
export function setAssetsId(payload) {
    return {
        type: SET_ASSETS_ID,
        payload: payload,
    };
}
export function setRenewalDuration(payload) {
    return {
        type: SET_RENEWAL_DURATION,
        payload: payload,
    };
}


export function search_asserts(assets, e) {
    return (dispatch) => {
        // console.log(e)
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_ASSERTS, payload: assets });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = assets.filter((el) => {
                // console.log(el.first_name)
                return (el.assets_name.toLowerCase().includes(e.toLowerCase()));
            })
            // const array = report.fil
            dispatch({ type: SEARCH_ASSERTS, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}



