import React, { Component } from "react";
import { connect } from "react-redux";
import Company from '../../Company/component/adminCompany';
import {
  close_snack_bar
} from "../../../common/snackbar/action";
import {
  onLogout,
  Docview,
} from "../../../auth/actions";
import {
  add_upload_company,
  add_company,
  viewAllCompany,
  bulk_delete_company,
  update_company_profile,
  update_company,
  view_company_by_id,

  add_company_assets,
  view_all_assets,
  bulk_delete_comp_assets,
  update_comp_service,
  add_company_service,
  view_all_comp_service,
  bulk_delete_comp_service,
  CheckedService,
  getallcheckedd,
  allCheckk,
  isCheckedId2,
  CheckedCompany,
  getallchecked,
  isCheckedIdd,
  isCheckedIdCompany,
  view_comp_service_by,
  update_comp_assets,

  setName,
  setOwnerName,
  setCompanyShortid,
  setContactNo,
  setEmail,
  setGstNum,
  setFixAmount,
  setAddress,
  setCompId,
  setPurchaseDate,
  setExpDate,
  setDesc,
  setRelatedTo,
  setComAssest,
  setServiceId,
  setCompServiceName,

  add_upload_request,
  add_request,
  viewAllRequestByProjectAndTypeChange,
  updateRequestStatus,
  CheckedChange,
  isCheckedIdChange,
  allCheckChange,
  bulkDeleteRequest,
  allCheckCompany,
  setPhaseId,
  search_company,
    setCompanyId,
    setCompanyName,
   
    setCompanyOwner,
    setCompanyEmail,
    setCompanyContactNo,
    viewAllCompanyAccess,





  

} from "../action";
// import{add_upload_project} from "../../Project/action"

export class Controller extends Component {
  render() {
    return (
      <Company {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    companyassets: store.companyassets,
    snackbar: store.snackbar,
    login: store.login,
    company: store.company,
    loader:store.loader


  };
};
export const mapDispatchToProps = dispatch => {
  return {
    add_upload_company: (org_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet) => {
      dispatch(add_upload_company(org_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet))
    },
    add_company: (org_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet) => {
      dispatch(add_company(org_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet))
    },
    viewAllCompany: (org_id) => {
      dispatch(viewAllCompany(org_id))
    },
    viewAllCompanyAccess: (user_id) => {
      dispatch(viewAllCompanyAccess(user_id))
    },
    bulk_delete_company: (org_id, company_id) => {
      dispatch(bulk_delete_company(org_id, company_id))
    },
    update_company_profile: (org_id, _id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet, attachment1) => {
      dispatch(update_company_profile(org_id, _id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet, attachment1))
    },
    update_company: (org_id, company_id, company_name, company_shortid, contact_no, email, gst_num, address) => {
      dispatch(update_company(org_id, company_id, company_name, company_shortid, contact_no, email, gst_num, address))
    },
    view_company_by_id: (comp_id) => {
      dispatch(view_company_by_id(comp_id))
    },
    search_company: (company, e) => {
      dispatch(search_company(company, e))
    },

    //assets
    add_company_assets: (comp_id, purchase_date, assets_id, exp_date, assets_name, desc, related_to) => {
      dispatch(add_company_assets(comp_id, purchase_date, assets_id, exp_date, assets_name, desc, related_to))
    },
    view_all_assets: () => {
      dispatch(view_all_assets())
    },
    bulk_delete_comp_assets: (com_assest) => {
      dispatch(bulk_delete_comp_assets(com_assest))
    },
    update_comp_service: (_id, comp_id, purchase_date, service_id, exp_date, comp_service_name, desc, related_to) => {
      dispatch(update_comp_service(_id, comp_id, purchase_date, service_id, exp_date, comp_service_name, desc, related_to));
    },
    add_company_service: (comp_id, purchase_date, service_id, exp_date, comp_service_name, desc, related_to) => {
      dispatch(add_company_service(comp_id, purchase_date, service_id, exp_date, comp_service_name, desc, related_to))
    },
    view_all_comp_service: (comp_id) => {
      dispatch(view_all_comp_service(comp_id))
    },
    bulk_delete_comp_service: (comp_service) => {
      dispatch(bulk_delete_comp_service(comp_service))
    },
    CheckedService:(index, checked, quantity, service)=> {
      dispatch(CheckedService(index, checked, quantity, service))
    },
    getallcheckedd:(service)  => {
      dispatch(getallcheckedd(service))
    },
    allCheckk:(checked, quantity, service)  => {
      dispatch(allCheckk(checked, quantity, service) )
    },
    isCheckedId2:(comp_id, service) => {
      dispatch( isCheckedId2(comp_id, service))
    },
    CheckedCompany: (index, checked, quantity, all_category) => {
      dispatch(CheckedCompany(index, checked, quantity, all_category))
    },
    getallchecked:(all_category) => {
      dispatch(getallchecked(all_category) )
    },

    isCheckedIdd:(comp_id, all_category) => {
      dispatch(isCheckedIdd(comp_id, all_category) )
    },

    isCheckedIdCompany: (org_id, company) => {
      dispatch(isCheckedIdCompany(org_id, company));
    },
    view_comp_service_by: (comp_service_id) => {
      dispatch(view_comp_service_by(comp_service_id))
    },
    update_comp_assets: (purchase_date, comp_id, _id, assets_id, exp_date, assets_name, desc, related_to) => {
      dispatch(update_comp_assets(purchase_date, comp_id, _id, assets_id, exp_date, assets_name, desc, related_to))
    },
   //setter
   setName:(payload)  => {
      dispatch(setName(payload))
    },
    setOwnerName:(payload)  => {
      dispatch(setOwnerName(payload) )
    },
   setCompanyShortid:(payload) => {
      dispatch( setCompanyShortid(payload))
    },
   setContactNo:(payload)=> {
      dispatch(setContactNo(payload) )
    },
    setEmail:(payload)  => {
      dispatch(setEmail(payload)  )
    },

   setGstNum:(payload)   => {
      dispatch(setGstNum(payload) )
    },
    setFixAmount:(payload)   => {
      dispatch( setFixAmount(payload)  )
    },
   setAddress:(payload)  => {
      dispatch(setAddress(payload) )
    },
   setCompId:(payload) => {
      dispatch(setCompId(payload)  )
    },
    setPurchaseDate:(payload)  => {
      dispatch(setPurchaseDate(payload))
    },

   setExpDate:(payload) => {
      dispatch(setExpDate(payload) )
    },
   setDesc:(payload)   => {
      dispatch( setDesc(payload)  )
    },
  setRelatedTo:(payload)   => {
      dispatch( setRelatedTo(payload) )
    },
    setComAssest:(payload) => {
      dispatch(setComAssest(payload)  )
    },
    setServiceId:(payload)  => {
      dispatch(setServiceId(payload))
    },
    setCompServiceName:(payload)  => {
      dispatch(setCompServiceName(payload) )
    },
   
   //request 
    add_upload_request:(project_id, desc, request_date, attachment, request_type, request_status, priority) => {
    dispatch(add_upload_request(project_id, desc, request_date, attachment, request_type, request_status, priority))
     },
    add_request:(project_id, desc, request_date, attachment, request_type, request_status, priority) => {
      dispatch(add_request(project_id, desc, request_date, attachment, request_type, request_status, priority))
    },
    viewAllRequestByProjectAndTypeChange:(project_id, request_type) => {
      dispatch(viewAllRequestByProjectAndTypeChange(project_id, request_type))
    },
    updateRequestStatus:(_id, request_status, project_id, request_type) => {
      dispatch( updateRequestStatus(_id, request_status, project_id, request_type))
    },
    CheckedChange:(index, checked, quantity, phase)  => {
      dispatch(CheckedChange(index, checked, quantity, phase) )
    },

    isCheckedIdChange:(project_id, phase)  => {
      dispatch(isCheckedIdChange(project_id, phase) )
    },
    allCheckChange:(checked, quantity, all_category)  => {
      dispatch(allCheckChange(checked, quantity, all_category) )
    },
    bulkDeleteRequest:(project_id, request_id)  => {
      dispatch(bulkDeleteRequest(project_id, request_id) );
    },
    allCheckCompany:(checked, quantity, comapany)=> {
      dispatch(allCheckCompany(checked, quantity, comapany));
    },
    setPhaseId:(payload) => {
      dispatch(setPhaseId(payload));
    },
   
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    Docview: (doc) => {
      dispatch(Docview(doc))
    },

    onLogout: () => {
      dispatch(onLogout());
    },


    add_company_service: (comp_id, purchase_date, service_id, exp_date, comp_service_name, desc, related_to) => {
      dispatch(add_company_service(comp_id, purchase_date, service_id, exp_date, comp_service_name, desc, related_to))
    },

    view_all_comp_service: (comp_id) => {
      dispatch(view_all_comp_service(comp_id))
    },
    view_comp_service_by: (comp_service_id) => {
      dispatch(view_comp_service_by(comp_service_id))
    },
    bulk_delete_comp_service: (comp_service) => {
      dispatch(bulk_delete_comp_service(comp_service))
    },
    update_comp_service: (_id, comp_id, purchase_date, service_id, exp_date, comp_service_name, desc, related_to) => {
      dispatch(update_comp_service(_id, comp_id, purchase_date, service_id, exp_date, comp_service_name, desc, related_to));
    },
    add_upload_company: (org_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet) => {
      dispatch(add_upload_company(org_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachmnet))
    },
    viewAllCompany: (org_id) => {
      dispatch(viewAllCompany(org_id))
    },

    setCompanyId: (payload) => {
      dispatch(setCompanyId(payload))
    },
    setCompanyName: (payload) => {
      dispatch(setCompanyName(payload))
    },
    setCompanyOwner: (payload) => {
      dispatch(setCompanyOwner(payload))
    },
    setCompanyEmail: (payload) => {
      dispatch(setCompanyEmail(payload))
    },
    setCompanyContactNo: (payload) => {
      dispatch(setCompanyContactNo(payload))
    },
    // update_company_profile: (org_id, company_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachment) => {
    //   dispatch(update_company_profile(org_id, company_id, name, owner_name, company_shortid, contact_no, email, gst_num, fix_Amount, address, attachment))
    // },
    CheckedCompany: (index, checked, quantity, all_category) => {
      dispatch(CheckedCompany(index, checked, quantity, all_category))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    isCheckedIdCompany: (org_id, company) => {
      dispatch(isCheckedIdCompany(org_id, company));
    },
    allCheckCompany: (checked, quantity, comapany) => {
      dispatch(allCheckCompany(checked, quantity, comapany));
    },





    
   
   

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);