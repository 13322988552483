import {
    VIEW_ALL_AUTH_PERSON,
    VIEW_ALL_AUTH_PERSON_BY_ID,
    SEARCH_AUTH_PERSON,
    VIEW_ALL_COMP_AUTH_PERSON
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";

import 'firebase/storage';



export function add_auth_person(org_id,comp_id, project_id,auth_name, phase_id, email, phone_num, desc, active_status) {
    return (dispatch) => {
        console.log("authperson",project_id,auth_name, phase_id, email, phone_num, desc, active_status)
        dispatch(setLoader());
       
        return fetch(UNIVERSAL.BASEURL + "add_auth_person", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                project_id:project_id,
            },
            body: JSON.stringify({
                org_id:org_id,
                comp_id:comp_id,
                project_id:project_id,
                auth_name: auth_name, 
                phase_id : phase_id,
                email: email,
                phone_num: phone_num,
                desc:  desc, 
                active_status:active_status,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllAuthPerson(project_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function viewAllAuthPerson(project_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_auth_person", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                project_id:project_id,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_AUTH_PERSON, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {                
                    dispatch({ type: VIEW_ALL_AUTH_PERSON , payload: [] })               
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAllCompAuthPerson(project_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_comp_auth_person", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                comp_id:project_id,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_COMP_AUTH_PERSON, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {                
                    dispatch({ type: VIEW_ALL_COMP_AUTH_PERSON , payload: [] })               
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAuthPersonById(auth_person_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_auth_person_by_id", {
            method: "POST",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
               
            },
            body: JSON.stringify({
                auth_person_id:auth_person_id,
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type:VIEW_ALL_AUTH_PERSON_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {                
                    dispatch({ type: VIEW_ALL_AUTH_PERSON_BY_ID, payload: [] })
                   
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function bulkDeleteAuthPerson(project_id,isChecked ) {
    return (dispatch) => {
        console.log("authpersonnn",isChecked)
        dispatch(setLoader());
      
        return fetch(UNIVERSAL.BASEURL + "bulk_delete_auth_person", {
            method: "delete",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
               
            },
            body: JSON.stringify({
                auth_person_id :isChecked , 
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllAuthPerson(project_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {          
                  
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function updateAuthPersonStatus(_id, active_status, project_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_auth_person_status", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
               
            },
            body: JSON.stringify({
                _id:_id,
                active_status: active_status,
               

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllAuthPerson(project_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                   
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };


}
export function updateRequest(auth_name, phase_id, email, phone_num, desc, active_status, _id, project_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_request", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
               
            },
            body: JSON.stringify({
                auth_name: auth_name, 
            phase_id:phase_id, 
            email:email,
            phone_num: phone_num, 
            desc: desc, 
            active_status: active_status, 
            _id: _id,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllAuthPerson(project_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                   
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };


}







export function CheckedAuthPerson(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    // payload.allCheck=false
    // console.log(payload)
    return {
        type: VIEW_ALL_AUTH_PERSON,
        payload: payload
    }
};

export function getallcheckedAuthPerson(all_category) {
    for (var i = 0; i <= all_category.length - 1; i++) {
        all_category[i].checked = true;
    }
    return {
        type: VIEW_ALL_AUTH_PERSON,

    }
};

export function allCheckAuthPerson(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: VIEW_ALL_AUTH_PERSON,
                payload: payload
            }
    }
};

export function isCheckedIdAuthPerson(project_id, all_category) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= all_category.length - 1; i++) {
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
            }
            count = i;
        }
        if (count === all_category.length - 1) {
            dispatch(bulkDeleteAuthPerson(project_id,isChecked ))
        }
    }

};



export function search_authperson(authoperson, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_AUTH_PERSON, payload: authoperson });
        }
        else {
            const newArray = authoperson.filter((el) => {
                return (el.auth_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_AUTH_PERSON, payload: newArray })
        }
    };
}

