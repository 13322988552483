import React, { Component } from "react";
import { connect } from "react-redux";
import Companyphase from '../component/Companyphase';

import {
  close_snack_bar
} from "../../../common/snackbar/action";
import {
  onLogout
} from "../../../auth/actions";

import {
  add_upload_phase,
  view_all_phase,
  bulk_delete_phase,
  isCheckedId,
  CheckedPhase,
  setPhaseId,
  update_upload_phase,
  search_project_phase,
  search_phase,
  getallchecked,
  allCheck,
  Filter_phase
} from "../../Phase/action";
import {
  view_all_status,
  view_all_priority,
  view_all_department,
} from "../../../auth/actions";
import {
  add_upload_request,
  viewAllRequestByProjectAndTypeChange,
  updateRequestStatus,
  isCheckedIdChange,allCheckChange,CheckedChange,
  search_change_request,
  upload_upadate_request,
  Filter_change_request
  
} from "../action";
import {setPhaseName} from '../../Company/action';
import {add_auth_person,viewAllAuthPerson, CheckedAuthPerson,
  getallcheckedAuthPerson,
  allCheckAuthPerson,
  isCheckedIdAuthPerson,search_authperson, 
  updateAuthPersonStatus,viewAllCompAuthPerson} from '../../authperson/action'





export class Controller extends Component {
  render() {
    return (
      <Companyphase {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {

    companyassets: store.companyassets,
    snackbar: store.snackbar,
    login: store.login,
    company: store.company,
    all_project: store.all_project,
    phase: store.phase,
    authperson:store.authperson

  };
};

export const mapDispatchToProps = dispatch => {
  return {

    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    view_all_status: (org_id) => {
      dispatch(view_all_status(org_id));
    },
    view_all_phase: (project_id) => {
      dispatch(view_all_phase(project_id));
    },
    // view_all_department: (org_id) => {
    //   dispatch(view_all_department(org_id));
    // },
    view_all_department: (org_id) => {
      dispatch(view_all_department(org_id));
    },
    view_all_priority: (org_id) => {
      dispatch(view_all_priority(org_id));
    },
    bulk_delete_phase: (project_id, isChecked) => {
      dispatch(bulk_delete_phase(project_id, isChecked))
    },
    isCheckedId: (project_id, phase) => {
      dispatch(isCheckedId(project_id, phase))
    },
    CheckedPhase: (index, checked, quantity, phase) => {
      dispatch(CheckedPhase(index, checked, quantity, phase));
  },

    onLogout: () => {
      dispatch(onLogout());
    },
    add_upload_phase: (project_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments) => {
      dispatch(add_upload_phase(project_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments))
    },

    update_upload_phase: (project_id,_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments, attachments1) => {
      dispatch(update_upload_phase(project_id,_id, title, desc, start_date, dead_line, status_id, priority_id, max_hr, attcahments, attachments1))
    },
    upload_upadate_request: (project_id,_id, change_title, desc, request_date, attachment, request_type, priority, attachments1) => {
      dispatch(upload_upadate_request(project_id,_id, change_title, desc, request_date, attachment, request_type, priority, attachments1))
    },
    add_upload_request: (project_id,change_title,desc, request_date, attachment, request_type,  priority) => {
      dispatch(add_upload_request(project_id,change_title,desc, request_date, attachment, request_type,  priority))
    },
    viewAllRequestByProjectAndTypeChange: (project_id,request_type) => {
      dispatch(viewAllRequestByProjectAndTypeChange(project_id,request_type))
    },
    updateRequestStatus: (_id, request_status, project_id, request_type) => {
      dispatch(updateRequestStatus(_id, request_status, project_id, request_type))
    },
    CheckedChange: (index, checked, quantity, phase) => {
      dispatch(CheckedChange(index, checked, quantity, phase))
    },
    allCheckChange: (checked, quantity, all_category) => {
      dispatch(allCheckChange(checked, quantity, all_category))
    },
    isCheckedIdChange: (project_id, phase) => {
      dispatch(isCheckedIdChange(project_id, phase))
    },

    setPhaseId: (payload) => {
      dispatch(setPhaseId(payload))
  },

  setPhaseName: (payload) => {
    dispatch(setPhaseName(payload))
},
  search_project_phase: (company_service, e) => {
      dispatch(search_project_phase(company_service, e))
  },
  search_change_request: (company_service, e) => {
      dispatch(search_change_request(company_service, e))
  },


  search_phase: (phase, e, type) => {
    dispatch(search_phase(phase, e, type))
},


getallchecked: ( phase) => {
  dispatch(getallchecked(phase));
},
allCheck: (checked, quantity, phase) => {
  dispatch(allCheck(checked, quantity, phase));
},


add_auth_person: (org_id, comp_id, project_id,auth_name, phase_id, email, phone_num, desc, active_status) => {
  dispatch(add_auth_person(org_id, comp_id, project_id,auth_name, phase_id, email, phone_num, desc, active_status));
},

viewAllAuthPerson: (project_id) => {
  dispatch(viewAllAuthPerson(project_id));
},
viewAllCompAuthPerson: (project_id) => {
  dispatch(viewAllCompAuthPerson(project_id));
},
updateAuthPersonStatus: (_id, active_status, project_id) => {
  dispatch(updateAuthPersonStatus(_id, active_status, project_id));
},



CheckedAuthPerson: (index, checked, quantity, all_category) => {
  dispatch(CheckedAuthPerson(index, checked, quantity, all_category));
},
getallcheckedAuthPerson: ( all_category) => {
  dispatch(getallcheckedAuthPerson(all_category));
},
allCheckAuthPerson: (checked, quantity, all_category) => {
  dispatch(allCheckAuthPerson(checked, quantity,all_category));
},
isCheckedIdAuthPerson: (project_id, all_category) => {
  dispatch(isCheckedIdAuthPerson(project_id, all_category) );
},

Filter_phase: (phase, e,type) => {
  dispatch(Filter_phase(phase, e,type) );
},

Filter_change_request: (company_service, e) => {
  dispatch(Filter_change_request(company_service, e) );
},

search_authperson: (authoperson, e) => {
  dispatch(search_authperson(authoperson, e) );
},


  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);