import {
  VIEW_PROJECT, SET_PROJECT_ID,SET_PROJECT_NAME,SET_PROJECT_STATUS,SET_PROJECT_PRIORITY,SET_PROJECT_AUTH_PRSN,SEARCH_PROJECT,VIEW_PROJECT_BY_ORG_ID,VIEW_PROJECT_BY_COMP_ID,SEARCH_COMPANY_PROJECT

} from "./constant";
const initial_state = {
  all_project: [],
  all_project_org_id:[],
  project_comp_id:[],
  project_id:"",
  project_title:"",
  status_id:"",
  priority_id:"",
  auth_person:"",
  search_project:[],
  search_comp_project:[]
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

   
      case VIEW_PROJECT:
        console.log(action.payload)
        return state = {
            ...state,
            all_project: action.payload.data, search_project: action.payload.filter
            
        };

    case SET_PROJECT_ID:
      return state = {
        ...state,
        project_id: action.payload
      };

      case VIEW_PROJECT_BY_ORG_ID:
        return state = { ...state, all_project_org_id: action.payload };

     
        case VIEW_PROJECT_BY_COMP_ID:
          // console.log("projectdetailsss",project_comp_id)
          return {
              ...state,
              project_comp_id: action.payload, search_comp_project: action.payload
          };


        case SEARCH_COMPANY_PROJECT:
          return {
              ...state,
              search_comp_project: action.payload
  
          };



    case SET_PROJECT_NAME:
      return state = {
        
        ...state,
        project_title: action.payload
      };
    case SET_PROJECT_STATUS:
      return state = {
        ...state,
        status_id: action.payload
      };
    case SET_PROJECT_PRIORITY:
      return state = {
        ...state,
        priority_id: action.payload
      };
    case SET_PROJECT_AUTH_PRSN:
      return state = {
        ...state,
        auth_person: action.payload
      };


      case SEARCH_PROJECT:
        return {
            ...state,
            search_project: action.payload

        };
    // case SET_ONE_USER:
    //   return state = { ...state, one_user: action.payload };

    default:
      return state;
  }
}
