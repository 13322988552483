import React, { Component } from "react";
import { connect } from "react-redux";
import Service from "../component/TlMilestone";
import {
  close_snack_bar
} from "../../../common/snackbar/action";
// import {
//   onLogout
// } from "../../../auth/actions";
import {
  view_all_milestone_by_member,
  update_milestone_status,
  search_Milestone2
} from "../action";
import {
  setPhaseId,
} from "../../Phase/action"
import {
  setMilestoneId,
  setMilestoneName
} from "../../Milestone/action"

import {
  setProjectId,
} from "../../Project/Actions"

import {
  setPhaseName,
} from "../../Company/action"

export class Controller extends Component {
  render() {
    return (
      <Service {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    milestone: store.milestone,
    user: store.user,
    one_user: store.one_user,
    snackbar: store.snackbar,
    login: store.login,
    loader: store.loader,


  };
};
export const mapDispatchToProps = dispatch => {
  return {
    view_all_milestone_by_member: (emp_id) => {
      dispatch(view_all_milestone_by_member(emp_id))
    },
    setPhaseId: (payload) => {
      dispatch(setPhaseId(payload));
    },
    setPhaseName: (payload) => {
      dispatch(setPhaseName(payload));
    },
    setProjectId: (payload) => {
      dispatch(setProjectId(payload));
    },
    setMilestoneId: (payload) => {
      dispatch(setMilestoneId(payload));
    },
    setMilestoneName: (payload) => {
      dispatch(setMilestoneName(payload));
    },
    search_Milestone2: (milestone, e) => {
      dispatch(search_Milestone2(milestone, e) );
    },
    update_milestone_status: (id, status_id, phase_id, user_type, emp_id) => {
      dispatch(update_milestone_status(id, status_id, phase_id, user_type, emp_id));
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);