import {
    VIEW_PROJECT, SET_PROJECT_ID, SET_PROJECT_NAME, SET_PROJECT_STATUS, SET_PROJECT_PRIORITY, SET_PROJECT_AUTH_PRSN, SEARCH_PROJECT, VIEW_PROJECT_BY_ORG_ID, VIEW_PROJECT_BY_COMP_ID, SEARCH_COMPANY_PROJECT
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";



// export function add_upload_project(org_id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, max_hr, per_hr_cost, desc, attachment, project_auth_person) {
//     return dispatch => {
//         console.log("dsgjk")
//         dispatch(setLoader());
//         if (attachment !== "") {
//             const storageRef = ref(getStorage(), "/Project-Attachment/" + attachment + Math.random() + ".png")
//             const uploadprofile = uploadBytesResumable(storageRef, attachment);
//             uploadprofile.on(
//                 "state_changed",
//                 function (snapshot) {
//                 },
//                 function (error) {
//                     // console.log(error)
//                     dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
//                 },
//                 function () {
//                     getDownloadURL(uploadprofile.snapshot.ref).then(function (attachment) {
//                         if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
//                             // console.log("Adsdsad")
//                             console.log(attachment)
//                             // console.log(org_id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, max_hr, per_hr_cost, desc,)
//                             dispatch(add_project(org_id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, max_hr, per_hr_cost, desc, attachment, project_auth_person));
//                             dispatch(set_snack_bar(true, "Image Uploaded"))
//                         }
//                         else {
//                             dispatch(set_snack_bar(true, "Image not Uploaded"));
//                         }
//                     });
//                 });
//         }
//         else{
//             // add_project(org_id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, max_hr, per_hr_cost, desc, attachment,project_auth_person)
//             dispatch(add_project(org_id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, max_hr, per_hr_cost, desc, attachment,project_auth_person));
//         }

//     }
// }

export function add_upload_project(org_id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date, max_hr, per_hr_cost, desc, attachment) {
    return dispatch => {
        // console.log("aaaajjjjjaaaaaa",comp_id)
        console.log("project details", end_date)

        dispatch(setLoader());
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/User_Profile/" + attachment + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachment);
            uploadprofile.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachment) {
                        console.log(attachment)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_project(org_id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date, max_hr, per_hr_cost, desc, attachment));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(add_project(org_id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date, max_hr, per_hr_cost, desc, attachment));
        }

    }
}


export function add_project(org_id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date, max_hr, per_hr_cost, desc, attachment) {
    return (dispatch) => {
        console.log("project details", end_date)

        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "add_project", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },
            body: JSON.stringify({
                comp_id: comp_id,
                project_id: project_id,
                project_title: project_title,
                priority_id: priority_id,
                status_id: status_id,
                department_id: department_id,
                project_type: project_type,
                start_date: start_date,
                end_date: end_date,
                max_hr: max_hr,
                per_hr_cost: per_hr_cost,
                desc: desc,
                attachment: attachment,
                // project_auth_person:project_auth_person
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_project_by_org_id(org_id));
                    dispatch(view_all_project_by_comp_id(comp_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function view_all_project_by_org_id(org_id) {
    return (dispatch) => {
        console.log("viewallpro", org_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_project_by_org_id", {
            method: "GET",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson.result)
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            const newArray = responseJson.result.filter((el) => {
                                return (el.status_id.toLowerCase().includes("On Going".toLowerCase()));
                            })
                            dispatch({ type: VIEW_PROJECT, payload: { data: responseJson.result, filter: newArray } });
                        }
                    }
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_PROJECT, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_all_project_by_comp_id(comp_id) {
    return (dispatch) => {
        // console.log("resultt",comp_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_project_by_comp_id", {
            method: "GET",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                comp_id: comp_id,
            },


        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            dispatch({ type: VIEW_PROJECT_BY_COMP_ID, payload: responseJson.result });
                        }
                    }

                    // dispatch({ type: VIEW_PROJECT, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_PROJECT_BY_COMP_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_all_project_by_comp_id_accessed(user_id, comp_id) {
    return (dispatch) => {
        // console.log("resultt",comp_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_project_by_comp_id_accessed", {
            method: "GET",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                comp_id: comp_id,
                user_id: user_id
            },


        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            dispatch({ type: VIEW_PROJECT_BY_COMP_ID, payload: responseJson.result });
                        }
                    }

                    // dispatch({ type: VIEW_PROJECT, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_PROJECT_BY_COMP_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_all_project_accessed_to_user(user_id) {
    return (dispatch) => {
        // console.log("resultt",comp_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_project_accessed_to_user", {
            method: "GET",
            headers: {

                Accept: "application/json",
                "Content-Type": "application/json",
                user_id: user_id
            },


        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            const newArray = responseJson.result
                            console.log(responseJson.result)
                            dispatch({ type: VIEW_PROJECT, payload: { data: responseJson.result, filter: newArray } });
                        }
                    }

                    // dispatch({ type: VIEW_PROJECT, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_PROJECT, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function bulk_delete_project(org_id, isChecked, comp_id, page) {
    return (dispatch) => {
        console.log(page)
        console.log("deleteeproject", isChecked, org_id)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_project", {
            method: "delete",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                Project_id: isChecked,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    if (page === "C") {
                        dispatch(view_all_project_by_comp_id(comp_id));
                    }
                    else {
                        dispatch(view_all_project_by_org_id(org_id));
                    }
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function update_project_(org_id, _id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date, max_hr, per_hr_cost, desc, attachment) {

    return (dispatch) => {
        console.log("updateenddate", end_date)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_project", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",


            },
            body: JSON.stringify({
                _id: _id,
                comp_id: comp_id,
                project_title: project_title,
                project_id: project_id,
                priority_id: priority_id,
                status_id: status_id,
                department_id: department_id,
                project_type: project_type,
                start_date: start_date,
                max_hr: max_hr,
                per_hr_cost: per_hr_cost,
                desc: desc,
                attachment: attachment,
                end_date: end_date

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_project_by_org_id(org_id));
                    dispatch(view_all_project_by_comp_id(comp_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };


}




export function update_project_profile(org_id, _id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date, max_hr, per_hr_cost, desc, attachment, attachment1) {
    return dispatch => {
        console.log("sfgdsdshiglds", _id)
        dispatch(setLoader(true));
        if (attachment1 == null) {
            dispatch(update_project_(org_id, _id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date, max_hr, per_hr_cost, desc, attachment));

        } else {
            const storageRef = ref(getStorage(), "/Profile/" + attachment1 + ".png")

            // .put(image)
            // uploadBytes(storageRef, image).then((snapshot) => {
            //     console.log('Uploaded a blob or file!');
            //   });
            const uploadTask = uploadBytesResumable(storageRef, attachment1);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (attachment1) {
                        // console.log(downloadURL)
                        dispatch(update_project_(org_id, _id, comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date, max_hr, per_hr_cost, desc, attachment1));
                    });
                }
            );
        }
    }
}

export function CheckedProject(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    // payload.allCheck=false
    // console.log(payload)
    return {
        type: VIEW_PROJECT,
        payload: payload
    }
};
export function getallchecked(all_category) {
    for (var i = 0; i <= all_category.length - 1; i++) {
        all_category[i].checked = true;
    }
    return {
        type: VIEW_PROJECT,

    }
};

export function allCheck(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: VIEW_PROJECT,
                payload: payload
            }
    }
};


export function isCheckedId(org_id, all_category) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= all_category.length - 1; i++) {
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
            }
            count = i;
        }
        if (count === all_category.length - 1) {
            dispatch(bulk_delete_project(org_id, isChecked))
        }
    }

};


export function setProjectId(payload) {
    return {
        type: SET_PROJECT_ID,
        payload: payload,
    };
}
export function setProjectName(payload) {
    console.log(payload)
    return {
        type: SET_PROJECT_NAME,
        payload: payload,
    };
}
export function setProjectStatus(payload) {
    return {
        type: SET_PROJECT_STATUS,
        payload: payload,
    };
}
export function setProjectPriority(payload) {
    return {
        type: SET_PROJECT_PRIORITY,
        payload: payload,
    };
}
export function setProjectAuthPrsn(payload) {
    return {
        type: SET_PROJECT_AUTH_PRSN,
        payload: payload,
    };
}

export function search_project(all_project, e, type) {
    return (dispatch) => {
        if (e === "" || e === "N") {
            dispatch({ type: SEARCH_PROJECT, payload: all_project });
        }
        else if (type == "search") {
            const newArray = all_project.filter((el) => {
                return (el.project_title.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PROJECT, payload: newArray })
        }
        else if (type == "status") {
            const newArray = all_project.filter((el) => {
                return (el.status_id.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PROJECT, payload: newArray })
        }
    };
}


// export function bulk_delete_project_by_orgid(org_id, isChecked) {
//     return (dispatch) => {
//         dispatch(setLoader());

//         return fetch(UNIVERSAL.BASEURL + "bulk_delete_project", {
//             method: "delete",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",

//             },
//             body: JSON.stringify({
//                 project_id: isChecked,
//             })
//         })
//             .then((response) => response.json())
//             .then((responseJson) => {

//                 if (responseJson.status) {
//                     dispatch(view_all_project_by_org_id(org_id));
//                     dispatch(set_snack_bar(responseJson.status, "Some message"));
//                     dispatch(unsetLoader());
//                 } else {

//                 }
//                 dispatch(unsetLoader());
//                 dispatch(set_snack_bar(true, responseJson.message));

//             })
//             .catch((error) => {
//                 dispatch(unsetLoader());
//                 dispatch(set_snack_bar(true, "Check Your Internet Connection"));
//                 console.error(error);
//             });
//     };
// }








export function CheckedCompanyProject(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    // payload.allCheck=false
    // console.log(payload)
    return {
        type: VIEW_PROJECT_BY_COMP_ID,
        payload: payload
    }
};
export function getallcheckedproject(all_category) {
    for (var i = 0; i <= all_category.length - 1; i++) {
        all_category[i].checked = true;
    }
    return {
        type: VIEW_PROJECT_BY_COMP_ID,

    }
};

export function allCheckproject(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: VIEW_PROJECT_BY_COMP_ID,
                payload: payload
            }
    }
};

export function isCheckedIdproject(comp_id, all_category, org_id, type) {
    console.log(comp_id, all_category, org_id, type)
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= all_category.length - 1; i++) {
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
            }
            count = i;
        }
        if (count === all_category.length - 1) {
            dispatch(bulk_delete_project(comp_id, isChecked, org_id, type))
        }
    }

};



// export function search_asserts(assets, e) {
//     return (dispatch) => {
//         // console.log(e)
//         // dispatch(setLoader());
//         if (e === "") {
//             dispatch({ type: SEARCH_ASSERTS, payload: assets });
//             // dispatch(unsetLoader());

//             // this.setState({ filterValue: this.props.all_model.all_model })
//             //   setFilter(model.all_model);
//         }
//         else {
//             const newArray = assets.filter((el) => {
//                 // console.log(el.first_name)
//                 return (el.assets_name.toLowerCase().includes(e.toLowerCase()));
//             })
//             // const array = report.fil
//             dispatch({ type: SEARCH_ASSERTS, payload: newArray })
//             // dispatch(unsetLoader());

//             //   setFilter(newArray)
//             // this.setState({ filterValue: newArray })
//         }
//     };
// }


export function search_company_project(project_comp_id, e) {
    return (dispatch) => {
        console.log(project_comp_id)
        if (e === "") {
            dispatch({ type: SEARCH_COMPANY_PROJECT, payload: project_comp_id });
        }
        else {
            const newArray = project_comp_id.filter((el) => {
                console.log(el.name)
                return (el.project_title.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_COMPANY_PROJECT, payload: newArray.sort((a, b) => a.date < b.date ? 1 : -1) })
        }
    };
}


