import {

    VIEW_ALL_MEMBERS,
    VIEW_MEMBERS_BY_ID,
    SEARCH_MEMBERS
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";




export function add_members(emp_id, phase_id) {
    return (dispatch) => {
        console.log("addmember", emp_id, phase_id)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_member", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                phase_id: phase_id

            },
            body: JSON.stringify({
                emp_id: emp_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_members_by_phase(phase_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    }
}
export function view_all_members_by_phase(phase_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_members_by_phase", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                phase_id: phase_id
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)

                    dispatch({ type: VIEW_ALL_MEMBERS, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_MEMBERS, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_members_by_id(memeber_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_members_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                memeber_id: memeber_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)

                    dispatch({ type: VIEW_MEMBERS_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_MEMBERS_BY_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });

    };
}
export function bulk_delete_members(isChecked,phase_id) {
    return (dispatch) => {
        console.log("bulkdeltemembers",isChecked,phase_id)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_members", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({

                member_id: isChecked
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_members_by_phase(phase_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());

                console.error(error);
            });
    };
}


export function CheckedPhaseMember(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    // payload.allCheck=false
    // console.log(payload)
    return {
        type: VIEW_ALL_MEMBERS,
        payload: payload
    }
};
export function getallcheckedMember(all_category) {
    for (var i = 0; i <= all_category.length - 1; i++) {
        all_category[i].checked = true;
    }
    return {
        type: VIEW_ALL_MEMBERS,

    }
};
export function allCheckPhaseMember(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: VIEW_ALL_MEMBERS,
                payload: payload
            }
    }
};

export function isCheckedIdMember(all_category, phase_id) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= all_category.length - 1; i++) {
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
            }
            count = i;
        }
        if (count === all_category.length - 1) {
            dispatch(bulk_delete_members(isChecked,phase_id))
        }
    }

};



export function update_members(_id, emp_id, phase_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "update_members", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
                emp_id: emp_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_members_by_phase(phase_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());

                console.error(error);
            });
    };
}

export function search_Members(members, e) {
    return (dispatch) => {
        if (e === "" && "N") {
            dispatch({ type: SEARCH_MEMBERS, payload: members });
        }
        else {
            const newArray = members.filter((el) => {
                return (el.emp_details.first_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_MEMBERS, payload: newArray })
        }
    };
}

