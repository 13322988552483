import {
  
  VIEW_ALL_FOLLOW_UP_BY_MILESTONE,
  VIEW_ALL_FOLLOW_UP_ID,
  SEARCH_FOLLOW_UPS
} from "./constant";
const initial_state = {
  follow_up_by_milestone: [],
  follow_up_id:[],
  search_followups:[]
 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
      case VIEW_ALL_FOLLOW_UP_BY_MILESTONE:
      return state = { ...state, follow_up_by_milestone: action.payload,search_followups: action.payload };
      case VIEW_ALL_FOLLOW_UP_ID:
        return state = { ...state, follow_up_id: action.payload };

        case SEARCH_FOLLOW_UPS:
          return state = { ...state, search_followups: action.payload };
    default:
      return state;
  }
}
