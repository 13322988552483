import {
   VIEW_ALL_REQUEST_BY_PROJECT_AND_TYPE,
    VIEW_ALL_REQUEST,
    VIEW_ALL_REQUEST_BY_PROJECT,
    VIEW_ALL_REQUEST_BY_ID
} from "./constant";
const initial_state = {
  view_all_request_by_project_and_name:[],
  view_all_request:[],
  view_all_request_by_project:[],
  request_by_id:[],
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case  VIEW_ALL_REQUEST_BY_PROJECT_AND_TYPE:
      return state = { ...state, view_all_request_by_project_and_name: action.payload };

    case  VIEW_ALL_REQUEST:
      return state = { ...state, view_all_request: action.payload };
    
       case  VIEW_ALL_REQUEST_BY_PROJECT:
      return state = { ...state, view_all_request_by_project: action.payload };
      case  VIEW_ALL_REQUEST_BY_ID:
      return state = { ...state, request_by_id: action.payload };
      
    default:
      return state;
  }
}
