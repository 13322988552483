import React, { Component } from "react";
import { connect } from "react-redux";
import Project from '../component/Adminproject';


   import {
  
    add_project,
    bulk_delete_project,
    view_all_project_by_org_id,
    view_all_project_by_comp_id,
    update_project_,
    CheckedProject,
    getallchecked,
    allCheck,
    isCheckedId,
    search_project,
    update_project_profile,
    view_all_project_accessed_to_user
    
    
  } from "../Actions.js"



import {
  close_snack_bar
} from "../../../common/snackbar/action";
import {
  onLogout,
  view_all_status
} from "../../../auth/actions";

export class Controller extends Component {
  render() {
    return (
      <Project {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_project: store.all_project,
    snackbar: store.snackbar,
    login: store.login,
    loader:store.loader
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    add_project:(org_id,project_name,comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, max_hr, per_hr_cost, desc, attachment ) => {
      dispatch(add_project(org_id,project_name,comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, max_hr, per_hr_cost, desc, attachment ))
    },
    view_all_project_by_org_id:(org_id) =>{
      dispatch(view_all_project_by_org_id(org_id))
    },
    view_all_project_accessed_to_user:(user_id) =>{
      dispatch(view_all_project_accessed_to_user(user_id))
    },
    view_all_project_by_comp_id:(comp_id) =>{
      dispatch(view_all_project_by_comp_id(comp_id))
    },
    view_all_status:(org_id) =>{
      dispatch(view_all_status(org_id))
    },
    bulk_delete_project:(project_id) => {
      dispatch(bulk_delete_project(project_id))
    },
    update_project_:(_id, project_name,comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, max_hr, per_hr_cost, desc, attachment ) => {
      dispatch(update_project_(_id, project_name,comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, max_hr, per_hr_cost, desc, attachment ))
    },
    update_project_profile:   (org_id, _id,  comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date,max_hr, per_hr_cost, desc, attachment) => {
      dispatch(update_project_profile(org_id, _id,  comp_id, project_title, project_id, priority_id, status_id, department_id, project_type, start_date, end_date,max_hr, per_hr_cost, desc, attachment))
    },
    onLogout: () => {
      dispatch(onLogout());
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    CheckedProject: (index, checked, quantity, all_category) => {
      dispatch(CheckedProject(index, checked, quantity, all_category));
    },
    getallchecked: ( all_category) => {
      dispatch(getallchecked(all_category));
    },
    allCheck: (checked, quantity, all_category) => {
      dispatch(allCheck(checked, quantity, all_category));
    },
    isCheckedId: (comp_id, all_category,org_id) => {
      dispatch(isCheckedId(comp_id, all_category,org_id) );
    },
    search_project: (all_project, e, type) => {
      dispatch(search_project(all_project, e, type));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);