import React, { Component } from 'react'
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineDown } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import InputAdornment from "@mui/material/InputAdornment";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
// import "../../Component/Header.scss"
import "../../Company/styles/companyphase.scss"
import { Link, useNavigate } from 'react-router-dom';
import { ImCross } from "react-icons/im";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import Button from '@mui/material/Button';
import edit from "../../image/edit.png";
import paperclip from "../../image/paperclip-2.png";
import Calender from "../../image/calendar.png";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
    DialogContent
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
import Avatar from '@mui/material/Avatar';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
// import { color } from 'html2canvas/dist/types/css/types/color';
export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};
class Companyphase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Phaseopen: true,
            changeReqOpen: false,
            problemOpen: false,
            authPerson: false,
            addFormOpen: false,
            addDeleteOpen: false,


            phase_title: "",
            phase_desc: "",
            phase_startdate: "",
            phase_deadline: "",
            phase_status: "Pending",
            phase_priority: "",
            phase_maxhr: "",
            phase_attachment: "",
            change_title: "",
            change_desc: "",
            change_startdate: "",
            change_deadline: "",
            change_status: "",
            change_priority: "",
            change_maxhr: "",
            change_attachment: "",
            change_attachment1: "",
            auth_name: "",
            mobile: "",
            auth_desc: "",
            auth_status: false,
            holdsssss: false,
            all_check: false,
            chk: false,
            isChecked: [],
            phase_id: "",
            editproblem: false,
            editCR: false,
            filter_status: 'N',
            filter_change_request: 'N',
            viewmodal: false,
            editmodal: false,
            phase_attachment1: "",

            request_status: false,
            change_id: "",
            status: "",
            email: "",
            desc: "",
            phone_num: "",
            active_status: 'false',
            edit_show: false,
            edit_show_id: "",
            delete: false,
            authPersondata: { auth_name: "name" },
            btn: false
        }
    }
    handelPhaseopen = () => {
        this.setState({
            Phaseopen: true,
            changeReqOpen: false,
            problemOpen: false,
            authPerson: false
        })

    }
    handelChangeRequestopen = () => {
        this.props.viewAllRequestByProjectAndTypeChange(this.props.all_project.project_id, "change_request");
        this.setState({

            Phaseopen: false,
            changeReqOpen: true,
            problemOpen: false,
            authPerson: false
        })

    }
    handelProblemopen = () => {
        this.props.viewAllRequestByProjectAndTypeChange(this.props.all_project.project_id, "problem");
        this.setState({
            Phaseopen: false,
            changeReqOpen: false,
            problemOpen: true,
            authPerson: false
        })

    }
    handelAuthopen = () => {
        this.setState({
            Phaseopen: false,
            changeReqOpen: false,
            problemOpen: false,
            authPerson: true
        })

    }
    handelOpen = () => {
        this.setState({
            addFormOpen: true,

        })
    }

    handleDeleteOpen = () => {
        this.setState({
            addDeleteOpen: true
        })
    }
    modaloff = () => {
        this.setState({
            addFormOpen: false,

        })
    }
    deleteModalOff = () => {
        this.setState({
            addDeleteOpen: false
        })
    }



    handelOpen1 = () => {
        this.setState({
            editFormOpen: true,

        })
    }



    componentDidMount() {
        // console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii",this.props.login.org_id);
        // this.props.view_all_assets(this.props.login.org_id);
        // console.log(this.props.assets.assets)
        this.props.view_all_status(this.props.login.org_id);
        this.props.view_all_priority(this.props.login.org_id);
        this.props.view_all_phase(this.props.all_project.project_id);
        this.props.viewAllAuthPerson(this.props.all_project.project_id);
        this.props.viewAllCompAuthPerson(this.props.company.company_id);

        console.log(this.props.company.change_request)
        this.props.view_all_department(this.props.login.org_id);
        // this.props.view_all_project_by_comp_id(this.props.company.company_id);
        // console.log(this.props.all_project.all_project);
        // this.props.view_all_assets(this.props.login.org_id);
    }


    CheckedPhase(p, index, phase) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedPhase(index, true, 1, phase)
        }
        else {
            this.props.CheckedPhase(index, false, 0, phase)
        }
    }
    AllCheckedPhase(p, phase) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheck(true, 1, phase)

        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheck(false, 0, phase)
        }

    }
    CheckedChangeRequest(p, index, assets) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedChange(index, true, 1, assets)
        }
        else {
            this.props.CheckedChange(index, false, 0, assets)
        }
    }
    AllCheckedChangeRequest(p, assets) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckChange(true, 1, assets)

        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheckChange(false, 0, assets)
        }

    }



    CheckedAuthPerson(p, index, authoperson) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            this.props.CheckedAuthPerson(index, true, 1, authoperson)
        }
        else {
            this.props.CheckedAuthPerson(index, false, 0, authoperson)
        }
    }
    AllCheckedProjectAuthPerson(p, authoperson) {
        // console.log(p.target.checked);
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheckAuthPerson(true, 1, authoperson)
        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets) 
            this.props.allCheckAuthPerson(false, 0, authoperson)
        }

    }

    render() {
        const items = this.props.login.all_status;
        const { company, all_project, phase, authperson, snackbar, close_snack_bar } = this.props
        return (
            <Grid style={{ marginTop: "80px" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between", marginLeft: "2%", marginRight: "1%" }}>
                    <div style={{ display: "flex", flexDirection: "row", }}>
                        <Link style={{ textDecoration: "none" }} to="/company">
                            <Button style={{ color: "#7A7E86" }}>
                                Company
                            </Button>
                        </Link>
                        <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
                        <Link style={{ textDecoration: "none" }} to="/companyopen">
                            <Button style={{ color: "#7A7E86" }}>
                                Projects
                            </Button>
                        </Link>
                        <ArrowForwardIosIcon style={{ fontSize: "20px", marginTop: "10px" }} />
                        <Link style={{ textDecoration: "none" }} to="/companyphase">
                            <Button style={{ color: "#7A7E86" }}>
                                Phase
                            </Button>
                        </Link>





                    </div>
                    <div>
                        <Paper
                            component="form"
                            style={{ width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}
                        >
                            <IconButton
                                type="button"
                                style={{ p: "150px" }}
                                aria-label="search"
                            >
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                style={{ ml: 5, flex: 1, fontSize: "15px" }}
                                placeholder="Search here"
                                inputProps={{ "aria-label": "Search here" }}

                                onChange={e => {
                                    this.props.search_phase(phase.phase, e.target.value, "search");
                                    this.props.Filter_change_request(company.change_request, e.target.value);
                                    this.props.search_authperson(authperson.authoperson, e.target.value)
                                }}
                            />
                        </Paper>
                    </div>
                </div>
                <div className="companyPhase" >
                    <div className="left" style={{ backgroundColor: 'white' }}>
                        <div className="title">
                            <h2>
                                {all_project.project_title}
                            </h2>

                        </div>
                        <div className="list">
                            <ul>
                                <li style={this.state.Phaseopen ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelPhaseopen}>Phase</li>
                                <li style={this.state.authPerson ? { borderBottom: "2px solid #9e21ff", color: "#9e21ff" } : { listStyle: 'none' }} onClick={this.handelAuthopen}>Authorized Person</li>
                            </ul>
                        </div>
                        {/* Phase */}
                        {this.state.Phaseopen &&

                            <>

                                <div className='header' style={{ display: 'flex', flexDirection: 'row' }}>

                                    <div className="title">

                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.filter_status}
                                            // onChange={handleChange}
                                            onChange={(e) => {
                                                this.props.Filter_phase(this.props.phase.phase, e.target.value)
                                                this.setState({ Filter_phase: e.target.value })
                                            }}
                                            label="Age"
                                            style={{ minWidth: 200 }}
                                        >
                                            <MenuItem value={"N"}>{"Select Status"}</MenuItem>
                                            {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e) => (
                                                <MenuItem value={e.name}>{e.name}</MenuItem>
                                            ))}
                                        </Select>

                                    </div>
                                    {this.props.login.type !== "AuthPerson" && <div className="button" style={{ marginRight: "1%" }}>
                                        <Button className="add-btn" onClick={this.handelOpen}>
                                            Add Phase
                                        </Button>
                                        <Button className="del-btn"
                                            onClick={() => {
                                                this.setState({ delete: true })
                                            }}
                                        >
                                            <MdDeleteOutline className='del-icon' /> Delete
                                        </Button>
                                    </div>}
                                </div>
                                <table className="student-table" style={{ margin: "1%" }} >
                                    <thead>
                                        <tr>
                                            <th>{this.props.login.type !== "AuthPerson" && <Checkbox
                                                checked={this.props.phase.phase.allCheck}
                                                onClick={(p) => {
                                                    {
                                                        this.AllCheckedPhase(p, this.props.phase.phase)
                                                    }
                                                }}
                                            />}</th>
                                            <th> Name</th>
                                            <th>Priority</th>
                                            <th>Status</th>
                                            {/* <th>Progress</th> */}
                                            <th>Start Date</th>
                                            <th>Deadline</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(this.props.phase.search_phase) && this.props.phase.search_phase.map((e, index) => {
                                            return (
                                                <tr hover={true}
                                                    onMouseEnter={() => {
                                                        this.setState({
                                                            edit_show: true,
                                                            edit_show_id: e._id
                                                        })
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            edit_show: false,
                                                            edit_show_id: ""
                                                        })
                                                    }}
                                                    onClick={() => {
                                                        this.props.setPhaseId(e._id);
                                                        this.props.setPhaseName(e.title);
                                                    }}
                                                >
                                                    <td>
                                                        {this.props.login.type !== "AuthPerson" && <Checkbox
                                                            checked={e.checked}
                                                            onClick={(p) => { this.CheckedPhase(p, index, this.props.phase.phase) }}
                                                        />}
                                                    </td>
                                                    <td onClick={() => { this.props.navigate('/Companymilestone') }}>{e.title}</td>

                                                    <td className='high'
                                                        onClick={() => {
                                                            this.props.navigate('/Companymilestone');
                                                            this.props.setPhaseId(e._id);
                                                            this.props.setPhaseName(e.title);
                                                        }}
                                                    >
                                                        <span style={{ padding: "2%" }}></span> <p>
                                                            {e.priority_id}
                                                        </p>
                                                    </td>

                                                    <td >
                                                        {/* <Link to="/Companymilestone"> */}
                                                        <td style={{ textAlign: "center", fontSize: "15px" }} onClick={() => {
                                                            this.props.navigate('/Companymilestone');
                                                            this.props.setPhaseId(e._id);
                                                            this.props.setPhaseName(e.title);
                                                        }}>
                                                            <Button style={{
                                                                backgroundColor: e.status_id === 'Closed' ? 'rgba(255, 84, 84, 0.40)' : e.status_id === 'On Hold' ? 'rgba(252, 255, 84, 0.40)' : e.status_id === 'Open' ? 'rgba(84, 140, 255, 0.40)' : e.status_id === 'Pending' ? 'rgba(255, 84, 217, 0.40)' : 'rgba(82, 183, 73, 0.40)',
                                                                border: e.status_id === 'Closed' ? '0.4px solid #C91E1E' : e.status_id === 'On Hold' ? '0.4px solid #C9B81E' : e.status_id === 'Open' ? '0.4px solid #1E57C9' : e.status_id === 'Pending' ? '0.4px solid #C91EA3' : '0.4px solid #008000',
                                                                color: e.status_id === 'Closed' ? '#C91E1E' : e.status_id === 'On Hold' ? '#C9B81E' : e.status_id === 'Open' ? '#1E57C9' : e.status_id === 'Pending' ? '#C91EA3' : '#008000', borderRadius: "0.8125rem", height: "1.75rem",

                                                                marginLeft: e.status_id === 'Closed' ? '10%' : e.status_id === 'On Hold' ? '10%' : e.status_id === 'Open' ? '10%' : e.status_id === 'Pending' ? '10%' : '8%',
                                                            }}
                                                                variant="outlined" >
                                                                <RxDotFilled />
                                                                {e.status_id}
                                                            </Button>
                                                        </td>
                                                        {/* </Link> */}
                                                    </td>
                                                    <td
                                                        onClick={() => {
                                                            this.props.navigate('/Companymilestone');
                                                            this.props.setPhaseId(e._id);
                                                            this.props.setPhaseName(e.title);
                                                        }}
                                                    >
                                                        {/* <Link to="/Companymilestone"> */}
                                                        {e.start_date}
                                                        {/* </Link> */}
                                                    </td>
                                                    <td
                                                        onClick={() => {
                                                            this.props.navigate('/Companymilestone');
                                                            this.props.setPhaseId(e._id);
                                                            this.props.setPhaseName(e.title);
                                                        }}
                                                    >{e.dead_line}</td>

                                                    {this.props.login.type !== "AuthPerson" && <td style={{ display: "flex", flexDirection: "row" }}>
                                                        <IconButton
                                                            style={{ width: 45, top: 30 }}

                                                            onClick={() => {
                                                                console.log("amhcvghdsv")
                                                                this.setState({
                                                                    viewmodal: !this.state.viewmodal,
                                                                    phase_title: e.title,
                                                                    phase_desc: e.desc,
                                                                    phase_startdate: e.start_date,
                                                                    phase_deadline: e.dead_line,
                                                                    phase_status: e.status_id,
                                                                    phase_priority: e.priority_id,
                                                                    phase_maxhr: e.max_hr,
                                                                    phase_attachment: e.attcahments,
                                                                })
                                                            }}>
                                                            {this.state.edit_show && this.state.edit_show_id === e._id &&
                                                                <VisibilityOutlinedIcon style={{ color: "blue", cursor: 'pointer' }} />}

                                                        </IconButton>
                                                        <IconButton
                                                            style={{ width: 45, top: 30 }}
                                                            onClick={() => {
                                                                console.log("skjhdgsc")
                                                                this.setState({
                                                                    editmodal: true,
                                                                    phase_id: e._id,
                                                                    phase_title: e.title,
                                                                    phase_desc: e.desc,
                                                                    phase_startdate: e.start_date,
                                                                    phase_deadline: e.dead_line,
                                                                    phase_status: e.status_id,
                                                                    phase_priority: e.priority_id,
                                                                    phase_maxhr: e.max_hr,
                                                                    phase_attachment: e.attcahments,
                                                                })
                                                            }}>
                                                            {this.state.edit_show && this.state.edit_show_id === e._id &&
                                                                <img style={{ color: "green", width: 25 }} src={edit} />}

                                                        </IconButton>
                                                        <IconButton
                                                            style={{ width: 45, top: 30 }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    holdsssss: true,


                                                                })
                                                            }}
                                                        >{this.state.edit_show && this.state.edit_show_id === e._id &&
                                                            <HourglassEmptyRoundedIcon style={{ color: "red" }} />} </IconButton>
                                                    </td>}
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                                <Dialog
                                    open={this.state.delete}
                                    onClose={() => {
                                        this.setState({ delete: false });
                                    }}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ width: "100%", height: "100%" }}
                                >


                                    <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                                        <center>
                                            <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                            <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                            <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                                        </center>
                                        <DialogActions style={{ marginTop: "40px" }}>

                                            <Button variant="contained"
                                                style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px", color: "black" }}

                                                onClick={() => { this.setState({ delete: false }) }}>

                                                Cancel
                                            </Button>
                                            <Button variant="contained"
                                                style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                                onClick={() => {
                                                    this.props.isCheckedId(this.props.all_project.project_id, this.props.phase.phase)
                                                    this.setState({ delete: false });
                                                }}

                                            >
                                                Confirm
                                            </Button>
                                        </DialogActions>
                                    </Grid>
                                </Dialog>
                                <Dialog
                                    open={this.state.addFormOpen}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <Grid>
                                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1%" }}>
                                                <Grid container>
                                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Add Phase</Typography></Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => {
                                                            this.setState({ addFormOpen: false });
                                                        }}>
                                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                            <DialogContent style={{ flexDirection: "row" }}>
                                                <Grid container direction={"column"} spacing={2}>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Title<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8} height={40}>

                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    value={this.state.phase_title}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => this.setState({ phase_title: e.target.value })}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Description<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8} height={125}>
                                                                <TextField
                                                                    multiline
                                                                    rows={4}
                                                                    style={{ width: '100%', height: "166.628px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.phase_desc}
                                                                    onChange={(e) => { this.setState({ phase_desc: e.target.value }) }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Start Time<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    type='date'
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.phase_startdate}
                                                                    onChange={(e) => this.setState({ phase_startdate: e.target.value })}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>

                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Dead Line<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    type='date'
                                                                    size='small'
                                                                    value={this.state.phase_deadline}
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => this.setState({ phase_deadline: e.target.value })}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Status<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    disabled={true}
                                                                    defaultValue={"Pending"}
                                                                    select
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    // value={this.state.phase_status}
                                                                    onChange={(e) => this.setState({ phase_status: e.target.value })}
                                                                >
                                                                    {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e, index) => (
                                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>

                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Priority<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    select
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.phase_priority}
                                                                    onChange={(e) => this.setState({ phase_priority: e.target.value })}
                                                                >
                                                                    {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e, index) => (
                                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Maximum Hours<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.phase_maxhr}
                                                                    onChange={(e) => this.setState({ phase_maxhr: e.target.value })}
                                                                >

                                                                </TextField>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Attachment<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80, }} >



                                                                <label htmlFor="file" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}

                                                                >
                                                                    <img src={paperclip} style={{
                                                                        width: "21.338px",
                                                                        height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                    }} />
                                                                    <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                    //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                                    >
                                                                        {this.state.phase_attachment !== "" ? "File Uploaded" : "Attachment"}
                                                                    </Typography>

                                                                </label>

                                                                <input style={{ display: "none" }} type="file" id="file"
                                                                    // onChange={(e) => this.setState({ phase_maxhr: e.target.value })}
                                                                    onChange={(e) => { this.setState({ phase_attachment: e.target.files[0] }) }}
                                                                // disabled={true}
                                                                />

                                                                {/* </Box> */}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => { this.setState({ addFormOpen: false }) }}
                                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        this.props.add_upload_phase(this.props.all_project.project_id, this.state.phase_title, this.state.phase_desc, this.state.phase_startdate, this.state.phase_deadline, this.state.phase_status, this.state.phase_priority, this.state.phase_maxhr, this.state.phase_attachment);
                                                        this.setState({
                                                            phase_title: "",
                                                            phase_desc: "",
                                                            phase_startdate: "",
                                                            phase_deadline: "",
                                                            phase_status: "",
                                                            phase_priority: "",
                                                            phase_maxhr: "",
                                                            addFormOpen: false
                                                        });

                                                    }}
                                                    // onClick={() => { this.setState({ add: false }) }}
                                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Grid>
                                    </Grid>
                                </Dialog >
                                <Dialog
                                    open={this.state.viewmodal}
                                    fullWidth
                                    maxWidth="md"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <Grid>
                                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1%" }}>
                                                <Grid container>
                                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>View Phase</Typography></Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => {
                                                            this.setState({ viewmodal: false });
                                                        }}>
                                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                            <DialogContent style={{ flexDirection: "row" }}>
                                                <Grid container direction={"column"} spacing={2}>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Title<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8} height={40}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    {this.state.phase_title}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Description<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    {this.state.phase_desc}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Start Time<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    {this.state.phase_startdate}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>

                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Dead Line<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    {this.state.phase_deadline}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Status<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    {this.state.phase_status}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>

                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Priority<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    {this.state.phase_priority}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Maximum Hours<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    {this.state.phase_maxhr}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Attachment<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} >
                                                                <a href={this.state.phase_attachment}><Typography>Phase Document</Typography></a>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => { this.setState({ viewmodal: false }) }}
                                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                    Cancel
                                                </Button>

                                            </DialogActions>
                                        </Grid>
                                    </Grid>
                                </Dialog >
                                {/* hold */}
                                <Dialog
                                    open={this.state.holdsssss}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "2%" }}>
                                            <Grid container>
                                                <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}> Hold Phase</Typography></Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => {
                                                        this.setState({ holdsssss: false });
                                                    }}>
                                                        <CloseOutlinedIcon style={{ color: "black" }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>



                                            <Grid container direction={"column"} spacing={2}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={0.5}></Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontSize: 'large' }}>
                                                                Description :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                placeholder='Description'
                                                                size='small'
                                                                fullWidth
                                                                multiline
                                                                onChange={(e) => this.setState({
                                                                    project_desc: e.target.value
                                                                })}
                                                                inputProps={{
                                                                    style: {
                                                                        height: 50,
                                                                    },
                                                                }}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={0.5}></Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => { this.setState({ holdsssss: false }) }}
                                                style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    this.setState({ holdsssss: false, })
                                                }}
                                                style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                Hold Phase
                                            </Button>
                                        </DialogActions>

                                    </Grid>
                                </Dialog >
                                <Dialog
                                    open={this.state.editmodal}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <Grid>
                                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1%" }}>
                                                <Grid container>
                                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Add Phase</Typography></Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => {
                                                            this.setState({
                                                                editmodal: false,
                                                                phase_id: "",
                                                                phase_title: "",
                                                                phase_desc: "",
                                                                phase_startdate: "",
                                                                phase_deadline: "",
                                                                phase_status: "",
                                                                phase_priority: "",
                                                                phase_maxhr: "",
                                                                phase_attachment: "",
                                                                phase_attachment1: "",
                                                            });
                                                        }}>
                                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                            <DialogContent style={{ flexDirection: "row" }}>
                                                <Grid container direction={"column"} spacing={2}>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Title<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8} height={40}>

                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    value={this.state.phase_title}
                                                                    id="outlined-select-currency-native"

                                                                    onChange={(e) => this.setState({ phase_title: e.target.value })}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Description<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8} height={125}>
                                                                <TextField
                                                                    multiline
                                                                    rows={4}
                                                                    style={{ width: '100%', height: "166.628px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.phase_desc}
                                                                    onChange={(e) => { this.setState({ phase_desc: e.target.value }) }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Start Time<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    type='date'
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.phase_startdate}
                                                                    onChange={(e) => this.setState({ phase_startdate: e.target.value })}
                                                                    date
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>

                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Dead Line<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    type='date'
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.phase_deadline}
                                                                    onChange={(e) => this.setState({ phase_deadline: e.target.value })}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Status<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    select
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.phase_status}
                                                                    onChange={(e) => this.setState({ phase_status: e.target.value })}
                                                                >
                                                                    {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e, index) => (
                                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>

                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Priority<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    select
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.phase_priority}
                                                                    onChange={(e) => this.setState({ phase_priority: e.target.value })}
                                                                >
                                                                    {Array.isArray(this.props.login.all_priority) && this.props.login.all_priority.map((e, index) => (
                                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Maximum Hours<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    value={this.state.phase_maxhr}
                                                                    onChange={(e) => this.setState({ phase_maxhr: e.target.value })}
                                                                >
                                                                    {Array.isArray(this.props.login.all_status) && this.props.login.all_status.map((e, index) => (
                                                                        <MenuItem value={e.name}>{e.name}</MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Attachment<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80, }} >



                                                                <label htmlFor="file1" style={{ display: "flex", flexDirection: "row", cursor: 'pointer' }}

                                                                >
                                                                    <img src={paperclip} style={{
                                                                        width: "21.338px",
                                                                        height: "20.3px", marginLeft: "3%", marginTop: "1.5%"
                                                                    }} />
                                                                    <Typography style={{ marginLeft: "2%", fontSize: "18px", marginTop: "1%", color: "rgba(0, 0, 0, 0.40)" }}
                                                                    //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"

                                                                    >
                                                                        {this.state.phase_attachment !== "" ? "File Uploaded" : "Attachment"}
                                                                    </Typography>

                                                                </label>

                                                                <input style={{ display: "none" }} type="file" id="file1"
                                                                    onChange={(e) => { this.setState({ phase_attachment1: e.target.files[0] }) }}
                                                                />

                                                                {/* </Box> */}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            editmodal: false,
                                                            phase_id: "",
                                                            phase_title: "",
                                                            phase_desc: "",
                                                            phase_startdate: "",
                                                            phase_deadline: "",
                                                            phase_status: "",
                                                            phase_priority: "",
                                                            phase_maxhr: "",
                                                            phase_attachment: "",
                                                            phase_attachment1: "",
                                                        })
                                                    }}
                                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        this.props.update_upload_phase(this.props.all_project.project_id, this.state.phase_id, this.state.phase_title, this.state.phase_desc, this.state.phase_startdate, this.state.phase_deadline, this.state.phase_status, this.state.phase_priority, this.state.phase_maxhr, this.state.phase_attachment, this.state.phase_attachment1);
                                                        this.setState({
                                                            phase_id: "",
                                                            phase_title: "",
                                                            phase_desc: "",
                                                            phase_startdate: "",
                                                            phase_deadline: "",
                                                            phase_status: "",
                                                            phase_priority: "",
                                                            phase_maxhr: "",
                                                            phase_attachment: "",
                                                            phase_attachment1: "",
                                                            editmodal: false
                                                        });

                                                    }}
                                                    // onClick={() => { this.setState({ add: false }) }}
                                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Grid>
                                    </Grid>
                                </Dialog >
                            </>
                        }
                        {/* ChangeRequest */}

                        {/* Problem */}

                        {/* Auth */}
                        {this.state.authPerson &&

                            <>

                                <div className="header">
                                    <div className="title">

                                        All Authorized Person
                                    </div>
                                    <div className="button">
                                        <TextField
                                            select
                                            fullWidth
                                            style={{ width: "100%", borderRadius: "7.113px", justifyContent: "center" }}
                                            id="outlined-select-currency-native"
                                            defaultValue={"name"}
                                            // value={this.state.authPersondata.auth_name}
                                            onChange={(e) => this.setState({ authPersondata: e.target.value, btn: true, })}
                                        >
                                            <MenuItem value={"name"}>Add Authorizedperoson</MenuItem>
                                            {Array.isArray(this.props.authperson.compauthoperson) && this.props.authperson.compauthoperson.map((e, index) => (
                                                <MenuItem value={e}>{e.auth_name}</MenuItem>
                                            ))}

                                            <Button onClick={this.handelOpen} style={{ border: " 2px dashed #2B7BFF", marginLeft: '20%' }}>
                                                + Add New Auth
                                            </Button>
                                        </TextField>
                                        {this.state.btn && <Button
                                            variant='outlined'
                                            onClick={() => {
                                                this.setState({ btn: false })
                                                this.props.add_auth_person(this.props.login.org_id, this.props.company.company_id, this.props.all_project.project_id, this.state.authPersondata.auth_name, this.state.phase_id, this.state.authPersondata.email, this.state.authPersondata.mobile, this.state.authPersondata.desc, true);
                                                // this.props.add_auth_person(this.props.login.org_id, this.props.company.company_id, this.props.all_project.project_id, this.state.auth_name, this.state.phase_id, this.state.email, this.state.phone_num, this.state.desc, true);

                                            }}

                                            style={{ marginLeft: 5 }}>
                                            Submit
                                        </Button>}
                                        {/* <button className="add-btn" onClick={this.handelOpen}>
                                            Add
                                        </button> */}
                                        <button className="del-btn" onClick={this.handleDeleteOpen}>
                                            <MdDeleteOutline className='del-icon' /> Delete
                                        </button>
                                    </div>
                                </div>
                                <table className="student-table" >
                                    <thead>
                                        <tr>
                                            <th><Checkbox
                                                checked={this.props.authperson.authoperson.allCheckAuthPerson}
                                                onClick={(p) => {
                                                    {
                                                        this.AllCheckedProjectAuthPerson(p, this.props.authperson.authoperson)
                                                    }
                                                }}
                                            /></th>
                                            <th>Project Name</th>
                                            <th>Created Date</th>
                                            {/* <th>Changed Date</th> */}
                                            <th>Authorized Person</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {/* <tbody>

                                        <tr >
                                            <td>
                                                <Checkbox
                                               
                                                />
                                            </td>
                                            <td>PMS</td>
                                            <td>10/12/1995</td>
                                            <td>Phase-1</td>

                                            <td>
                                                12/12/1998
                                            </td>

                                            <td>

                                                MR.xyz

                                            </td>
                                            <td>
                                                <button className='Change'>
                                                    Changed
                                                </button>
                                            </td>

                                        </tr>

                                    </tbody> */}
                                    <tbody>
                                        {Array.isArray(this.props.authperson.search_auth_person) && this.props.authperson.search_auth_person.map((e, index) => (
                                            <tr >
                                                <td>
                                                    <Checkbox

                                                        checked={e.checked}

                                                        onClick={(p) => { this.CheckedAuthPerson(p, index, this.props.authperson.authoperson) }}
                                                    />
                                                </td>
                                                <td> {all_project.project_title}</td>
                                                <td>{e.date}</td>
                                                {/* <td> */}
                                                {/* 12/12/1998 */}
                                                {/* </td> */}

                                                <td>

                                                    {e.auth_name}

                                                </td>

                                                <td style={{ display: "flex", flexDirection: "row", marginLeft: "26%" }}>
                                                    <Button
                                                        // disabled={!e.active_status}
                                                        className='Change' style={e.active_status ? { backgroundColor: "#74FF67", color: 'black' } : { backgroundColor: "#9E9D9D", color: 'white' }}
                                                        onClick={() => {
                                                            this.props.updateAuthPersonStatus(e._id, !e.active_status, e.project_id)
                                                        }}
                                                    >change</Button>
                                                </td>


                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                                <Dialog
                                    open={this.state.addFormOpen}
                                    fullWidth
                                    maxWidth="lg"
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{

                                        backdropFilter: "blur(5px) sepia(5%)",
                                        // 👇 Another option to style Paper
                                        "& .MuiDialog-paper": {
                                            borderRadius: "17.782px",
                                        },
                                    }}
                                >
                                    <Grid>
                                        <Grid>
                                            <DialogTitle style={{ marginBottom: "1%" }}>
                                                <Grid container>
                                                    <Grid item xs={11} ><Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>Add Authorized Person</Typography></Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => {
                                                            this.setState({ addFormOpen: false });
                                                        }}>
                                                            <CloseOutlinedIcon style={{ color: "black" }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                            <DialogContent style={{ flexDirection: "row" }}>
                                                <Grid container direction={"column"} spacing={2}>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Phone Number<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => this.setState({ phone_num: e.target.value })}
                                                                    date
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1.5}></Grid>

                                                            {/* <Grid item xs={1.5}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Project Phase<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    style={{ width: '223px', height: '2vh', marginTop: "2%", marginLeft: "0.5%" }}
                                                                    id="outlined-select-currency-native"
                                                                    select
                                                                    value={this.state.phase_id}
                                                                    onChange={(e) => {
                                                                        this.setState({ phase_id: e.target.value })

                                                                    }}

                                                                >

                                                                    <MenuItem value={"N"}>{"Select Phase"}</MenuItem>
                                                                    {Array.isArray(this.props.phase.phase) && this.props.phase.phase.map((e) => (
                                                                        <MenuItem value={e._id}>{e.title}</MenuItem>
                                                                    ))}

                                                                </TextField>
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Authorized Name<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => this.setState({ auth_name: e.target.value })}
                                                                    date
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1.5}></Grid>

                                                            <Grid item xs={1.5}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Email<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2.5} height={40}>
                                                                <TextField
                                                                    size='small'
                                                                    style={{ width: "100%", borderRadius: "7.113px" }}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => this.setState({ email: e.target.value })}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={2}>
                                                                <Typography style={{ fontSize: 'large' }}>
                                                                    Description<strong style={{ color: "red" }}>*</strong>:
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={8} height={150}>
                                                                <TextField
                                                                    multiline
                                                                    rows={4}
                                                                    style={{ width: '100%', height: "166.628px" }}
                                                                    id="outlined-select-currency-native"
                                                                    onChange={(e) => { this.setState({ desc: e.target.value }) }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>


                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => { this.setState({ addFormOpen: false }) }}
                                                    style={{ backgroundColor: "#D9D9D9", marginBottom: "2%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        this.props.add_auth_person(this.props.login.org_id, this.props.company.company_id, this.props.all_project.project_id, this.state.auth_name, this.state.phase_id, this.state.email, '91' + this.state.phone_num, this.state.desc, true);
                                                        this.setState({ addFormOpen: false, project_id: "", auth_name: "", phase_id: "", email: "", desc: "", phone_num: "" });

                                                    }}
                                                    // onClick={() => { this.setState({ add: false }) }}
                                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                                    Save
                                                </Button>
                                            </DialogActions>


                                        </Grid>




                                    </Grid>
                                </Dialog >

                                <div className={this.state.addDeleteOpen ? `deletemodalopen` : `deletemodaloff`}>
                                    <div className="upper">
                                        <AiOutlineExclamationCircle className='icon' />
                                        <span>Are you sure ?</span>
                                        <p>You want to Delete</p>

                                    </div>
                                    <div className="lowerBtn">
                                        <button className='cancel-btn' onClick={this.deleteModalOff}>Cancel</button>
                                        <button className='confirm-btn'
                                            onClick={() => {
                                                this.setState({ deleteModalOff: false })
                                                this.props.isCheckedIdAuthPerson(this.props.all_project.project_id, this.props.authperson.authoperson)
                                            }}
                                        >Confirm</button>
                                    </div>
                                </div>

                            </>
                        }
                    </div>
                    <div className="right">
                        <div className="upper">

                            <div className="title">
                                <p>Project Name:</p>
                                <span>
                                    {all_project.project_title}
                                </span>
                            </div>
                            {/* <div className="pid">
                                <p>
                                    Project ID
                                </p>
                                <span>
                                    {all_project.project_id}
                                </span>
                            </div> */}
                            <div className="status">
                                <p>Status</p>
                                <span>
                                    <RxDotFilled />
                                    {all_project.status_id}
                                </span>
                            </div>
                            <div className="priority">
                                <p>

                                    Priority
                                </p>
                                <span>   <RxDotFilled className='dotIcon' />{all_project.priority_id}</span>


                            </div>
                            {/* <div className="Authorizedperoson">
                                <p>Authorized Person</p>
                                <span>
                                    {all_project.auth_person}
                                </span>
                            </div> */}
                            {/* <div className="progress">
                                Progress
                                <div className='progressbar'>

                                    <div className='innerBorder'></div>
                                </div>
                                <span>

                                    5%
                                </span>
                            </div> */}

                        </div>
                        <div className="middel">
                            <div className="title">
                                Phase
                            </div>
                            <div className="task">
                                <div className='first'>
                                    <span>{this.props.phase.phase.filter(item => item.status_id === 'Completed').length}</span>
                                    <p>completed</p>
                                </div>
                                <div className='second'>
                                    <span>{this.props.phase.phase.filter(item => item.status_id === 'Pending').length}</span>
                                    <p>pending</p>
                                </div>
                                <div className='third'>
                                    <span>{this.props.phase.phase.length}</span>
                                    <p>total</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="down">
                            <div className="req">
                                <p>Request:</p>
                                <span>0</span>
                            </div>
                            <div className="req">
                                <p>change Request:</p>
                                <span>0</span>
                            </div>
                            <div className="req">
                                <p>Adds on:</p>
                                <span>0</span>
                            </div>
                        </div> */}

                    </div>
                </div >
                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />
            </Grid>
        )
    }
}
export default withRouter(Companyphase);