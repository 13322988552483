import {

    SERVICE,
    SERVICE_BY_ID,
    SEARCH_SERVICE,
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export function add_upload_service(org_id, service_name, duration, attachment) {
    return dispatch => {
        dispatch(setLoader());
        if (attachment !== "") {
            const storageRef = ref(getStorage(), "/User_Profile/" + attachment + ".png")
            const uploadprofile = uploadBytesResumable(storageRef, attachment);
            uploadprofile.on(
                "state_changed", //"state_changed" event is commonly used to track the progress of an upload
                function (snapshot) {
                },
                function (error) {
                    // console.log(error)
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                },
                function () {
                    getDownloadURL(uploadprofile.snapshot.ref).then(function (attachmnet) {
                        console.log(attachmnet)
                        if (getDownloadURL(uploadprofile.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(add_service(org_id, service_name, duration, attachmnet));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                });
        }
        else {
            dispatch(add_service(org_id, service_name, duration, attachment));
        }

    }
}
export function search_service(service, e) {
    return (dispatch) => {
        console.log(service)
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_SERVICE, payload: service });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = service.filter((el) => {
                console.log(el.service_name)
                return (el.service_name.toLowerCase().includes(e.toLowerCase()));
            })
            // const array = report.fil
            dispatch({ type: SEARCH_SERVICE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}
export function add_service(org_id, service_name, duration, attachment) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_service", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                service_name: service_name,
                renewal_duration: duration,
                attachmnet: attachment

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllService(org_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function viewAllService(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_service", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            // body: JSON.stringify({

            // })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            console.log(responseJson.result)
                            dispatch({ type: SERVICE, payload: responseJson.result });
                        }
                    }

                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: SERVICE, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_service_by_id(org_id, service_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_service_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                service_id: service_id
            })

        })
        .then((response) => response.json())
        .then((responseJson) => {

            if (responseJson.status) {
                dispatch({ type: SERVICE_BY_ID, payload: responseJson.result });
                dispatch(set_snack_bar(responseJson.status, "Some message"));
                dispatch(unsetLoader());
            } else {                
                dispatch({ type: SERVICE_BY_ID , payload: [] })
               
            }
            dispatch(unsetLoader());
        })
        .catch((error) => {
            console.error(error);
        });

    };
}
export function update_service_profile(org_id, _id, service_name, renewal_duration, attachmnet, attachmnet_old) {
    return dispatch => {
        //   console.log(Emp_Id)
        dispatch(setLoader(true));
        if (attachmnet == null) {
            dispatch(update_service(org_id, _id, service_name, renewal_duration, attachmnet, attachmnet_old));

        } else {
            const storageRef = ref(getStorage(), "/Profile/" + _id + ".png")

            // .put(image)
            // uploadBytes(storageRef, image).then((snapshot) => {
            //     console.log('Uploaded a blob or file!');
            //   });
            const uploadTask = uploadBytesResumable(storageRef, attachmnet);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic) {
                        // console.log(downloadURL)
                        dispatch(update_service(org_id, _id, service_name, renewal_duration, profile_pic));
                    });
                }
            );
        }
    }
}
export function update_service(org_id, _id, service_name, renewal_duration, attachmnet) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_service", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
                service_name: service_name,
                renewal_duration: renewal_duration,
                attachmnet: attachmnet
            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllService(org_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}
export function deleteService(org_id, service_id) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "delete_service_by_id", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({

                service_id: service_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllService(org_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());

                console.error(error);
            });
    };
}
export function bulk_delete_service(org_id, isChecked) {
    return (dispatch) => {
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_service", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({

                service_id: isChecked
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllService(org_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());

                console.error(error);
            });
    };
}



export function CheckedService(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheck=false
    return {
        type: SERVICE,
        payload: payload
    }
};
export function getallchecked( all_category) {
    for(var i =0;i<=all_category.length -1;i++){
        all_category[i].checked= true;
    }
    return {
        type: SERVICE,
        
    }
};
export function allCheck(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: SERVICE,
                payload: payload
            }
    }
};
export function isCheckedId(org_id, all_category) {
    var count=0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= all_category.length - 1; i++) {
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
            }
            count=i;
        }
         if (count === all_category.length - 1) {
            dispatch(bulk_delete_service(org_id, isChecked))
        }
    }

};












