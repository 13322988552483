import {

    VIEW_ALL_MILESTONE_MEMBERS,
    VIEW_ALL_MILESTONE_MEMBERS_BY_ID,
    SEARCH_MILESTONE_MEMBERS
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";




export function add_milestone_member(milestone_id, emp_id) {
    return (dispatch) => {
        // console.log("milestonememberss",milestone_id,emp_id, start_date, end_date, desc)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_milestone_members", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                milestone_id: milestone_id
            },
            body: JSON.stringify({
                emp_id: emp_id,


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_milestone_members(milestone_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



export function view_all_milestone_members(milestone_id) {
    return (dispatch) => {
      
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_milestone_members", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                milestone_id: milestone_id
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)

                    dispatch({ type: VIEW_ALL_MILESTONE_MEMBERS, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_MILESTONE_MEMBERS, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function bulk_delete_milestone_members(milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "bulk_delete_milestone_members", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                milestone_id: milestone_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(view_all_milestone_members(milestone_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function view_milestone_members_by_id(milestone_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_milestone_members_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                milestone_id: milestone_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)

                    dispatch({ type: VIEW_ALL_MILESTONE_MEMBERS_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: VIEW_ALL_MILESTONE_MEMBERS_BY_ID, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function update_milestone_members(_id,milestone_id, emp_id, start_date, end_date, desc) {
    return (dispatch) => {
        console.log("updatemilemembersssss",_id,milestone_id, emp_id, start_date, end_date, desc)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_milestone_members", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: _id,
                emp_id: emp_id,
                start_date: start_date,
                end_date: end_date,
                desc: desc


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_milestone_members(milestone_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function search_Milestone_Members(milestone_members, e) {
    return (dispatch) => {
        if (e === "" && "N") {
            dispatch({ type: SEARCH_MILESTONE_MEMBERS, payload: milestone_members });
        }
        else {
            const newArray = milestone_members.filter((el) => {
                return (el.start_date.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_MILESTONE_MEMBERS, payload: newArray })
        }
    };
}