import React, { Component } from 'react'
import IconButton from '@mui/material/IconButton';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import {
    Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography
} from '@mui/material';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import edit from "../../image/edit.png";
import Button from '@mui/material/Button';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "../styles/Employee.css";
import Avatar from '@mui/material/Avatar';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Skeleton from '@mui/material/Skeleton';
import Search from "../../image/vuesax-outline-search-normal.png";
import Trash from "../../image/trash.png";
import {
    DialogContent
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default class Employee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editemployee: false,
            // add: true,
            addFormOpen: false,
            done: false,
            cancel: false,
            profile_pic: '',
            profile_pic2: '',
            first_name: '',
            last_name: '',
            emp_id: '',
            position: '',
            joining_date: '',
            mobile: '',
            email_id: '',
            org_id: '',
            _id: '',
            addemp: false,
            profile_pic3: '',
            style: { display: 'none' },
            filter_status: 'All Employee',
            access: "",
        }
    }

    handleMouseEnter = () => {
        this.setState({ style: { display: 'block' } });
    };

    handleMouseLeave = () => {
        this.setState({ style: { display: 'none' } });
    };
    handleOptionChange = (event) => {
        this.setState({
            selectedOption: event.target.value
        });
    }
    handelOpen = () => {
        this.setState({
            addFormOpen: true,

        })
    }
    modaloff = () => {
        this.setState({
            addFormOpen: false,

        })
    }
    CheckedEmployee(p, index, employee) {
        if (p.target.checked) {
            this.props.CheckedEmployee(index, true, 1, employee)
        }
        else {
            this.props.CheckedEmployee(index, false, 0, employee)
        }
    }
    AllCheckedEmployee(p, employee) {
        if (p.target.checked) {
            this.props.allCheck(true, 1, employee)
        }
        else {
            this.props.allCheck(false, 0, employee)
        }
    }
    componentDidMount() {
        document.body.style.backgroundColor = "#f5edff";
        this.props.view_all_employee(this.props.login.org_id);
        console.log("viewemp", this.props.login.org_id)
        this.props.view_all_department(this.props.login.org_id);
    }
    render() {
        const { employee } = this.props
        if (this.props.loader.open) {
            return (
                <Grid>
                    <div style={{ width: "98%", marginTop: "85px", }}>
                        <Paper component="form" style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none" }}>
                            <Skeleton variant="rectangular" width={"367px"} height={"43px"} animation="wave" />
                        </Paper>
                    </div>
                    <CardContent style={{ marginLeft: "1.5%", marginRight: "1.5%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                <Skeleton variant="rectangular" width={150} height={40} animation="wave" style={{ margin: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginRight: "3%", marginLeft: "1%", }} />
                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                        <TableCell>
                                            <Skeleton variant="rectangular" animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation="pulse" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Grid>
            )
        }
        else
            return (
                <Grid>
                    <div style={{ marginRight: "1%", marginTop: "80px", }}>
                        <Paper component="form" style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}>
                            <IconButton
                                type="button"
                                style={{ p: "15px" }}
                                aria-label="search"
                            >
                                <img src={Search} />
                            </IconButton>
                            <InputBase
                                style={{ ml: 5, flex: 1, fontSize: "16px", fontFamily: "Montserrat", color: "#8C8C8C", fontWeight: "300" }}
                                placeholder="Search here"
                                inputProps={{ "aria-label": "Search here" }}
                                onChange={(e) => this.props.search_employee(employee.employee, e.target.value)}
                            />
                        </Paper>

                    </div>


                    <CardContent style={{ marginLeft: "1.5%", marginRight: "1.5%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row", marginBottom: "20px" }}>

                                <Typography style={{ marginLeft: "2%", marginTop: "1%", fontSize: "27px", fontWeight: "600" }}>
                                    {this.state.filter_status}
                                </Typography>
                                <FormControl variant="standard" sx={{ marginTop: "1.5%", border: "none" }}>
                                    <Select

                                        size='small'
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard select"
                                        disableUnderline
                                        value={""}
                                        onChange={(e) => {
                                            this.props.filter_employee(this.props.employee.employee, e.target.value)
                                            this.setState({ filter_status: e.target.value })
                                        }}
                                        label="Age"
                                        style={{ minWidth: 0 }}
                                        IconComponent={KeyboardArrowDownIcon}


                                    >
                                        <MenuItem value={"All Employee"}>{"All Employee"}</MenuItem>
                                        {Array.isArray(this.props.login.all_department) && this.props.login.all_department.map((e) => (
                                            <MenuItem value={e.name}>{e.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button

                                    onClick={() => {
                                        this.setState({
                                            addemp: true,
                                        })
                                    }}

                                    style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "1.5%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined"> Add Employee </Button>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            delete: true,

                                        })

                                    }}
                                    style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "1.5%", marginRight: "3%", marginLeft: "1%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined" startIcon={<img src={Trash} style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                    Delete
                                </Button>
                            </div>
                            <Table style={{ minWidth: 600 }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                        <TableCell style={{ fontSize: "16px", width: "5%" }}><Checkbox
                                            checked={this.props.employee.employee?.allCheck}
                                            onClick={(p) => {
                                                {
                                                    this.AllCheckedEmployee(p, this.props.employee.employee)
                                                }
                                            }}
                                        /></TableCell>
                                        <TableCell style={{ fontSize: "13px", textAlign: "center", }}>Employee Name</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Employee ID</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Position</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Email ID</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Phone Number</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}>Joining Date</TableCell>
                                        <TableCell style={{ textAlign: "center", fontSize: "13px" }}></TableCell>
                                    </TableRow>
                                </TableHead>

                                {Array.isArray(this.props.employee.search_employee) && this.props.employee.search_employee.map((item, index) => {
                                    return (
                                        <TableRow hover={true}
                                            onMouseEnter={() => {
                                                this.setState({
                                                    edit_show: true,
                                                    edit_show_id: item._id
                                                })
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({
                                                    edit_show: false,
                                                    edit_show_id: ""
                                                })
                                            }}
                                        >
                                            <TableCell style={{ fontSize: "13px" }}>
                                                <Checkbox
                                                    checked={item.checked}
                                                    onClick={(p) => { this.CheckedEmployee(p, index, this.props.employee.employee) }}
                                                /></TableCell>
                                            <TableCell style={{ justifyContent: "center", fontSize: "13px" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Avatar
                                                        alt={item.first_name} src={item.profile_pic}
                                                        style={{ width: 30, height: 30, right: 10 }} >
                                                        {item.first_name == null ? item.name?.substring(0, 1).toUpperCase() : item.first_name?.substring(0, 1).toUpperCase()}
                                                    </Avatar>
                                                    {item.first_name ? item.first_name : item.name} {item.last_name}
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.emp_id}</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.department}</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px", color: '#548CFF' }}>{item.email_id}</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.mobile} </TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }}>{item.joining_date}</TableCell>
                                            <TableCell style={{ textAlign: "center", fontSize: "13px" }} >
                                                <Button onClick={() => {
                                                    this.setState({
                                                        editemployee: true,
                                                        first_name: item.first_name ? item.first_name : item.name?.split(" ")[0],
                                                        last_name: item.last_name ? item.last_name : item.name?.split(" ")[1],
                                                        emp_id: item.emp_id,
                                                        position: item.position,
                                                        joining_date: item.joining_date,
                                                        mobile: item.mobile,
                                                        profile_pic2: item.profile_pic,
                                                        email_id: item.email_id,
                                                        _id: item._id,
                                                        access: item.type

                                                    })
                                                }}
                                                >{this.state.edit_show && this.state.edit_show_id === item._id &&
                                                    <img style={{ width: 25, color: "rgba(0, 0, 0, 0.4)" }} src={edit} />} </Button>
                                            </TableCell>
                                        </TableRow>

                                    )
                                })}
                            </Table>
                        </TableContainer>

                    </CardContent>

                    {/* add modal start */}
                    <Dialog
                        open={this.state.addemp}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1.5%" }}>
                                <Grid container>
                                    <Grid item xs={11} >
                                        <Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>
                                            Add Employee
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ addemp: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black", display: "flex", justifyContent: "right" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent style={{ flexDirection: "row" }}>
                                <Grid container direction={"row"} spacing={2} style={{ marginTop: "10px" }}>
                                    <Grid item xs={2}>
                                        <Grid container direction={"column"} spacing={2}>
                                            <Grid >
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    <div>
                                                        <Avatar
                                                            alt="Profile"
                                                            src={this.state.profile_pic !== "" ? URL.createObjectURL(this.state.profile_pic) : this.state.profile_pic}
                                                            variant={"circular"}
                                                            style={{ width: "150px", height: "150px", borderRadius: "50%", border: "10px solid #7900FFCC" }}
                                                        />
                                                    </div>
                                                    <div >
                                                        <Button variant="contained" style={{ background: "#7110DCCC", marginTop: "3%" }}  >
                                                            <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                                <AddPhotoAlternateIcon style={{ marginLeft: "10%", }} />
                                                                <Typography style={{ fontSize: "13px", width: "100px" }}>
                                                                    Upload Image
                                                                </Typography>
                                                            </label>
                                                            <input style={{ display: "none" }} type="file" id="file"
                                                                onChange={(e) => { this.setState({ profile_pic: e.target.files[0] }) }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={10}>
                                        <Grid container direction={"column"} spacing={2}>
                                            <Grid item>
                                                <Grid container direction={"row"} spacing={2}>
                                                    <Grid item xs={0.5}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            First Name<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            onChange={(e) => { this.setState({ first_name: e.target.value }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Last Name<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            onChange={(e) => { this.setState({ last_name: e.target.value }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={0.5}></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item mt={2}>
                                                <Grid container direction={"row"} spacing={2}>
                                                    <Grid item xs={0.5}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Employee ID<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            onChange={(e) => { this.setState({ emp_id: e.target.value }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Phone No<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            onChange={(e) => { this.setState({ mobile: e.target.value }) }}
                                                            onInput={(e) => {
                                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={0.5}></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction={"row"} spacing={2}>
                                                    <Grid item xs={0.5}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Email ID<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            type='email'
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            onChange={(e) => { this.setState({ email_id: e.target.value }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Position<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            select
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            value={this.state.project_id}
                                                            onChange={(e) => { this.setState({ position: e.target.value }) }}
                                                        >
                                                            {Array.isArray(this.props.login.all_department) && this.props.login.all_department.map((e) => (
                                                                <MenuItem value={e._id}>{e.name}</MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={0.5}></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction={"row"} spacing={2}>
                                                    <Grid item xs={0.5}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Joining Date<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            type="date"
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            onChange={(e) => { this.setState({ joining_date: e.target.value }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Access<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            select
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            value={this.state.access}
                                                            onChange={(e) => { this.setState({ access: e.target.value }) }}
                                                        >
                                                            <MenuItem value={"Owner"}>Owner</MenuItem>
                                                            <MenuItem value={"Executive"}>Executive</MenuItem>
                                                            <MenuItem value={"TeamLead"}>Team Lead</MenuItem>
                                                            <MenuItem value={"Manager"}>Manager</MenuItem>
                                                            <MenuItem value={"Consultant"}>Consultant</MenuItem>
                                                            <MenuItem value={"HumanResource"}>Human Resource</MenuItem>
                                                            <MenuItem value={"Finance"}>Finance</MenuItem>
                                                            <MenuItem value={"Support"}>Support</MenuItem>
                                                            <MenuItem value={"QA"}>QA</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={0.5}></Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item>
                                                <Grid container direction={"row"} spacing={2}>
                                                    <Grid item xs={0.5}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Joining Date<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            type="date"
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            onChange={(e) => { this.setState({ joining_date: e.target.value }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>
                                                </Grid>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            addemp: false,
                                        })
                                    }}
                                    style={{ backgroundColor: "#FFFFFF", marginBottom: "2%", width: "148px", color: "#7900FF", height: "44px", border: "1px solid #7900FF", borderRadius: "5px" }} variant="contained" >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={
                                        this.state.first_name === '' ||
                                        this.state.last_name === '' ||
                                        this.state.emp_id === '' ||
                                        this.state.position === '' ||
                                        this.state.joining_date === '' ||
                                        this.state.mobile === '' ||
                                        this.state.email_id === '' || this.state.access === ""}
                                    onClick={() => {
                                        this.props.add_upload_employee(this.props.login.org_id, this.state.profile_pic, this.state.first_name, this.state.last_name, this.state.emp_id, this.state.position, this.state.joining_date, this.state.mobile, this.state.email_id, this.state.access);
                                        this.setState({
                                            addemp: false,
                                            profile_pic: '',
                                            profile_pic2: '',
                                            first_name: '',
                                            last_name: '',
                                            emp_id: '',
                                            position: '',
                                            joining_date: '',
                                            mobile: '',
                                            email_id: '',
                                            access: ""
                                        });

                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Done
                                </Button>
                            </DialogActions>
                        </Grid>

                    </Dialog >
                    {/* add modal ends */}


                    {/* edit modal start */}
                    <Dialog
                        open={this.state.editemployee}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{

                            backdropFilter: "blur(5px) sepia(5%)",
                            // 👇 Another option to style Paper
                            "& .MuiDialog-paper": {
                                borderRadius: "17.782px",
                            },
                        }}
                    >
                        <Grid>
                            <DialogTitle style={{ borderBottom: "2px solid #BEBEBE", marginBottom: "1.5%" }}>
                                <Grid container>
                                    <Grid item xs={11} >
                                        <Typography style={{ color: "#000", fontFamily: "Montserrat", fontSize: "20px", fontStyle: "normal", fontWeight: "500" }}>
                                            Edit Employee
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => {
                                            this.setState({ editemployee: false });
                                        }}>
                                            <CloseOutlinedIcon style={{ color: "black", display: "flex", justifyContent: "right" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent style={{ flexDirection: "row" }}>
                                <Grid container direction={"row"} spacing={2} style={{ marginTop: "10px" }}>
                                    <Grid item xs={2}>
                                        <Grid container direction={"column"} spacing={2}>
                                            <Grid >
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    <div>
                                                        <Avatar
                                                            alt="Profile"
                                                            src={this.state.profile_pic3 !== "" ? URL.createObjectURL(this.state.profile_pic3) : this.state.profile_pic2}
                                                            variant={"circular"}
                                                            style={{ width: "150px", height: "150px", borderRadius: "50%", border: "10px solid #7900FFCC" }}
                                                        />
                                                    </div>
                                                    <div >
                                                        <Button variant="contained" style={{ background: "#7110DCCC", marginTop: "5%" }}  >
                                                            <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                                                <AddPhotoAlternateIcon style={{ marginLeft: "10%", }} />
                                                                <Typography style={{ fontSize: "13px", width: "100px", color: "white" }}>
                                                                    Edit Image
                                                                </Typography>
                                                            </label>
                                                            <input style={{ display: "none" }} type="file" id="file"
                                                                onChange={(e) => { this.setState({ profile_pic3: e.target.files[0] }) }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={10}>
                                        <Grid container direction={"column"} spacing={2}>
                                            <Grid item>
                                                <Grid container direction={"row"} spacing={2}>
                                                    <Grid item xs={0.5}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Frist Name<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            value={this.state.first_name}
                                                            onChange={(e) => { this.setState({ first_name: e.target.value }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Last Name<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            value={this.state.last_name}
                                                            onChange={(e) => { this.setState({ last_name: e.target.value }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={0.5}></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction={"row"} spacing={2}>
                                                    <Grid item xs={0.5}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Employee ID<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            value={this.state.emp_id}
                                                            onChange={(e) => { this.setState({ emp_id: e.target.value }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Phone No<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            type='number'
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            value={this.state.mobile}
                                                            onChange={(e) => { this.setState({ mobile: e.target.value }) }}

                                                        />
                                                    </Grid>
                                                    <Grid item xs={0.5}></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction={"row"} spacing={2}>
                                                    <Grid item xs={0.5}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Email ID<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            value={this.state.email_id}
                                                            onChange={(e) => { this.setState({ email_id: e.target.value }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Position<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            select
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            value={this.state.position}
                                                            onChange={(e) => { this.setState({ position: e.target.value }) }}
                                                        >
                                                            {Array.isArray(this.props.login.all_department) && this.props.login.all_department.map((e) => (
                                                                <MenuItem value={e._id}>{e.name}</MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={0.5}></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction={"row"} spacing={2}>
                                                    <Grid item xs={0.5}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Joining Date<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            type="date"
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            value={this.state.joining_date}
                                                            onChange={(e) => { this.setState({ joining_date: e.target.value }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{ fontSize: 'large' }}>
                                                            Access<strong style={{ color: "red" }}>*</strong>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} height={40}>
                                                        <TextField
                                                            select
                                                            size='small'
                                                            style={{ width: "100%", borderRadius: "7.113px" }}
                                                            id="outlined-select-currency-native"
                                                            value={this.state.access}
                                                            onChange={(e) => { this.setState({ access: e.target.value }) }}
                                                        >
                                                            <MenuItem value={"Owner"}>Owner</MenuItem>
                                                            <MenuItem value={"Executive"}>Executive</MenuItem>
                                                            <MenuItem value={"TeamLead"}>Team Lead</MenuItem>
                                                            <MenuItem value={"Manager"}>Manager</MenuItem>
                                                            <MenuItem value={"Consultant"}>Consultant</MenuItem>
                                                            <MenuItem value={"HumanResource"}>Human Resource</MenuItem>
                                                            <MenuItem value={"Finance"}>Finance</MenuItem>
                                                            <MenuItem value={"Support"}>Support</MenuItem>
                                                            <MenuItem value={"QA"}>QA</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            editemployee: false,
                                        })
                                    }}
                                    style={{ backgroundColor: "#FFFFFF", marginBottom: "2%", width: "148px", color: "#7900FF", height: "44px", border: "1px solid #7900FF", borderRadius: "5px" }} variant="contained" >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.props.update_emp_profile(this.props.login.org_id, this.state._id, this.state.profile_pic2, this.state.profile_pic3, this.state.first_name, this.state.last_name, this.state.emp_id, this.state.position, this.state.joining_date, this.state.mobile, this.state.email_id, this.state.access);
                                        // console.log("edit",this.props.login.org_id, this.state._id, this.state.profile_pic, this.state.profile_pic3, this.state.first_name, this.state.last_name, this.state.emp_id, this.state.position, this.state.joining_date, this.state.mobile, this.state.email_id)
                                        this.setState({
                                            editemployee: false,
                                            profile_pic2: "",
                                            profile_pic3: "",
                                        });
                                    }}
                                    style={{ backgroundColor: "#7900FF", marginBottom: "2%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                    Done
                                </Button>
                            </DialogActions>
                        </Grid>

                    </Dialog>
                    {/* edit modal end */}

                    {/* delete modal start */}
                    <Dialog
                        open={this.state.delete}
                        onClose={() => {
                            this.setState({ delete: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ width: "100%", height: "100%", }}
                    >

                        <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>
                            <center>
                                <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                <Typography style={{ fontSize: "18px" }}>You want to delete ?</Typography >
                            </center>
                            <DialogActions style={{ marginTop: "40px" }}>
                                <Button variant="contained"
                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", color: "black", width: "172px" }}
                                    onClick={() => { this.setState({ delete: false }) }}>
                                    Cancel
                                </Button>
                                <Button variant="contained"
                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px", }}
                                    onClick={() => {
                                        this.props.isCheckedId(this.props.login.org_id, this.props.employee.employee)
                                        this.setState({ delete: false });
                                    }}>Confirm
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Dialog>
                    {/* delete modal start */}
                </Grid >

            )
    }
}
