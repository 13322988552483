export const  VIEW_ALL_COMPANY=" VIEW_ALL_COMPANY";
export const  VIEW_COMPANY_BY_ID=" VIEW_COMPANY_BY_ID";
export const VIEW_ALL_COMPANY_ASSETS="VIEW_ALL_COMPANY_ASSETS";
export const VIEW_ALL_COMPANY_SERVICE="VIEW_ALL_COMPANY_SERVICE";
export const VIEW_COMPANY_SERVICE_BY_ID="VIEW_COMPANY_SERVICE_BY_ID";
export const VIEW_ALL_COMPANY_ASETS_BY_ID="VIEW_ALL_COMPANY_ASETS_BY_ID";
export const VIEW_ALL_CHANGE_REQUEST="VIEW_ALL_CHANGE_REQUEST";
export const SET_PHASE_ID="SET_PHASE_ID";


export const SET_NAME="SET_NAME";
export const SET_OWNER_NAME="SET_OWNER_NAME";
export const SET_CCONTACT_NO="SET_CCONTACT_NO";
export const SET_COMPANY_SHORT_ID="SET_COMPANY_SHORT_ID";
export const SET_EMAIL="SET_EMAIL";
export const SET_GST_NUM="SET_GST_NUM";
export const SET_ADDRESS="SET_ADDRESS";

export const SET_COMP_ID="SET_COMP_ID";
export const SET_PURCHASE_DATE="SET_PURCHASE_DATE";
export const SET_EXP_DATE="SET_EXP_DATE";
export const SET_DESC="SET_DESC";
export const SET_RELATED_TO="SET_RELATED_TO";
export const SET_COMPANY_ASSESTS="SET_COMPANY_ASSESTS";
export const SET_COMP_SERVICE_ID="SET_COMP_SERVICE_ID";

export const SET_COMP_SERVICE_NAME="SET_COMP_SERVICE_NAME";

export const SET_FIX_AMOUNT="SET_FIX_AMOUNT";
export const SEARCH_COMPANY="SEARCH_COMPANY";


export const SET_COMPANY_ID="SET_COMPANY_ID";


export const VIEW_ALL_ASSETS_BY_COMPANY_ID="VIEW_ALL_ASSETS_BY_COMPANY_ID";
export const SEARCH_COMPANY_ASSETS="SEARCH_COMPANY_ASSETS";


export const SEARCH_COMPANY_SERVICE="SEARCH_COMPANY_SERVICE";
export const SEARCH_PROJECT_PHASE="SEARCH_PROJECT_PHASE";
export const SEARCH_CHANGE_REQUEST="SEARCH_CHANGE_REQUEST";
export const SET_PHASE_NAME="SET_PHASE_NAME";

export const VIEW_ALL_TASK="VIEW_ALL_TASK";

export const SET_COMPANY_NAME="SET_COMPANY_NAME";

export const SET_COMPANY_EMAIL="SET_COMPANY_EMAIL";

export const SET_CONTACT_NUMBER="SET_CONTACT_NUMBER";
export const VIEW_ALL_REQIREMENT="VIEW_ALL_REQIREMENT";







