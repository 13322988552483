import {

    TICKETS,
    SEARCH_TICKET,
    VIEW_ALL_REQUEST_BY_PROJECT,
    REQUEST,
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import {update_employee} from '../Employee/action'
import 'firebase/storage';

import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export function searchTicket(ticket, e) {
    return (dispatch) => {
        // console.log(employee)
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_TICKET, payload: ticket });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = ticket.filter((el) => {
                // console.log(el.first_name)
                return (el.ticketshort_Id.toLowerCase().includes(e.toLowerCase()));
            })
            // const array = report.fil
            dispatch({ type: SEARCH_TICKET, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}
export function searchTicketDate(ticket, e) {
    return (dispatch) => {
        // console.log(employee)
        // dispatch(setLoader());
        if (e === "") {
            dispatch({ type: SEARCH_TICKET, payload: ticket });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = ticket.filter((el) => {
                // console.log(el.first_name)
                return (el.ticket_date.toLowerCase().includes(e.toLowerCase()));
            })
            // const array = report.fil
            dispatch({ type: SEARCH_TICKET, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

export function filterTicket(ticket, e) {
    return (dispatch) => {
        // console.log(employee)
        // dispatch(setLoader());
        if (e === "All Tickets") {
            dispatch({ type: SEARCH_TICKET, payload: ticket });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = ticket.filter((el) => {
                // console.log(el.first_name)
                return (el.comp_details.name.toLowerCase().includes(e.toLowerCase()));
            })
            // const array = report.fil
            dispatch({ type: SEARCH_TICKET, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

export function addTicket(org_id, ticketshort_Id, ticket_date, project_id, phase_id, start_date, resolve_date, request, type, auth_person) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_ticket", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                ticketshort_Id: ticketshort_Id,
                ticket_date: ticket_date,
                project_id: project_id,
                phase_id: phase_id,
                start_date: start_date,
                resolve_date: resolve_date,
                request: request,
                auth_person:auth_person,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    if (type === 'c') {
                        dispatch(view_company_tickets(org_id,project_id))
                        
                    }
                    else{
                        dispatch(viewAllTickets(org_id));
                    }

                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllTickets(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_ticket", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            // body: JSON.stringify({

            // })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    console.log(responseJson)
                    for (var i = 0; i <= responseJson.result.length - 1; i++) {
                        responseJson.result[i].checked = false;
                        responseJson.result[i].quantity = 0;
                        responseJson.result.allCheck = false;
                        if (i === responseJson.result.length - 1) {
                            dispatch({ type: TICKETS, payload: responseJson.result });
                        }
                    }
                    // dispatch({ type: TICKETS, payload: responseJson.result });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    console.log(responseJson)
                    dispatch({ type: TICKETS, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_company_tickets(org_id, company_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_company_tickets", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
                company_id: company_id
            },
            // body: JSON.stringify({

            // })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    console.log(responseJson)

                    dispatch({ type: TICKETS, payload: responseJson.result });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                    dispatch({ type: TICKETS, payload: [] })

                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
{/*------------------------------------------------------------- UPDATE Status ------------------------------------------------------------*/ }
export function updateTicketStatus(org_id, comp_id, ticket_id, status, type, auth_person, ticketshort_Id, company_shortid) {
    return (dispatch) => {
        dispatch(setLoader());
        console.log("update", org_id, comp_id, ticket_id, status, type, auth_person, ticketshort_Id, company_shortid)
        return fetch(UNIVERSAL.BASEURL + "update_ticket_status", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                _id: ticket_id,
                status: status,
                auth_person: auth_person,
                ticketshort_Id: ticketshort_Id,
                company_shortid: company_shortid
            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    if (type === "c") {
                        dispatch(view_company_tickets(comp_id))
                    }
                    else if (type=== "d") {
                        // console.log("Soumya")
                        dispatch(viewAllTickets(org_id));
                    }
                    dispatch(viewAllTickets(org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    // dispatch(unsetLoader());
                } else {

                }
                // dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}

{/*------------------------------------------------------------- UPDATE ------------------------------------------------------------*/ }

export function updateTicket(org_id, ticket_id, ticketshort_Id,
    ticket_date, project_id, phase_id, start_date, resolve_date, note_desc) {
    return (dispatch) => {
        dispatch(setLoader());
        console.log("update", ticket_id)
        return fetch(UNIVERSAL.BASEURL + "update_ticket", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                _id: ticket_id,
                ticketshort_Id: ticketshort_Id,
                ticket_date: ticket_date,
                project_id: project_id,
                phase_id: phase_id,
                start_date: start_date,
                resolve_date: resolve_date,
                note_desc: note_desc
            })

        })

            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTickets(org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });

    };
}
{/*------------------------------------------------------------- DELETE ------------------------------------------------------------*/ }

export function bulkDeleteTicket(org_id, isChecked) {
    return (dispatch) => {
        console.log("aaaaaaaaaaaaa", isChecked)
        dispatch(setLoader());

        return fetch(UNIVERSAL.BASEURL + "bulk_delete_ticket", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({

                ticket_id: isChecked
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllTickets(org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());

                console.error(error);
            });
    };
}

export function CheckedTicket(index, checked, quantity, all_category) {
    var payload = all_category;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheck = false
    // console.log(payload)
    return {
        type: TICKETS,
        payload: payload
    }
};
export function getallchecked(all_category) {
    for (var i = 0; i <= all_category.length - 1; i++) {
        all_category[i].checked = true;
    }
    return {
        type: TICKETS,
        // payload: payload

    }
};

export function allCheck(checked, quantity, all_category) {
    var payload = all_category;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: TICKETS,
                payload: payload
            }
    }
};

export function isCheckedId(org_id, all_category) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        // console.log(all_category)

        // var payload = all_category;
        for (var i = 0; i <= all_category.length - 1; i++) {
            // console.log("asdgtfsdt",i)
            if (all_category[i].checked === true) {
                isChecked.push(all_category[i]._id)
                // console.log(all_category[i]._id)
            }
            count = i;
        }
        if (count === all_category.length - 1) {
            // console.log("asdgtfsdt",count)
            // console.log("hiiiiiiiiiiiiiiiiiiiii", isChecked)
            dispatch(bulkDeleteTicket(org_id, isChecked))
        }
    }

};

{/*  --------------------------------------------------------- Request ---------------------------------------------------------------- */ }

export function request(new_request) {
    // var request = [];
    // request.push(new_request)

    return {
        type: REQUEST,
        payload: new_request,
    };

};
export function update_emp_profile(org_id, _id, profile_pic, profile_pic2, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access) {
    return dispatch => {
        //   console.log(Emp_Id)
        dispatch(setLoader(true));
        if (profile_pic2 == null) {
            dispatch(update_employee(org_id, _id, profile_pic, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access));

        } else {
            const storageRef = ref(getStorage(), "/Profile/" + _id + Math.random() + ".png")
            const uploadTask = uploadBytesResumable(storageRef, profile_pic2);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic2) {
                        // console.log(downloadURL)
                        if (getDownloadURL(uploadTask.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(update_employee(org_id, _id, profile_pic2, first_name, last_name, emp_id, position, joining_date, mobile, email_id, access));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
    }
}